import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { getUserByFilterData, setUserSearchData } from "../../Redux/Actions/UserRegistration";
import AgentSearchChip from "./AgentSearchChip";

const useStyles = makeStyles(styles);

function SimpleDialog(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.seacrhChipData({
      Name: "",
      Current_Status: "",
      Role: "",
      searchAgentId: "",
    });
    const allState = { ...props.tableData.searchDetails };
    allState.Name = "";
    allState.Role = "";
    props.seacrhChipData(allState);
    props.getUserByFilterData(allState);
    props.setUserSearchData(allState);
    props.close();
  };

  const onChange = (value, type) => {
    const allState = { ...props.tableData.searchDetails };
    allState[type] = value;
    props.setUserSearchData(allState);
  };

  const onFormSubmit = () => {
    props.seacrhChipData(props.tableData.searchDetails);
    const allState = { ...props.tableData.searchDetails };
    allState.pageNo = 1;
    props.setUserSearchData(allState);
    props.getUserByFilterData(allState);
    props.close();
  };

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize,
              },
            }}
            autoComplete="off"
            label="Name / Email / Phone"
            value={props.tableData.searchDetails.Name}
            onChange={e => onChange(e.target.value, "Name")}
            name="Name"
            id="Name"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <AgentSearchChip
          onChange={(value, type) => onChange(value, type)}
          values={props.tableData.searchDetails}
        />
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize,
              },
            }}
            label="Role Name"
            value={props.tableData.searchDetails.Role}
            onChange={e => onChange(e.target.value, "Role")}
            name="Roll"
            id="Roll"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.margin} style={{ marginBottom: "8%" }}>
        <Grid item xs textAlign="right">
          <br />
          <Button
            cancel
            variant="contained"
            className={classes.clearButton}
            disabled={
              props.tableData.searchDetails.Current_Status === ""
              && props.tableData.searchDetails.Role === ""
              && props.tableData.searchDetails.Name === ""
              && props.tableData.searchDetails.searchAgentId === ""
            }
            size="md"
            onClick={() => handleClose()}
          >
            clear
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            disabled={
              props.tableData.searchDetails.Current_Status === ""
              && props.tableData.searchDetails.Role === ""
              && props.tableData.searchDetails.Name === ""
              && props.tableData.searchDetails.searchAgentId === ""
            }
            size="md"
            onClick={() => onFormSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  close: PropTypes.func,
  seacrhChipData: PropTypes.func,
  getUserByFilterData: PropTypes.func,
  setUserSearchData: PropTypes.func,
  tableData: PropTypes.object,
};

const mapStateToProps = app => ({
  formData: app.users,
  tableData: app.users,
});

export default connect(mapStateToProps, {
  getUserByFilterData,
  setUserSearchData,
})(SimpleDialog);
