import {
  grayColor,
} from "assets/jss/material-dashboard-react";

const dashbordStyle = {
  backgroudColor: {
    color: "#212123a6",
    fontSize: "18px",
    fontFamily: "Verdana",
    fontWeight: "400",
    backgroundColor: "#f0ffef",
    marginTop: "1%",
  },
  clearButton: {
    backgroundColor: "#868686",
    height: "30px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#868686",
    },
  },
  noBackgroundColor: {
    color: "#212123a6",
    fontSize: "18px",
    fontFamily: "Verdana",
    fontWeight: "300",
    marginTop: "1%",
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  MuiExpanded: {
    margin: "0px",
  }
};

export default dashbordStyle;
