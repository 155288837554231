/* eslint-disable global-require */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  cellText: {
    fontSize: "70%",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark,
    padding: "1%",
    borderBottom: "0px",
    whiteSpace: "nowrap",
  },
  tableFooter: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    padding: "5px",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  noWrap: {
    whiteSpace: "nowrap",
    marginLeft: "10%",
    borderBottom: "0px",
  },
  patientDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  patientNameColor: {
    fontSize: "70%",
    whiteSpace: "nowrap",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.main,
    borderBottom: "0px",
    padding: "1%",
  },
  more: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "5px",
    paddingRight: "10px",
  },
  mailLinkColor: {
    color: "#9a9a9a",
    fontSize: "13px",
    fontFamily: "Calibri",
    fontWeight: "bold",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  margin: {
    width: "100%",
  },
  searchContainer: {
    display: "flex",
    paddingLeft: "10px",
    flexDirection: "row",
    padding: "1%",
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
  },
  searchWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  root: {
    width: "30%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif",
    },
  },
  overLayBox: {
    position: "absolute",
    width: "35%",
    marginTop: "4%",
    padding: "1%",
    zIndex: 5,
    cursor: "pointer",
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: "1px 1px 2px white, 0 0 25px #d6d0d0, 0 0 5px #737373",
    marginRight: "5%",
  },
}));

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function createData(ehrId, patientName, closeDate, downPay, monthlyPay) {
  return {
    ehrId, patientName, closeDate, downPay, monthlyPay,
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const {
    count, page, rowsPerPage, onChangePage,
  } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <Typography>Last</Typography> : <Typography>First</Typography>}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <Typography>Previous</Typography> : <Typography>Previous</Typography>}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
      >
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <Typography>Next</Typography>}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <Typography>Last</Typography>}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function ActivePatient(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);

  if (props.dashboardData.recentAcceptOffer) {
    rows = [];
    props.dashboardData.recentAcceptOffer.data.forEach((v, i) => {
      const ehrId = v.offerNumber;
      const patientName = v.patientName;
      const closeDate = v.endDate;
      const downPay = v.downPay;
      const monthlyPay = v.monthlyPay;
      rows.push(createData(ehrId, patientName, closeDate, downPay, monthlyPay));
    });
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellText} />
              <TableCell className={classes.cellText} align="center">OFFER NUMBER</TableCell>
              <TableCell className={classes.cellText} align="center">CLOSE DATE</TableCell>
              <TableCell className={classes.cellText} align="center">DOWN PAY</TableCell>
              <TableCell className={classes.cellText} align="center">MONTHLY PAY</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map(row => (
              <TableRow>
                <TableCell className={classes.cellText}>
                  <div className={classes.patientDetails}>
                    <Avatar alt="Remy Sharp" src={require("assets/img/marc.jpg")} className={classes.small} />
                    <div className={classes.noWrap}>
                      {row.patientName}
                    </div>
                  </div>
                </TableCell>
                <TableCell className={classes.patientNameColor} align="center">{row.ehrId}</TableCell>
                <TableCell className={classes.cellText} align="center">{row.closeDate}</TableCell>
                <TableCell className={classes.cellText} align="center">{row.downPay}</TableCell>
                <TableCell className={classes.cellText} align="center">{row.monthlyPay}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.more}>
          <a className={classes.mailLinkColor} href="/admin/activePatient">
            More
            {">>"}
          </a>
        </div>
      </TableContainer>
    </>
  );
}

ActivePatient.propTypes = {
  dashboardData: PropTypes.object,
};

export default connect(null, null)(ActivePatient);
