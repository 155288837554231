/* eslint-disable import/extensions */
import React from "react";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { useSnackbar } from "notistack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { actionPermission } from "../../Utils/commonUtils";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { sendMailAgain } from "actions/MerchantSignUp";
import {
  getProviderDetailsById,
  changeProviderFormMode,
  getProviderActivityById,
  getProviderParameterCall
} from "../../Redux/Actions/ProvideRegistration";

const useStyles = makeStyles(styles);

const merchantsCatId = "62c28d681bc249cb83529a32";

const merchantsLivePageId = "62c28d681bc249cb83529a33";

const merchantsInprogessPageId = "62c28d681bc249cb83529a34";

const defaults = {
  view: 1,
  create: 2,
  edit: 3,
  changeStatus: 4,
  allowToBoard: 5,
  allowToChecklist: 6,
  allowToLive: 7
};

function LongMenu(props) {
  const classes = useStyles();
  const path = window.location.pathname.split("/")[2];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alterOpen, setAlertOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);
  const PROVIDER_STATUS_CODE =
    process.env.REACT_APP_PROVIDER_STATUS_CODE.split(",");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    props.setValues((pre) => {
      pre.status = props.details.Current_Status;
      return pre;
    });
  };

  const resendMailApiCall = () => {
    props.sendMailAgain(
      { email: props.details.E_mail },
      (data) => {
        if (data.status) {
          enqueueSnackbar(`${data.message}`, {
            variant: "success"
          });
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
        handleClose();
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
        handleClose();
      }
    );
  };

  const handleClose = (type) => {
    if (type === "edit") {
      props.getProviderDetailsById(props.details.Id);
      props.changeProviderFormMode("Edit");
    }
    setAnchorEl(null);
  };

  const openStatusForm = () => {
    props.setValues((pre) => {
      pre.status = props.details.Current_Status;
      return pre;
    });
    props.openForm();
    props.setStatusPid();
    handleClose();
  };

  const activity = () => {
    const data = {
      providerId: props.details.Id
    };
    props.getProviderActivityById(data);
    props.history.push(`/${props.userDetails.layout}/merchantactivity`);
  };

  const handleProviderParameter = () => {
    const data = {
      providerId: props.details.Id
    };
    props.getProviderParameterCall(data);
    props.history.push(`/${props.userDetails.layout}/providerParameter`);
  };

  const openStatusChange = () => {
    const data = {
      id: props.details.Id,
      status: 13
    };
    props.setValues(data);
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleAlertModalClose = () => {
    props.saveStatus();
    setAlertOpen(false);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        style={{ padding: "0px" }}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.dialogBoxDropDown}
          onClick={() => handleClose("edit")}
        >
          Edit
        </MenuItem>
        <MenuItem className={classes.dialogBoxDropDown} onClick={handleClose}>
          View
        </MenuItem>

        {(props.details.Current_Status === 1 ||
          props.details.Current_Status === 10) && (
          <MenuItem
            className={classes.dialogBoxDropDown}
            onClick={() => openStatusChange()}
          >
            Cancel App
          </MenuItem>
        )}

        {/* {props.provider ? props.userOptions && props.userOptions.permission && props.details.status !== "New Entry" && <MenuItem
          className={classes.dialogBoxDropDown}
          onClick={() => openStatusForm()}
        >
          Change Status
        </MenuItem> : <MenuItem
          className={classes.dialogBoxDropDown}
          onClick={() => openStatusForm()}
        >
          Change Status
        </MenuItem>} */}
        {props.provider && (
          <>
            <MenuItem
              className={classes.dialogBoxDropDown}
              onClick={() => activity()}
            >
              Activity History
            </MenuItem>
            {PROVIDER_STATUS_CODE.some(
              (x) => x === props.details.Current_Status.toString()
            ) &&
              actionPermission(
                props.roles,
                merchantsCatId,
                path === "Live"
                  ? merchantsLivePageId
                  : merchantsInprogessPageId,
                defaults.edit
              ) && (
                <>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    onClick={() => handleProviderParameter()}
                  >
                    Set Configuration
                  </MenuItem>
                </>
              )}
          </>
        )}
        {props.details.Client_Id &&
          actionPermission(
            props.roles,
            merchantsCatId,
            path === "Live" ? merchantsLivePageId : merchantsInprogessPageId,
            defaults.edit
          ) && (
            <MenuItem
              className={classes.dialogBoxDropDown}
              onClick={() => {
                props.history.push({
                  pathname: `/${props.userDetails.layout}/panelConfigurations`,
                  state: { details: props.details }
                });
              }}
            >
              Portal Setup
            </MenuItem>
          )}
        {props.provider &&
        actionPermission(
          props.roles,
          merchantsCatId,
          path === "Live" ? merchantsLivePageId : merchantsInprogessPageId,
          defaults.edit
        ) ? (
          props.userOptions &&
          props.userOptions.permission &&
          actionPermission(
            props.roles,
            merchantsCatId,
            path === "Live" ? merchantsLivePageId : merchantsInprogessPageId,
            defaults.edit
          ) && (
            <MenuItem
              className={classes.dialogBoxDropDown}
              onClick={resendMailApiCall}
            >
              Send merchant signup link
            </MenuItem>
          )
        ) : (
          <MenuItem
            className={classes.dialogBoxDropDown}
            onClick={resendMailApiCall}
          >
            Send merchant signup link
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={alterOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancel App</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel this app? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            No
          </Button>
          <Button onClick={handleAlertModalClose} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

LongMenu.propTypes = {
  details: PropTypes.object,
  getProviderDetailsById: PropTypes.func,
  history: PropTypes.any,
  changeProviderFormMode: PropTypes.func,
  openForm: PropTypes.func,
  setStatusPid: PropTypes.func,
  userDetails: PropTypes.object,
  provider: PropTypes.bool,
  getProviderActivityById: PropTypes.func,
  getProviderParameterCall: PropTypes.func,
  setValues: PropTypes.func,
  userOptions: PropTypes.object,
  sendMailAgain: PropTypes.func,
  roles: PropTypes.object,
  saveStatus: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  roles: app.app
});

export default connect(mapStateToProps, {
  getProviderDetailsById,
  changeProviderFormMode,
  getProviderActivityById,
  sendMailAgain,
  getProviderParameterCall
})(LongMenu);
