import React, { useRef, useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import ListIcon from '@material-ui/icons/List';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import exportCss from '../../assets/css/amchart/export';

export default function MemberRegistrations() {
	am4core.useTheme(am4themes_animated);

  	var active_patients = '{"Data": [{ "Active_Patients": [{"month_year": "December-2019", "total_count": 24},{"month_year": "January-2020", "total_count": 6},{"month_year": "February-2020", "total_count": 9},{"month_year": "March-2020", "total_count": 12},{"month_year": "April-2020", "total_count": 15},{"month_year": "May-2020", "total_count": 22},{"month_year": "June-2020", "total_count": 27},{"month_year": "July-2020", "total_count": 31},{"month_year": "August-2020", "total_count": 31},{"month_year": "September-2020", "total_count": 30},{"month_year": "October-2020", "total_count": 31},{"month_year": "November-2020", "total_count": 28}]}]}';
  useEffect(() => {
    if (document.getElementById('memberchart') !== null) {
		let chart = am4core.create("memberchart", am4charts.PieChart3D);
		chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chart.angle = 40;
		chart.depth = 45;

		let title = chart.titles.create();
		title.text = "\nTPO Level Distributions";
		title.fontSize = 13;
		title.fontFamily="Calibri";
		title.paddingBottom = 10;
		title.align = "center";
		title.fontWeight = "bold";

		chart.data = [
		{
			tpoLevel: "Level-1",
			count: 100
		},
		{
			tpoLevel: "Level-2",
			count: 80
		},
		{
			tpoLevel: "Level-3",
			count: 70
		},
		{
			tpoLevel: "Level-4",
			count: 65
		},
		{
			tpoLevel: "Level-5",
			count: 55
		},
		{
			tpoLevel: "Level-6",
			count: 50
		},
		{
			tpoLevel: "Level-7",
			count: 35
		},
		{
			tpoLevel: "Level-8",
			count: 25
		},
		{
			tpoLevel: "Level-9",
			count: 20
		},
		{
			tpoLevel: "Level-10",
			count: 10
		}
		];

		let series = chart.series.push(new am4charts.PieSeries3D());
		series.dataFields.value = "count";
		series.dataFields.category = "tpoLevel";
		series.labels.template.disabled = true;
		series.marginLeft = 500;
		let colorSet = new am4core.ColorSet();
		colorSet.list = ["#c6f700", "#f60102", "#c603f7", "#0262f7", "#03f766"].map(function(color) {
			return new am4core.color(color);
		});
		series.colors = colorSet;
		series.slices.template.tooltipHTML = `<table style="font-size:10px;">
		   	<tr">
		   		<center style="font-size:12px; word-wrap: break-all;">{tpoLevel}</center>
		   		<hr />
		 	</tr>
			<tr align="center">
		   		<center style="text-align: center; width: 100% !important; font-size: 14px;"><b>{count}</b> ({value.percent.formatNumber('#.0')}%)</center>
			</tr>
		   
		   </table>`;

		let titles = chart.titles.push(new am4core.Label());
		titles.textAlign = "middle";
		titles.wrap = true;

		//chart.innerRadius = am4core.percent(40);
		//chart.depth = 30;

		chart.legend = new am4charts.Legend();
		chart.legend.fontWeight = 400;
		chart.legend.position = "right";
		chart.legend.marginRight = 100;
		//chart.legend.marginLeft = 200;
		chart.legend.markers.template.paddingLeft = "2";
		chart.legend.markers.template.paddingTop = "2";
		chart.legend.markers.template.paddingRight = "2";
		chart.legend.markers.template.paddingBottom = "2";
		series.legendSettings.valueText = " ";

		
		chart.radius = "100%";
		chart.angle = 30;
		chart.responsive = new am4core.Responsive();
		chart.responsive.enabled = true;
		chart.logo.disabled = true;


		/* suresh's changes */


		chart.legend.fontSize=10;
		chart.legend.useDefaultMarker = true;
		var marker = chart.legend.markers.template.children.getIndex(0);
		marker.cornerRadius(12, 12, 12, 12);
		marker.strokeWidth = 2;
		marker.strokeOpacity = 1;
		marker.stroke = am4core.color("#ccc");
		chart.tooltip.label.fill = am4core.color("#f00");
		var markerTemplate = chart.legend.markers.template;
		markerTemplate.width = 10;
		markerTemplate.height = 10;
		 

/* ************************** */


    }
  }, []);
  return (
      <div id="memberchart" style={{ width: "100%", height: "20rem" }} />
  );
}

