const status = {
  newEntry: 1,
  sendForUnderwriting: 2,
  approve: 6,
  sendForBording: 7,
  manualReview: 8,
  underReview: 4,
  sendToMerchant: 10,
  decline: 5,
  appSubmitted: 3,
  live: 9,
};

export const showCheckList = (props, values) => {
  if (props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    let show = true;
    if (props.mainData.roles.roleTab) {
      const filterSetUp = props.mainData.roles.roleTab.Roles_Data.LeftMenu.filter(
        key => key.catCode === 2
      );
      const filterPageMenu = filterSetUp.length > 0
        && filterSetUp[0].Page_Menu.filter(key => key.Page_Code === 1);
      const filterOption = filterPageMenu
        && filterPageMenu[0]?.Options?.filter(key => key?.value === 6);
      if (filterOption) {
        show = filterOption[0].permission;
      }
      if (
        preData.Current_Status === 1
        || preData.Current_Status === 10
        || preData.Current_Status === 5
      ) {
        show = false;
      }
    }
    if (
      preData.Current_Status === status.live
      || preData.Current_Status === status.decline
    ) {
      show = false;
    }
    if (!props.mainData.roles.roleTab) {
      show = false;
    }
    return show;
  }
};

export const changeStatusPermission = (props, values, statusData) => {
  if (props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    let allowChangeStatus = false;
    if (props.mainData.roles.roleTab) {
      const filterSetUp = props.mainData.roles.roleTab.Roles_Data.LeftMenu.filter(
        key => key.catCode === 2
      );
      const filterPageMenu = filterSetUp.length > 0
        && filterSetUp[0].Page_Menu.filter(key => key.Page_Code === 1);
      const filterOption = filterPageMenu
        && filterPageMenu[0]?.Options?.filter(key => key?.value === 4);
      if (filterOption) {
        allowChangeStatus = !filterOption[0].permission;
        if (filterOption[0].permission) {
          if (statusData) {
            const filterStatus = statusData.filter(
              key => key.Status_Code === preData.Current_Status
            );
            if (filterStatus.length) {
              allowChangeStatus = filterStatus[0].statusChangeAllowed;
            }
          }
        }
      }
    }
    return allowChangeStatus;
  }
};

export const checkFormDisabled = (props, values, statusData) => {
  if (props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    let allowChangeStatus = false;
    if (props.mainData.roles.roleTab) {
      const filterSetUp = props.mainData.roles.roleTab.Roles_Data.LeftMenu.filter(
        key => key.catCode === 2
      );
      const filterPageMenu = filterSetUp.length > 0
        && filterSetUp[0].Page_Menu.filter(key => key.Page_Code === 1);
      const filterOption = filterPageMenu
        && filterPageMenu[0]?.Options?.filter(key => key?.value === 4);
      if (filterOption) {
        allowChangeStatus = !filterOption[0].permission;
        if (filterOption[0].permission) {
          if (statusData) {
            const filterStatus = statusData.filter(
              key => key.Status_Code === preData.Current_Status
            );
            if (filterStatus.length) {
              allowChangeStatus = filterStatus[0].agentReadOnly;
            }
          }
        }
      }
    } else if (!props.mainData.roles.roleTab) {
      if (statusData) {
        const filterStatus = statusData.filter(
          key => key.Status_Code === preData.Current_Status
        );
        if (filterStatus.length) {
          allowChangeStatus = filterStatus[0].merchantReadOnly;
        }
      }
    }
    return allowChangeStatus;
  }
};

export const disbaleAndHide = (props, values) => {
  let hide = false;
  if (props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    if (preData.Current_Status === status.appSubmitted) {
      hide = true;
    }
  }
  return hide;
};

export const disableSaveDraftButton = (props, values) => {
  let disabled = false;
  if (props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    if (preData.Current_Status === status.appSubmitted) {
      disabled = true;
    }
    if (preData.acceptTermsCondition) {
      disabled = true;
    }
  }
  return disabled;
};
