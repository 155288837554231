/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import isEmpty from "lodash/isEmpty";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ChipInput from "material-ui-chip-input";
import { exportToCSV } from "../../Utils/commonUtils";
import noDataImage from "../../assets/img/noDataImage.png";
import { activePatient } from "../../Redux/Actions/TableAction";
import { SimpleDialog } from "../../components/DialogBox/Index";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { getAgentByHierarchy } from "../../Redux/Actions/ProvideRegistration";

const useStyles = makeStyles(tableStyle);

function createData(
  providerId,
  userCode,
  mid,
  DBA_Name,
  Crypto_Name,
  Crypto_Units,
  Currency,
  cryptoValue,
  transactionDate,
  card,
  amount,
  status,
  id
) {
  return {
    providerId,
    userCode,
    mid,
    DBA_Name,
    Crypto_Name,
    Crypto_Units,
    Currency,
    cryptoValue,
    transactionDate,
    card,
    amount,
    status,
    id,
  };
}

const ActivePatient = props => {
  const classes = useStyles();
  const userid = localStorage.getItem("userId");
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [initialCall, setInitialCall] = React.useState(false);
  const [sortColumn, setSortColumn] = React.useState("Transaction_Date");
  const [orderBy, setOrderBy] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState("desc");
  const [selected, setSelected] = React.useState([]);
  const [recordPerPage, setRecordPerPage] = React.useState(10);
  const [pageNo, setPageNo] = React.useState(1);
  const [chipValue, setChipValue] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [searchVlaues, setSearchValues] = React.useState({
    MidOrDba: "",
    cryptoName: "",
    maxAmount: "",
    minAmount: "",
    fromDate: moment().startOf("month").format("MM/DD/YYYY"),
    toDate: moment().format("MM/DD/YYYY"),
    searchAgentId: "",
    recordPerPage: 10,
    sortColumn: "",
    sortOrder: "",
    pageNo: 1,
  });

  React.useEffect(() => {
    const filterData = { ...searchVlaues };
    filterData.sortColumn = sortColumn;
    filterData.sortOrder = sortOrder;
    filterData.recordPerPage = recordPerPage;
    filterData.pageNo = pageNo;
    props.activePatient(filterData);
  }, [recordPerPage, pageNo, sortColumn, sortOrder]);

  React.useEffect(() => {
    const data = {
      userID: userid,
    };
    if (!props.tableData.agentList) {
      props.getAgentByHierarchy(data);
    }
  }, []);

  React.useEffect(() => {
    if (
      props.tableData.agentList
      && props.tableData.agentList.data
      && props.tableData.agentList.data
    ) {
      setChipValue([
        `Transaction Date (From): ${moment()
          .startOf("month")
          .format("MM/DD/YYYY")}`,
        `Transaction Date (To): ${moment().format("MM/DD/YYYY")}`,
      ]);
    }
  }, [props.tableData.agentList]);

  function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const {
      count, page, rowsPerPage, onChangePage,
    } = props;

    const handleFirstPageButtonClick = event => {
      setPageNo(1);
      onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
      setPageNo(pageNo - 1);
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
      setPageNo(pageNo + 1);
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
      setPageNo(Math.max(0, Math.ceil(count / rowsPerPage)));
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.rootPagination}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Last</Typography>
          ) : (
            <Typography>First</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Previous</Typography>
          ) : (
            <Typography>Previous</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleFirstPageButtonClick}
          aria-label="first page"
          className={classes.bottonPage}
        >
          <Typography>{page + 1}</Typography>
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <Typography>Next</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? (
            <FirstPageIcon />
          ) : (
            <Typography>Last</Typography>
          )}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  function EnhancedTableHead(props) {
    const {
      classes, sortOrder, orderBy, onRequestSort,
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    const headCells = [
      {
        id: "MID",
        numeric: false,
        disablePadding: true,
        label: "MID",
        // headClassName: classes.DBA_Name,
      },
      {
        id: "userCode",
        numeric: false,
        disablePadding: true,
        label: "User Code",
        // headClassName: classes.E_mail,
      },
      {
        id: "DBA_Name",
        numeric: false,
        disablePadding: true,
        label: "DBA Name",
        // headClassName: classes.DBA_Name,
      },
      {
        id: "Crypto_Name",
        numeric: false,
        disablePadding: false,
        label: "Crypto Name",
        // headClassName: classes.Crypto_Name,
      },
      {
        id: "currency",
        numeric: false,
        disablePadding: false,
        label: "Currency",
        // headClassName: classes.Currency
      },
      {
        id: "Crypto_Units",
        numeric: false,
        disablePadding: false,
        label: "Crypto Units",
        // headClassName: classes.OwnershipType,
      },
      {
        id: "Crypto_Value",
        numeric: true,
        disablePadding: false,
        label: "Exchange Rate",

        // headClassName: classes.Phone,
      },
      {
        id: "Transaction_Date",
        numeric: true,
        disablePadding: false,
        label: "Transaction Date",
        // headClassName: classes.Creation_Date,
      },
      {
        id: "Amount",
        numeric: false,
        disablePadding: false,
        label: "Amount",
        headClassName: classes.amount,
      },
      {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        headClassName: classes.status,
      },
    ];

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              className={classes.cellTextHeaderOffer}
              align="left"
              sortDirection={orderBy === headCell.id ? sortOrder : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? sortOrder : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    sortOrder: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  React.useEffect(() => {
    if (props.tableData.activePatient?.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData]);

  React.useEffect(() => {
    if (props.tableData.activePatient && props.tableData.activePatient.data) {
      const tepmData = [];
      props.tableData.activePatient.data.forEach((v, i) => {
        const providerId = v.Provider_Id;
        const userCode = v.userCode;
        const mid = v.MID;
        const DBA_Name = v.DBA_Name;
        const Crypto_Name = v.Crypto_Name;
        const Currency = v.Currency;
        const Crypto_Units = v.Crypto_Units;
        const cryptoValue = v.Crypto_Value;
        const transactionDate = moment(v.Transaction_Date).format(
          "MM/DD/YYYY HH:MM:ss"
        );
        const card = v.Card;
        const amount = v.Amount;
        const status = v.status;
        const id = v.Transaction_Id;
        tepmData.push(
          createData(
            providerId,
            userCode,
            mid,
            DBA_Name,
            Crypto_Name,
            Crypto_Units,
            Currency,
            cryptoValue,
            transactionDate,
            card,
            amount,
            status,
            id
          )
        );
      });
      setRows([...tepmData]);
    }
  }, [props.tableData.activePatient.data]);

  const exportListData = () => {
    if (!isEmpty(rows)) {
      const mappedLogs = rows.map(row => ({
        MID: row.MID,
        "User Code": row.userCode,
        "DBA Name": row.DBA_Name,
        "Crypto Name": row.Crypto_Name,
        Currency: row.Currency,
        "Crypto Units": row.Crypto_Units,
        "Crypto Value ($)": row.cryptoValue,
        Amount: row.amount,
        "Wallet Address": row.Card,
        "Transaction Date": row.Transaction_Date,
        "Transaction Id": row.Transaction_Id,
        "Provider Id": row.Provider_Id,
      }));
      exportToCSV(mappedLogs, "Transactions Report");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRecordPerPage(parseInt(event.target.value, 10));
    setPageNo(1);
    setPage(0);
    const preSearchValue = { ...searchVlaues };
    preSearchValue.recordPerPage = parseInt(event.target.value, 10);
    setSearchValues(preSearchValue);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortColumn(property);
  };

  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      const chipName = chip.split(":")[0];
      const newChipValues = [...chipValue];
      newChipValues.splice(index, 1);
      setChipValue([...newChipValues]);
      setSearchValues(searchVlaues);
      const newSearchValue = {
        ...searchVlaues,
        sortColumn,
        sortOrder,
        recordPerPage,
        pageNo,
      };
      if (chipName === "Minimum Amount") {
        newSearchValue.minAmount = "";
      } else if (chipName === "MidOrDba") {
        newSearchValue.MidOrDba = "";
      } else if (chipName === "Crypto Name") {
        newSearchValue.cryptoName = "";
      } else if (chipName === "Maximum Amount") {
        newSearchValue.maxAmount = "";
      } else if (chipName === "Transaction Date (From)") {
        newSearchValue.fromDate = "";
      } else if (chipName === "Transaction Date (To)") {
        newSearchValue.toDate = "";
      } else if (chipName === "Agent") {
        newSearchValue.searchAgentId = "";
      }
      setSearchValues({ ...newSearchValue });
      props.activePatient({ ...newSearchValue });
    }
  };

  const getActivePatientByFilterDataHandle = value => {
    setPageNo(1);
    setPage(0);
    const finalSearchData = {
      ...value,
      sortColumn,
      sortOrder,
      recordPerPage,
      pageNo,
    };
    setSearchValues(finalSearchData);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (property === "MidOrDba") {
          chipArray.push(
            `${"MidOrDba"}:
                    ${value[property]}`
          );
        } else if (property === "cryptoName") {
          chipArray.push(
            `${"Crypto Name"}:
              ${value[property]}`
          );
        } else if (property === "maxAmount") {
          chipArray.push(
            `${"Maximum Amount"}:
                    ${value[property]}`
          );
        } else if (property === "minAmount") {
          chipArray.push(
            `${"Minimum Amount"}:
                ${value[property]}`
          );
        } else if (property === "fromDate") {
          chipArray.push(
            `${"Transaction Date (From)"}:
                  ${value[property]}`
          );
        } else if (property === "toDate") {
          chipArray.push(
            `${"Transaction Date (To)"}:
                          ${value[property]}`
          );
        } else if (property === "searchAgentId") {
          chipArray.push(
            `${"Agent"}:
                          ${
  props.tableData.agentList.data.filter(
    item => item.User_Id === value[property]
  )[0].Name
}`
          );
        }
      }
    }
    props.activePatient(finalSearchData);
    setChipValue(chipArray);
  };

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.searchWrapper}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ minWidth: "35%" }}>
                  <ChipInput
                    value={chipValue.length > 0 ? chipValue : []}
                    style={{ display: "flex" }}
                    classes={{ chipContainer: classes.chipContainer }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    fullWidth
                    label="Search"
                    onClick={() => handleClickOpen()}
                    name="Search"
                    id="Search"
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog
                      close={() => handleClose()}
                      searchValue={searchVlaues}
                      seacrhChipData={data => getActivePatientByFilterDataHandle(data)}
                    />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <Button
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </Button>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="contained"
                className={classes.ExportButtonStyle}
                onClick={exportListData}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "85%" }}>
                  <TextField
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    inputProps={{
                      autoComplete: "off",
                    }}
                    fullWidth
                    label="Search"
                    onClick={() => handleClickOpen()}
                    name="Search"
                    id="Search"
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog close={() => handleClose()} />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <Button
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </Button>
            </Grid>
          </Grid>
        )}
        <Table
          className={classes.table}
          aria-label="simple table"
          style={{ marginBottom: recordPerPage > 25 ? "55px" : "" }}
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            sortOrder={sortOrder}
            orderBy={sortColumn}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />

          <TableBody>
            {(!isEmpty(rows) && initialCall)
            || (isEmpty(rows) && !initialCall) ? (
                rows.map((row, index) => (
                  <TableRow>
                    <TableCell className={classes.cellText}>{row.mid}</TableCell>
                    <TableCell className={classes.cellText}>
                      {row.userCode}
                    </TableCell>
                    <TableCell className={classes.cellText}>
                      {row.DBA_Name}
                    </TableCell>
                    <TableCell className={classes.cellText}>
                      {row.Crypto_Name}
                    </TableCell>
                    <TableCell className={classes.cellText}>
                      {row.Currency}
                    </TableCell>
                    <TableCell className={classes.cellText} align="left">
                      {row.Crypto_Units}
                    </TableCell>
                    <TableCell className={classes.cellText} align="left">
                      {row.cryptoValue}
                    </TableCell>
                    <TableCell className={classes.cellText} align="center">
                      {row.transactionDate}
                    </TableCell>
                    <TableCell className={classes.cellText} align="left">
                      {row.amount}
                    </TableCell>
                    <TableCell className={classes.cellText} align="left">
                      {row.status}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={12}>
                    <img
                      src={noDataImage}
                      alt=""
                      className={classes.noDataImage}
                    />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
          <TableFooter className={recordPerPage > 25 && classes.stickyFooter}>
            <TableRow
              style={{
                display: recordPerPage > 25 ? "flex" : "",
                justifyContent: recordPerPage > 25 ? "end" : "",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: props.tableData.activePatient ? props.tableData.totCount : 0 },
                ]}
                colSpan={12}
                count={props.tableData.activePatient.totCount}
                rowsPerPage={recordPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

ActivePatient.propTypes = {
  activePatient: PropTypes.func,
  tableData: PropTypes.any,
  getAgentByHierarchy: PropTypes.func,
};

const mapStateToProps = table => ({
  tableData: table.table,
});
export default connect(mapStateToProps, {
  activePatient,
  getAgentByHierarchy,
})(ActivePatient);
