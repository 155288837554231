/* eslint-disable import/extensions */
import React from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DateFnsUtils from "@date-io/date-fns";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import DateRangeRoundedIcon from "@material-ui/icons/DateRangeRounded";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import Tooltip from "@material-ui/core/Tooltip";
import { locationCity, countryList } from "../../constant/locationConstant";

const useStyles = makeStyles(styles);

const ProccessMethod = props => {
  const classes = useStyles();

  return (
    <Box className={classes.marginBox}>
      <Grid container className={classes.margin}>
        <Grid item lg={4} sm={6} xs={12}>
          <AttachMoneyRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            error={props.validationError.cardVolumeMonth}
            helperText={
              props.validationError.cardVolumeMonth
                      && "Only numbers are allowed"
            }
            label="Monthly Card Volume"
            value={props.values.cardVolumeMonth}
            onChange={e => (/^[0-9]\d*$/g.test(e.target.value)
                        || e.target.value === "")
                      && props.onChange(e.target.value, "cardVolumeMonth")}
            name="cardVolumeMonth"
            id="cardVolumeMonth"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <AttachMoneyRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            error={props.validationError.averageTicketSize}
            helperText={
              props.validationError.averageTicketSize
                      && "Only numbers are allowed"
            }
            label="Average Ticket Size"
            value={props.values.averageTicketSize}
            onChange={e => (/^[0-9]\d*$/g.test(e.target.value)
                        || e.target.value === "")
                      && props.onChange(e.target.value, "averageTicketSize")}
            name="averageTicketSize"
            id="averageTicketSize"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <AttachMoneyRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            error={props.validationError.highTicket}
            helperText={
              props.validationError.highTicket && "Only numbers are allowed"
            }
            label="High Ticket"
            value={props.values.highTicket}
            onChange={e => (/^[0-9]\d*$/g.test(e.target.value)
                        || e.target.value === "")
                      && props.onChange(e.target.value, "highTicket")}
            name="highTicket"
            id="highTicket"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.margin}>
        <Grid item lg={4} sm={6} xs={12}>
          <AttachMoneyRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Monthly ACH/eCheck Volume"
            value={props.values.eCheckVolumeMonth}
            onChange={e => (/^[0-9]\d*$/g.test(e.target.value)
                        || e.target.value === "")
                      && props.onChange(e.target.value, "eCheckVolumeMonth")}
            name="eCheckVolumeMonth"
            id="eCheckVolumeMonth"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <AttachMoneyRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Average ACH/eCheck"
            value={props.values.eCheckAverage}
            onChange={e => (/^[0-9]\d*$/g.test(e.target.value)
                        || e.target.value === "")
                      && props.onChange(e.target.value, "eCheckAverage")}
            name="eCheckAverage"
            id="eCheckAverage"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <AttachMoneyRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="High ACH/eCheck"
            value={props.values.eCheckHigh}
            onChange={e => (/^[0-9]\d*$/g.test(e.target.value)
                        || e.target.value === "")
                      && props.onChange(e.target.value, "eCheckHigh")}
            name="eCheckHigh"
            id="eCheckHigh"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.margin}>
        <Grid item lg={4} sm={6} xs={12}>
          <StrikethroughSIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Card Present/Swiped"
            value={props.values.cardPresent}
            onChange={e => (/^[0-9]\d*$/g.test(e.target.value)
                        || e.target.value === "")
                      && props.onChange(e.target.value, "cardPresent")}
            name="cardPresent"
            id="cardPresent"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StrikethroughSIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Card Not Present/Keyed"
            value={props.values.cardNotPresent}
            onChange={e => (/^[0-9]\d*$/g.test(e.target.value)
                        || e.target.value === "")
                      && props.onChange(e.target.value, "cardNotPresent")}
            helperText={
              props.validationError.cardNotPresent && "Value should be 1-100"
            }
            name="cardNotPresent"
            id="cardNotPresent"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StrikethroughSIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="E-Commerce"
            value={props.values.eCommers}
            // error={props.validationError.eCommers}
            onChange={e => (/^[0-9]\d*$/g.test(e.target.value)
                        || e.target.value === "")
                      && props.onChange(e.target.value, "eCommers")}
            helperText={
              props.validationError.eCommers && "Value should be 1-100"
            }
            name="eCommers"
            id="eCommers"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

ProccessMethod.propTypes = {
  disabled: PropTypes.bool,
  validationError: PropTypes.object,
  values: PropTypes.object,
  onChange: PropTypes.func,
};

const mapStateToProps = app => ({
  formData: app.provideRegistration,
});
export default connect(mapStateToProps, null)(ProccessMethod);
