/* eslint-disable import/extensions */
import React from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DateFnsUtils from "@date-io/date-fns";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import DateRangeRoundedIcon from "@material-ui/icons/DateRangeRounded";
import styles from "assets/jss/material-dashboard-react/components/businessForm.js";
import PersonIcon from "@material-ui/icons/Person";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import NearMeRoundedIcon from "@material-ui/icons/NearMeRounded";
import RoomIcon from "@material-ui/icons/Room";
import Table from "@material-ui/core/Table";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import StarIcon from "@material-ui/icons/Star";
import AddIcon from "@material-ui/icons/Add";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import Tooltip from "@material-ui/core/Tooltip";
import { locationCity, countryList } from "../../constant/locationConstant";
import { formatPhoneNumber } from "../../Utils/commonUtils";
import isEmpty from "lodash/isEmpty";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(styles);

const OwnerInformation = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [stateInput, setStateInput] = React.useState(true);
  const [photoIdType, setPhotoIdType] = React.useState(false);
  const [biometricIdType, setBiometricIdType] = React.useState(false);
  const [addressFileType, setAddressFileType] = React.useState(false);

  const handleSSNFocus = (e) => {
    e.preventDefault();
    e.target.select();
  };

  const form = (
    <div>
      <Grid container className={classes.margin}>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            error={props.validationError.Owner_First_Name}
            helperText={
              props.validationError.Owner_First_Name &&
              "Owner First name is required"
            }
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            label="First Name"
            value={props.data.Owner_First_Name}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_First_Name")
            }
            name="Owner_First_Name"
            id="Owner_First_Name"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Last Name"
            value={props.data.Owner_Last_Name}
            error={props.validationError.Owner_Last_Name}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Last_Name")
            }
            helperText={
              props.validationError.Owner_Last_Name &&
              "Owner last name is required"
            }
            name="Owner_Last_Name"
            id="Owner_Last_Name"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <MenuBookRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            inputProps={{
              autoComplete: "off",
              maxLength: 11
            }}
            required
            label="Social Security Number (SSN)"
            value={props.data.Owner_Last4_SSN}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Last4_SSN", e)
            }
            name="Owner_Last4_SSN"
            id="Owner_Last4_SSN"
            error={props.validationError.Owner_Last4_SSN}
            onFocus={(e) => handleSSNFocus(e)}
            helperText={
              props.validationError.Owner_Last4_SSN &&
              "Owner SSN Number should be 9 Digits"
            }
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DateRangeRoundedIcon className={classes.inputBox} fontSize="small" />
          <FormControl className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                clearable
                disabled={props.disabled}
                inputVariant="standard"
                onChange={(e) => {
                  if (e === null) {
                    props.handleOwnerChange(e, "Owner_DOB");
                  } else if (e > new Date()) {
                    props.handleOwnerChange(e, "Owner_DOB");
                  } else {
                    props.handleOwnerChange(
                      moment(e).format("MM/DD/YYYY"),
                      "Owner_DOB"
                    );
                  }
                }}
                value={
                  props.data.Owner_DOB
                    ? moment(props.data.Owner_DOB).format("YYYY-MM-DD")
                    : null
                }
                required
                label="Date Of Birth"
                id="birthday"
                name="birthday"
                maxDate={moment().subtract(18, "years")._d}
                maxDateMessage="Age must be atleast 18 years."
                format="MM/dd/yyyy"
                placeholder="MM/DD/YYYY"
                keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                KeyboardButtonProps={{
                  size: "small"
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  },
                  endAdornment: (
                    <Tooltip title="cds_DateHelp" placement="top">
                      <IconButton edge="end" size="small">
                        <HelpOutlineOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                error={props.validationError.Owner_DOB}
                helperText={
                  props.validationError.Owner_DOB &&
                  "Owner DOB is required with atleast 18 years of age."
                }
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StrikethroughSIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Ownership Percentage"
            value={props.data.Owner_Ownership_Percentage}
            onChange={(e) =>
              props.handleOwnerChange(
                e.target.value,
                "Owner_Ownership_Percentage"
              )
            }
            error={props.validationError.Owner_Ownership_Percentage}
            helperText={
              props.data.Owner_Ownership_Percentage === ""
                ? "Ownership Percentage(%) is required."
                : props.validationError.Owner_Ownership_Percentage &&
                  "Invalid Percentage(%) of Ownership."
            }
            name="Owner_Ownership_Percentage"
            id="Owner_Ownership_Percentage"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <MenuBookRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Title"
            value={props.data.Owner_Title}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Title")
            }
            error={props.validationError.Owner_Title}
            helperText={
              props.validationError.Owner_Title && "Owner title is required"
            }
            name="Owner_Title"
            id="Owner_Title"
            select
          >
            <MenuItem className={classes.dialogBoxDropDown} value="Analyst">
              Analyst
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="CEO">
              CEO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="COO">
              COO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="CFO">
              CFO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="CTO">
              CTO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Director">
              Director
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Manager">
              Manager
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Other">
              Other
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Owner">
              Owner
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Supervisor">
              Supervisor
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StayCurrentPortraitIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Phone"
            value={formatPhoneNumber(props.data.Owner_Phone)}
            error={props.validationError.Owner_Phone}
            helperText={
              props.validationError.Owner_Phone &&
              "Owner phone number should be 10 Digits"
            }
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Phone")
            }
            name="Owner_Phone"
            id="Owner_Phone"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <HomeRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Street Address (No P.O. Box)"
            value={props.data.Owner_Street_Address}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Street_Address")
            }
            error={props.validationError.Owner_Street_Address}
            helperText={
              props.validationError.Owner_Street_Address &&
              "Owner street address is required"
            }
            name="Owner_Street_Address"
            id="Owner_Street_Address"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <MoreHorizRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.Owner_Suite}
            helperText={
              props.validationError.Owner_Suite && "Owner suite is required"
            }
            label="Suite"
            value={props.data.Owner_Suite}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Suite")
            }
            name="Owner_Suite"
            id="Owner_Suite"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <AddIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.Owner_Country_Code}
            helperText={
              props.validationError.Owner_Country_Code && "Country is required"
            }
            label="Country"
            value={props.data.Owner_Country_Code}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Country_Code")
            }
            name="Owner_Country_Code"
            id="Owner_Country_Code"
            select
          >
            {[
              {
                Country_Code: "US",
                Country_Name: "United States",
                Dial_Code: 1
              }
            ].map((key) => (
              <MenuItem
                className={classes.dialogBoxDropDown}
                value={key.Country_Code}
              >
                {key.Country_Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <NearMeRoundedIcon className={classes.inputBox} fontSize="small" />
          {props.data.Owner_Country_Code === "US" ? (
            <TextField
              disabled={props.disabled}
              required
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              label="State"
              value={props.data.Owner_State_Code}
              onChange={(e) =>
                props.handleOwnerChange(e.target.value, "Owner_State_Code")
              }
              name="Owner_State_Code"
              id="Owner_State_Code"
              select={stateInput}
              error={props.validationError.Owner_State_Code}
              helperText={
                props.validationError.Owner_State_Code && "State is required"
              }
            >
              {stateInput &&
                locationCity.map((key) => (
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value={key.value}
                  >
                    {key.name}
                  </MenuItem>
                ))}
            </TextField>
          ) : (
            <TextField
              disabled={props.disabled}
              required
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              error={props.validationError.Owner_State_Code}
              helperText={
                props.validationError.Owner_State_Code && "State is required"
              }
              label="State"
              value={props.data.Owner_State_Code}
              onChange={(e) =>
                props.handleOwnerChange(e.target.value, "Owner_State_Code")
              }
              name="Owner_State_Code"
              id="Owner_State_Code"
            />
          )}
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.Owner_City}
            helperText={
              props.validationError.Owner_City && "City name is required"
            }
            label="City"
            value={props.data.Owner_City}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_City")
            }
            name="Owner_City"
            id="Owner_City"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StarIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="International Province"
            value={props.data.Owner_International_Province}
            onChange={(e) =>
              props.handleOwnerChange(
                e.target.value,
                "Owner_International_Province"
              )
            }
            name="Owner_International_Province"
            id="Owner_International_Province"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <RoomIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Postal Code"
            value={props.data.Owner_Postal_Code}
            error={props.validationError.Owner_Postal_Code}
            onChange={(e) =>
              (/^[0-9]\d*$/g.test(e.target.value) || e.target.value == "") &&
              props.handleOwnerChange(e.target.value, "Owner_Postal_Code")
            }
            name="Owner_Postal_Code"
            id="Owner_Postal_Code"
            helperText={
              props.validationError.Owner_Postal_Code &&
              "Postal code should be 5 digits"
            }
          />
        </Grid>
      </Grid>
      <>
        {(props.data.ownerAddressProofDetails.File_Name !== "" ||
          props.data.ownerPhotoIdDetails.File_Name !== "" ||
          props.data.ownerPhotoIdBackDetails.File_Name !== "" ||
          props.data.ownerBiometricIdDetails.File_Name !== "") && (
          <Grid item xs={12}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    style={{ width: "5%" }}
                    align="center"
                  >
                    Document Type
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    style={{ width: "5%" }}
                    align="center"
                  >
                    Document Type Name
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    style={{ width: "5%" }}
                    align="center"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    style={{ width: "10%" }}
                    align="center"
                  >
                    File Type
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    style={{ width: "10%" }}
                    align="center"
                  >
                    Upload Time (UTC)
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    style={{ width: "5%" }}
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.ownerPhotoIdDetails.File_Name !== "" && (
                  <TableRow>
                    <TableCell align="center">Photo ID Front Side</TableCell>
                    <TableCell align="center">
                      {props.data.ownerPhotoIdType}
                    </TableCell>
                    <TableCell align="center">
                      <a
                        href={props.data.ownerPhotoIdDetails?.File_Path}
                        style={{ pointerEvents: props.disabled ? "none" : "" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {props.data.ownerPhotoIdDetails.File_Name}
                      </a>
                    </TableCell>

                    <TableCell align="center">
                      {props.data.ownerPhotoIdDetails.File_Type}
                    </TableCell>

                    <TableCell align="center">
                      {moment
                        .utc(props.data.ownerPhotoIdDetails.Upload_Date_Time)
                        .format("MM/DD/YYYY hh:mm:ss A")}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={props.disabled}
                        onClick={() => props.handleDeleteUpload("ownerPhotoId")}
                        color="secondary"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
                {props.data.ownerPhotoIdBackDetails.File_Name !== "" && (
                  <TableRow>
                    <TableCell align="center">Photo ID Back Side</TableCell>
                    <TableCell align="center">
                      {props.data.ownerPhotoIdTypeBack}
                    </TableCell>
                    <TableCell align="center">
                      <a
                        href={props.data.ownerPhotoIdBackDetails?.File_Path}
                        style={{ pointerEvents: props.disabled ? "none" : "" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {props.data.ownerPhotoIdBackDetails.File_Name}
                      </a>
                    </TableCell>

                    <TableCell align="center">
                      {props.data.ownerPhotoIdBackDetails.File_Type}
                    </TableCell>

                    <TableCell align="center">
                      {moment
                        .utc(
                          props.data.ownerPhotoIdBackDetails.Upload_Date_Time
                        )
                        .format("MM/DD/YYYY hh:mm:ss A")}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={props.disabled}
                        onClick={() =>
                          props.handleDeleteUpload("ownerPhotoIdBack")
                        }
                        color="secondary"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
                {props.data.ownerAddressProofDetails.File_Name !== "" && (
                  <TableRow>
                    <TableCell align="center">Address Proof</TableCell>
                    <TableCell align="center">
                      {props.data.ownerAddressProofType}
                    </TableCell>
                    <TableCell align="center">
                      <a
                        href={props.data.ownerAddressProofDetails?.File_Path}
                        style={{ pointerEvents: props.disabled ? "none" : "" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {props.data.ownerAddressProofDetails.File_Name}
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      {props.data.ownerAddressProofDetails.File_Type}
                    </TableCell>
                    <TableCell align="center">
                      {moment
                        .utc(
                          props.data.ownerAddressProofDetails.Upload_Date_Time
                        )
                        .format("MM/DD/YYYY hh:mm:ss A")}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={props.disabled}
                        onClick={() =>
                          props.handleDeleteUpload("ownerAddressProof")
                        }
                        color="secondary"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
                {props.data.ownerBiometricIdDetails.File_Name !== "" && (
                  <TableRow>
                    <TableCell align="center">
                      Biometric Verification ID
                    </TableCell>
                    <TableCell align="center">
                      {props.data.ownerBiometricIdType}
                    </TableCell>
                    <TableCell align="center">
                      <a
                        href={props.data.ownerBiometricIdDetails.File_Path}
                        style={{ pointerEvents: props.disabled ? "none" : "" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {props.data.ownerBiometricIdDetails.File_Name}
                      </a>
                    </TableCell>

                    <TableCell align="center">
                      {props.data.ownerBiometricIdDetails.File_Type}
                    </TableCell>

                    <TableCell align="center">
                      {moment
                        .utc(
                          props.data.ownerBiometricIdDetails.Upload_Date_Time
                        )
                        .format("MM/DD/YYYY hh:mm:ss A")}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={props.disabled}
                        onClick={() =>
                          props.handleDeleteUpload("ownerBiometricId")
                        }
                        color="secondary"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        )}
        {props.data.ownerAddressProofDetails.File_Name === "" && (
          <Grid
            container
            style={{ flexDirection: windowWidth ? "row" : "column" }}
            className={classes.uploadDocumentBox}
          >
            <Grid item xs={7}>
              <Typography
                style={{ marginLeft: "10px", marginRight: "10px" }}
                variant="subtitle1"
              >
                P.O.A (Proof of Address) * (required) (You must provide a
                personal proof of address in the form of a utility bill, cell
                phone bill, government bill or credit card bill. It must be
                dated within 3 months of today’s date, with your current
                address, full name and date visible in order to get verified)
                Maximum file size: 25MB (format: PDF, JPG, PNG)
              </Typography>
            </Grid>
            <Grid
              item
              xs={windowWidth ? 5 : 12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: windowWidth ? "row" : "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%"
                }}
              >
                <TextField
                  className={classes.rootDocumentSelect}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    }
                  }}
                  required
                  label="Document Type"
                  value={props.data.ownerAddressProofType}
                  error={props.validationError.ownerAddressProof}
                  helperText={
                    props.validationError.ownerAddressProof &&
                    "Address Id is required"
                  }
                  disabled={props.disabled}
                  onChange={(e) => {
                    setAddressFileType(true);
                    props.handleOwnerChange(
                      e.target.value,
                      "ownerAddressProofType"
                    );
                  }}
                  name="ownerAddressProofType"
                  id="ownerAddressProofType"
                  select
                  SelectProps={{ MenuProps: { disableScrollLock: true } }}
                >
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Cell Phone Bill"
                  >
                    Cell Phone Bill
                  </MenuItem>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Credit Card Bill"
                  >
                    Credit Card Bill
                  </MenuItem>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Government Bill"
                  >
                    Government Bill
                  </MenuItem>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Utility Bill"
                  >
                    Utility Bill
                  </MenuItem>
                </TextField>
                <label
                  htmlFor={`upload-photoProof-${props.index}`}
                  style={{ margin: windowWidth ? "0px" : "10px" }}
                >
                  <input
                    style={{ display: "none" }}
                    id={`upload-photoProof-${props.index}`}
                    accept="image/*, application/pdf"
                    name={`upload-photoProof-${props.index}`}
                    required
                    type="file"
                    display="none"
                    disabled={
                      (!addressFileType || props.disabled) &&
                      isEmpty(props.data.ownerAddressProofType)
                    }
                    onChange={(e) => {
                      props.handleUploadOnChange(
                        e.target.files[0],
                        "ownerAddressProof"
                      );
                    }}
                  />
                  <Button
                    component="span"
                    style={{
                      backgroundColor:
                        props.validationError.ownerAddressProof && "red",
                      color: props.validationError.ownerAddressProof && "white"
                    }}
                    variant={
                      !addressFileType &&
                      isEmpty(props.data.ownerAddressProofType)
                        ? "outlined"
                        : "contained"
                    }
                    disabled={
                      (!addressFileType || props.disabled) &&
                      isEmpty(props.data.ownerAddressProofType)
                    }
                  >
                    Upload Address Proof
                  </Button>
                </label>
              </div>
            </Grid>
          </Grid>
        )}
        {(props.data.ownerPhotoIdDetails.File_Name === "" ||
          props.data.ownerPhotoIdBackDetails.File_Name === "") && (
          <Grid
            container
            style={{ flexDirection: windowWidth ? "row" : "column" }}
            className={classes.uploadDocumentBox}
          >
            <Grid item xs={7}>
              <Typography
                variant="subtitle1"
                style={{ marginLeft: "10px", marginRight: "10px" }}
              >
                Photo ID (Driver License, Passport, Gov. issued ID) * (required)
                Maximum file size: 25MB (format: PDF, JPG, PNG)
              </Typography>
            </Grid>

            <Grid
              item
              xs={windowWidth ? 5 : 12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: windowWidth ? "row" : "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%"
                }}
              >
                {props.data.ownerPhotoIdDetails.File_Name === "" && (
                  <>
                    <TextField
                      className={classes.rootDocumentSelect}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      required
                      label="Document type"
                      disabled={
                        props.disabled ||
                        props.data.ownerPhotoIdDetails.File_Name
                      }
                      value={props.data.ownerPhotoIdType}
                      onChange={(e) => {
                        setPhotoIdType(true);
                        props.handleOwnerChange(
                          e.target.value,
                          "ownerPhotoIdType"
                        );
                      }}
                      error={props.validationError.ownerPhotoId}
                      helperText={
                        props.validationError.ownerPhotoId &&
                        "Photo Id Type is required"
                      }
                      name="ownerPhotoIdType"
                      id="ownerPhotoIdType"
                      select
                      SelectProps={{ MenuProps: { disableScrollLock: true } }}
                    >
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Drivers License"
                      >
                        Driver License
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Govt. Issued ID"
                      >
                        Govt. Issued ID
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value=" Passport"
                      >
                        Passport
                      </MenuItem>
                    </TextField>
                    <label
                      htmlFor={`upload-photoId-${props.index}`}
                      style={{ margin: windowWidth ? "0px" : "10px" }}
                    >
                      <input
                        style={{ display: "none" }}
                        id={`upload-photoId-${props.index}`}
                        accept="image/*, application/pdf"
                        name={`upload-photoId-${props.index}`}
                        required
                        type="file"
                        display="none"
                        disabled={
                          (!photoIdType || props.disabled) &&
                          isEmpty(props.data.ownerPhotoIdType)
                        }
                        onChange={(e) => {
                          props.handleUploadOnChange(
                            e.target.files[0],
                            "ownerPhotoId"
                          );
                        }}
                      />

                      <Button
                        component="span"
                        variant={
                          !photoIdType && isEmpty(props.data.ownerPhotoIdType)
                            ? "outlined"
                            : "contained"
                        }
                        disabled={
                          (!photoIdType || props.disabled) &&
                          isEmpty(props.data.ownerPhotoIdType)
                        }
                        style={{
                          backgroundColor:
                            props.validationError.ownerPhotoId && "red",
                          color: props.validationError.ownerPhotoId && "white"
                        }}
                      >
                        Upload Front Side
                      </Button>
                    </label>
                  </>
                )}
                {props.data.ownerPhotoIdBackDetails.File_Name === "" &&
                  props.data.ownerPhotoIdDetails.File_Name != "" && (
                    <>
                      <TextField
                        className={classes.rootDocumentSelect}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused"
                          }
                        }}
                        required
                        label="Document type"
                        disabled={props.disabled}
                        value={props.data.ownerPhotoIdTypeBack}
                        onChange={(e) => {
                          setPhotoIdType(true);
                          props.handleOwnerChange(
                            e.target.value,
                            "ownerPhotoIdTypeBack"
                          );
                        }}
                        error={props.validationError.ownerPhotoId}
                        helperText={
                          props.validationError.ownerPhotoId &&
                          "Photo Id Type is required"
                        }
                        name="ownerPhotoIdTypeBack"
                        id="ownerPhotoIdTypeBack"
                        select
                        SelectProps={{ MenuProps: { disableScrollLock: true } }}
                      >
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value="Drivers License"
                        >
                          Driver License
                        </MenuItem>
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value="Govt. Issued ID"
                        >
                          Govt. Issued ID
                        </MenuItem>
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value=" Passport"
                        >
                          Passport
                        </MenuItem>
                      </TextField>

                      <label
                        htmlFor={`upload-photoId-Back-${props.index}`}
                        style={{ margin: windowWidth ? "0px" : "10px" }}
                      >
                        <input
                          style={{ display: "none" }}
                          id={`upload-photoId-Back-${props.index}`}
                          accept="image/*, application/pdf"
                          name={`upload-photoId-Back-${props.index}`}
                          required
                          type="file"
                          display="none"
                          disabled={
                            (!photoIdType || props.disabled) &&
                            isEmpty(props.data.ownerPhotoIdTypeBack)
                          }
                          onChange={(e) => {
                            if (
                              props.data.ownerPhotoIdType !=
                              props.data.ownerPhotoIdTypeBack
                            ) {
                              enqueueSnackbar(
                                "Front and Back Photo document type should be same",
                                {
                                  variant: "error"
                                }
                              );
                            } else {
                              props.handleUploadOnChange(
                                e.target.files[0],
                                "ownerPhotoIdBack"
                              );
                            }
                          }}
                        />

                        <Button
                          component="span"
                          variant={
                            !photoIdType && isEmpty(props.data.ownerPhotoIdType)
                              ? "outlined"
                              : "contained"
                          }
                          disabled={
                            (!photoIdType || props.disabled) &&
                            isEmpty(props.data.ownerPhotoIdTypeBack)
                          }
                          style={{
                            backgroundColor:
                              props.validationError.ownerPhotoIdBack && "red",
                            color:
                              props.validationError.ownerPhotoIdBack && "white"
                          }}
                        >
                          Upload Back Side
                        </Button>
                      </label>
                    </>
                  )}
              </div>
            </Grid>
          </Grid>
        )}
        {props.data.ownerBiometricIdDetails.File_Name === "" && (
          <Grid
            container
            style={{ flexDirection: windowWidth ? "row" : "column" }}
            className={classes.uploadDocumentBox}
          >
            <Grid item xs={7}>
              <Typography
                variant="subtitle1"
                style={{ marginLeft: "10px", marginRight: "10px" }}
              >
                Biometric Verification (Photo) * (required). Please upload a
                Biometric holding your ID that you supplying for verification,
                and a piece of paper with todays full date. Must be a clear
                photo where all 3 elements are visible (Face, ID and Paper)
                Maximum file size: 25mb (PDF, JPG, JPEG, PNG, DOC & DOCX
                accepted)
              </Typography>
            </Grid>
            <Grid
              item
              xs={windowWidth ? 5 : 12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: windowWidth ? "row" : "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%"
                }}
              >
                <TextField
                  className={classes.rootDocumentSelect}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    }
                  }}
                  required
                  label="Document type"
                  disabled={props.disabled}
                  value={props.data.ownerBiometricIdType}
                  onChange={(e) => {
                    setBiometricIdType(true);
                    props.handleOwnerChange(
                      e.target.value,
                      "ownerBiometricIdType"
                    );
                  }}
                  error={props.validationError.ownerBiometricId}
                  helperText={
                    props.validationError.ownerBiometricId &&
                    "Biometric ID is required for verification"
                  }
                  name="ownerBiometricIdType"
                  id="ownerBiometricIdType"
                  select
                  SelectProps={{ MenuProps: { disableScrollLock: true } }}
                >
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Biometric ID"
                  >
                    Biometric ID
                  </MenuItem>
                </TextField>
                <label
                  htmlFor={`upload-BiometricId-${props.index}`}
                  style={{ margin: windowWidth ? "0px" : "10px" }}
                >
                  <input
                    style={{ display: "none" }}
                    id={`upload-BiometricId-${props.index}`}
                    accept="image/*, application/pdf"
                    name={`upload-BiometricId-${props.index}`}
                    required
                    type="file"
                    display="none"
                    disabled={
                      (!biometricIdType || props.disabled) &&
                      isEmpty(props.data.ownerBiometricIdType)
                    }
                    onChange={(e) => {
                      props.handleUploadOnChange(
                        e.target.files[0],
                        "ownerBiometricId"
                      );
                    }}
                  />
                  <Button
                    component="span"
                    style={{
                      backgroundColor:
                        props.validationError.ownerBiometricId && "red",
                      color: props.validationError.ownerBiometricId && "white"
                    }}
                    variant={
                      !biometricIdType &&
                      isEmpty(props.data.ownerBiometricIdType)
                        ? "outlined"
                        : "contained"
                    }
                    disabled={
                      (!biometricIdType || props.disabled) &&
                      isEmpty(props.data.ownerBiometricIdType)
                    }
                  >
                    Upload Biometric ID
                  </Button>
                </label>
              </div>
            </Grid>
          </Grid>
        )}
      </>
      <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
    </div>
  );

  return (
    <div key={`${props.index}-owners`}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "20px",
          position: "relative"
        }}
        className={classes.ownerHeader}
      >
        <div style={{ margin: "auto" }}>
          <Typography className={classes.ownerTittle}>
            <b>{`Owner ${props.index + 1}`}</b>
          </Typography>
        </div>

        <div
          style={{
            position: "absolute",
            marginLeft: "85%",
            display: "flex"
          }}
        >
          <Tooltip title="Add Owner" aria-label="add">
            <Button
              className={classes.addIcon}
              size="sm"
              disabled={props.disabled}
              aria-label="open drawer"
              onClick={() => props.add()}
            >
              <AddIcon />
            </Button>
          </Tooltip>
          {props.index !== 0 && (
            <Tooltip
              style={{ marginLeft: "10px" }}
              title="Remove"
              aria-label="Remove"
            >
              <Button
                className={classes.deleteIcon}
                disabled={props.disabled}
                aria-label="open drawer"
                onClick={() => props.remove()}
              >
                <DeleteOutlineRoundedIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      </Box>
      <Box>{form}</Box>
    </div>
  );
};

OwnerInformation.propTypes = {
  index: PropTypes.any,
  data: PropTypes.object,
  add: PropTypes.func,
  remove: PropTypes.func,
  handleOwnerChange: PropTypes.func,
  validationError: PropTypes.object,
  disabled: PropTypes.bool,
  handleUploadOnChange: PropTypes.func,
  handleDeleteUpload: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration
});
export default connect(mapStateToProps, null)(OwnerInformation);
