import { post, put, get } from "./httpClient";
import {
  UPDATE_GROUP_DATA_URL,
  CREATE_NEW_GROUP_URL,
  QUESTION_REPORT_CHANGE_STATUS,
  QUESTION_REPORT_KYC_CHANGE_STATUS,
  KYC_STATUS_APPROVE_CALL,
  MPA_STATUS_APPROVE_CALL,
  MPA_ATTECHMENT_CHECKLIST_REVIEW_SAVE,
  KYC_ATTECHMENT_CHECKLIST_REVIEW_SAVE,
  CHECKLIST_COMMENT_SAVE,
  AUTO_GENERATED_REPO_CODE,
  PATIENT_FORGET_PASSWORD_URL,
  USER_REGISTRATION_URL,
  UPDATE_PRICING_TEMPLATE_CALL,
  UPDATE_TEMPLETE_UNDER_PROVIDER_ID,
  MERCHANT_INVITE_TEMPLETE_CALL,
  GENERATE_SECRET_KEY,
  GET_AGENT_LIST_HIERARCHY,
  GET_PROVIDER_DETAILS,
  DELETE_OWNER_API_CALL,
  CREATE_NOTES,
  SHOW_NOTES_LIST
} from "../Uintls/Urls";

export const createNewGroupApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(CREATE_NEW_GROUP_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updateGroupApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(UPDATE_GROUP_DATA_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusPreameter =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(QUESTION_REPORT_CHANGE_STATUS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusPreameterKyc =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(QUESTION_REPORT_KYC_CHANGE_STATUS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusApproveMpa =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(MPA_STATUS_APPROVE_CALL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusApproveKyc =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(KYC_STATUS_APPROVE_CALL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const mpaChecklistComment =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(CHECKLIST_COMMENT_SAVE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const kycChecklistAttechment =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(KYC_ATTECHMENT_CHECKLIST_REVIEW_SAVE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const mpaChecklistAttechment =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(MPA_ATTECHMENT_CHECKLIST_REVIEW_SAVE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const confirmForgetPassword =
  (payload, successCallback, failureCallback) => async () => {
    try {
      // const url = `${PATIENT_FORGET_PASSWORD_URL}?email=${payload.email}&countryCode=${payload.countryCode}`;
      const data = await get(PATIENT_FORGET_PASSWORD_URL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const autoGeneratedRepCode =
  (successCallback, failureCallback) => async () => {
    try {
      // const url = `${PATIENT_FORGET_PASSWORD_URL}?email=${payload.email}&countryCode=${payload.countryCode}`;
      const data = await get(AUTO_GENERATED_REPO_CODE);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const createNewUserApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(USER_REGISTRATION_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getUpdatePricingTemplate =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(UPDATE_PRICING_TEMPLATE_CALL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updateUserApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(USER_REGISTRATION_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updatePricingTemplete =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(UPDATE_TEMPLETE_UNDER_PROVIDER_ID, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const merchantInviteTemplate =
  (successCallback, failureCallback) => async () => {
    try {
      // const url = `${PATIENT_FORGET_PASSWORD_URL}?email=${payload.email}&countryCode=${payload.countryCode}`;
      const data = await get(MERCHANT_INVITE_TEMPLETE_CALL);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const generateSecretKey =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(GENERATE_SECRET_KEY, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getConfigurationProviderDetails =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(GET_PROVIDER_DETAILS, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const deleteOwnerApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(DELETE_OWNER_API_CALL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const createNotes =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(CREATE_NOTES, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
