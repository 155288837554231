import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Paper from "@material-ui/core/Paper";
import { Typography, MenuItem } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import TextField from "@material-ui/core/TextField";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import {
  generateSecretKey,
  getConfigurationProviderDetails
} from "../../actions/Operations";
import { getProviderDetailsById } from "../../Redux/Actions/ProvideRegistration";

const useStyles = makeStyles(styles);
const Configuration = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = React.useRef(null);
  const [initialValues, setInitialValues] = React.useState({
    Coinsuites_id: "",
    Merchant_ID: "",
    Program_Access_Email: "",
    Password: "",
    Public_Key: "",
    Secret_Key: ""
  });
  const [errorText, setErrorText] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [keyGenerationText, setKeyGenerationText] = React.useState(false);
  const [configurationType, setConfigurationType] = React.useState(2);

  const configTypeArr = [
    {
      configType: "API Implementation",
      configValue: 1
    },
    {
      configType: "PayNow Widget",
      configValue: 2
    },
    {
      configType: "WooCommerce",
      configValue: 3
    }
  ];

  const handleChange = (value) => {
    setConfigurationType(value);
  };

  const handleBack = () => {
    if (props.formData.formMode === "Edit") {
      props.history.push(`/${props.userDetails.layout}/user`);
    } else {
      props.history.push(`/${props.userDetails.layout}/Live`);
    }
    setKeyGenerationText(false);
    if (!props.formData.providerDetailsById) {
      handleBack();
    }
  };

  React.useEffect(() => {
    const data = {
      providerId:
        props.formData.providerDetailsById &&
        props.formData.providerDetailsById?.Providers_Data[0]?._id
    };
    props.getConfigurationProviderDetails(data, (res) => {
      if (res.status) {
        const updatedValues = {
          Coinsuites_id: res.data.Coinsuites_id,
          Merchant_ID: res.data.Merchant_ID,
          Program_Access_Email: res.data.Program_Access_Email,
          Password: res.data.Password,
          Public_Key: res.data.ApiKeyManagement?.Public_Key || "",
          Secret_Key: res.data.ApiKeyManagement?.Secret_Key || ""
        };
        setInitialValues({ ...updatedValues });
      }
    });
  }, []);

  const generateKey = () => {
    setLoading(true);
    const data = {
      providerId:
        props.formData.providerDetailsById &&
        props.formData.providerDetailsById?.Providers_Data[0]?._id
    };
    props.generateSecretKey(data, (res) => {
      setLoading(false);
      setErrorText(false);
      if (res.status) {
        setKeyGenerationText(true);
        formikRef.current.setFieldValue("Secret_Key", res.data.secretKey);
        formikRef.current.setFieldValue("Public_Key", res.data.publickKey);
        setInitialValues({
          ...initialValues,
          Secret_Key: res.data.secretKey,
          Public_Key: res.data.publickKey
        });
      } else if (!res.status) {
        setErrorText(res.data);
      }
    });
  };

  const copySecretKey = () => {
    if (!isEmpty(initialValues.Secret_Key)) {
      navigator.clipboard.writeText(initialValues.Secret_Key);
      enqueueSnackbar("You have copied secret key", {
        variant: "info"
      });
    } else {
      enqueueSnackbar("Secret key not found", {
        variant: "error"
      });
    }
  };

  return (
    <Paper>
      <Box
        className={classes.tittleColor}
        height={40}
        style={{
          alignItems: "center"
        }}
      >
        <Typography className={classes.tittleTextColor}>
          Configuration
        </Typography>
      </Box>
      <Box className={classes.marginConfigs} style={{ padding: "20px 0px" }}>
        <Grid container justifyContent="center" style={{ paddingLeft: "15%" }}>
          <Grid container className={classes.marginConfigs}>
            <Grid
              item
              style={{ display: "flex", alignItems: "center", gap: "1rem" }}
            >
              <Typography className={classes.textFieldWidth}>
                Configuration Type:
              </Typography>
              <TextField
                className={classes.textFieldInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                value={configurationType}
                onChange={(e) =>
                  handleChange(e.target.value, "configurationType")
                }
                name="configurationType"
                id="configurationType"
                select
              >
                {configTypeArr &&
                  configTypeArr.map((type) => (
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value={type.configValue}
                    >
                      {type.configType}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Formik
          onSubmit={handleBack}
          enableReinitialize
          initialValues={initialValues}
        >
          {() => (
            <Form>
              <Grid
                container
                justifyContent="center"
                style={{ paddingLeft: "15%" }}
              >
                <Grid container className={classes.marginConfigs}>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem"
                    }}
                  >
                    <Typography className={classes.textFieldWidth}>
                      Coinsuites ID:
                    </Typography>
                    <Field
                      as={TextField}
                      disabled
                      // required
                      className={classes.textFieldInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      name="Coinsuites_id"
                      id="Coinsuites_id"
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.marginConfigs}>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem"
                    }}
                  >
                    <Typography className={classes.textFieldWidth}>
                      Merchant ID:
                    </Typography>
                    <Field
                      as={TextField}
                      disabled
                      // required
                      className={classes.textFieldInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      name="Merchant_ID"
                      id="Merchant_ID"
                    />
                  </Grid>
                </Grid>
                {(configurationType == 1 || configurationType == 3) && (
                  <>
                    <Grid container className={classes.marginConfigs}>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem"
                        }}
                      >
                        <Typography className={classes.textFieldWidth}>
                          Email ID:
                        </Typography>
                        <Field
                          as={TextField}
                          disabled
                          // required
                          className={classes.textFieldInput}
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused"
                            }
                          }}
                          InputProps={{
                            color: "secondary",
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused"
                            }
                          }}
                          name="Program_Access_Email"
                          id="Program_Access_Email"
                        />
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginConfigs}>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem"
                        }}
                      >
                        <Typography className={classes.textFieldWidth}>
                          Password:
                        </Typography>
                        <Field
                          as={TextField}
                          disabled
                          // required
                          className={classes.textFieldInput}
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused"
                            }
                          }}
                          InputProps={{
                            color: "secondary",
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused"
                            }
                          }}
                          name="Password"
                          id="Password"
                        />
                      </Grid>
                    </Grid>
                    <Grid container span style={{ margin: "2% 1% 1% 1% " }}>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem"
                        }}
                      >
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                          API Key Management
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginConfigs}>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem"
                        }}
                      >
                        <Typography className={classes.textFieldWidth}>
                          Public Key:
                        </Typography>
                        <Field
                          as={TextField}
                          disabled
                          className={classes.textFieldInput}
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused"
                            }
                          }}
                          InputProps={{
                            color: "secondary",
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused"
                            }
                          }}
                          name="Public_Key"
                          id="Public_Key"
                        />
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginConfigs}>
                      <>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            gap: "1rem",
                            marginTop: "8px"
                          }}
                        >
                          <Typography className={classes.textFieldWidth}>
                            Secret Key:
                          </Typography>

                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "-8px"
                              }}
                            >
                              <Field
                                as={TextField}
                                disabled
                                className={classes.textFieldInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused"
                                  }
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resize,
                                    focused: "focused"
                                  }
                                }}
                                name="Secret_Key"
                                id="Secret_Key"
                                type={keyGenerationText ? "text" : "password"}
                              />
                              <Button
                                className={classes.clearButton}
                                style={{
                                  width: "fit-content",
                                  marginLeft: "auto"
                                }}
                                onClick={() => generateKey()}
                              >
                                {isEmpty(initialValues.Secret_Key) && !loading
                                  ? "Generate Key"
                                  : loading
                                  ? "Generating..."
                                  : "Regenerate Key"}
                              </Button>
                            </div>
                            {!isEmpty(initialValues.Secret_Key) &&
                              keyGenerationText && (
                                <Tooltip title="Copy" placement="top">
                                  <FileCopyIcon
                                    style={{
                                      marginTop: "-8px",
                                      cursor: "pointer"
                                    }}
                                    onClick={() => copySecretKey()}
                                  />
                                </Tooltip>
                              )}
                          </>
                        </Grid>
                      </>
                    </Grid>
                  </>
                )}
                <Grid container className={classes.marginConfigs}>
                  {keyGenerationText && (
                    <Typography style={{ color: "red" }}>
                      Due to security concern, the secret key will not be
                      displayed again. Please store it at a safe location.
                    </Typography>
                  )}
                  {errorText && (
                    <Typography style={{ color: "red" }}>
                      {errorText}
                    </Typography>
                  )}
                </Grid>
                <Grid container className={classes.marginConfigs}>
                  Please visit
                  <a
                    href="https://developer.coinsuites.com/developer-api.html"
                    target="_blank"
                    style={{ margin: "0px 5px" }}
                    rel="noreferrer"
                  >
                    Developers API documentation
                  </a>
                  to access the implementation guide.
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                style={{ marginTop: "2rem" }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem"
                  }}
                >
                  <Button
                    className={classes.clearButton}
                    onClick={() => handleBack()}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Paper>
  );
};

Configuration.propTypes = {
  userDetails: PropTypes.object,
  getConfigurationProviderDetails: PropTypes.func,
  getProviderDetailsById: PropTypes.func,
  formData: PropTypes.object,
  history: PropTypes.object,
  generateSecretKey: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  userDetails: app.app
});
export default connect(mapStateToProps, {
  getConfigurationProviderDetails,
  getProviderDetailsById,
  generateSecretKey
})(Configuration);
