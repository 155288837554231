/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Search from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "components/Card/Card";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomButton from "components/CustomButtons/Button";
import ErrorIcon from "@material-ui/icons/Error";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  emptyPatientSelect,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  getPatientOfferData,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  setSelectPatientOffer,
  cernerPatientSearch,
} from "Redux/Actions/genrateOfferPatient";
import Modal from "@material-ui/core/Modal";
import { Typography } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Radio from "@material-ui/core/Radio";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import isEmpty from "lodash/isEmpty";
import brandLogo from "../../assets/img/imgpsh_fullsize_anim.png";
import OffersStyle from "./OffersStyle";
import PatientTable from "./PatientTable";
import gif from "../../assets/img/success.gif";
import AddPatientForm from "../Patient/AddPatient";

const GreenRadio = withStyles(theme => ({
  root: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))(props => <Radio color="default" {...props} />);

const useStyles = makeStyles(OffersStyle);
const PaymentGenrateOffer = props => {
  const classes = useStyles();
  const [searchData, setSearchData] = React.useState(props.tableData.data);
  const [selectedSearch, setSelectedSearch] = React.useState("local");
  const [logoPath, setLogoPath] = React.useState("");
  const [searchError, setSearchError] = React.useState(false);
  const [preAddPatientData, setPreAddPatientData] = React.useState(
    props.tableData.addPatientData
  );
  const [addPatientModal, setAddPatientModal] = React.useState(false);
  const [patientModal, setPatiemtModal] = React.useState(false);
  const [showTaable, setShowTable] = React.useState(false);
  const [preCptCodeData, setPreCptCodeData] = React.useState(
    props.tableData.cptCodeData
  );
  const [commonText, setCommonText] = React.useState("");

  React.useEffect(() => {
    if (
      props.tableData.activePlanPatientList
      && props.tableData.completedPlanPatientList
      && props.tableData.patientDocumentList
    ) {
      props.state();
      props.history.push("/admin/offerDetails");
    }
    if (searchData !== props.tableData.data) {
      setSearchData(props.tableData.data);
      setShowTable(true);
      setSearchData(props.tableData.data);
    }
    if (preCptCodeData !== props.tableData.cptCodeData) {
      setPreCptCodeData(props.tableData.cptCodeData);
      props.history.push("/admin/addpatient");
    }
    if (preAddPatientData !== props.tableData.addPatientData) {
      if (props.tableData.addPatientData.status) {
        setAddPatientModal(true);
        setPatiemtModal(false);
      }
      setPreAddPatientData(props.tableData.addPatientData);
    }
  }, [props]);

  const handlePatiemtModal = () => {
    props.cptCodeDataCall();
  };

  const onClickHandle = () => {
    const radioSelected = selectedSearch;

    const data = {
      commonText,
      next: "",
    };
    if (radioSelected === "local") {
      props.patientSearch(data);
    }

    if (radioSelected === "global") {
      if (commonText === "") {
        setSearchError(true);
      } else {
        setSearchError(false);
        props.cernerPatientSearch(data);
      }
    }
  };

  const handleKeypress = e => {
    if (e.key === "Enter") {
      onClickHandle();
    }
  };

  const handlePatientNameSelect = data => {
    props.setSelectPatientOffer(data);
    const requestData = {
      patientId: data.data.Patient_id,
    };
    props.getPatientOfferData(requestData);
    const request = {
      patientId: data.data.Patient_id,
      ClientId: JSON.stringify(localStorage.getItem("sendApiClientId")),
    };
    props.getAllActivePlanList(request);
    props.getAllDocumentList(request);
    props.getAllCompletedPlanList(request);
  };

  if (isEmpty(logoPath)) {
    const logo_path = new Image();
    if (props.app.portalConfig) {
      const x = props.app.portalConfig.data[0]?.Logo_Image;
      logo_path.src = `${x}?" + ${new Date().getTime()}`;
      logo_path.onload = function () {
        setLogoPath(logo_path.src);
      };
      logo_path.onerror = function () {
        setLogoPath(brandLogo);
      };
    }
  }

  const handleChipDelete = () => {
    const data = {
      commonText: "",
    };
    setCommonText("");
    props.patientSearch(data);
  };

  return (
    <Card>
      <>
        <Box className={classes.patientBox}>
          <Grid
            spacing={2}
            className={classes.patientSearchContainer}
            container
          >
            <Grid item sm={12} xs={12}>
              <CardMedia className={classes.brandlogo} image={logoPath} />
            </Grid>
            <Grid item sm={7} xs={10}>
              <TextField
                variant="outlined"
                error={searchError}
                fullWidth
                autoFocus
                size="small"
                value={commonText}
                className={classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                color="secondary"
                helperText={`${searchError ? "Please Enter Some value in Search" : ""}`}
                placeholder="Patient Name / SSN / Email/ Phone"
                // label="Patient Name / SSN / Email / Phone"
                onChange={e => setCommonText(e.target.value)}
                name="Search"
                id="Search"
                onKeyPress={handleKeypress}
              />
            </Grid>
          </Grid>
          <Grid className={classes.searchAddButtons} container>
            <Grid sm={2} xs={12} item direction="row" style={{ display: "flex" }}>
              <GreenRadio
                className={classes.checkBox}
                checked={selectedSearch === "local"}
                onChange={() => setSelectedSearch("local")}
                value="local"
                name="radio-button-demo"
                inputProps={{ "aria-label": "A" }}
              />
              <Typography color="primary" className={classes.textColorRadioCard} style={{ marginTop: "3.5%" }}>Local Search</Typography>
            </Grid>
            <Grid sm={2} xs={12} item direction="row" style={{ display: "flex" }}>
              <GreenRadio
                checked={selectedSearch === "global"}
                onChange={() => setSelectedSearch("global")}
                className={classes.checkBox}
                value="global"
                name="radio-button-demo"
                inputProps={{ "aria-label": "B" }}
              />
              <Typography color="primary" className={classes.textColorRadioCard} style={{ marginTop: "3.5%" }}>Global Search</Typography>
            </Grid>
          </Grid>
          <Grid className={classes.searchAddButtons} container>
            <Grid sm={3} xs={12} item>
              <Button
                aria-label="edit"
                justIcon
                color="primary"
                round
                size="medium"
                onClick={() => onClickHandle()}
                startIcon={<Search />}
              >
                Search Patient
              </Button>
            </Grid>
            <Grid sm={3} xs={12} item>
              <Button
                size="medium"
                color="primary"
                onClick={() => handlePatiemtModal()}
                startIcon={<PersonAddIcon />}
              >
                Register a new patient
              </Button>
            </Grid>
          </Grid>
          {showTaable && (
            <>
              {/* <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  style={{
                    margin: "1%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Chip
                    label={commonText}
                    onDelete={() => handleChipDelete()}
                  />
                </Grid>
              </Grid> */}
              <Grid className={classes.patientTable} container>
                <Grid item xs>
                  <PatientTable
                    tableData={searchData}
                    setShowOfferDEtails={handlePatientNameSelect}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </>

      <Modal open={addPatientModal} onClose={() => setAddPatientModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div className={classes.cardDiv}>
              {!preAddPatientData.status ? (
                <ErrorIcon style={{ fontSize: "60px", color: "#FF0000" }} />
              ) : (
                <img src={gif} width="50%" />
              )}
              <div className={classes.successText}>
                {preAddPatientData.message}
              </div>
              <br />
              <CustomButton
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="sm"
                onClick={() => setAddPatientModal(false)}
              >
                Ok
              </CustomButton>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal open={patientModal} onClose={() => setPatiemtModal(false)}>
        <AddPatientForm
          callResponse={props.tableData.addPatientData}
          setPatiemtModal={() => setPatiemtModal(false)}
        />
      </Modal>
    </Card>
    // <Card><FinalGenrateOffer handleSendEmailSuccess={() => handleSendEmailSuccess()} values={values} {...props} showOfferDEtails={showOfferDEtails} onClick={() => handlePruntBack()} /></Card>
  );
};

PaymentGenrateOffer.propTypes = {
  patientSearch: PropTypes.func,
  tableData: PropTypes.object,
  getPatientOfferData: PropTypes.func,
  cptCodeDataCall: PropTypes.func,
  getAllActivePlanList: PropTypes.func,
  getAllDocumentList: PropTypes.func,
  state: PropTypes.func,
  history: PropTypes.object,
  getAllCompletedPlanList: PropTypes.func,
  setSelectPatientOffer: PropTypes.func,
  app: PropTypes.object,
  cernerPatientSearch: PropTypes.func,
};

const mapStateToProps = app => ({
  tableData: app.genrateOfferPatient,
  userProvider: app.users,
  app: app.app,
});

export default connect(mapStateToProps, {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  emptyPatientSelect,
  getPatientOfferData,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  setSelectPatientOffer,
  cernerPatientSearch,
})(PaymentGenrateOffer);
