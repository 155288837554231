/* eslint-disable import/extensions */
import React from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Table from "@material-ui/core/Table";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles(styles);

const IncroprationDocument = props => {
  const classes = useStyles();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));


  return (
    <>
      {props.values.incorportionDocumentDetails.File_Name === "" ? (
        <Grid
          container
          style={{ flexDirection: windowWidth ? "column" : "row" }}
          className={
            !props.validationError.incorportionDocument
              ? classes.uploadDocumentBox
              : classes.uploadDocumentBoxRequired
          }
        >
          <Grid item xs={8} style={{ margin: "10px" }}>
            <Typography style={{ marginLeft: "10px" }} variant="subtitle1">
              Incorporation document * (required) (If you are a business, you
              must provide the incorporation document in order to get verified)
              Maximum file size: 25MB (format: PDF, JPG, PNG)
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <label htmlFor="upload-incropraton">
              <input
                style={{ display: "none" }}
                id="upload-incropraton"
                name="upload-incropraton"
                type="file"
                accept="image/*, application/pdf"
                display="none"
                onChange={e => props.handleUploadOnChange(e.target.files[0], "main")}
              />

              <Button
                className={
                  !props.validationError.incorportionDocument
                    ? classes.buttonStyle
                    : classes.buttonStyleError
                }
                disabled={props.disabled}
                variant="contained"
                component="span"
              >
                Upload Incorporation Document
              </Button>
            </label>
          </Grid>
        </Grid>
      ) : (
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell
                className={classes.cellTextHeaderOffer}
                style={{ width: "5%" }}
                align="center"
              >
                Document Type
              </TableCell>
              <TableCell
                className={classes.cellTextHeaderOffer}
                style={{ width: "5%" }}
                align="center"
              >
                Name
              </TableCell>
              <TableCell
                className={classes.cellTextHeaderOffer}
                style={{ width: "10%" }}
                align="center"
              >
                File Type
              </TableCell>
              <TableCell
                className={classes.cellTextHeaderOffer}
                style={{ width: "10%" }}
                align="center"
              >
                Upload Time (UTC)
              </TableCell>
              <TableCell
                className={classes.cellTextHeaderOffer}
                style={{ width: "5%" }}
                align="center"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">Incorporation</TableCell>
              <TableCell align="center">
                <a
                  href={props.values.incorportionDocumentDetails.File_Path}
                  style={{ pointerEvents: props.disabled ? "none" : "" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.values.incorportionDocumentDetails.File_Name}
                </a>
              </TableCell>
              <TableCell align="center">
                {props.values.incorportionDocumentDetails.File_Type}
              </TableCell>
              <TableCell align="center">
                {moment
                  .utc(props.values.incorportionDocumentDetails.Upload_Date_Time)
                  .format("MM/DD/YYYY hh:mm:ss A")}
              </TableCell>
              <TableCell align="center">
                <IconButton
                  onClick={() => props.handleDeleteUpload("main")}
                  disabled={props.disabled}
                  color="secondary"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
};

IncroprationDocument.propTypes = {
  disabled: PropTypes.bool,
  validationError: PropTypes.object,
  values: PropTypes.object,
  handleUploadOnChange: PropTypes.func,
  handleDeleteUpload: PropTypes.func,
};

const mapStateToProps = app => ({
  formData: app.provideRegistration,
});
export default connect(mapStateToProps, null)(IncroprationDocument);
