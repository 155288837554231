/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  getProviderByFilterData,
  addSearchData,
} from "../../Redux/Actions/ProvideRegistration";
import AgentSearchChip from "./AgentSearchChip";

const useStyles = makeStyles(styles);


function SimpleDialog(props) {
  const classes = useStyles();
  const location = useLocation();

  const handleClose = () => {
    const allState = { ...props.tableData.searchDetails };
    allState.pageNo = 1;
    allState.searchAgentId = "";
    allState.searchDbaName = "";
    allState.searchStatus = "";
    props.getProviderByFilterData(allState);
    props.addSearchData(allState);
  };

  const onChange = (value, type) => {
    const allState = { ...props.tableData.searchDetails };
    allState[type] = value;
    props.addSearchData(allState);
  };

  const onFormSubmit = () => {
    const allState = { ...props.tableData.searchDetails };
    allState.pageNo = 1;
    props.getProviderByFilterData(allState);
    props.seacrhChipData(allState);
    props.close();
  };
  let statusList = [];
  const liveStatus = props.tableData.statusList.Provider_Status_Data.filter(
    status => status.Status_Code == 9
  );
  const inProgressStatus = props.tableData.statusList.Provider_Status_Data.filter(
    status => status.Status_Code != 9
  );
  if (location.pathname === "/admin/Live") {
    statusList = liveStatus;
  } else {
    statusList = inProgressStatus;
  }

  const statusInProgress = [];

  if (statusList.length > 0 && location.pathname === "/admin/InProgress") {
    statusList.map(progress => {
      statusInProgress.push(progress.Status_Code);
    });
  }

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize,
              },
            }}
            label="DBA Name / Business Email"
            value={props.tableData.searchDetails.searchDbaName}
            onChange={e => onChange(e.target.value, "searchDbaName")}
            name="DBA"
            id="DBA"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <AgentSearchChip
          onChange={(value, type) => onChange(value, type)}
          values={props.tableData.searchDetails}
        />
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            placeholder="Select Status"
            label="Status"
            value={props.tableData.searchDetails.searchStatus}
            onChange={e => onChange(e.target.value, "searchStatus")}
            name="Current_Status"
            id="Current_Status"
            select
            style={{ fontSize: "82%" }}
          >
            {statusList
              && statusList.map((status, i) => (
                <MenuItem
                  key={i}
                  className={classes.dialogBoxDropDown}
                  value={status.Status_Code}
                >
                  {status.Provider_Status}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "8%" }}>
        <Grid item xs textAlign="right">
          <br />
          <Button
            cancel
            variant="contained"
            className={classes.clearButton}
            disabled={
              props.tableData.searchDetails.searchDbaName === ""
              && props.tableData.searchDetails.searchStatus === ""
              && props.tableData.searchDetails.searchAgentId === ""
            }
            size="md"
            onClick={() => handleClose()}
          >
            clear
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            disabled={
              props.tableData.searchDetails.searchDbaName === ""
              && props.tableData.searchDetails.searchStatus === ""
              && props.tableData.searchDetails.searchAgentId === ""
            }
            size="md"
            onClick={() => onFormSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  close: PropTypes.func,
  tableData: PropTypes.object,
  getProviderByFilterData: PropTypes.func,
  seacrhChipData: PropTypes.func,
  addSearchData: PropTypes.func,
};

const mapStateToProps = app => ({
  formData: app.getProviderByFilterData,
  tableData: app.provideRegistration,
});

export default connect(mapStateToProps, {
  getProviderByFilterData,
  addSearchData,
})(SimpleDialog);
