/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import ClearIcon from "@material-ui/icons/Clear";
import CoustomButton from "components/CustomButtons/Button.js";
import Menu from "@material-ui/core/Menu";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import Button from "components/CustomButtons/Button.js";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Card from "components/Card/Card.js";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Chip from "@material-ui/core/Chip";
import Modal from "@material-ui/core/Modal";
import isEmpty from "lodash/isEmpty";
import styles from "assets/jss/material-dashboard-react/components/businessForm.js";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/Providers";
import LongMenu from "../../components/Menu/Index";
import {
  getProviderList,
  changeProviderFormMode,
  getProviderStatusNormal,
  getProviderByFilterData,
  changeSingleStatus,
} from "../../Redux/Actions/ProvideRegistration";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";

const useStyles = makeStyles(tableStyle);

function createData(
  DBA_Name,
  E_mail,
  Activity_Type,
  Sub_Merchant_ID,
  Updated_By,
  Updated_On,
  Description,
  Message
) {
  return {
    DBA_Name,
    E_mail,
    Activity_Type,
    Sub_Merchant_ID,
    Updated_By,
    Updated_On,
    Description,
    Message,
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    count, page, rowsPerPage, onChangePage,
  } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: "Contact_Name",
    numeric: false,
    disablePadding: false,
    label: "Activity-Type",
    headClassName: "activityContactName",
  },
  {
    id: "Phone",
    numeric: true,
    disablePadding: false,
    label: "Status",
    headClassName: "activityPhone",
  },
  {
    id: "Creation_Date",
    numeric: true,
    disablePadding: false,
    label: "Description",
    headClassName: "activityCreationDate",
  },
  {
    id: "Update_Date",
    numeric: true,
    disablePadding: false,
    label: "Updated By",
    headClassName: "activityUpdateDate",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Updated On",
    headClassName: "status",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ProviderActivity = props => {
  const [statusDetails, setStatusPid] = React.useState({
    pid: "",
    name: "",
    status: "",
  });
  const [values, setValues] = React.useState({
    status: "",
    id: "",
  });
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Updated_On");
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const [openModel, setOpenModel] = React.useState(false);
  const [initialCall, setInitialCall] = React.useState(false);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [openBoardingModel, setopenBoardingModel] = React.useState(false);
  const [preStatusUpdate, setPreStatusUpdate] = React.useState(
    props.tableData.statusUpdate
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (!props.tableData.statusList) {
      props.getProviderStatusNormal();
    }
  }, []);

  React.useEffect(() => {
    if (preStatusUpdate !== props.tableData.statusUpdate) {
      setPreStatusUpdate(props.tableData.statusUpdate);
      setOpenModel(false);
    }

    setopenBoardingModel(false);
  }, [props]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const saveStatus = () => {
    props.changeSingleStatus(values);
  };

  const handleBack = () => {
    if (props.tableData.formMode === "Edit") {
      props.history.push(`/${props.userDetails.layout}/user`);
    } else {
      props.history.push(`/${props.userDetails.layout}/Live`);
    }
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    if (
      !props.tableData.providerList
      && !props.tableData.loading
      && !props.tableData.error
    ) {
      const requestData = { ...props.tableData.searchDetails };
      props.getProviderByFilterData({ ...requestData });
    }
  }, [props]);

  rows = [];

  if (props.tableData.providerActivityById) {
    props.tableData.providerActivityById.data.forEach((v, i) => {
      const DBA_Name = v.DBA_Name;
      const E_mail = v.Business_EMail;
      const Activity_Type = v.Activity_Type;
      const Sub_Merchant_ID = v.Sub_Merchant_ID;
      const Updated_By = v.Updated_By;
      const Updated_On = v.Updated_On;
      const Description = v.Description;
      const Message = v.Message;
      rows.push(
        createData(
          DBA_Name,
          E_mail,
          Activity_Type,
          Sub_Merchant_ID,
          Updated_By,
          Updated_On,
          Description,
          Message
        )
      );
    });
  }

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    allState.id = statusDetails.id;
    setValues(allState);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  React.useEffect(() => {
    if (props.tableData.providerActivityById?.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData]);

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        <Modal
          open={openBoardingModel}
          onClose={() => setopenBoardingModel(false)}
          align="center"
        >
          <div
            className={classes.modalDiv}
            style={{ width: "48%" }}
            align="center"
          >
            <Card className={classes.modalRoot}>
              <div>
                <ClearIcon
                  onClick={() => setopenBoardingModel(false)}
                  style={{ float: "right", pointer: "cursor" }}
                />
                <CardHeader subheader="Activity Response" />
              </div>
              <div style={{ margin: "3%" }}>
                <Grid container className={classes.margin}>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>DBA Name: </span>
                    {" "}
                    {`${statusDetails.name}`}
                  </Grid>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>Email: </span>
                    {" "}
                    {`${statusDetails.email}`}
                  </Grid>
                </Grid>
                <Grid container className={classes.margin}>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>
                      Sub Marchent Id:
                      {" "}
                    </span>
                    {" "}
                    {`${statusDetails.submarchecntId}`}
                  </Grid>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>Status: </span>
                    {" "}
                    {`${statusDetails.status}`}
                  </Grid>
                </Grid>
              </div>
              <div>
                <CardHeader subheader="Error Log" />
              </div>
              <div>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        Error Code
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        Field Name
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        Description
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.cellText} />
                      <TableCell className={classes.cellText} align="center" />
                      <TableCell className={classes.cellText} align="center" />
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Card>
          </div>
        </Modal>
        <Modal
          open={openModel}
          onClose={() => setOpenModel(false)}
          align="center"
        >
          <div
            className={classes.modalDiv}
            style={{ width: "50%" }}
            align="center"
          >
            <Card className={classes.modalRoot}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "40%",
                }}
              >
                <CardHeader subheader="Change Status" />
                <ClearIcon onClick={() => setOpenModel(false)} />
              </div>
              <Grid container className={classes.margin}>
                <Grid item className={classes.searchRoot}>
                  {`DBA: ${statusDetails.name}`}
                </Grid>
              </Grid>
              <div className={classes.modalCard}>
                <Grid container className={classes.margin}>
                  <Grid item xs>
                    <TextField
                      className={classes.rootInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Status"
                      value={values.status}
                      onChange={e => onChange(e.target.value, "status")}
                      name="status"
                      id="status"
                      select
                    >
                      {props.tableData.statusList
                        && props.tableData.statusList.Provider_Status_Data.map(
                          key => (
                            <MenuItem
                              className={classes.dialogBoxDropDown}
                              value={key.Status_Code}
                            >
                              {key.Provider_Status}
                            </MenuItem>
                          )
                        )}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container className={classes.marginTop}>
                  <Grid item xs={8} />
                  <Grid item xs={4}>
                    <CoustomButton
                      aria-controls="simple-menu"
                      onClick={() => saveStatus()}
                      color="primary"
                    >
                      Change Status
                    </CoustomButton>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </div>
        </Modal>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item xs={2}>
              <Button
                className={classes.clearButton}
                onClick={() => handleBack()}
              >
                Back
              </Button>
            </Grid>
            <Grid
              item
              sm={2}
              xs={2}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ fontWeight: "bold" }}>DBA: </span>
              &#160;
              <span style={{ color: "#4856c1", fontWeight: "bold" }}>
                {` ${
                  rows.length > 0 ? rows[0].DBA_Name : ""
                }`}
              </span>
            </Grid>
            <Grid
              item
              sm={4}
              xs={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ fontWeight: "bold" }}>E-mail: </span>
              &#160;
              <span style={{ color: "#4856c1", fontWeight: "bold" }}>
                {` ${
                  rows.length > 0 ? rows[0].E_mail : ""
                }`}
              </span>
            </Grid>
            {/* <Button aria-controls="simple-menu" color="primary" onClick={providerregisterurl}>
                <AddIcon />
                New
              </Button> */}
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "85%" }}>
                  <TextField
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      autoComplete: "off",
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    fullWidth
                    label="Search"
                    onClick={() => handleClickOpen()}
                    name="status"
                    id="status"
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog close={() => handleClose()} />
                  </Collapse>
                </Box>
              </ClickAwayListener>

              <Button
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.clearButton}
                onClick={() => handleBack()}
              >
                Back
              </Button>
            </Grid>
            <Grid xs={6} item>
              {" "}
            </Grid>
            <Grid item>
              <CoustomButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.ExportButtonStyle}
              >
                Export
              </CoustomButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleCloseMenu}>As Pdf</MenuItem>
                <MenuItem onClick={handleCloseMenu}>My account</MenuItem>
              </Menu>
              {/* <Button aria-controls="simple-menu" color="primary" onClick={providerregisterurl}>
                <AddIcon />
                New
              </Button> */}
            </Grid>
          </Grid>
        )}
        <Grid
          container
          style={{ flexDirection: "row-reverse", paddingRight: "5%" }}
        >
          <Grid item>
            {chipValue
              && chipValue.map(data => (
                <Chip
                  label={`${data.name}: ${data.value}`}
                  // onDelete={() => handleDeleteChip(data)}
                />
              ))}
          </Grid>
        </Grid>
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {(!isEmpty(rows) && initialCall)
            || (isEmpty(rows) && !initialCall) ? (
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow>
                      <TableCell className={classes.cellText} align="left">
                        {row.Activity_Type}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.Message}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.Description !== undefined
                          ? typeof row.Description === "string"
                            ? row.Description
                            : row.Description.map(v => v.errorDescription)
                          : ""}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.Updated_By}
                      </TableCell>
                      <TableCell className={classes.cellText} align="left">
                        {row.Updated_On}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={12}>
                    <img
                      src={noDataImage}
                      alt=""
                      className={classes.noDataImage}
                    />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value: -1,
                    },
                  ]}
                  colSpan={12}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } },
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

ProviderActivity.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  getProviderStatusNormal: PropTypes.func,
  changeSingleStatus: PropTypes.func,
  userDetails: PropTypes.object,
  getProviderByFilterData: PropTypes.func,
};

const mapStateToProps = app => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
});
export default connect(mapStateToProps, {
  getProviderList,
  changeProviderFormMode,
  getProviderStatusNormal,
  getProviderByFilterData,
  changeSingleStatus,
})(ProviderActivity);
