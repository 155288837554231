/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/extensions */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
// import InputLabel from "@material-ui/core/InputLabel";
import isEmpty from "lodash/isEmpty";
// core components
import TextField from "@material-ui/core/TextField";
// import FormControl from "@material-ui/core/FormControl";
// import MenuItem from "@material-ui/core/MenuItem";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Grid from "@material-ui/core/Grid";
import { Typography, Checkbox, MenuItem } from "@material-ui/core";
import closeImage from "assets/img/Close_Image.png";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { ErrorMessage, Field, Form, Formik } from "formik";
import styles from "assets/jss/material-dashboard-react/components/businessForm.js";
import Modal from "@material-ui/core/Modal";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { locationCity } from "../../constant/locationConstant";
import gif from "../../assets/img/success.gif";
import { callOnBoarding } from "../../Redux/Actions/OnBordingActions";
import Validations from "../../Utils/Validations";
import {
  sendMailAgain,
  getPricingTemplete,
  getApplicationType,
  getProccesorList
} from "../../actions/MerchantSignUp";
import {
  pricingTempleteCallInvite,
  setPricingTempleteEmpty
} from "../../Redux/Actions/ProvideRegistration";

const useStyles = makeStyles(styles);
function OnBording(props) {
  const theme = useTheme();
  const [data, setData] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const windowWidth = useMediaQuery(theme.breakpoints.up("sm"));
  const windowWidthLG = useMediaQuery(theme.breakpoints.up("lg"));
  const [pricingTemplateList, setPricingTemplateList] = React.useState([]);
  const [proccssorList, setProccssorList] = React.useState([]);
  const [applicationTypeList, setApplicationTypeList] = React.useState([]);
  const [bla, setBla] = React.useState({
    processId: "",
    applicationType: "",
    templateId: ""
  });
  const [preOnBoradData, setPreOnBoradData] = React.useState(
    props.mainData.onBoarding.data
  );
  const initialValues = {
    Profile_Code: "Merchant",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Email: "",
    Password: "Temp@1234",
    Role_ID: "62ab2be24d4b6a1295ea3d0a",
    Address1: "Testst",
    Address2: "",
    City: "AL",
    Mobile: "9874586321",
    Phone_No: "",
    Country_Code: "US",
    State_Code: "AR",
    Zip: "00124",
    Time_Zone_Code: "AKST",
    Accessible_Provider: ["H001"],
    ClientID: "H001",
    CreateByClientId: "H001",
    ClientId: "H001",
    legalName: "",
    dbaName: "",
    formType: "MerchantSetup",
    applicationType: "",
    Created_By: localStorage.getItem("userId")
  };

  // const formik = React.useRef();

  const [successModal, setSuccessModal] = React.useState(false);
  const classes = useStyles();
  const [tempValues, setTempValues] = React.useState({});
  const [customValue, setCustomValue] = React.useState("");

  const handelValidation = () => {
    let validate = true;
    data.forEach((key) => {
      key.categoryItem.forEach((main) => {
        if (main.activeCheckbox) {
          if (main.perItemFrom > 0 && main.perItemTo > 0) {
            const checkValue =
              (Number(main.perItemRate) >= Number(main.perItemFrom) &&
                Number(main.perItemRate) <= Number(main.perItemTo) &&
                Number(main.perItemRate) <= Number(main.perItemTo)) ||
              Number(main.perItemRate) === 0;
            if (!checkValue && validate) {
              validate = false;
              enqueueSnackbar(
                `The value of ${main.itemName} Should be in range of ${main.perItemFrom} to ${main.perItemTo}`,
                {
                  variant: "error"
                }
              );
            }
          }
        }
      });
    });
    return validate;
  };

  const handleOnChangeProccess = (data, type) => {
    const allValue = { ...bla };
    allValue[type] = data;
    if (type === "processId") {
      allValue.applicationType = "";
      allValue.templateId = "";
      props.setPricingTempleteEmpty();
    }
    if (type === "applicationType") {
      allValue.templateId = "";
      props.setPricingTempleteEmpty();
    }
    if (type === "templateId") {
      props.setPricingTempleteEmpty();
    }
    setBla(allValue);
  };

  const handleSubmit = (values) => {
    setTempValues(values);
    const addCreatedBy = { ...values };
    if (isEmpty(addCreatedBy.dbaName)) {
      addCreatedBy.dbaName = addCreatedBy.legalName;
    }
    addCreatedBy.Created_By = localStorage.getItem("userId");
    if (handelValidation()) {
      addCreatedBy.merchantTemplate = data;
      props.callOnBoarding({ ...addCreatedBy });
    }
  };

  const handleModal = () => {
    setSuccessModal(false);
    if (props.mainData.onBoarding.data.status) {
      props.history.push("/admin/InProgress");
    }
  };

  React.useEffect(() => {
    if (
      data !== props.mainData.provideRegistration.pricingTemplete &&
      props.mainData.provideRegistration.pricingTemplete.data
    ) {
      if (
        props.mainData.provideRegistration.pricingTemplete.data.length === 0
      ) {
        setData([]);
        const preBla = { ...bla };
        preBla.templateId = "";
        setBla(preBla);
      } else {
        setData(props.mainData.provideRegistration.pricingTemplete.data);
      }
    }
    if (
      data.length > 0 &&
      !props.mainData.provideRegistration.pricingTemplete
    ) {
      setData(false);
    }
  }, [props.mainData.provideRegistration.pricingTemplete]);

  React.useEffect(() => {
    props.getPricingTemplete(
      (data) => {
        if (data.status) {
          setPricingTemplateList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
    props.getApplicationType(
      (data) => {
        if (data.status) {
          setApplicationTypeList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
    props.getProccesorList(
      (data) => {
        if (data.status) {
          setProccssorList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  }, []);

  const resendMailApiCall = () => {
    props.sendMailAgain(
      { email: tempValues.Email },
      (data) => {
        if (data.status) {
          enqueueSnackbar(`${data.message}`, {
            variant: "success"
          });
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
        handleModal();
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
        handleModal();
      }
    );
  };
  React.useEffect(
    () => () => {
      props.setPricingTempleteEmpty();
    },
    []
  );

  const handleFocus = (data) => {
    setTimeout(() => document.getElementById(data).focus(), 50);
  };

  React.useEffect(() => {
    if (props.mainData.onBoarding.data !== preOnBoradData) {
      if (props.mainData.onBoarding.data) {
        setSuccessModal(true);
      }
      setPreOnBoradData(props.mainData.onBoarding.data);
    }
  }, [props]);

  React.useEffect(() => {
    if (
      bla.processId !== "" &&
      bla.templateId !== "" &&
      (!data || data.length === 0)
    ) {
      const data = {
        templateId: bla.templateId,
        processorId: bla.processId
      };
      props.pricingTempleteCallInvite(data);
    }
  }, [bla, data]);

  const showIempMin = (data) => {
    const returnArray = [];
    const ticker = data.perItemTicker * 10000;
    const to = data.perItemTo * 10000;
    const from = data.perItemFrom * 10000;
    if (data.perItemTicker !== 0) {
      if (data.perItemTicker % 1 !== 0) {
        for (let i = from; i <= to; i += ticker) {
          if (i >= 0) {
            const finalValue = i / 10000;
            returnArray.push(
              data.perItemTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      } else {
        for (let i = from; i <= to; i += ticker) {
          if (i >= 0) {
            const finalValue = i / 10000;
            returnArray.push(
              data.perItemTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      }
    }
    if (ticker === 0 && to === 0 && from === 0) {
      returnArray.push("0");
    }
    returnArray.unshift("Custom");

    // if (
    //   (returnArray.filter(
    //     (key) => Number(key) === Number(data.perItemRate)
    //   ).length = 0)
    // ) {
    //   returnArray.unshift(data.perItemRate);
    // }
    // console.log("returnArray", returnArray);
    return returnArray;
  };

  const onChange = (value, type, mainIndex, itemIndex) => {
    const updatedDate = [...data];
    if (type !== "check") {
      updatedDate[mainIndex].categoryItem[itemIndex].perItemRate = value;
    } else {
      updatedDate[mainIndex].categoryItem[itemIndex].perItemRate = 0;
      updatedDate[mainIndex].categoryItem[itemIndex].activeCheckbox = value;
    }
    if (type === "text") {
      handleFocus(updatedDate[mainIndex].categoryItem[itemIndex].itemName);
    }
    setData([...updatedDate]);
  };

  return (
    <div>
      <Modal open={successModal} onClose={() => handleModal(false)}>
        <div className={classes.modalDiv}>
          <Card
            className={
              windowWidth ? classes.modalCard : classes.mobileModalCard
            }
          >
            <div
              style={{ display: "inline-table" }}
              className={classes.cardDiv1}
            >
              <img
                src={
                  props.mainData.onBoarding.data &&
                  props.mainData.onBoarding.data.status
                    ? gif
                    : closeImage
                }
                width="75px"
              />

              <div
                style={{
                  textAlign: "center",
                  padding: "4%",
                  color: "gray",
                  fontSize: "14px",
                  whiteSpace: "normal"
                }}
              >
                {props.mainData.onBoarding.data &&
                props.mainData.onBoarding.data.status
                  ? props.mainData.onBoarding.data.message
                  : props.mainData.onBoarding.data.message}
              </div>
              <br />
              {props.mainData.onBoarding.data.status === false &&
              props.mainData.onBoarding.data.message ===
                "Signup invitation has been already sent to this email id. Do you want to send invitation again?" ? (
                <Grid justifyContent="center" spacing={2} container>
                  <Grid xs={4} item>
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      color="#696969"
                      size="sm"
                      onClick={resendMailApiCall}
                    >
                      Yes
                    </Button>
                  </Grid>
                  <Grid xs={4} item>
                    <Button
                      variant="contained"
                      className={classes.clearButton}
                      color="#696969"
                      size="sm"
                      onClick={() => handleModal()}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  size="sm"
                  onClick={() => handleModal()}
                >
                  Ok
                </Button>
              )}
            </div>
          </Card>
        </div>
      </Modal>
      <Card
        style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
      >
        <Box
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            alignItems: "center"
          }}
          className={classes.tittleColor}
          height={40}
        >
          <Typography className={classes.tittleTextColor}>
            Merchant Setup
          </Typography>
        </Box>
        <Formik
          // innerRef={values => { formik.current = values; }}
          validationSchema={Validations.merchantSignUp}
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <Box style={{ paddingTop: "20px" }} className={classes.marginBox}>
                <Grid container>
                  <Grid item lg={4} sm={6} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      required
                      className={classes.root}
                      helperText={
                        errors.First_Name && touched.First_Name
                          ? errors.First_Name
                          : ""
                      }
                      error={errors.First_Name && touched.First_Name}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="First Name"
                      name="First_Name"
                      id="First_Name"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Last Name"
                      name="Last_Name"
                      helperText={
                        errors.Last_Name && touched.Last_Name
                          ? errors.Last_Name
                          : ""
                      }
                      error={errors.Last_Name && touched.Last_Name}
                      id="Last_Name"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <EmailIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Email"
                      name="Email"
                      helperText={
                        errors.Email && touched.Email ? errors.Email : ""
                      }
                      error={errors.Email && touched.Email}
                      id="Email"
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <EventNoteIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <Field
                      as={TextField}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Legal Name"
                      name="legalName"
                      id="legalName"
                      helperText={
                        errors.legalName && touched.legalName
                          ? errors.legalName
                          : ""
                      }
                      error={errors.legalName && touched.legalName}
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <EventNoteIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <Field
                      as={TextField}
                      fullWidth
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      required
                      label="DBA Name"
                      name="dbaName"
                      id="dbaName"
                      helperText={
                        errors.dbaName && touched.dbaName ? errors.dbaName : ""
                      }
                      error={errors.dbaName && touched.dbaName}
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      select
                      required
                      onChange={(e) => {
                        setFieldValue("processId", e.target.value);
                        handleOnChangeProccess(e.target.value, "processId");
                      }}
                      fullWidth
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Processor"
                      name="processId"
                      id="processId"
                    >
                      {proccssorList.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key._id}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      select
                      required
                      disabled={bla.processId === ""}
                      fullWidth
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("applicationType", e.target.value);
                        handleOnChangeProccess(
                          e.target.value,
                          "applicationType"
                        );
                      }}
                      label="Application Type,"
                      name="applicationType"
                      id="applicationType"
                    >
                      {applicationTypeList.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key._id}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <EmailIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      select
                      required
                      fullWidth
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      disabled={
                        bla.processId === "" || bla.applicationType === ""
                      }
                      onChange={(e) => {
                        setFieldValue("pricingTemplate", e.target.value);
                        handleOnChangeProccess(e.target.value, "templateId");
                      }}
                      label="Pricing Template"
                      name="pricingTemplate"
                      id="pricingTemplate"
                    >
                      {pricingTemplateList.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key._id}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center"
                    // padding: "4%",
                  }}
                >
                  <>
                    {data &&
                      data.length > 0 &&
                      data
                        .sort((a, b) =>
                          a.categoryName > b.categoryName ? 1 : -1
                        )
                        .map((key, mainIndex) => (
                          <>
                            <Box
                              style={{
                                alignItems: "center",
                                marginTop: "10px"
                              }}
                              key={Math.random()}
                              className={classes.tittleColor}
                              height={40}
                            >
                              <Typography className={classes.tittleTextColor}>
                                {key.categoryName}
                              </Typography>
                            </Box>
                            <Box className={classes.marginBox}>
                              <Grid container className={classes.margin}>
                                {(key.categoryItem.length == 2
                                  ? key.categoryItem
                                  : key.categoryItem.sort((a, b) =>
                                      a.itemName > b.itemName ? 1 : -1
                                    )
                                ).map((item, index) => (
                                  <>
                                    <Grid
                                      key={Math.random()}
                                      item
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        padding: "20px"
                                      }}
                                      lg={4}
                                      sm={6}
                                      xs={12}
                                    >
                                      <div style={{ minWidth: "5%" }}>
                                        <Checkbox
                                          checked={item.activeCheckbox}
                                          onChange={(e) => {
                                            onChange(
                                              e.target.checked,
                                              "check",
                                              mainIndex,
                                              index
                                            );
                                          }}
                                          inputProps={{
                                            "aria-label": "select all desserts"
                                          }}
                                          name={`name-${index}`}
                                          id={`id-${index}`}
                                          color="primary"
                                        />
                                      </div>
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          lineHeight: "20px",
                                          maxWidth: "25%",
                                          minWidth: "25%",
                                          padding: "1%"
                                        }}
                                      >
                                        {item.itemName}
                                      </span>
                                      <div
                                        style={{
                                          maxWidth: "25%",
                                          minWidth: "25%",
                                          padding: "1%"
                                        }}
                                      >
                                        <TextField
                                          className={classes.quaterInputPricing}
                                          style={{ minWidth: "100%" }}
                                          key={Math.random()}
                                          disabled={!item.activeCheckbox}
                                          InputLabelProps={{
                                            classes: {
                                              root: classes.activeInputLableColor,
                                              focused: "focused"
                                            }
                                          }}
                                          InputProps={{
                                            classes: {
                                              root: classes.activeInputColor,
                                              input: classes.resizePrice,
                                              focused: "focused"
                                            }
                                          }}
                                          defaultValue="Custom"
                                          value={
                                            item.perItemRate !== "Custom"
                                              ? item.perItemTicker % 1 !== 0
                                                ? Number(item.perItemRate)
                                                    .toFixed(4)
                                                    .toString()
                                                : item.perItemRate
                                              : "Custom"
                                          }
                                          onChange={(e) => {
                                            onChange(
                                              e.target.value,
                                              item,
                                              mainIndex,
                                              index
                                            );
                                          }}
                                          name={`name-${index}`}
                                          id={`id-${index}`}
                                          select
                                        >
                                          {showIempMin(
                                            item,
                                            index,
                                            key.categoryItem.length
                                          ).length > 0 &&
                                            showIempMin(
                                              item,
                                              index,
                                              key.categoryItem.length
                                            ).map((number) => (
                                              <MenuItem
                                                key={Math.random()}
                                                className={
                                                  classes.dialogBoxDropDown
                                                }
                                                value={
                                                  number === "Custom"
                                                    ? item.perItemTicker % 1 !==
                                                      0
                                                      ? Number(item.perItemRate)
                                                          .toFixed(4)
                                                          .toString()
                                                      : item.perItemRate
                                                    : number
                                                }
                                              >
                                                {number}
                                              </MenuItem>
                                            ))}
                                        </TextField>
                                      </div>
                                      <div
                                        style={{
                                          maxWidth: "25%",
                                          minWidth: "25%",
                                          padding: "1%",
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            lineHeight: "20px",
                                            maxWidth: "25%",
                                            minWidth: "25%",
                                            padding: "1%",
                                            textAlign: "end"
                                          }}
                                        >
                                          {item.rateTypeSing === "$"
                                            ? item.rateTypeSing
                                            : ""}
                                        </span>
                                        <TextField
                                          className={classes.quaterInputPricing}
                                          key={Math.random()}
                                          InputLabelProps={{
                                            classes: {
                                              root: classes.activeInputLableColor,
                                              focused: "focused"
                                            }
                                          }}
                                          InputProps={{
                                            classes: {
                                              root: classes.activeInputColor,
                                              input: classes.resizePrice,
                                              focused: "focused"
                                            }
                                          }}
                                          variant="outlined"
                                          style={{
                                            backgroundColor: "#f9f7f7",
                                            minWidth: "100%"
                                          }}
                                          value={
                                            item.perItemRate !== "Custom"
                                              ? item.perItemRate
                                              : customValue
                                          }
                                          disabled={!item.activeCheckbox}
                                          onChange={(e) => {
                                            if (item.perItemRate === "Custom") {
                                              onChange(
                                                setCustomValue(e.target.value),
                                                "text",
                                                mainIndex,
                                                index
                                              );
                                            } else {
                                              onChange(
                                                e.target.value,
                                                "text",
                                                mainIndex,
                                                index
                                              );
                                            }
                                          }}
                                          name={item.itemName}
                                          id={item.itemName}
                                        />
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            lineHeight: "20px",
                                            maxWidth: "25%",
                                            minWidth: "25%",
                                            padding: "1%"
                                          }}
                                        >
                                          {item.rateTypeSing !== "$"
                                            ? item.rateTypeSing
                                            : ""}
                                        </span>
                                      </div>
                                    </Grid>
                                  </>
                                ))}
                              </Grid>
                            </Box>
                          </>
                        ))}
                    {data.length === 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <h5>
                          <strong>No Pricing Data Present</strong>
                        </h5>
                      </div>
                    )}
                  </>
                </div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    textAlign: "center",
                    justifyContent: "space-evenly",
                    marginTop: "30px",
                    marginBottom: "20px"
                  }}
                >
                  <Grid item sm={3} xs={8}>
                    <Button
                      className={classes.buttonStyle}
                      variant="contained"
                      fullWidth
                      disabled={!isEmpty(errors)}
                      color="#696969"
                      type="submit"
                    >
                      Submit and Send
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}

OnBording.propTypes = {
  mainData: PropTypes.object,
  // history: PropTypes.object,
  callOnBoarding: PropTypes.func,
  sendMailAgain: PropTypes.func,
  getPricingTemplete: PropTypes.func,
  history: PropTypes.object,
  getApplicationType: PropTypes.func,
  getProccesorList: PropTypes.func,
  pricingTempleteCallInvite: PropTypes.func,
  setPricingTempleteEmpty: PropTypes.func
};

const mapStateToProps = (app) => ({
  mainData: app
});
export default connect(mapStateToProps, {
  callOnBoarding,
  sendMailAgain,
  getPricingTemplete,
  getApplicationType,
  getProccesorList,
  pricingTempleteCallInvite,
  setPricingTempleteEmpty
})(OnBording);
