/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  getRoleByFilterData,
  setRoleSearchData,
} from "../../Redux/Actions/RoleRegistration";

const useStyles = makeStyles(styles);

function SimpleDialog(props) {
  const classes = useStyles();

  const onChange = (value, type) => {
    const allState = { ...props.formData.searchDetails };
    allState[type] = value;
    props.setRoleSearchData(allState);
  };

  const onFormSubmit = () => {
    const allState = { ...props.formData.searchDetails };
    allState.pageNo = 1;
    props.getRoleByFilterData(allState);
    props.seacrhChipData(allState);
    props.close();
  };

  const clearButton = () => {
    const allState = { ...props.formData.searchDetails };
    allState.Name = "";
    allState.Role = "";
    props.seacrhChipData(allState);
    props.getRoleByFilterData(allState);
    props.setRoleSearchData(allState);
    props.close();
  };

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize,
              },
            }}
            autoComplete="off"
            label="Roll Name"
            value={props.formData.searchDetails.Name}
            onChange={e => onChange(e.target.value, "Name")}
            name="Name"
            id="Name"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            SelectProps={{
              MenuProps: { disablePortal: false },
            }}
            label="Status"
            value={props.formData.searchDetails.Current_Status}
            onChange={e => onChange(e.target.value, "Current_Status")}
            name="Current_Status"
            id="Current_Status"
            select
            style={{ fontSize: "82%" }}
          >
            <MenuItem className={classes.dialogBoxDropDown} value="All">All</MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Active">Active</MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Inactive">Inactive</MenuItem>
          </TextField>

        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "8%" }}>
        <Grid item xs textAlign="right">
          <br />
          <Button
            cancel
            disabled={props.formData.searchDetails.Current_Status === "" && props.formData.searchDetails.Name === ""}
            variant="contained"
            className={classes.clearButton}
            size="md"
            onClick={() => clearButton()}>
            clear
          </Button>
&nbsp;&nbsp;&nbsp;
          <Button
            disabled={props.formData.searchDetails.Current_Status === "" && props.formData.searchDetails.Name === ""}
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            size="md"
            onClick={() => onFormSubmit()}>
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  close: PropTypes.func,
  getRoleByFilterData: PropTypes.func,
  seacrhChipData: PropTypes.func,
  searchValue: PropTypes.object,
  formData: PropTypes.object,
  setRoleSearchData: PropTypes.func,
};

const mapStateToProps = app => ({
  formData: app.roles,
});

export default connect(mapStateToProps, {
  getRoleByFilterData,
  setRoleSearchData,
})(SimpleDialog);
