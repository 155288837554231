/* eslint-disable import/extensions */
import React from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {
  RadioGroup, FormControlLabel, Radio, AppBar, Tab, Tabs,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import IconButton from "@material-ui/core/IconButton";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const useStyles = makeStyles(styles);

function TabPanelAttchment(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: "20px" }}>{children}</Box>}
    </div>
  );
}

TabPanelAttchment.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: "20px" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const KycChecklist = props => {
  const classes = useStyles();
  const theme = useTheme();
  console.log(props.accordian);

  return (
    <>
      <Grid
        container
        style={{ flexWrap: "nowrap" }}
        className={classes.fabCheckList}
      >
        <Grid
          container
          style={{ flexWrap: "nowrap" }}
          className={classes.tittleColorCheck}
          justifyContent="space-between"
          height={50}
        >
          <Typography className={classes.tittleTextColorList}>
            KYC-Checklist Review
          </Typography>
          <IconButton
            edge="end"
            style={{ margin: "15px" }}
            size="small"
            onClick={() => props.handleCheckListModalClose("kyc")}
          >
            <CloseIcon fontSize="small" style={{ color: "#fff" }} />
          </IconButton>
        </Grid>
        {!props.commentSection ? (
          <>
            <Grid
              style={{
                display: "flex",
                marginBottom: "25px",
                marginTop: "25px",
                alignItems: "center",
                justifyContent: "center",
              }}
              container
            >
              <Grid item xs={8}>
                <Autocomplete
                  id="selectOwner"
                  options={[...props.values.ownerArray.owners]}
                  getOptionLabel={option => option
                    ? `${option.Owner_First_Name} ${option.Owner_Last_Name}`
                    : ""}
                  fullWidth
                  value={props.ownerSelect || props.values.ownerArray.owners[0]}
                  onChange={(event, newValue) => {
                    props.setOwnerSelect(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Owner"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                  helperText="Please select a Owner"
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{ flexWrap: "nowrap" }}
              className={classes.margin}
            >
              <Grid
                item
                xs
                className={classes.marginCheckList}
                style={{ border: "0.5px solid" }}
                alignItems="center"
                justifyContent="center"
              >
                <Typography align="center" style={{ color: "#ff4747" }}>
                  Pending
                </Typography>
                <Typography align="center" style={{ color: "#ff4747" }}>
                  {props.kycCheckListStatus.pending}
                </Typography>
              </Grid>
              <Grid
                item
                xs
                className={classes.marginCheckList}
                style={{ border: "0.5px solid" }}
                alignItems="center"
                justifyContent="center"
              >
                <Typography align="center" style={{ color: "#f5d16e" }}>
                  In Progress
                </Typography>
                <Typography align="center" style={{ color: "#f5d16e" }}>
                  {props.kycCheckListStatus.progress}
                </Typography>
              </Grid>
              <Grid
                item
                xs
                className={classes.marginCheckList}
                style={{ border: "0.5px solid" }}
                alignItems="center"
                justifyContent="center"
              >
                <Typography align="center" style={{ color: "#0abf28" }}>
                  Completed
                </Typography>
                <Typography align="center" style={{ color: "#0abf28" }}>
                  {props.kycCheckListStatus.completed}
                </Typography>
              </Grid>
            </Grid>
            {props.formData.kycQuestionData
                && props.formData.kycQuestionData.data
                && props.formData.kycQuestionData.data.map((key, i) => (
                  <Grid
                    container
                    style={{ flexWrap: "nowrap", marginTop: "3%" }}
                  >
                    <Accordion
                      style={{ width: "inherit", margin: "5px" }}
                      elevation={1}
                      expanded={
                        props.accordian.index.length > 0
                          ? props.accordian.index.filter(v => v === i).length > 0
                          : false
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => props.handleAccordian(i)}
                      >
                        <Typography color="primary">{key.header}</Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {key.list.map((item, index) => (
                          <>
                            <Grid container direction="column">
                              <Grid item>
                                {`${index + 1}. ${item.qusestion}`}
                              </Grid>
                              <Grid
                                item
                                justifyContent="space-between"
                                style={{ display: "flex", paddingLeft: "20px" }}
                              >
                                <RadioGroup
                                  row
                                  aria-label="position"
                                  name="position"
                                  defaultValue="top"
                                >
                                  {item.options.map(keyItem => (
                                    <FormControlLabel
                                      value={keyItem.option}
                                      style={{
                                        color: "black",
                                        fontSize: "14px",
                                      }}
                                      control={
                                        <Radio
                                          color="primary"
                                          checked={keyItem.optionSelected}
                                        />
                                      }
                                      onClick={() => props.handleQuestionStatus(
                                        item,
                                        keyItem._id,
                                        "KYC_Checklist",
                                        i
                                      )}
                                      label={keyItem.option}
                                    />
                                  ))}
                                </RadioGroup>
                                <IconButton
                                  onClick={() => props.handleCommentSection(item, "KYCChecklist")}
                                >
                                  <ChatBubbleOutlineIcon fontSize="small" />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Divider />
                          </>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
            <Grid
              container
              style={{ justifyContent: "flex-start" }}
              className={classes.margin}
            >
              <Grid item sm={1} xs={10} />
              {props.formData.questionData
                  && props.formData.questionData.data
                  && props.formData.questionData.confirmButton !== "Approve" && (
                  <Grid item sm={3} xs={10}>
                  <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      color="#696969"
                      onClick={() => props.setApproveModal(true)}
                      size="large"
                  >
                      <Typography nowrap>approve</Typography>
                    </Button>
                </Grid>
              )}
              <Grid item sm={1} xs={10} />
              {props.formData.questionData
                  && props.formData.questionData.data
                  && props.formData.questionData.confirmButton !== "Approve" && (
                  <Grid item sm={3} xs={10}>
                  <Button
                      variant="contained"
                      className={classes.clearButton}
                      color="#696969"
                      onClick={() => props.setDeclineModal(true)}
                      size="large"
                  >
                      <Typography nowrap>Decline</Typography>
                    </Button>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              justifyContent="space-between"
              height={50}
            >
              <Typography className={classes.tittleTextColorAtteched}>
                {`Checklist items: ${
                  props.selectedQuestion && props.selectedQuestion.qusestion
                }`}
              </Typography>

              <IconButton onClick={() => props.setCommentSection(false)}>
                <ArrowBackIcon fontSize="small" style={{ color: "#000" }} />
              </IconButton>
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <AppBar position="static" color="default">
                <Tabs
                  value={props.value}
                  onChange={props.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Comments" {...a11yProps(0)} />
                  <Tab label="Attachments" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                style={{ width: "100%" }}
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={props.value}
                onChangeIndex={props.handleChangeIndex}
              >
                <TabPanel value={props.value} index={0} dir={theme.direction}>
                  <TextareaAutosize
                    onChange={e => props.setTextAreaText(e.target.value)}
                    value={props.textAreaText}
                    style={{ width: "100%" }}
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Add comment here"
                  />
                  <Grid container>
                    <Grid container item xs justify="flex-end">
                      <Button
                        variant="contained"
                        className={classes.buttonStyle}
                        color="#696969"
                        style={{ margin: "10px" }}
                        size="sm"
                        onClick={() => props.sendComment()}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanelAttchment
                  value={props.value}
                  index={1}
                  dir={theme.direction}
                >
                  <Grid container>
                    <Grid container item xs justify="flex-end">
                      <label htmlFor="upload-photo">
                        <input
                          style={{ display: "none" }}
                          id="upload-photo"
                          name="upload-photo"
                          type="file"
                          display="none"
                          onChange={e => props.handleUploadAttechmentOnChange(e.target.files[0])}
                        />

                        <Button
                          color="primary"
                          variant="contained"
                          component="span"
                        >
                          New
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                  {props.fileHandleAttechment.files.length > 0 && (
                    <>
                      <Grid
                        container
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                        justifyContent="flex-start"
                        height={50}
                      >
                        <Typography
                          className={classes.tittleTextColorAtteched}
                        >
                          Attechments
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                        justifyContent="flex-start"
                        height={50}
                      >
                        {props.fileHandleAttechment.files.map(item => (
                          <Typography className={classes.attechmentSection}>
                            {item.File_Name}
                          </Typography>
                        ))}
                      </Grid>
                    </>
                  )}
                </TabPanelAttchment>
              </SwipeableViews>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

KycChecklist.propTypes = {
  handleCheckListModalClose: PropTypes.func,
  fileHandleAttechment: PropTypes.object,
  formData: PropTypes.object,
  handleUploadAttechmentOnChange: PropTypes.func,
  sendComment: PropTypes.func,
  commentSection: PropTypes.bool,
  values: PropTypes.object,
  ownerSelect: PropTypes.object,
  setOwnerSelect: PropTypes.func,
  kycCheckListStatus: PropTypes.object,
  accordian: PropTypes.object,
  handleAccordian: PropTypes.func,
  handleQuestionStatus: PropTypes.func,
  handleCommentSection: PropTypes.func,
  selectedQuestion: PropTypes.object,
  setCommentSection: PropTypes.func,
  setApproveModal: PropTypes.func,
  setDeclineModal: PropTypes.func,
  setTextAreaText: PropTypes.func,
  value: PropTypes.any,
  textAreaText: PropTypes.any,
  handleChange: PropTypes.func,
  handleChangeIndex: PropTypes.func,
};

const mapStateToProps = app => ({
  formData: app.provideRegistration,
});
export default connect(mapStateToProps, null)(KycChecklist);
