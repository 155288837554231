export default {
  app: {
    token: "",
    sessionToken: "",
    loggedIn: false,
    mainAppLoading: false,
    loading: false,
    role: false,
    error: false,
    success: false,
    userDetails: false,
    appBarName: "",
    leftMenu: false,
    layout: false,
    defaultTheme: false,
    appTheme: false,
    portalConfig: false,
  },
  menu: {
    data: false,
  },
  onBoarding: {
    data: false,
    error: false,
    loading: false,
  },
  provideRegistration: {
    formMode: "Create",
    questionData: false,
    searchDetails: {
      searchDbaName: "",
      searchStatus: "",
      searchAgentId: "",
      apiType: "search",
      recordPerPage: 10,
      pageNo: 1,
      sortColumn: "Updated_date",
      sortOrder: "desc",
      statusExpression: "NOT_IN",
    },
    kycQuestionData: false,
    pricingTemplete: false,
    questionUpdate: false,
    data: false,
    mcclist: false,
    accountTypeList: false,
    ownerShipTypeList: false,
    statusList: false,
    statusListById: false,
    countryTypeList: false,
    providerList: false,
    search: false,
    providerDetailsById: false,
    statusUpdate: false,
    changeSingleStatus: false,
    providerActivityById: false,
    providerParameterData: false,
    saveProviderParameter: false,
    deleteIncropration: false,
    deleteAddress: false,
    deleteIdProof: false,
    loading: false,
    error: false,
    providerDetails: false,
    agentList: false,
    notesList: false
  },
  table: {
    activePatient: false,
    loading: false,
    error: false,
    initialCall: false,
    agentList: false,
    merchantList: false,
    notesList: false
  },
  forms: {
    master: false,
  },
  genrateOfferPatient: {
    data: false,
    selectPatient: false,
    success: false,
    emailState: false,
    forgetPassword: false,
    authTokenCheck: false,
    changePassword: false,
    patientId: false,
    updatePatientDetails: false,
    activePlanPatientList: false,
    error: false,
    loading: false,
    deleteDocument: false,
    paymentDetailsData: false,
    paymentCheckoutData: false,
    cptCodeData: false,
    addPatientData: false,
    paymentMakeDetails: false,
    patientGraphData: false,
    printOfferData: false,
    uploadDocument: false,
    genrateOfferData: false,
    sendEmailOffer: false,
    createOffer: false,
    showPatientDetailsViaMail: false,
    updatePatientDetailsOffer: false,
    showPatientInfoViaMail: false,
    patientRegisterData: false,
    patientDocumentList: false,
    offerDownloadFile: false,
    addProposalData: false,
    setUpMonthlyPayment: false,
    completedPlanPatientList: false,
    setUpMonthltFromOffer: false,
    genrateOfferLoading: false,
    defaultValueOffer: false,
    preposalDataIds: false,
    flowType: false,
    sentOTPData: false,
    verifyOTPData: false,
    userIdForgetPassword: false,
    patientTPOScoreList: false,
    generatePatientTPOScore: false,
    cernerPatientSearch: false,
  },
  roles: {
    formMode: "init",
    data: false,
    roleList: false,
    userList: false,
    roleDetailsById: false,
    updateRolePermission: false,
    profileTypeList: false,
    landingpageList: false,
    timeZoneList: false,
    updateRoleStatus: false,
    loading: false,
    searchDetails: {
      Name: "",
      Current_Status: "Active",
      pageNo: 1,
      recordPerPage: 10,
      sortColumn: "RoleName",
      sortOrder: "asc",
    },
    roleTab: false,
    error: false,
    notesList: false
  },
  users: {
    formMode: "init",
    searchDetails: {
      Name: "",
      Role: "",
      Current_Status: "Active",
      searchAgentId: "",
      recordPerPage: 10,
      pageNo: 1,
      sortColumn: "FName",
      statusExpression: "IN",
      sortOrder: "asc",
    },
    data: false,
    userList: false,
    userDetailsById: false,
    roleList: false,
    countryTypeList: false,
    profileTypeList: false,
    statusUpdate: false,
    passwordUpdate: false,
    loading: false,
    userProvider: false,
    timeZoneList: false,
    groupListData: false,
    error: false,
    agentList: false,
    merchantList: false,
    notesList: false
  },
  patientDashboard: {
    data: false,
    success: false,
    openFundingData: false,
    activePlanPatientList: false,
    patientOfferAmountData: false,
    paymentStatusData: false,
    offerAccountStatusData: false,
    originalLoanTermData: false,
    remainingFundingData: false,
    error: false,
    loading: false,
    paymentList: false,
    offetDetailsPatient: false,
  },
  dashboard: {
    data: false,
    success: false,
    recentContactData: false,
    error: false,
    loading: false,
    recentAcceptOffer: false,
    expireApplicationData: false,
    activePatientData: false,
    defaultData: false,
    moveToCollectionData: false,
    cptDistributionData: false,
    badDebatesData: false,
    totalOutStandingData: false,
    dailyDepositeData: false,
  },
  payment: {
    data: false,
  },
};
