/* eslint-disable no-restricted-syntax */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ChipInput from "material-ui-chip-input";
import moment from "moment";
import { makeStyles, useTheme, lighten } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteIcon from "@material-ui/icons/Delete";
import isEmpty from "lodash/isEmpty";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CoustomButton from "components/CustomButtons/Button";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import Card from "components/Card/Card";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Modal from "@material-ui/core/Modal";
import { useLocation } from "react-router-dom";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/Users";
import LongMenu from "../../components/Menu/User";
import { exportToCSV, actionPermission } from "../../Utils/commonUtils";
import {
  changeUserFormMode,
  changeSingleStatus,
  changePassword,
  getUserByFilterData,
  getUserDetailsById,
  setUserSearchData,
  setUserSearchEmpty,
  getRoleList,
} from "../../Redux/Actions/UserRegistration";
import { getAgentByHierarchy } from "../../Redux/Actions/ProvideRegistration";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";

const useStyles = makeStyles(tableStyle);

const userSetupCatId = "6007ff8ebf79fc1f082ab128";

const usersPageId = "60145c9252da2979768479a7";

const merchantUser = "60145cc552da297976847989";

const defaults = {
  view: 1,
  create: 2,
  edit: 3,
  import: 4,
  export: 5,
  reset_password: 6,
  Change_Status: 7,
};

function createData(
  FName,
  MName,
  userType,
  LName,
  userReportsToFullname,
  Email,
  Phone,
  Role,
  ProfileMaster,
  Created_date,
  status,
  UserID,
  editable
) {
  return {
    FName,
    MName,
    userType,
    LName,
    userReportsToFullname,
    Email,
    Phone,
    Role,
    ProfileMaster,
    Created_date,
    status,
    UserID,
    editable,
  };
}

const longText = (
  <ul>
    <li>Uppercase Letters</li>
    <li>Lowercase Letters</li>
    <li>Numbers</li>
    <li>Special Character</li>
  </ul>
);
function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    count, page, rowsPerPage, onChangePage,
  } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography>First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography>Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography>Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography>Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: "FName",
    className: "FName",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "userType",
    className: "userType",
    numeric: false,
    disablePadding: true,
    label: "User Type",
  },
  {
    id: "ClientID",
    className: "ClientID",
    numeric: false,
    disablePadding: true,
    label: "Reports To",
  },
  {
    id: "Email",
    className: "Email",
    numeric: false,
    disablePadding: true,
    label: "User Name",
  },
  {
    id: "Phone",
    className: "Phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "Role",
    className: "Role",
    numeric: false,
    disablePadding: true,
    label: "Role",
  },
  {
    id: "Created_date",
    className: "Created_date",
    numeric: true,
    disablePadding: false,
    label: "Created On",
  },
  {
    id: "status",
    className: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected}
          {" "}
          selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function UserDataList(props) {
  const classes = useStyles();
  const location = useLocation();
  const userid = localStorage.getItem("userId");
  const path = window.location.pathname.split("/")[2];
  const [order, setOrder] = React.useState("asc");
  const [initialCall, setInitialCall] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("FName");
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(props.tableData.searchDetails.pageNo);
  const [temp, setTemp] = React.useState(false);
  const [dense, setDense] = React.useState(false);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [statusDetails, setStatusPid] = React.useState({
    id: "",
    name: "",
    email: "",
    status: "",
  });
  const [values, setValues] = React.useState({
    Name: "",
    Current_Status: "Inactive",
    Role: "",
    searchAgentId: "",
  });
  const [openModel, setOpenModel] = React.useState(false);
  const [openPasswordModel, setopenPasswordModel] = React.useState(false);
  const [preStatusUpdate, setPreStatusUpdate] = React.useState(
    props.tableData.statusUpdate
  );
  const [prePasswordUpdate, setPrePasswordUpdate] = React.useState(
    props.tableData.passwordUpdate
  );
  const [validationModal, setValidationModal] = React.useState(false);
  const [preUserList, setPreUserList] = React.useState(
    props.tableData.userList
  );
  const [preRoleList, setPreRoleList] = React.useState(
    props.tableData.roleList
  );

  const [chipValue, setChipValue] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [rolePermission, setRolePermission] = React.useState(
    props.tableData.roleDetailsById
  );
  const [profileCode, setProfileCode] = React.useState("");

  const [validationError, setVlidationError] = React.useState({
    Password: false,
    ConfirmPassword: false,
  });

  const checkValidation = () => {
    const error = { ...validationError };
    let validate = true;
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,250})"
    );
    if (values.Password.match(strongRegex)) {
      if (values.Password === values.ConfirmPassword) {
        for (const key in values) {
          if (
            values[key] === ""
            && (key === "Password" || key === "ConfirmPassword")
          ) {
            error[key] = true;
            validate = false;
          } else {
            error[key] = false;
          }
        }
      } else {
        error.ConfirmPassword = true;
        validate = false;
      }
    } else {
      error.Password = true;
      validate = false;
    }
    setVlidationError(error);
    return validate;
  };

  React.useEffect(() => {
    if (preStatusUpdate !== props.tableData.statusUpdate) {
      setPreStatusUpdate(props.tableData.statusUpdate);
      setOpenModel(false);
    }

    if (prePasswordUpdate !== props.tableData.passwordUpdate) {
      setPrePasswordUpdate(props.tableData.passwordUpdate);
      setopenPasswordModel(false);
    }
  }, [props]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const requestData = { ...props.tableData.searchDetails };
    requestData.sortColumn = property;
    requestData.sortOrder = isAsc ? "desc" : "asc";
    props.getUserByFilterData(requestData);
    props.setUserSearchData(requestData);
  };

  const saveStatus = () => {
    props.changeSingleStatus(values);
  };

  const savePassword = () => {
    if (checkValidation()) {
      props.changePassword(values);
    } else {
      setValidationModal(true);
    }
  };

  const exportListData = () => {
    if (!isEmpty(rows)) {
      const mappedLogs = rows.map(row => ({
        "First Name": row.FName,
        "Middle Name": row.MName,
        "Last Name": row.LName,
        "Client Id": row.userReportsToFullname,
        Email: row.Email,
        Phone: row.Phone,
        Role: `${row.Role} , Profile Type:
        ${row.ProfileMaster} `,
        "Creation Date": row.Created_date,
        Status: row.status,
      }));
      exportToCSV(mappedLogs, "Users List");
    }
  };

  React.useEffect(() => {
    if (rolePermission !== props.tableData.roleDetailsById) {
      if (props.tableData.roleDetailsById.status) {
        setRolePermission(props.tableData.roleDetailsById);
      }
    }
  }, [props]);

  React.useEffect(() => {
    const data = {
      userID: userid,
    };
    if (!props.tableData.agentList) {
      props.getAgentByHierarchy(data);
    }
  }, []);

  React.useEffect(() => {
    if (
      !props.tableData.roleList
      && !props.tableData.loading
      && !props.tableData.error
    ) {
      props.getRoleList();
    }
    if (preRoleList !== props.tableData.roleList) {
      setPreRoleList(props.tableData.roleList);
    }
    if (preRoleList) {
      const allRoles = preRoleList
        && preRoleList.Roles_Data.map(roles => roles.Profile_Code);
      const NonMerchantRolesArr = allRoles && allRoles.filter(role => role != "Merchant");
      const MerchantRoleArr = allRoles && allRoles.filter(role => role == "Merchant");
      const NonMerchantRoles = NonMerchantRolesArr && [...new Set(NonMerchantRolesArr)].toString();
      const MerchantRole = MerchantRoleArr && [...new Set(MerchantRoleArr)].toString();
      if (location.pathname === "/admin/Users" && NonMerchantRoles) {
        setProfileCode(NonMerchantRoles);
      } else if (
        location.pathname === "/admin/merchant-users"
        && MerchantRole
      ) {
        setProfileCode(MerchantRole);
      }
    }
    if (preUserList !== props.tableData.userList) {
      setPreUserList(props.tableData.userList);
      if (!props.tableData.userList) {
        const searchData = { ...props.tableData.searchDetails };
        searchData.statusExpression = location.pathname === "/admin/merchant-users" ? "IN" : "NOT_IN";
        if (searchData.Name === "" && searchData.Role === "" && searchData.searchAgentId === "") {
          setChipValue([]);
        }
        props.getUserByFilterData({ ...searchData });
        props.setUserSearchData(searchData);
      }
    }
    if (props.tableData.searchDetails.pageNo !== page) {
      setPage(props.tableData.searchDetails.pageNo);
    }
    if (props.tableData.searchDetails.sortOrder !== order) {
      setOrder(props.tableData.searchDetails.sortOrder);
    }
    if (props.tableData.searchDetails.sortColumn !== orderBy) {
      setOrderBy(props.tableData.searchDetails.sortColumn);
    }
  }, [props]);

  React.useEffect(() => {
    const searchData = { ...props.tableData.searchDetails };
    searchData.statusExpression = location.pathname === "/admin/merchant-users" ? "IN" : "NOT_IN";
    props.getUserByFilterData({ ...searchData });
    props.setUserSearchData(searchData);
    getProviderByFilterData(searchData);
    if (props.tableData.searchDetails.pageNo !== page) {
      setPage(props.tableData.searchDetails.pageNo);
    }
    if (props.tableData.searchDetails.sortOrder !== order) {
      setOrder(props.tableData.searchDetails.sortOrder);
    }
    if (props.tableData.searchDetails.sortColumn !== orderBy) {
      setOrderBy(props.tableData.searchDetails.sortColumn);
    }
  }, []);

  const getProviderByFilterData = value => {
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (property === "Name"
        || property === "Role"
        || property === "searchAgentId") {
          chipArray.push(
            `${
              property === "Current_Status"
                ? "Status"
                : property === "searchAgentId"
                  ? "Agent"
                  : property
            }: ${
              property === "searchAgentId"
                ? props.tableData.agentList.data.filter(
                  item => item.User_Id === value[property]
                )[0].Name
                : value[property]
            }`
          );
        }
      }
    }
    setChipValue(chipArray);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    error[type] = false;
    allState[type] = value;
    allState.id = statusDetails.id;
    setValues(allState);
    setVlidationError(error);
  };

  const handleDeleteChip = chip => {
    if (chipValue.length) {
      setChipValue(chipValue.filter(v => v !== chip));
      const requestData = { ...props.tableData.searchDetails };
      const fillterPreChip = chipValue.filter(v => v !== chip);
      const filterRemoveChip = chipValue.filter(v => v === chip);
      if (fillterPreChip.length !== 0) {
        fillterPreChip.forEach(v => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "Name") {
            requestData.Name = splitSearchName[1].trim();
          }
          if (splitSearchName[0] === "Role") {
            requestData.Role = splitSearchName[1];
          }
          if (splitSearchName[0] === "Agent") {
            const filterAgentName = props.agentList.data.filter(key => key.Name.trim() === splitSearchName[1].trim());
            requestData.searchAgentId = filterAgentName[0].User_Id;
          }
        });
      }
      if (filterRemoveChip.length !== 0) {
        filterRemoveChip.forEach(v => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "Name") {
            requestData.Name = "";
          }
          if (splitSearchName[0] === "Role") {
            requestData.Role = "";
          }
          if (splitSearchName[0] === "Agent") {
            requestData.searchAgentId = "";
          }
        });
      }
      props.getUserByFilterData(requestData);
      props.setUserSearchData(requestData);
    } else {
      const requestData = { ...props.tableData.searchDetails };
      requestData.Name = "";
      requestData.searchAgentId = "";
      requestData.Role = "";
      props.getUserByFilterData(requestData);
      props.setUserSearchData(requestData);
      setChipValue(chipValue);
    }
    if (chipValue.length === 0) {
      const requestData = { ...props.tableData.searchDetails };
      requestData.Name = "";
      requestData.searchAgentId = "";
      requestData.Role = "";
      props.getUserByFilterData(requestData);
      props.setUserSearchData(requestData);
      setChipValue([]);
    }
    setTemp(temp);
  };
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const requestData = { ...props.tableData.searchDetails };
    requestData.pageNo = newPage + 1;
    props.getUserByFilterData(requestData);
    props.setUserSearchData(requestData);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const requestData = { ...props.tableData.searchDetails };
    requestData.recordPerPage = parseInt(event.target.value, 10);
    requestData.pageNo = 1;
    props.getUserByFilterData(requestData);
    props.setUserSearchData(requestData);
    setPage(0);
  };
  const rows = [];
  if (preUserList) {
    preUserList.Users_Data.forEach((v, i) => {
      const UserID = v.UserID;
      const FName = v.FName;
      const MName = v.MName;
      const LName = v.LName;
      const userType = v.Profile_Code;
      const ClientID = v.userReportsToFullname;
      const Email = v.Email.toLowerCase();
      const Phone = v.Mobile;
      const Role = v.Role;
      const ProfileMaster = v.ProfileMaster;
      const Created_date = moment(v.Created_date).format("MM/DD/YYYY");
      const status = v.Status;
      const editable = v.UserEdit;
      rows.push(
        createData(
          FName,
          MName,
          userType,
          LName,
          ClientID,
          Email,
          Phone,
          Role,
          ProfileMaster,
          Created_date,
          status,
          UserID,
          editable
        )
      );
    });
  }

  React.useEffect(() => {
    if (preUserList.initialCall) {
      setInitialCall(true);
    }
  }, [preUserList]);

  const handleEdit = (detail, profilecode) => {
    if (
      actionPermission(
        props.roles,
        userSetupCatId,
        path === "Users" ? usersPageId : merchantUser,
        defaults.edit
      )
    ) {
      props.changeUserFormMode("Edit");
      props.getUserDetailsById(detail, profilecode);
    }
  };

  return (
    <>
      <Modal
        open={openPasswordModel}
        onClose={() => setopenPasswordModel(false)}
        align="center"
      >
        <div
          // className={classes.modalDiv}
          style={{
            width: "auto",
            height: "auto",
            maxHeight: "400px",
            maxWidth: "450px",
          }}
        >
          <Card className={classes.modalRoot}>
            <div className={classes.statusModalHeader}>
              <div className={classes.statusHeadingText}>Change Password</div>
              <ClearIcon
                style={{ color: "white" }}
                onClick={() => setopenPasswordModel(false)}
              />
            </div>
            <div style={{ padding: "3%" }}>
              <Grid
                container
                direction="column"
                className={classes.margin}
                style={{
                  flexDirection: "column",

                  alignItems: "flex-start",
                }}
              >
                <Grid
                  item
                  className={classes.searchRoot}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Typography variant="h1" style={{ padding: "10px" }}>
                    User:
                  </Typography>
                  {"    "}
                  <Typography
                    component="p"
                    variant="body1"
                    style={{ padding: "10px" }}
                  >
                    {statusDetails.name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.searchRoot}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Typography variant="h1" style={{ padding: "10px" }}>
                    Email:
                  </Typography>
                  {"    "}
                  <Typography
                    component="p"
                    variant="body1"
                    style={{ padding: "10px" }}
                  >
                    {statusDetails.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="column">
                <Grid item md={12} lg={12} sm={12} xs={10}>
                  <TextField
                    required
                    error={validationError.Password}
                    className={classes.rootInput}
                    fullWidth
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                      endAdornment: (
                        <Tooltip
                          title={longText}
                          classes={{
                            tooltip: classes.customTooltip,
                          }}
                        >
                          <ErrorOutlineIcon />
                        </Tooltip>
                      ),
                      required: true,
                    }}
                    label="Password"
                    value={values.Password}
                    onChange={e => onChange(e.target.value, "Password")}
                    name="Password"
                    id="Password"
                    type="password"
                  />
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={10}>
                  <TextField
                    required
                    error={validationError.ConfirmPassword}
                    className={classes.rootInput}
                    fullWidth
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                      endAdornment: (
                        <Tooltip
                          title={longText}
                          classes={{
                            tooltip: classes.customTooltip,
                          }}
                        >
                          <ErrorOutlineIcon />
                        </Tooltip>
                      ),
                      required: true,
                    }}
                    // fullWidth
                    label="Confirm Password"
                    value={values.ConfirmPassword}
                    onChange={e => onChange(e.target.value, "ConfirmPassword")}
                    name="ConfirmPassword"
                    id="ConfirmPassword"
                    type="password"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "3%" }}>
                <Grid item xs={8} />
                <Grid
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  item
                  xs={4}
                >
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    aria-controls="simple-menu"
                    onClick={() => savePassword()}
                    color="primary"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal
        open={openModel}
        onClose={() => setOpenModel(false)}
        align="center"
      >
        <Card
          className={windowWidthSm ? classes.modalRoot : classes.modalRootXs}
        >
          <div className={classes.statusModalHeader}>
            <div className={classes.statusHeadingText}>Change User Status</div>
            <ClearIcon
              style={{ color: "white" }}
              onClick={() => setOpenModel(false)}
            />
          </div>
          <Grid
            container
            direction="column"
            className={classes.margin}
            style={{
              flexDirection: "column",
              paddingLeft: "4%",
              alignItems: "flex-start",
            }}
          >
            <Grid
              item
              className={classes.searchRoot}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Typography variant="h1" style={{ padding: "10px" }}>
                User:
              </Typography>
              {"    "}
              <Typography
                component="p"
                variant="body1"
                style={{ padding: "10px" }}
              >
                {statusDetails.name}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.searchRoot}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Typography variant="h1" style={{ padding: "10px" }}>
                Email:
              </Typography>
              {"    "}
              <Typography
                component="p"
                variant="body1"
                style={{ padding: "10px" }}
              >
                {statusDetails.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.margin}
            style={{ paddingTop: "2%" }}
          >
            <Grid item xs={11}>
              <TextField
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                }}
                fullWidth
                label="Status"
                value={values.status}
                onChange={e => onChange(e.target.value, "status")}
                name="status"
                id="status"
                select
              >
                <MenuItem className={classes.dialogBoxDropDown} value="Active">
                  Active
                </MenuItem>
                <MenuItem
                  className={classes.dialogBoxDropDown}
                  value="Inactive"
                >
                  Inactive
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid
            className={classes.marginTop}
            container
            style={{
              paddingTop: "2%",
            }}
          >
            <Grid item xs={8} />
            <Grid item xs={4}>
              <CoustomButton
                align="center"
                variant="contained"
                onClick={() => saveStatus()}
              >
                Update
              </CoustomButton>
            </Grid>
          </Grid>
        </Card>
      </Modal>
      {windowWidth && (
        <Grid container className={classes.searchContainer}>
          <Grid item className={classes.margin}>
            <div className={classes.searchWrapper}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "35%" }}>
                  <ChipInput
                    value={chipValue.length > 0 ? chipValue : []}
                    onClick={() => handleClickOpen()}
                    style={{ display: "flex" }}
                    classes={{ chipContainer: classes.chipContainer }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    label="Search"
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog
                      close={() => handleClose()}
                      seacrhChipData={data => getProviderByFilterData(data)}
                    />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <CoustomButton
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </CoustomButton>
              <CoustomButton
                variant="contained"
                className={classes.ExportButtonStyle}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportListData}
              >
                Export
              </CoustomButton>
            </div>
          </Grid>
        </Grid>
      )}
      {!windowWidth && (
        <Grid container className={classes.searchContainer}>
          <Grid item className={classes.margin} style={{ display: "flex" }}>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => handleClose()}
            >
              <Box style={{ width: "85%" }}>
                <ChipInput
                  value={chipValue.length > 0 ? chipValue : []}
                  onClick={() => handleClickOpen()}
                  style={{ display: "flex" }}
                  classes={{ chipContainer: classes.chipContainer }}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  label="Search"
                  onDelete={(chip, index) => handleDeleteChip(chip, index)}
                />
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                  className={classes.overLayBox}
                >
                  <SimpleDialog
                    close={() => handleClose()}
                    seacrhChipData={data => getProviderByFilterData(data)}
                  />
                </Collapse>
              </Box>
            </ClickAwayListener>
            <CoustomButton
              color="white"
              aria-label="edit"
              justIcon
              round
              onClick={() => {}}
            >
              <Search />
            </CoustomButton>
          </Grid>
          <Grid item className={classes.expirtItemGrid}>
            <Button
              variant="contained"
              className={classes.buttonStyle}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={exportListData}
              color="primary"
            >
              Export
            </Button>
          </Grid>
        </Grid>
      )}
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={dense ? "small" : "medium"}
        aria-label="enhanced table"
        style={{ marginBottom: rowsPerPage > 25 ? "55px" : "" }}
      >
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />
        <TableBody>
          {!isEmpty(rows) ? (
            rows.map((row, index) => (
              <TableRow>
                <TableCell
                  onClick={() => handleEdit(row.UserID, row.userType)}
                  className={classes.patientNameColor}
                  align="left"
                >
                  <b>
                    {row.FName}
                    {" "}
                    {row.MName}
                    {" "}
                    {row.LName}
                  </b>
                </TableCell>
                <TableCell
                  onClick={() => handleEdit(row.UserID, row.userType)}
                  className={classes.cellText}
                  align="left"
                >
                  {row.userType}
                </TableCell>
                <TableCell
                  onClick={() => handleEdit(row.UserID, row.userType)}
                  className={classes.cellText}
                  align="left"
                >
                  {row.userReportsToFullname}
                </TableCell>
                <TableCell
                  onClick={() => handleEdit(row.UserID, row.userType)}
                  className={classes.cellText}
                  align="left"
                >
                  {row.Email}
                </TableCell>
                <TableCell
                  onClick={() => handleEdit(row.UserID, row.userType)}
                  className={classes.cellText}
                  align="left"
                >
                  {row.Phone}
                </TableCell>
                <TableCell
                  onClick={() => handleEdit(row.UserID, row.userType)}
                  className={classes.patientNameColor}
                  align="left"
                >
                  {row.Role}
                  <br />
                  Profile Type:
                  {row.ProfileMaster}
                </TableCell>
                <TableCell
                  onClick={() => handleEdit(row.UserID, row.userType)}
                  className={classes.cellText}
                  align="left"
                >
                  {row.Created_date}
                </TableCell>
                <TableCell
                  onClick={() => handleEdit(row.UserID, row.userType)}
                  className={classes.cellText}
                  align="left"
                >
                  {row.status}
                </TableCell>
                <TableCell>
                  <LongMenu
                    rowId={row.UserID}
                    rowType={row.userType}
                    {...props}
                    editable={row.editable}
                    openForm={() => setOpenModel(true)}
                    openPasswordModel={() => setopenPasswordModel(true)}
                    setStatusPid={() => setStatusPid({
                      id: row.UserID,
                      name: `${row.FName} ${row.LName}`,
                      email: row.Email,
                      status: row.status,
                    })}
                    editPermission={actionPermission(
                      props.roles,
                      userSetupCatId,
                      path === "Users" ? usersPageId : merchantUser,
                      defaults.edit
                    )}
                    changeStatusPermission={actionPermission(
                      props.roles,
                      userSetupCatId,
                      path === "Users" ? usersPageId : merchantUser,
                      defaults.Change_Status
                    )}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell className="no-data" colSpan={12}>
                <img src={noDataImage} alt="" className={classes.noDataImage} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {rows.length ? (
          <TableFooter className={rowsPerPage > 25 && classes.stickyFooter}>
            <TableRow
              style={{
                display: rowsPerPage > 25 ? "flex" : "",
                justifyContent: rowsPerPage > 25 ? "end" : "",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  {
                    label: "All",
                    value: preUserList && preUserList.Users_Data
                      ? props.tableData.totCount
                      : 0,
                  },
                ]}
                colSpan={12}
                count={props.tableData.userList.totCount}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        ) : (
          ""
        )}
      </Table>
    </>
  );
}

UserDataList.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  changeUserFormMode: PropTypes.func,
  getUserDetailsById: PropTypes.func,
  changeSingleStatus: PropTypes.func,
  changePassword: PropTypes.func,
  getUserByFilterData: PropTypes.func,
  userDetails: PropTypes.object,
  setUserSearchData: PropTypes.func,
  setUserSearchEmpty: PropTypes.func,
  roles: PropTypes.object,
  getRoleList: PropTypes.func,
  getAgentByHierarchy: PropTypes.func,
};

const mapStateToProps = app => ({
  tableData: app.users,
  userDetails: app.app,
  roles: app.app,
});
export default connect(mapStateToProps, {
  getUserDetailsById,
  changeUserFormMode,
  changeSingleStatus,
  changePassword,
  getUserByFilterData,
  setUserSearchData,
  setUserSearchEmpty,
  getRoleList,
  getAgentByHierarchy,
})(UserDataList);
