/* eslint-disable import/extensions */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import PropTypes from "prop-types";
import AchForm from "./AchForm";
import CardForm from "./CardForm";

const GreenRadio = withStyles(theme => ({
  root: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))(props => <Radio color="default" {...props} />);

const useStyles = makeStyles(styles);
const MonthlyPayment = props => {
  const classes = useStyles();
  const [values, setValue] = React.useState(props.preValues);
  const [selectedPayment, setSelectedPayment] = React.useState("a");
  const [showCard, setShowCard] = React.useState(true);

  const handlePayment = event => {
    if (event.target.value === "b") {
      setSelectedPayment(event.target.value);
      setShowCard(false);
    }
    if (event.target.value === "a") {
      setSelectedPayment(event.target.value);
      setShowCard(true);
    }
  };
  const onChange = () => {
    console.log("hello");
  };
  return (<div>
    <Box>
      <Typography>
        Monthly Payment Information:
      </Typography>
    </Box>
    <Grid container className={classes.margin}>
      <Grid item xs>
        <TextField
          className={classes.fullInput}
          InputLabelProps={{
            classes: {
              root: classes.activeInputLableColor,
              focused: "focused",
            },
          }}
          InputProps={{
            readOnly: true,
            classes: {
              root: classes.readOnlyInput,
              input: classes.resize,
              focused: "focused",
            },
          }}
          disabled
          label="Payment Amount"
          value={values.downPayment}
          onChange={e => onChange(e.target.value, "downPayment")}
          name="downPayment"
          id="downPayment"
        />
      </Grid>
      <Grid item xs>
        <TextField
          className={classes.fullInput}
          InputLabelProps={{
            classes: {
              root: classes.activeInputLableColor,
              focused: "focused",
            },
          }}
          InputProps={{
            readOnly: true,
            classes: {
              root: classes.readOnlyInput,
              input: classes.resize,
              focused: "focused",
            },
          }}
          disabled
          label="No. Of Payment"
          value={values.totalEmi}
          onChange={e => onChange(e.target.value, "totalEmi")}
          name="totalEmi"
          id="totalEmi"
        />
      </Grid>
      <Grid item xs>
        <TextField
          className={classes.fullInput}
          InputLabelProps={{
            classes: {
              root: classes.activeInputLableColor,
              focused: "focused",
            },
          }}
          InputProps={{
            classes: {
              root: classes.activeInputColor,
              input: classes.resize,
              focused: "focused",
            },
          }}
          label="Payment Start Date"
          value={values.start_date}
          onChange={e => onChange(e.target.value, "start_date")}
          name="start_date"
          id="start_date"
        />
      </Grid>
      <Grid item xs>
        <TextField
          className={classes.fullInput}
          InputLabelProps={{
            classes: {
              root: classes.activeInputLableColor,
              focused: "focused",
            },
          }}
          InputProps={{
            classes: {
              root: classes.activeInputColor,
              input: classes.resize,
              focused: "focused",
            },
          }}
          label="Payment Last Date"
          value={values.end_date}
          onChange={e => onChange(e.target.value, "end_date")}
          name="end_date"
          id="end_date"
        />
      </Grid>
    </Grid>
    <Grid container className={classes.margin}>
      <Grid item xs>
        <Box height={40} className={classes.radioBox}>
          <Typography className={classes.textColorRadioCard}>Down Payment via</Typography>
          <div className={classes.radioBoxRoot}>
            <GreenRadio
              className={classes.checkBox}
              checked={selectedPayment === "a"}
              onChange={handlePayment}
              value="a"
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />
            <Typography className={classes.textColorRadioCard}>Card</Typography>
          </div>
          <div className={classes.radioBoxRoot}>
            <GreenRadio
              checked={selectedPayment === "b"}
              onChange={handlePayment}
              className={classes.checkBox}
              value="b"
              name="radio-button-demo"
              inputProps={{ "aria-label": "B" }}
            />
            <Typography className={classes.textColorRadioCard}>ACH</Typography>
          </div>
        </Box>
      </Grid>
    </Grid>
    {showCard ? <CardForm /> : <AchForm />}
    <Grid container className={classes.margin}>
      <Box className={classes.buttonBox}>
        <Button variant="contained" className={classes.buttonStyle} color="#696969" size="large" onClick={() => props.nextStep()}>
          Save and Confirm
        </Button>
      </Box>
    </Grid>
  </div>);
};

MonthlyPayment.propTypes = {
  preValues: PropTypes.any,
  nextStep: PropTypes.func,
};

export default MonthlyPayment;
