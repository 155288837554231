import {
  drawerWidth,
  transition,
  container,
} from "../../material-dashboard-react";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    // [theme.breakpoints.up("md")]: {
    //   width: `calc(100% - ${drawerWidth}%)`
    // },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "78%",
    overflowScrolling: "touch",
  },
  mainPanelHide: {
    // [theme.breakpoints.up("md")]: {
    //   //width: `calc(115% - ${drawerWidth}px)`
    //   width: `calc(115% - 160px)`
    // },
    position: "relative",
    ...transition,
    maxHeight: "100%",
    [theme.breakpoints.up("sm")]: {
      // width: `calc(115% - ${drawerWidth}px)`
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      // width: `calc(115% - ${drawerWidth}px)`
      width: "93.5%",
      float: "right",
    },
    overflowScrolling: "touch",
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "77px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "77px",
    },
  },
  container: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "15px",
      paddingLeft: "15px",
      marginRight: "auto",
      marginLeft: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "74px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "74px",
    },
  },
  map: {
    marginTop: "70px",
  },
});

export default appStyle;
