/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-expressions */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import CommentIcon from "@material-ui/icons/Comment";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import closeImage from "assets/img/Close_Image.png";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import { CardActions } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Modal from "@material-ui/core/Modal";
import deepclone from "lodash/cloneDeep";
import AppLoader from "components/AppLoader/Index";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OwnerInformation from "./OwnerInformation";
import {
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  mpaChecklistComment,
  kycChecklistAttechment,
  mpaChecklistAttechment,
  deleteOwnerApiCall
} from "../../actions/Operations";
import {
  provideRegistration,
  deleteIncroptration,
  deleteAddressProof,
  deleteIdentityProof,
  getMccList,
  getQuestionData,
  getKycQuestionData,
  getCountryTypeList,
  accountTypeList,
  ownerTypeList,
  updateRegistration,
  getProviderStatusWithID,
  getProviderStatusNormal,
  getupdatestatus,
  getProviderActivityById,
  setPricingTempleteEmpty,
  pricingTempleteCall,
  getProviderList,
  changeSingleStatus,
  saveQuestionCall,
  changeProviderFormMode,
  setResetWuestion,
  getProviderParameterCall
} from "../../Redux/Actions/ProvideRegistration";
import InfoHeaderSection from "./InfoHeaderSection";
import BusinessInformationSection from "./BusinessInfo";
import IncroprationDocument from "./IncroprationDocumentUpload";
import SettlementAccount from "./SettlementAccountInformation";
import KycChecklist from "./KycCheckList";
import MpaChecklist from "./MpaChecklist";
import ProccessMethod from "./ProcessingMethodandVolume";
import {
  formatSocialSecurity,
  formatPhoneNumber,
  actionPermission
} from "../../Utils/commonUtils";
import {
  showCheckList,
  changeStatusPermission,
  checkFormDisabled,
  disbaleAndHide,
  disableSaveDraftButton
} from "./SetRoleBase";
import {
  checkValidation,
  initialValuie,
  validationInitailValue,
  setValidationAfterSaveClick,
  ownerArray,
  ownerValidation
} from "./Vlidation";
import { getSignNowCall, getDocStatus } from "../../actions/signNowAction";
import SignOnModal from "./SignOnModal";

const useStyles = makeStyles(styles);

const merchantsCatId = "62c28d681bc249cb83529a32";

const merchantsInprogessPageId = "62c28d681bc249cb83529a34";

const defaults = {
  view: 1,
  create: 2,
  edit: 3,
  changeStatus: 4,
  allowToBoard: 5,
  allowToChecklist: 6,
  allowToLive: 7
};

function BusinessForm(props) {
  const path = window.location.pathname.split("/")[2];
  const [check, setCheck] = React.useState(false);
  const [tcErrorPrompt, setTCErrorPrompt] = React.useState(false);
  const [checkTC, setCheckTC] = React.useState(false);
  const [validationModal, setValidationModal] = React.useState(false);
  const [selectedQuestion, setSelectedQuestion] = React.useState(false);
  const [commentSection, setCommentSection] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = React.useState(false);
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down("xs"));
  const [stateInput, setStateInput] = React.useState(true);
  const [checkListModal, setCheckListModal] = React.useState(false);
  const [approveModal, setApproveModal] = React.useState(false);
  const [declineModal, setDeclineModal] = React.useState(false);
  const [textAreaText, setTextAreaText] = React.useState("");
  const [ownerSelect, setOwnerSelect] = React.useState("");
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [isDocModalOpen, setIsDocModalOpen] = React.useState(false);
  const [alterOpen, setAlertOpen] = React.useState(false);
  const [docLink, setDocLink] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(false);
  const [signedDocData, setSignedDocData] = React.useState({});
  const [mccList, setMccList] = React.useState([]);
  const [fileHandleAttechment, setFileHandleAttechment] = React.useState({
    files: []
  });
  const [submissionSuccess, setSubmissionSuccess] = React.useState(
    props.formData.data
  );
  const [accordian, handleAccordClick] = React.useState({
    index: []
  });
  const [statusUpdate, setStatusUpdate] = React.useState(
    props.formData.statusUpdate
  );
  const [preUpdateQuestion, setPreUpdateQuestion] = React.useState(
    props.formData.questionUpdate
  );
  const [kycListModal, setKycListModal] = React.useState(false);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthLG = useMediaQuery(theme.breakpoints.up("lg"));
  const [mpaCheckListStatus, setMpaCheckListStatus] = React.useState({
    pending: 0,
    progress: 0,
    completed: 0
  });
  const [kycCheckListStatus, setKycCheckListStatus] = React.useState({
    pending: 0,
    progress: 0,
    completed: 0
  });

  const [userOptions, setUserOptions] = React.useState(
    props.mainData.roles.roleTab &&
      props.mainData.roles.roleTab.Roles_Data.LeftMenu[1].Page_Menu[0] &&
      props.mainData.roles.roleTab.Roles_Data.LeftMenu[1].Page_Menu[0]
        .Options[3] &&
      props.mainData.roles.roleTab.Roles_Data.LeftMenu[1].Page_Menu[0]
        .Options[3]
  );
  const [values, handleInput] = React.useState(deepclone(initialValuie));
  const [validationError, setVlidationError] = React.useState(
    validationInitailValue
  );
  const [ownerDeleteModal, setOwnerDeleteModal] = React.useState(false);

  const handleClose = () => {
    setValidationModal(false);
  };

  React.useEffect(() => {
    if (props.formData.mcclist.MCC_Data) {
      const sortedData = props.formData.mcclist.MCC_Data.map((option) => ({
        MCC_Code: option.MCC_Code,
        MCC_Name: `[${option.MCC_Code}] - ${option.MCC_Name}`
      }));
      setMccList([...sortedData]);
    }
  }, [props.formData.mcclist]);

  React.useEffect(() => {
    if (!props.formData.mcclist) {
      props.getMccList();
    }
    if (!props.formData.accountTypeList) {
      props.accountTypeList();
    }
    if (!props.formData.ownerShipTypeList) {
      props.ownerTypeList();
    }
    if (!props.formData.countryTypeList) {
      props.getCountryTypeList();
    }
    if (
      !props.formData.questionData &&
      props.formData.formMode === "Edit" &&
      props.formData.providerDetailsById
    ) {
      const data = {
        providerId:
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById.Providers_Data[0]._id,
        ownerId: ownerSelect?.Owner_ID
      };
      props.getQuestionData(data);
    }
    if (
      !props.formData.kycQuestionData &&
      props.formData.formMode === "Edit" &&
      props.formData.providerDetailsById
    ) {
      const data = {
        providerId:
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById.Providers_Data[0]._id
      };
      props.getKycQuestionData(data);
    }
  }, []);

  React.useEffect(() => {
    if (props.mainData.roles.roleTab && !userOptions) {
      setUserOptions(
        props.mainData.roles.roleTab.Roles_Data.LeftMenu[1].Page_Menu[0]
          .Options[3]
      );
    }
    if (submissionSuccess !== props.formData.data) {
      setValidationModal(false);
      if (props.formData.data && props.formData.data.status) {
        setSubmissionSuccess(props.formData.data);
        enqueueSnackbar("Merchant Saved Successfully", {
          variant: "success"
        });
        handlelistBack();
      } else if (
        props.formData.data &&
        !props.formData.data.status &&
        props.formData.data.errors
      ) {
        setErrorMessage(props.formData.data.errors);
        setValidationAfterSaveClick(
          validationError,
          props,
          setVlidationError,
          setSubmissionSuccess,
          setValidationModal
        );
      }
    }

    if (statusUpdate !== props.formData.statusUpdate) {
      if (props.formData.statusUpdate && props.formData.statusUpdate.status) {
        setStatusUpdate(props.formData.statusUpdate);
        enqueueSnackbar("Provider status is updated Successfully", {
          variant: "success"
        });
        handlelistBack();
      } else if (
        props.formData.statusUpdate &&
        !props.formData.statusUpdate.status
        // &&
        // props.formData.statusUpdate.message === "Provider status already exits"
      ) {
        enqueueSnackbar(props.formData.statusUpdate.message, {
          variant: "error"
        });
        setSubmissionSuccess(props.formData.data);
      }
    }
    if (props.formData.questionUpdate !== preUpdateQuestion) {
      setPreUpdateQuestion(props.formData.questionUpdate);
      if (
        props.formData.questionUpdate &&
        props.formData.questionUpdate.status
      ) {
        const data = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
          ownerId: ownerSelect?.Owner_ID
        };
        props.getQuestionData(data);
        props.getKycQuestionData(data);
        enqueueSnackbar(props.formData.questionUpdate.message, {
          variant: "success"
        });
        const providerName = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id
        };
        props.statusPreameterKyc(
          providerName,
          (data) => {
            // eslint-disable-next-line no-unused-expressions
            data.status ? setKycCheckListStatus(data.data) : console.log(data);
          },
          (err) => {
            enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
              variant: "error"
            });
          }
        );
        props.statusPreameter(
          providerName,
          (data) => {
            // eslint-disable-next-line no-unused-expressions
            data.status ? setMpaCheckListStatus(data.data) : console.log(data);
          },
          (err) => {
            enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
              variant: "error"
            });
          }
        );
      }
    }
  }, [submissionSuccess, props]);

  React.useEffect(() => {
    if (ownerSelect) {
      const data = {
        providerId:
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById?.Providers_Data[0]._id,
        ownerId: ownerSelect?.Owner_ID
      };
      props.getKycQuestionData(data);
    }
  }, [ownerSelect]);

  const handleAddNew = () => {
    props.changeProviderFormMode("Create");
    handleInput(deepclone(initialValuie));
    setVlidationError(deepclone(validationInitailValue));
    setCheck(false);
  };

  const addOwner = () => {
    if (values.ownerArray.owners.length < 5) {
      const ownerData = { ...values };
      const validation = { ...validationError };
      values.ownerArray.owners.push(deepclone(ownerArray));
      validation.ownerArray.owners.push(deepclone(ownerValidation));
      setVlidationError(validation);
      handleInput(ownerData);
    }
  };

  const handleAccordian = (i) => {
    const accordianValue = { ...accordian };
    if (accordianValue.index.length > 0) {
      const checkPreValue = accordianValue.index.filter((v) => v === i);
      if (checkPreValue.length > 0) {
        accordianValue.index = accordianValue.index.filter((v) => v !== i);
      } else {
        accordianValue.index.push(i);
      }
    } else {
      accordianValue.index.push(i);
    }
    handleAccordClick(accordianValue);
  };

  const handleCommentSection = (value, type) => {
    setSelectedQuestion(value);
    if (value.comment) {
      setTextAreaText(value.comment);
    } else {
      setTextAreaText("");
    }
    setCommentSection(true);
  };

  const ownerOnchange = (index, data, type, e) => {
    const ownerData = { ...values };
    const error = { ...validationError };
    if (type === "Owner_Last4_SSN") {
      if (
        e.nativeEvent.inputType === "deleteContentBackward" ||
        e.nativeEvent.inputType === "deleteContentForward"
      ) {
        ownerData.ownerArray.owners[index][type] = "";
        error.ownerArray.owners[index][type] = true;
      } else if (ownerData.ownerArray.owners[index][type].length !== 10) {
        ownerData.ownerArray.owners[index][type] = formatSocialSecurity(data);
        error.ownerArray.owners[index][type] = true;
      } else {
        ownerData.ownerArray.owners[index][type] = formatSocialSecurity(data);
        error.ownerArray.owners[index][type] = false;
      }
    } else if (type === "Owner_Phone") {
      error.ownerArray.owners[index][type] = false;
      ownerData.ownerArray.owners[index][type] = formatPhoneNumber(data);
    } else if (type === "Owner_DOB") {
      if (data) {
        const eighteenYearsAgo = moment().subtract(18, "years");
        const birthday = moment(data);

        if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(data) || !birthday.isValid()) {
          error.ownerArray.owners[index][type] = true;
          ownerData.ownerArray.owners[index][type] = data;
        } else if (eighteenYearsAgo.isAfter(birthday)) {
          error.ownerArray.owners[index][type] = false;
          ownerData.ownerArray.owners[index][type] = data;
        }
        if (moment().diff(data, "days") < 0) {
          error.ownerArray.owners[index][type] = true;
          ownerData.ownerArray.owners[index][type] = data;
        }
      }
    } else if (type === "Owner_Ownership_Percentage") {
      if (!/^(?:100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type === "Owner_Postal_Code") {
      if (!/^[0-9]{5}$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else {
      ownerData.ownerArray.owners[index][type] = data;
      error.ownerArray.owners[index][type] = false;
    }
    setVlidationError(error);
    handleInput(ownerData);
  };

  const deleteOwner = (index) => {
    const initialValue = {
      owners: []
    };
    const initialValidateValue = {
      owners: []
    };
    const value = { ...values };
    const ValidateErrorDelete = { ...validationError };
    if (value.ownerArray.owners.length > 1) {
      value.ownerArray.owners.forEach((v, i) => {
        if (i !== ownerDeleteModal) {
          initialValue.owners.push(v);
        }
      });
      if (ValidateErrorDelete.ownerArray.owners.length > 1) {
        ValidateErrorDelete.ownerArray.owners.forEach((v, i) => {
          if (i !== ownerDeleteModal) {
            initialValidateValue.owners.push(v);
          }
        });
        ValidateErrorDelete.ownerArray = initialValidateValue;
        value.ownerArray = initialValue;
      }
      if (values.ownerArray.owners[ownerDeleteModal]?.Owner_ID !== undefined) {
        const deleteOwnerRequest = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
          ownerId: values.ownerArray.owners[ownerDeleteModal]?.Owner_ID
        };
        props.deleteOwnerApiCall(
          deleteOwnerRequest,
          (data) => {
            enqueueSnackbar(data.message, {
              variant: "success"
            });
            props.getProviderDetailsById(
              props.formData.providerDetailsById.Providers_Data[0]._id
            );
          }
          // (err) => {
          //   enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
          //     variant: "error"
          //   });
          // }
        );
      }
      setOwnerDeleteModal(false);
      handleInput(value);
      setVlidationError(ValidateErrorDelete);
    }
  };

  React.useEffect(() => {
    if (
      props.formData.providerDetailsById &&
      props.formData.providerDetailsById.Providers_Data[0]._id
    ) {
      if (props.formData.providerDetailsById.Providers_Data) {
        props.getProviderStatusWithID({
          statusCode:
            props.formData.providerDetailsById.Providers_Data[0].Current_Status
        });
        onChange(
          props.formData.providerDetailsById.Providers_Data[0].Current_Status,
          "status"
        );
      }
    }
  }, [props.formData.providerDetailsById]);

  const approveYesClick = () => {
    if (approveModal) {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Approve"
      };
      props.statusApproveMpa(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success"
          });
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error"
          });
        }
      );
      setApproveModal(false);
    }
    if (declineModal) {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Approve"
      };
      props.statusApproveKyc(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success"
          });
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error"
          });
        }
      );
      setDeclineModal(false);
    }
  };

  const declineYesClick = () => {
    if (approveModal) {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Reject"
      };
      props.statusApproveMpa(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success"
          });
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error"
          });
        }
      );
      setApproveModal(false);
    }
    if (declineModal) {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Reject"
      };
      props.statusApproveKyc(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success"
          });
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error"
          });
        }
      );
      setDeclineModal(false);
    }
  };

  const handleCheckListModalClose = (type) => {
    if (type === "kyc") {
      setKycListModal(false);
      handleAccordClick({ index: [] });
    }
    if (type === "mpa") {
      setCheckListModal(false);
      handleAccordClick({ index: [] });
    }
  };

  const handleBack = () => {
    if (props.mainData.app.appBarName.includes("Progress")) {
      props.history.push(`/${props.mainData.app.layout}/InProgress`);
    }
    if (props.mainData.app.appBarName.includes("Live")) {
      props.history.push(`/${props.mainData.app.layout}/Live`);
    }
    props.getProviderStatusNormal();
    props.setResetWuestion();
  };

  React.useEffect(() => {
    const documentStatus =
      props.formData.providerDetailsById &&
      props.formData.providerDetailsById.Providers_Data[0].termAndConditions;
    if (documentStatus?.docStatus == "Signed") {
      setCheckTC(true);
    } else {
      setCheckTC(false);
    }
  }, [props.formData.providerDetailsById]);

  if (!data && props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    const allState = { ...values };
    const statusId = {
      statusCode: preData.Current_Status
    };
    props.getProviderStatusNormal(statusId);
    allState.status = preData.Current_Status;
    allState.editable = preData.Edit_Status;
    allState.businessName = preData.Business_Name;
    allState.legalBusiness = preData.Legal_Name;
    allState.businessStartDate =
      preData.Business_Start_Date &&
      preData.Business_Start_Date !== "Invalid date"
        ? moment(preData.Business_Start_Date).format("YYYY-MM-DD")
        : null;
    allState.businessDiscrition = preData.Business_Description;
    allState.ownerShipType = preData.Ownership_Type;
    allState.businessPhoneNumber = formatPhoneNumber(preData.Business_Phone);
    allState.businessEmail = preData.Business_EMail;
    allState.businessWebsite = preData.Website;
    allState.businessTaxId = preData.Fedral_Tax_ID;
    allState.businessStock = preData.Stock_Symbol;
    allState.businessAddress = preData.Business_Address;
    allState.businessSuite = preData.Suite;
    allState.description = preData.Description;
    allState.businessCity = preData.City;
    allState.businessState = preData.State_Code;
    allState.USID = preData.MCC;
    allState.businessPostalCode = preData.Postal_Code;
    allState.businessInterNational = preData.International_Province;
    allState.businessCountry = preData.Country_Code;
    allState.businessFormationCountry = preData.Business_Formation_Country;
    allState.businessTaxResidence = preData.Business_Tax_Residence;
    allState.businessDomicile = preData.Business_Domicile;
    allState.titleTermsCondition = preData.titleTermsCondition;
    allState.nameTermsCondition = preData.nameTermsCondition;
    allState.acceptTermsCondition = preData.acceptTermsCondition;
    allState.accountNumber = preData.Sattlement_Account?.Account_Number;
    allState.routingNumber = preData.Sattlement_Account?.Routing_Number;
    allState.accountName = preData.Sattlement_Account?.Name_On_Account;
    allState.accountType = preData.Sattlement_Account?.Account_Type;
    allState.cardPresent = preData.Processing_Method?.Card_Swiped;
    allState.cardNotPresent = preData.Processing_Method?.Card_Not_Present;
    allState.eCommers = preData.Processing_Method?.ECommerce;
    allState.cardVolumeMonth = preData.Processing_Method?.Monthly_Card_Volume;
    allState.averageTicketSize = preData.Processing_Method?.Average_Ticket_Size;
    allState.highTicket = preData.Processing_Method?.High_Ticket;
    allState.ussidTypeInput = "";
    allState.eCheckVolumeMonth = preData.Processing_Method?.Monthly_ACH_Volume;
    allState.eCheckAverage = preData.Processing_Method?.Average_ACH;
    allState.eCheckHigh = preData.Processing_Method?.High_ACH;
    allState.incorportionDocumentDetails =
      preData.incorportionDocument === ""
        ? {
            File_Name: "",
            File_Type: "",
            Upload_Date_Time: ""
          }
        : preData.incorportionDocument;
    allState.docAdd = preData.incorportionDocument !== "";
    allState.incorportionDocument = preData.incorportionDocument
      ? preData.incorportionDocument.File_Name
      : "";
    const ownerData = preData.Owner_Information;
    const preDataArray = [];
    const validationAddToOwner = { ...validationError };
    ownerData.forEach((key, i) => {
      if (i > 0) {
        validationAddToOwner.ownerArray.owners.push({
          Owner_First_Name: false,
          Owner_Last_Name: false,
          Owner_Last4_SSN: false,
          Owner_DOB: false,
          Owner_Ownership_Percentage: false,
          Owner_Title: false,
          Owner_Phone: false,
          Owner_Street_Address: false,
          Owner_Suite: false,
          Owner_City: false,
          Owner_State_Code: false,
          Owner_Postal_Code: false,
          Owner_International_Province: false,
          Owner_Country_Code: false,
          Owner_Timezone_Code: false,
          ownerAddressProof: false,
          ownerPhotoId: false,
          ownerPhotoIdBack: false,
          ownerBiometricId: false,
          ownerAddressProofDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          },
          ownerPhotoIdDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          },
          ownerPhotoIdBackDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          },
          ownerBiometricIdDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          }
        });
      }
      const obj = { ...key };
      obj.ownerAddressProofDetails =
        key.ownerAddressProof === ""
          ? {
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: ""
            }
          : {
              File_Type: key.ownerAddressProof.File_Type,
              File_Name: key.ownerAddressProof.File_Name,
              File_Path: key.ownerAddressProof.File_Path,
              Upload_Date_Time: key.ownerAddressProof.Upload_Date_Time
            };
      obj.ownerAddressProofType =
        key.ownerAddressProof === "" ? "" : key.ownerAddressProof.Doc_Type;

      obj.ownerPhotoIdType =
        key.ownerPhotoId === "" ? "" : key.ownerPhotoId.Doc_Type;
      obj.ownerPhotoIdTypeBack =
        key.ownerPhotoId === "" ? "" : key.ownerPhotoIdBack.Doc_Type;
      obj.ownerPhotoIdDetails =
        key.ownerPhotoId === ""
          ? {
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: ""
            }
          : {
              File_Type: key.ownerPhotoId.File_Type,
              File_Name: key.ownerPhotoId.File_Name,
              File_Path: key.ownerPhotoId.File_Path,
              Upload_Date_Time: key.ownerPhotoId.Upload_Date_Time
            };
      obj.ownerPhotoIdBackDetails =
        key.ownerPhotoIdBack === ""
          ? {
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: ""
            }
          : {
              File_Type: key.ownerPhotoIdBack.File_Type,
              File_Name: key.ownerPhotoIdBack.File_Name,
              File_Path: key.ownerPhotoIdBack.File_Path,
              Upload_Date_Time: key.ownerPhotoIdBack.Upload_Date_Time
            };

      obj.ownerBiometricIdType =
        key.ownerBiometricId === "" ? "" : key.ownerBiometricId.Doc_Type;
      obj.ownerBiometricIdDetails =
        key.ownerBiometricId === ""
          ? {
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: ""
            }
          : {
              File_Type: key.ownerBiometricId?.File_Type,
              File_Name: key.ownerBiometricId?.File_Name,
              File_Path: key.ownerBiometricId?.File_Path,
              Upload_Date_Time: key.ownerBiometricId?.Upload_Date_Time
            };

      obj.ownerBiometricId = key.ownerBiometricId?.File_Path;
      obj.ownerAddressProof = key.ownerAddressProof.File_Path;
      obj.ownerPhotoId = key.ownerPhotoId.File_Path;
      obj.ownerPhotoIdBack = key.ownerPhotoIdBack.File_Path;
      preDataArray.push(obj);
    });
    allState.ownerArray.owners = preDataArray;
    handleInput(allState);
    const providerName = {
      providerId:
        props.formData.providerDetailsById &&
        props.formData.providerDetailsById.Providers_Data[0]._id
    };
    props.statusPreameter(
      providerName,
      (data) => {
        data.status ? setMpaCheckListStatus(data.data) : console.log(data);
      },
      (err) => {
        enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
          variant: "error"
        });
      }
    );
    props.statusPreameterKyc(
      { ...providerName, ownerId: allState.ownerArray.owners[0]?.Owner_ID },
      (data) => {
        data.status ? setKycCheckListStatus(data.data) : console.log(data);
      },
      (err) => {
        enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
          variant: "error"
        });
      }
    );
    setData(true);
  }

  const saveStatus = () => {
    const data = {
      id: props.formData.providerDetailsById.Providers_Data[0]._id,
      status: values.status
    };
    props.changeSingleStatus(data);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUploadOnChange = (files, type, ownerType, index) => {
    const allState = { ...values };
    if (type === "main") {
      if (files) {
        allState.incorportionDocumentDetails.File_Name = files.name;
        allState.incorportionDocumentDetails.File_Path = files.path;
        allState.incorportionDocumentDetails.File_Type =
          files.name.split(".")[files.name.split(".").length - 1];
        allState.incorportionDocumentDetails.Upload_Date_Time = moment();
        const reader = new FileReader();
        reader.readAsDataURL(files);
        if (files.size / 1024 / 1024 < 25) {
          reader.onload = function () {
            allState.incorportionDocument = reader.result;
            allState.docAdd = true;
            handleInput(allState);
          };
          reader.onerror = function (error) {
            console.log("Error: ", error);
          };
        } else {
          enqueueSnackbar("Max file size can be upto 25 MB", {
            variant: "error"
          });
        }
      }
    }
    if (type === "owner") {
      if (files) {
        if (ownerType === "ownerAddressProof") {
          allState.ownerArray.owners[index].ownerAddressProofDetails.File_Name =
            files.name;
          allState.ownerArray.owners[index].ownerAddressProofDetails.Doc_Type =
            allState.ownerArray.owners[index].ownerAddressProofType;
          allState.ownerArray.owners[index].ownerAddressProofDetails.File_Type =
            files.name.split(".")[files.name.split(".").length - 1];
          allState.ownerArray.owners[
            index
          ].ownerAddressProofDetails.Upload_Date_Time = moment();
        }

        if (ownerType === "ownerPhotoId") {
          allState.ownerArray.owners[index].ownerPhotoIdDetails.File_Name =
            files.name;
          allState.ownerArray.owners[index].ownerPhotoIdDetails.Doc_Type =
            allState.ownerArray.owners[index].ownerPhotoIdType;
          allState.ownerArray.owners[index].ownerPhotoIdDetails.File_Type =
            files.name.split(".")[files.name.split(".").length - 1];
          allState.ownerArray.owners[
            index
          ].ownerPhotoIdDetails.Upload_Date_Time = moment();
        }

        if (ownerType === "ownerPhotoIdBack") {
          allState.ownerArray.owners[index].ownerPhotoIdBackDetails.File_Name =
            files.name;
          allState.ownerArray.owners[index].ownerPhotoIdBackDetails.Doc_Type =
            allState.ownerArray.owners[index].ownerPhotoIdTypeBack;
          allState.ownerArray.owners[index].ownerPhotoIdBackDetails.File_Type =
            files.name.split(".")[files.name.split(".").length - 1];
          allState.ownerArray.owners[
            index
          ].ownerPhotoIdBackDetails.Upload_Date_Time = moment();
        }
        if (ownerType === "ownerBiometricId") {
          allState.ownerArray.owners[index].ownerBiometricIdDetails.File_Name =
            files.name;
          allState.ownerArray.owners[index].ownerBiometricIdDetails.Doc_Type =
            allState.ownerArray.owners[index].ownerBiometricIdType;
          allState.ownerArray.owners[index].ownerBiometricIdDetails.File_Type =
            files.name.split(".")[files.name.split(".").length - 1];
          allState.ownerArray.owners[
            index
          ].ownerBiometricIdDetails.Upload_Date_Time = moment();
        }
        const reader = new FileReader();
        reader.readAsDataURL(files);
        if (files.size / 1024 / 1024 < 25) {
          reader.onload = function () {
            allState.ownerArray.owners[index][ownerType] = reader.result;
            allState.docAdd = true;
            handleInput(allState);
          };
          reader.onerror = function (error) {
            console.log("Error: ", error);
          };
        } else {
          enqueueSnackbar("Max file size can be upto 25 MB", {
            variant: "error"
          });
        }
      }
    }
  };

  const handleUploadAttechmentOnChange = (files) => {
    if (files) {
      const preFileValues = { ...fileHandleAttechment };
      const fileValues = {
        file: "",
        File_Name: "",
        File_Path: "",
        File_Type: "",
        Upload_Date_Time: ""
      };
      fileValues.File_Name = files.name;
      fileValues.File_Path = files.path;
      fileValues.File_Type =
        files.name.split(".")[files.name.split(".").length - 1];
      fileValues.Upload_Date_Time = `${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()} ${new Date().getHours()}:${new Date().getMinutes()}`;
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = function () {
        fileValues.file = reader.result;
        preFileValues.files.push(fileValues);
        setFileHandleAttechment(preFileValues);
        if (kycListModal) {
          const kycAttechmentData = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
            quesId: selectedQuestion._id,
            attachment: reader.result
          };
          props.kycChecklistAttechment(
            kycAttechmentData,
            (data) => {
              enqueueSnackbar(data.message, {
                variant: "success"
              });
            },
            (err) => {
              enqueueSnackbar(
                err.message ? err.message : "Failed to get Data",
                {
                  variant: "error"
                }
              );
            }
          );
        }
        if (checkListModal) {
          const kycAttechmentData = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
            quesId: selectedQuestion._id,
            attachment: reader.result
          };
          props.mpaChecklistAttechment(
            kycAttechmentData,
            (data) => {
              enqueueSnackbar(data.message, {
                variant: "success"
              });
            },
            (err) => {
              enqueueSnackbar(
                err.message ? err.message : "Failed to get Data",
                {
                  variant: "error"
                }
              );
            }
          );
        }
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  };

  const sendComment = () => {
    const requestData = {
      providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
      quesId: selectedQuestion._id,
      comment: textAreaText,
      checklistName: kycListModal ? "KYCChecklist" : "MPAChecklist"
    };
    props.mpaChecklistComment(
      requestData,
      (data) => {
        const dataQuestion = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id
        };
        props.getQuestionData(dataQuestion);
        props.getKycQuestionData(dataQuestion);
        enqueueSnackbar(data.message, {
          variant: "success"
        });
      },
      (err) => {
        enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
          variant: "error"
        });
      }
    );
  };

  const onFormPreviewSubmit = () => {
    setTCErrorPrompt(true);
    handleClose();
    setValidationModal(false);
    props.getProviderStatusNormal();
    if (
      checkValidation(
        false,
        errorMessage,
        values,
        setErrorMessage,
        setVlidationError,
        setValidationModal,
        checkTC,
        validationError
      )
    ) {
      if (props.formData.formMode === "Create") {
        const requestData = { ...values };
        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );
        const arrayNew = [];
        values.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          arrayNew.push(obj);
          obj.Owner_Last4_SSN = key.Owner_Last4_SSN.replace(/-/g, "");
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
        });
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = true;
        props.provideRegistration({
          ...requestData,
          acceptTermsCondition: checkTC
        });
      }

      if (props.formData.formMode === "Edit") {
        const requestData = { ...values };
        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );
        const arrayNew = [];
        values.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          obj.Owner_Last4_SSN = key.Owner_Last4_SSN.replace(/-/g, "");
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
          arrayNew.push(obj);
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
        });
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = true;
        const tCM = props.formData.providerDetailsById.Providers_Data[0]
          .acceptTermsCondition
          ? true
          : checkTC;
        const providerDatabyId =
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById.Providers_Data[0];
        const termAndConditions = {
          docCreatedDate: providerDatabyId.termAndConditions.docCreatedDate
            ? providerDatabyId.termAndConditions.docCreatedDate
            : signedDocData.docCreatedDate,
          docEmailId: providerDatabyId.termAndConditions.docEmailId
            ? providerDatabyId.termAndConditions.docEmailId
            : signedDocData.docEmailId,
          docInviteId: providerDatabyId.termAndConditions.docInviteId
            ? providerDatabyId.termAndConditions.docInviteId
            : signedDocData.docInviteId,
          docRoleId: providerDatabyId.termAndConditions.docRoleId
            ? providerDatabyId.termAndConditions.docRoleId
            : signedDocData.docRoleId,
          docSignDate: providerDatabyId.termAndConditions.docSignDate
            ? providerDatabyId.termAndConditions.docSignDate
            : signedDocData.docSignDate,
          docStatus:
            providerDatabyId.termAndConditions?.docStatus == "Signed"
              ? providerDatabyId.termAndConditions?.docStatus
              : signedDocData?.docStatus,
          documentId: providerDatabyId.termAndConditions.documentId
            ? providerDatabyId.termAndConditions.documentId
            : signedDocData.documentId,
          documentName: providerDatabyId.termAndConditions.documentName
            ? providerDatabyId.termAndConditions.documentName
            : signedDocData.documentName,
          name: requestData.nameTermsCondition,
          templateId: providerDatabyId.termAndConditions.templateId
            ? providerDatabyId.termAndConditions.templateId
            : signedDocData.templateId,
          title: requestData.titleTermsCondition
        };
        props.updateRegistration(
          {
            ...requestData,
            acceptTermsCondition: tCM,
            termAndConditions
          },
          props.formData.providerDetailsById.Providers_Data[0]._id
        );
      }
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
  };

  const onFormSubbmit = () => {
    setTCErrorPrompt(false);
    handleClose();
    setValidationModal(false);
    props.getProviderStatusNormal();
    if (
      checkValidation(
        true,
        errorMessage,
        values,
        setErrorMessage,
        setVlidationError,
        setValidationModal,
        check,
        validationError
      )
    ) {
      if (props.formData.formMode === "Create") {
        const arrayNew = [];
        const requestData = { ...values };
        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );
        requestData.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          obj.Owner_Last4_SSN = key.Owner_Last4_SSN.replace(/-/g, "");
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
          arrayNew.push(obj);
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
        });
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = false;
        requestData.acceptTermsCondition = false;
        props.provideRegistration(requestData);
      }
      if (props.formData.formMode === "Edit") {
        const arrayNew = [];
        const requestData = { ...values };
        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );
        requestData.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          obj.Owner_Last4_SSN = key.Owner_Last4_SSN.replace(/-/g, "");
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
          arrayNew.push(obj);
        });
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = false;
        requestData.acceptTermsCondition = false;
        const providerDatabyId =
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById.Providers_Data[0];
        const termAndConditions = {
          docCreatedDate: providerDatabyId.termAndConditions.docCreatedDate
            ? providerDatabyId.termAndConditions.docCreatedDate
            : signedDocData.docCreatedDate,
          docEmailId: providerDatabyId.termAndConditions.docEmailId
            ? providerDatabyId.termAndConditions.docEmailId
            : signedDocData.docEmailId,
          docInviteId: providerDatabyId.termAndConditions.docInviteId
            ? providerDatabyId.termAndConditions.docInviteId
            : signedDocData.docInviteId,
          docRoleId: providerDatabyId.termAndConditions.docRoleId
            ? providerDatabyId.termAndConditions.docRoleId
            : signedDocData.docRoleId,
          docSignDate: providerDatabyId.termAndConditions.docSignDate
            ? providerDatabyId.termAndConditions.docSignDate
            : signedDocData.docSignDate,
          docStatus:
            providerDatabyId.termAndConditions?.docStatus == "Signed"
              ? providerDatabyId.termAndConditions?.docStatus
              : signedDocData?.docStatus,
          documentId: providerDatabyId.termAndConditions.documentId
            ? providerDatabyId.termAndConditions.documentId
            : signedDocData.documentId,
          documentName: providerDatabyId.termAndConditions.documentName
            ? providerDatabyId.termAndConditions.documentName
            : signedDocData.documentName,
          name: requestData.nameTermsCondition,
          templateId: providerDatabyId.termAndConditions.templateId
            ? providerDatabyId.termAndConditions.templateId
            : signedDocData.templateId,
          title: requestData.titleTermsCondition
        };
        props.updateRegistration(
          { ...requestData, termAndConditions },
          props.formData.providerDetailsById.Providers_Data[0]._id
        );
        if (requestData.incorportionDocument === "" && values.docAdd) {
          const data = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
            document:
              props.formData.providerDetailsById.Providers_Data[0]
                .incorportionDocument
          };
          props.deleteIncroptration(data);
        }

        values.ownerArray.owners.forEach((key, i) => {
          if (key.ownerAddressProofDetails.File_Name === "" && values.docAdd) {
            const dataOwner = {
              providerId:
                props.formData.providerDetailsById.Providers_Data[0]._id,
              ownerId: key?.Owner_ID,
              document:
                props.formData.providerDetailsById.Providers_Data[0]
                  .Owner_Information[i]?.ownerAddressProof
            };
            props.deleteAddressProof(dataOwner);
          }
          if (key.ownerPhotoIdDetails.File_Name === "" && values.docAdd) {
            const dataOwnerId = {
              providerId:
                props.formData.providerDetailsById.Providers_Data[0]._id,
              ownerId: key?.Owner_ID,
              document:
                props.formData.providerDetailsById.Providers_Data[0]
                  .Owner_Information[i]?.ownerPhotoId
            };
            props.deleteIdentityProof(dataOwnerId);
          }
          if (key.ownerPhotoIdBackDetails.File_Name === "" && values.docAdd) {
            const dataOwnerId = {
              providerId:
                props.formData.providerDetailsById.Providers_Data[0]._id,
              ownerId: key?.Owner_ID,
              document:
                props.formData.providerDetailsById.Providers_Data[0]
                  .Owner_Information[i].ownerPhotoIdBack
            };
            props.deletebBackIdentityProof(dataOwnerId);
          }
          if (key.ownerBiometricIdDetails.File_Name === "" && values.docAdd) {
            const dataOwnerId = {
              providerId:
                props.formData.providerDetailsById.Providers_Data[0]._id,
              ownerId: key?.Owner_ID,
              document:
                props.formData.providerDetailsById.Providers_Data[0]
                  .Owner_Information[i]?.ownerBiometricId
            };
            props.deleteBiometricProof(dataOwnerId);
          }
        });
      }
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handlelistBack = () => {
    props.getProviderStatusNormal();
    if (props.mainData.app.appBarName.includes("Progress")) {
      props.history.push(`/${props.mainData.app.layout}/InProgress`);
    }
    if (props.mainData.app.appBarName.includes("Live")) {
      props.history.push(`/${props.mainData.app.layout}/Live`);
    }
  };

  const handleErrorMessage = () => {
    setValidationModal(false);
    setErrorMessage(false);
    setTCErrorPrompt(false);
  };

  const handleQuestionStatus = (item, value, type, i) => {
    if (type === "MPA_Checklist") {
      const data = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        quesId: item._id,
        userId: localStorage.getItem("userId"),
        option: value,
        comment: "Testing option comment",
        checklistName: "MPAChecklist"
      };
      const providerName = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id
      };
      props.statusPreameter(
        providerName,
        (data) => {
          // eslint-disable-next-line no-unused-expressions
          data.status ? setMpaCheckListStatus(data.data) : console.log(data);
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error"
          });
        }
      );
      props.saveQuestionCall(data);
    }
    if (type === "KYC_Checklist") {
      const data = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        quesId: item._id,
        userId: localStorage.getItem("userId"),
        option: value,
        comment: "Testing option comment",
        checklistName: "KYCChecklist"
      };
      props.saveQuestionCall(data);
    }
  };

  const handleDeleteUpload = (type, index, docType) => {
    const allState = { ...values };
    if (type === "main") {
      allState.incorportionDocument = "";
      allState.incorportionDocumentDetails = {
        File_Name: "",
        File_Type: "",
        Upload_Date_Time: ""
      };
      allState.docAdd = true;
    }
    if (type === "owner") {
      allState.ownerArray.owners[index][docType] = "";
      allState.ownerArray.owners[index][`${docType}Details`] = {
        File_Name: "",
        File_Type: "",
        Upload_Date_Time: ""
      };
      allState.docAdd = true;
    }
    handleInput(allState);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    if (type === "businessCountry") {
      if (value === "US") {
        setStateInput(true);
        allState.businessState = "";
        allState[type] = value;
      } else {
        setStateInput(false);
        allState.businessState = "";
        allState[type] = value;
      }
    } else if (type === "businessStartDate") {
      if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    } else if (type === "businessEmail") {
      const res = value.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      );
      if (res === null) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    } else if (type === "businessPhoneNumber") {
      error[type] = false;
      allState[type] = formatPhoneNumber(value);
    } else if (type === "businessPostalCode") {
      if (!/^[0-9]{5}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    } else if (type === "accountNumber") {
      if (!/^[0-9]{4,17}$/.test(value)) {
        console.log("error");
        error[type] = true;
        allState[type] = value;
      } else {
        console.log("no error");

        error[type] = false;
        allState[type] = value;
      }
    } else if (type === "businessWebsite") {
      const res = value.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      if (res === null) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    } else if (type === "businessTaxId" || type === "routingNumber") {
      if (!/^[0-9]{9}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    } else if (
      type === "cardPresent" ||
      type === "cardNotPresent" ||
      type === "eCommers"
    ) {
      if (!/\b((100)|[1-9]\d?)\b/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    } else {
      error[type] = false;
      allState[type] = value;
    }
    handleInput(allState);
    setVlidationError(error);
  };

  const handlePricingTemplete = () => {
    props.setPricingTempleteEmpty();
    const callPricingTempleteData = {
      providerId: props.formData.providerDetailsById.Providers_Data[0]._id
    };
    props.pricingTempleteCall(callPricingTempleteData);
    props.history.push(`/${props.mainData.app.layout}/pricingTemplete`);
  };

  // sign on dodc
  let docData = "";
  const preData =
    props.formData.providerDetailsById &&
    props.formData.providerDetailsById.Providers_Data[0];

  const handleESign = () => {
    if (props.formData.formMode === "Edit" && isEmpty(errorMessage)) {
      if (
        values.titleTermsCondition == "" ||
        isEmpty(values.titleTermsCondition) ||
        values.nameTermsCondition == "" ||
        isEmpty(values.nameTermsCondition)
      ) {
        enqueueSnackbar(
          "Please fill Title and Name before signing Addendum document",
          {
            variant: "warning"
          }
        );
      } else if (
        (preData.termAndConditions?.docStatus == "Signed" || checkTC) &&
        values.titleTermsCondition != "" &&
        values.nameTermsCondition != ""
      ) {
        window.open(
          `${process.env.REACT_APP_AWS_PATH}${process.env.REACT_APP_AWS_SIGNED_ADDENDUM}/${preData._id}.pdf`
        );
      } else if (
        preData.termAndConditions?.docStatus != "Signed" &&
        values.titleTermsCondition != "" &&
        values.nameTermsCondition != ""
      ) {
        if (preData.termAndConditions.templateId == "") {
          docData = {
            Provider_ID: preData._id,
            Template_ID: "",
            Document_ID: "",
            Document_Name: "",
            Doc_Role_ID: "",
            Doc_Email_ID: preData.Business_EMail,
            Doc_Invite_ID: "",
            Acceptance_Title: values.titleTermsCondition,
            Acceptance_Name: values.nameTermsCondition,
            Template_Key: "",
            Title_field_ID: "",
            Name_field_ID: ""
          };
        } else {
          docData = {
            Provider_ID: preData._id,
            Template_ID: preData.termAndConditions.templateId,
            Document_ID: preData.termAndConditions.documentId,
            Document_Name: preData.termAndConditions.documentName,
            Doc_Role_ID: preData.termAndConditions.docRoleId,
            Doc_Email_ID: preData.Business_EMail,
            Doc_Invite_ID: preData.termAndConditions.docInviteId,
            Acceptance_Title: values.titleTermsCondition,
            Acceptance_Name: values.nameTermsCondition,
            Template_Key: "",
            Title_field_ID: "",
            Name_field_ID: ""
          };
        }
        setLoading(true);
        props.getSignNowCall({ ...docData }, (res) => {
          setLoading(false);
          if (res.status) {
            setIsDocModalOpen(true);
            setDocLink(res.link);
          } else {
            enqueueSnackbar(res.data.errors[0].message, {
              variant: "error"
            });
          }
        });
      }
    } else {
      enqueueSnackbar(
        "Please save the form first by clicking on Save Draft button below",
        {
          variant: "warning"
        }
      );
    }
  };

  function DocAlert() {
    const handleAlertClose = () => {
      setAlertOpen(false);
    };

    const handleAlertModalClose = () => {
      setAlertOpen(false);
      setIsDocModalOpen(false);
    };
    return (
      <Dialog
        open={alterOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Sign the Addendum Document
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have not signed the document yet. Do you still want to close the
            window?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            No
          </Button>
          <Button onClick={handleAlertModalClose} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  const closeDocModal = () => {
    const providerId = preData._id;
    props.getDocStatus(providerId, (data) => {
      setSignedDocData(data.data);
      if (data.data?.docStatus == "Signed") {
        setCheckTC(true);
        setIsDocModalOpen(false);
        setAlertOpen(false);
        enqueueSnackbar("Addendum document successfully signed", {
          variant: "success"
        });
      } else if (data.data?.docStatus != "Signed") {
        setCheckTC(false);
        setAlertOpen(true);
      }
    });
  };
  // sign on doc conditin close
  const disabled =
    props.formData.formMode === "Edit" &&
    checkFormDisabled(
      props,
      values,
      props.formData.statusListById &&
        props.formData.statusListById.Provider_Status_Data
    );
  const showKycButton =
    props.formData.formMode === "Edit" && showCheckList(props, values);
  const changeStatus =
    props.formData.formMode === "Edit" &&
    changeStatusPermission(
      props,
      values,
      props.formData.statusListById &&
        props.formData.statusListById.Provider_Status_Data
    );

  return (
    <div style={{ paddingBottom: "40px" }}>
      <Modal open={validationModal} onClose={() => setValidationModal(false)}>
        <div className={classes.modalDiv}>
          <Card
            style={{
              width: "50%",
              maxWidth: "40%",
              height: "auto",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                minInlineSize: "-webkit-fill-available",
                textAlign: "start",
                height: "auto",
                maxHeight: "70vh",
                display: "flex",
                overflow: "auto",
                flexWrap: "nowrap",
                marginTop: "10%",
                alignItems: "center",
                flexDirection: "column"
              }}
            >
              <img
                src={closeImage}
                style={{ width: "10%", marginBottom: "3%" }}
              />
              <div className={classes.successText}>
                {!errorMessage || isEmpty(errorMessage) ? (
                  <Typography
                    style={{ textAlign: "center" }}
                    color="error"
                    variant="subtitle2"
                    gutterBottom
                  >
                    Fields marked with an asterisk (*) are mandatory
                  </Typography>
                ) : (
                  ""
                )}
              </div>
              {/* <div className={classes.successText}>-Email ID should be unique</div> */}
              {errorMessage && (
                <div
                  style={{ marginTop: "2%" }}
                  className={classes.successText}
                >
                  {/* {errorMessage} */}
                  {errorMessage &&
                    errorMessage.map((key) => (
                      <div className={classes.successText}>-{key.msg} </div>
                    ))}
                  {!checkTC &&
                    preData.termAndConditions?.docStatus != "Signed" &&
                    tcErrorPrompt && (
                      <div className={classes.successText}>
                        - Please click on 'Sign Document' button to sign the
                        'Terms and Conditions' to process further.
                      </div>
                    )}
                </div>
              )}
              <br />
            </div>

            <CardActions style={{ margin: "5%" }}>
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="sm"
                onClick={() => handleErrorMessage()}
              >
                Ok
              </Button>
            </CardActions>
          </Card>
        </div>
      </Modal>
      <Card
        style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
      >
        <Box
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            alignItems: "center",
            padding: "10px"
          }}
          className={classes.tittleColor}
          height={40}
        >
          <Grid
            style={{
              display: "flex",
              position: "relative",
              flexWrap: "nowrap",
              boxSizing: "border-box",
              flexDirection: "row",
              alignItems: "center"
            }}
            container
          >
            <Grid style={{ position: "absolute" }} item>
              <IconButton
                color="white"
                aria-label="Back"
                onClick={() => handleBack()}
                component="span"
                style={{ background: "white", padding: "6px" }}
              >
                <KeyboardBackspaceIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid style={{ margin: "auto" }} item>
              <Typography
                style={{ margin: "auto" }}
                className={classes.tittleTextColor}
              >
                Business Information
              </Typography>
            </Grid>
            {actionPermission(
              props.roles,
              merchantsCatId,
              merchantsInprogessPageId,
              defaults.create
            ) && (
              <Grid style={{ display: "flex" }} item>
                <IconButton
                  color="white"
                  aria-label="Back"
                  onClick={() => handleAddNew()}
                  component="span"
                  style={{
                    background: "white",
                    padding: "6px",
                    margin: "10px"
                  }}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
                <Typography
                  style={{ margin: "auto" }}
                  className={classes.tittleTextColor}
                >
                  Add New
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center"
            // padding: "4%",
          }}
        >
          <FormControl style={{ marginTop: "20px" }}>
            {props.formData.formMode === "Edit" && (
              <InfoHeaderSection
                permission={{
                  changeStatus: actionPermission(
                    props.roles,
                    merchantsCatId,
                    merchantsInprogessPageId,
                    defaults.changeStatus
                  ),
                  edit: actionPermission(
                    props.roles,
                    merchantsCatId,
                    merchantsInprogessPageId,
                    defaults.edit
                  )
                }}
                handlePricingTemplete={() => handlePricingTemplete()}
                values={values}
                changeStatus={changeStatus}
                disabled={disabled}
                saveStatus={() => saveStatus()}
                history={props.history}
                changeSingleStatus={(data) => props.changeSingleStatus(data)}
                getProviderActivityById={(data) =>
                  props.getProviderActivityById(data)
                }
                onChange={(data, type) => onChange(data, type)}
              />
            )}
            <Box className={classes.marginBox}>
              <BusinessInformationSection
                disabled={disabled}
                validationError={validationError}
                values={values}
                onChange={(data, type) => onChange(data, type)}
                setVlidationError={(data) => setVlidationError(data)}
                getMccList={() => props.getMccList()}
              />
              <IncroprationDocument
                disabled={disabled}
                validationError={validationError}
                values={values}
                handleUploadOnChange={(data, type) =>
                  handleUploadOnChange(data, type)
                }
                handleDeleteUpload={(type) => handleDeleteUpload(type)}
              />
            </Box>
            <Box
              style={{
                alignItems: "center",
                marginTop: "30px",
                marginBottom: "30px"
              }}
              className={classes.tittleColor}
              height={40}
            >
              <Typography className={classes.tittleTextColor}>
                Settlement Account Information
              </Typography>
            </Box>
            <SettlementAccount
              disabled={disabled}
              validationError={validationError}
              values={values}
              onChange={(data, type) => onChange(data, type)}
            />
            <Box
              style={{
                alignItems: "center",
                marginTop: "30px",
                marginBottom: "30px"
              }}
              className={classes.tittleColor}
              height={40}
            >
              <Typography className={classes.tittleTextColor}>
                Processing Method and Volume
              </Typography>
            </Box>
            <ProccessMethod
              disabled={disabled}
              validationError={validationError}
              values={values}
              onChange={(data, type) => onChange(data, type)}
            />
            <Box
              style={{
                alignItems: "center",
                marginTop: "30px"
              }}
              className={classes.tittleColor}
              height={40}
            >
              <Typography className={classes.tittleTextColor}>
                Owner Information
              </Typography>
            </Box>
            {values.ownerArray.owners &&
              values.ownerArray.owners.map((key, i) => (
                <OwnerInformation
                  handleOwnerChange={(data, type, e) =>
                    ownerOnchange(i, data, type, e)
                  }
                  handleDeleteUpload={(docType) =>
                    handleDeleteUpload("owner", i, docType)
                  }
                  handleUploadOnChange={(data, type) =>
                    handleUploadOnChange(data, "owner", type, i)
                  }
                  index={i}
                  disabled={disabled}
                  validationError={validationError.ownerArray.owners[i]}
                  data={key}
                  add={() => addOwner()}
                  remove={() => setOwnerDeleteModal(i)}
                />
              ))}

            {props.mainData.app.leftMenu &&
            props.mainData.app.leftMenu.data &&
            props.mainData.app.leftMenu.data.Profile_Code &&
            props.mainData.app.leftMenu.data.Profile_Code === "Merchant" ? (
              ""
            ) : (
              <Grid item lg={12} sm={12} xs={12}>
                <CommentIcon className={classes.inputBox} fontSize="small" />
                <TextField
                  disabled={disabled}
                  multiline
                  style={{
                    marginTop: "20px",
                    width: windowWidthLG ? "94%" : "80%",
                    "&.focused": {
                      color: theme.palette.primary.main,
                      fontSize: "14px",
                      fontStyle: "Helvetica, Arial,sans-serif"
                    }
                  }}
                  rows={3}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    }
                  }}
                  label="Notes"
                  value={values.description}
                  error={validationError.description}
                  onChange={(e) => onChange(e.target.value, "description")}
                  name="description"
                  id="description"
                />
              </Grid>
            )}

            <Box style={{ marginTop: "20px" }} height={20}>
              <Typography style={{ marginLeft: "10px" }}>
                Terms and Conditions:
              </Typography>
            </Box>
            <Grid spacing={2} container className={classes.margin}>
              <Grid
                style={{ marginTop: "10px" }}
                item
                container
                xs={11}
                sm={11}
                md={11}
                lg={6}
                xl={6}
              >
                <Grid display="flex" item xs={12} sm={6}>
                  <MenuBookRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    required
                    disabled={
                      preData?.termAndConditions?.docStatus == "Signed" ||
                      checkTC
                    }
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Title"
                    error={validationError.titleTermsCondition}
                    value={values.titleTermsCondition}
                    onChange={(e) =>
                      onChange(e.target.value, "titleTermsCondition")
                    }
                    helperText={
                      validationError.titleTermsCondition &&
                      "Title is Required."
                    }
                    name="titleTermsCondition"
                    id="titleTermsCondition"
                  />
                </Grid>
                <Grid display="flex" item xs={12} sm={6}>
                  <PersonIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    required
                    disabled={
                      preData?.termAndConditions?.docStatus == "Signed" ||
                      checkTC
                    }
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Name"
                    error={validationError.nameTermsCondition}
                    value={values.nameTermsCondition}
                    onChange={(e) =>
                      onChange(e.target.value, "nameTermsCondition")
                    }
                    helperText={
                      validationError.nameTermsCondition && "Name is Required."
                    }
                    name="nameTermsCondition"
                    id="nameTermsCondition"
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {(preData?.termAndConditions?.docStatus == "Signed" ||
                  checkTC) && (
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    onClick={() => handleESign()}
                  >
                    <Typography nowrap>Download Document</Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid container className={classes.margin}>
              <DocAlert />
              <Box className={classes.tncBox}>
                <Typography style={{ marginTop: "10px", marginBottom: "20px" }}>
                  <Checkbox
                    className={classes.checkBox}
                    checked={
                      preData?.termAndConditions?.docStatus == "Signed" ||
                      checkTC
                    }
                    disabled
                    name="checkedB"
                    size="small"
                    style={{ marginRight: "5px" }}
                    color="primary"
                  />
                  By clicking on{" "}
                  <Button
                    variant="contained"
                    disabled={
                      isEmpty(values.nameTermsCondition) ||
                      isEmpty(values.titleTermsCondition) ||
                      preData?.termAndConditions?.docStatus == "Signed" ||
                      checkTC
                    }
                    className={classes.buttonStyle}
                    style={{
                      textTransform: "none",
                      padding: "2px 6px",
                      height: "unset"
                    }}
                    color="#696969"
                    onClick={() => handleESign()}
                    // size="large"
                  >
                    <Typography
                      style={{
                        textTransform: "none"
                      }}
                      nowrap
                    >
                      eSign Now
                    </Typography>
                  </Button>{" "}
                  I am agreeing to T/C and submitting my application for
                  underwriting.
                </Typography>
              </Box>
            </Grid>

            {loading && <AppLoader loader={loading} />}
            {!loading && (
              <SignOnModal
                isDocModalOpen={isDocModalOpen}
                closeDocModal={closeDocModal}
                docLink={docLink}
              />
            )}
            <Grid
              container
              style={{ justifyContent: "center", marginTop: "20px" }}
              className={classes.margin}
            >
              <Box className={classes.buttonBox}>
                <Grid
                  container
                  item
                  spacing={2}
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    justifyContent: "inherit",
                    flexWrap: smallDevice ? "wrap" : "nowrap"
                  }}
                >
                  {/* <Grid item sm={2} xs={windowWidth ? 3 : 10}>
                    <Button
                      className={classes.clearButton}
                      variant="contained"
                      fullWidth
                      color="#696969"
                      onClick={() => handleBack()}
                    >
                      Back
                    </Button>
                  </Grid> */}
                  {!disabled && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        gap: "10px"
                      }}
                    >
                      {!disableSaveDraftButton(props, values) &&
                        (preData?.termAndConditions?.docStatus !== "Signed" ||
                          !checkTC) && (
                          <Button
                            variant="contained"
                            className={classes.buttonStyle}
                            color="#696969"
                            // ref={(node) => (this.saveDraftBusinessForm = node)}
                            id="saveDraftBusinessForm"
                            onClick={() => onFormSubbmit()}
                            size="large"
                          >
                            <Typography nowrap>Save Draft</Typography>
                          </Button>
                        )}
                      <Button
                        variant="contained"
                        disabled={disabled}
                        className={classes.buttonStyle}
                        color="#696969"
                        onClick={() => onFormPreviewSubmit()}
                        size="large"
                      >
                        <Typography nowrap>
                          {!disableSaveDraftButton(props, values)
                            ? "Submit"
                            : "Update"}
                        </Typography>
                      </Button>
                    </div>
                  )}
                </Grid>
              </Box>
            </Grid>
          </FormControl>
        </div>
      </Card>
      {showKycButton && (
        <Grid
          container
          className={classes.fab}
          spacing={2}
          justifyContent="center"
        >
          <Grid item>
            <Button
              fab
              variant="fab"
              color="primary"
              aria-label="add"
              className={classes.verticalButtonStyle}
              onClick={() => setCheckListModal(true)}
              size="large"
            >
              <Typography
                style={{
                  width: "1em",
                  flexBasis: "min-content",
                  fontSize: "12px"
                }}
              >
                Checklist
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              fab
              variant="fab"
              color="primary"
              aria-label="add"
              className={classes.verticalButtonStyle}
              onClick={() => setKycListModal(true)}
              size="large"
            >
              <Typography
                style={{
                  width: "1em",
                  flexBasis: "min-content",
                  fontSize: "12px"
                }}
              >
                KYC - Checklist
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
      <Modal open={approveModal} onClose={() => setApproveModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCardApprove}>
            <div className={classes.cardDivApprove}>
              <ErrorOutlineIcon style={{ fontSize: 60, fill: "#f5d16e" }} />
              <p>
                <strong style={{ fontSize: "14px", color: "primary" }}>
                  Are you sure you want to approve the checklist?
                </strong>
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  style={{ margin: "10px" }}
                  size="sm"
                  onClick={() => approveYesClick()}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  className={classes.clearButton}
                  color="#696969"
                  style={{ margin: "10px" }}
                  size="sm"
                  onClick={() => setApproveModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal open={declineModal} onClose={() => setDeclineModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCardApprove}>
            <div className={classes.cardDivApprove}>
              <ErrorOutlineIcon style={{ fontSize: 60, fill: "#f5d16e" }} />
              <p>
                <strong style={{ fontSize: "14px", color: "primary" }}>
                  Are you sure you want to decline the checklist?
                </strong>
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  style={{ margin: "10px" }}
                  size="sm"
                  onClick={() => declineYesClick()}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  className={classes.clearButton}
                  color="#696969"
                  style={{ margin: "10px" }}
                  size="sm"
                  onClick={() => setDeclineModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal
        open={ownerDeleteModal !== false}
        onClose={() => setOwnerDeleteModal(false)}
      >
        <div className={classes.modalDiv}>
          <Card
            className={
              windowWidth ? classes.modalCard : classes.mobileModalCard
            }
          >
            <div
              style={{ display: "inline-table" }}
              className={classes.cardDiv1}
            >
              <img src={closeImage} width="75px" />

              <div
                style={{
                  textAlign: "center",
                  padding: "4%",
                  color: "gray",
                  fontSize: "14px",
                  whiteSpace: "normal"
                }}
              >
                Are you sure to remove?
              </div>
              <br />
              <Grid spacing={2} container>
                <Grid xs={4} item>
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    size="sm"
                    onClick={() => deleteOwner()}
                  >
                    Yes
                  </Button>
                </Grid>
                <Grid xs={1} item />
                <Grid xs={4} item>
                  <Button
                    variant="contained"
                    className={classes.clearButton}
                    color="#696969"
                    size="sm"
                    onClick={() => setOwnerDeleteModal(false)}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </Modal>
      {kycListModal && (
        <KycChecklist
          sendComment={() => sendComment()}
          handleCheckListModalClose={(data) => handleCheckListModalClose(data)}
          handleUploadAttechmentOnChange={(data) =>
            handleUploadAttechmentOnChange(data)
          }
          fileHandleAttechment={fileHandleAttechment}
          commentSection={commentSection}
          values={values}
          ownerSelect={ownerSelect}
          setOwnerSelect={(data) => setOwnerSelect(data)}
          kycCheckListStatus={kycCheckListStatus}
          accordian={accordian}
          handleAccordian={(index) => handleAccordian(index)}
          handleQuestionStatus={(data, type, index) =>
            handleQuestionStatus(data, type, index)
          }
          handleCommentSection={(data, type) =>
            handleCommentSection(data, type)
          }
          selectedQuestion={selectedQuestion}
          setCommentSection={(data) => setCommentSection(data)}
          setApproveModal={(data) => setApproveModal(data)}
          setDeclineModal={(data) => setDeclineModal(data)}
          setTextAreaText={(data) => setTextAreaText(data)}
          value={value}
          textAreaText={textAreaText}
          handleChange={handleChange}
          handleChangeIndex={handleChangeIndex}
        />
      )}
      {checkListModal && (
        <MpaChecklist
          sendComment={() => sendComment()}
          mpaCheckListStatus={mpaCheckListStatus}
          handleCheckListModalClose={(data) => handleCheckListModalClose(data)}
          fileHandleAttechment={fileHandleAttechment}
          handleUploadAttechmentOnChange={(data) =>
            handleUploadAttechmentOnChange(data)
          }
          commentSection={commentSection}
          values={values}
          accordian={accordian}
          handleAccordian={(index) => handleAccordian(index)}
          handleQuestionStatus={(data, type, index) =>
            handleQuestionStatus(data, type, index)
          }
          handleCommentSection={(data, type) =>
            handleCommentSection(data, type)
          }
          selectedQuestion={selectedQuestion}
          setCommentSection={(data) => setCommentSection(data)}
          setApproveModal={(data) => setApproveModal(data)}
          setDeclineModal={(data) => setDeclineModal(data)}
          setTextAreaText={(data) => setTextAreaText(data)}
          value={value}
          textAreaText={textAreaText}
          handleChange={handleChange}
          handleChangeIndex={handleChangeIndex}
        />
      )}
    </div>
  );
}

BusinessForm.propTypes = {
  provideRegistration: PropTypes.func,
  formData: PropTypes.object,
  getMccList: PropTypes.func,
  getCountryTypeList: PropTypes.func,
  accountTypeList: PropTypes.func,
  ownerTypeList: PropTypes.func,
  updateRegistration: PropTypes.func,
  getProviderStatusWithID: PropTypes.func,
  getProviderStatusNormal: PropTypes.func,
  mainData: PropTypes.object,
  history: PropTypes.object,
  getProviderActivityById: PropTypes.func,
  changeSingleStatus: PropTypes.func,
  deleteIncroptration: PropTypes.func,
  deleteAddressProof: PropTypes.func,
  deleteIdentityProof: PropTypes.func,
  getQuestionData: PropTypes.func,
  saveQuestionCall: PropTypes.func,
  getKycQuestionData: PropTypes.func,
  statusPreameter: PropTypes.func,
  statusPreameterKyc: PropTypes.func,
  statusApproveMpa: PropTypes.func,
  statusApproveKyc: PropTypes.func,
  setResetWuestion: PropTypes.func,
  mpaChecklistComment: PropTypes.func,
  kycChecklistAttechment: PropTypes.func,
  pricingTempleteCall: PropTypes.func,
  mpaChecklistAttechment: PropTypes.func,
  changeProviderFormMode: PropTypes.func,
  setPricingTempleteEmpty: PropTypes.func,
  getSignNowCall: PropTypes.func,
  roles: PropTypes.object,
  getDocStatus: PropTypes.func,
  deleteOwnerApiCall: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app,
  roles: app.app
});

export default connect(mapStateToProps, {
  provideRegistration,
  getMccList,
  getKycQuestionData,
  getCountryTypeList,
  accountTypeList,
  ownerTypeList,
  updateRegistration,
  getProviderStatusWithID,
  getProviderStatusNormal,
  getupdatestatus,
  getProviderActivityById,
  getProviderList,
  changeSingleStatus,
  getProviderParameterCall,
  deleteIncroptration,
  deleteAddressProof,
  deleteIdentityProof,
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  saveQuestionCall,
  setResetWuestion,
  mpaChecklistComment,
  kycChecklistAttechment,
  changeProviderFormMode,
  mpaChecklistAttechment,
  pricingTempleteCall,
  getQuestionData,
  setPricingTempleteEmpty,
  getSignNowCall,
  getDocStatus,
  deleteOwnerApiCall
})(BusinessForm);
