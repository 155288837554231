import {
  defaultBoxShadow,
  transition
} from "assets/jss/material-dashboard-react";

const styles = (theme) => ({
  tittleColor: {
    backgroundColor: theme.palette.primary.dark,
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    paddingTop: ".7%",
    height: "2rem"
  },
  tittleTextColor: {
    color: theme.palette.primary.contrastText,
    fontSize: "120%",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  margin: {
    margin: "1%",
    marginTop: "0px",
    marginBottom: "0px"
  },
  radioBox: {
    display: "flex",
    flexDirection: "row",
    alignItem: "center",
    // justifyContent: "space-around",
    justifyContent: "center",
    margin: "2%",
    marginBottom: "0%"
  },
  radioBoxUnique: {
    display: "flex",
    height: "auto",
    flexDirection: "row",
    alignItem: "center",
    justifyContent: "center"
  },
  radioBoxRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  customTooltip: {
    backgroundColor: "#fff",
    color: "#595c5c"
  },
  textColor: {
    color: "#595c5c",
    fontSize: "90%",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  modalDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "stretch"
  },
  input: {
    width: "80%"
  },
  cardWidth: {
    width: "100%"
  },
  iconBox: {
    backgroundColor: theme.palette.info.contrastText
  },
  tncBox: {
    height: "40px",
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    borderColor: "black"
  },
  buttonBox: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alighItem: "center",
    margin: "2rem"
  },
  ownerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1%"
  },
  dialogBoxDropDown: {
    width: "auto",
    color: theme.palette.primary.dark,
    overflow: "hidden",
    marginRight: "2%",
    marginLeft: "2%",
    fontSize: ".7rem",
    boxSizing: "border-box",
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "200",
    lineHeight: "1.5",
    "&:hover": {
      backgroundColor: "#00aab4",
      color: "#fff"
    }
  },
  successText: {
    color: "gray",
    fontSize: "14px",
    whiteSpace: "nowrap"
  },
  statusContainer: {
    margin: "1%"
  },
  marginStatusItem: {
    marginRight: "2%"
  },
  ownerTittle: {
    marginTop: ".5rem"
  },
  buttonStyle: {
    height: "30px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  inputBox: {
    margin: "10px",
    marginTop: "20px",
    color: "#bfbdbd"
  },
  activeInputLableColor: {
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif",
    "&.focused": {
      color: theme.palette.primary.main
    }
  },
  root: {
    width: "100%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
    // backgroundColor: theme.palette.background.paper,
  },
  rootSelectInput: {
    width: "80%",
    marginBottom: "10%",
    fontSize: "90%",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  marginBox: {
    margin: "3%"
  },
  resize: {
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark
  },
  checkBox: {
    padding: "0px"
  },
  cardDiv: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    margin: "10% 10%"
  },
  modalCard: {
    width: "30%",
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  modalCardXs: {
    width: "80%",
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  modalRoot: {
    width: "40%",
    marginTop: "10%",
    height: "auto",
    padding: "30px",
    paddingTop: "10px",
    alignItems: "center",
    justifyContent: "center"
  },
  fullInput: {
    width: "93.2%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  halfInput: {
    width: "87%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      height: "2.1876em",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  halfInputSingle: {
    width: "100%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  withoutOption: {
    width: "26.8%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  quaterInput: {
    width: "73%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  addIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main
    }
  },
  deleteIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: "#ff4747",
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#ff4747"
    }
  },
  dialogWidth: {
    width: "100%"
  },
  calender: {
    zIndex: "10002",
    position: "absolute",
    width: "auto",
    ...defaultBoxShadow,
    ...transition
  },
  rootDialog: {
    width: "100%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  clearButton: {
    backgroundColor: theme.palette.primary.dark,
    height: "30px",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  },
  uploadDocumentBox: {
    display: "flex",
    alignItems: "center",
    margin: "10px 31px",
    justifyContent: "center",
    borderRadius: 10,
    backgroundColor: "#eeeeee",
    padding: "10px",
    width: "auto"
  },
  uploadDocumentBoxRequired: {
    margin: "10px 31px",
    justifyontent: "center",
    padding: "10px",
    borderRadius: 10,
    backgroundColor: "#eeeeee",
    width: "auto"
  }
});

export default styles;
