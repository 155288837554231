import { Modal, Button } from "@material-ui/core";
import React from "react";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { makeStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);

function SignOnModal(props) {
  const classes = useStyles();
  return (
    <Modal
      open={props.isDocModalOpen}
      className={classes.signOnModal}
      onClose={props.closeDocModal}
    >
      <>
        <iframe src={props.docLink} width="900" height="600"></iframe>
        <Button
          variant="contained"
          onClick={props.closeDocModal}
          style={{
            position: "fixed",
            bottom: "50px",
            backgroundColor: "#4856c1"
          }}
        >
          Close
        </Button>
      </>
    </Modal>
  );
}
export default SignOnModal;
