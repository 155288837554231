/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.js";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Modal } from "@material-ui/core";
import ButtonOriginal from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import gif from "../../assets/img/success.gif";

const useStyles = makeStyles(styles);
const PatientDetailsSection = props => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [preValueData, setPreValueData] = React.useState(
    props.showOfferDEtails.data
  );
  const [patintDetailsEdit, setPatintDetailsEdit] = React.useState(false);
  const [preUpdatePatientData, setPreUpdatePatientData] = React.useState(
    props.tableData.updatePatientDetails
  );
  const [errorMessga, setErrorMessga] = React.useState(false);
  const [values, setValues] = React.useState({
    Patient_Name: props.showOfferDEtails.data.Patient_Name,
    Address: props.showOfferDEtails.data.Address,
    Address2: props.showOfferDEtails.data.Address2,
    City: props.showOfferDEtails.data.City,
    Zip: props.showOfferDEtails.data.ZipCode,
    SSN: props.showOfferDEtails.data.SSNFull,
    Email: props.showOfferDEtails.data.Email,
    Mobile_Number: props.showOfferDEtails.data.Mobile_Number,
    DoB: moment(props.showOfferDEtails.data.DOB).format("YYYY-MM-DD"),
    ProcedureName: "",
    ProcedureDate: "",
    Insurance_Provider: props.showOfferDEtails.data.Insurance_Provider,
    Policy_Number: props.showOfferDEtails.data.Policy_Number,
    Patient_Responsibility: props.showOfferDEtails.data.Patient_Responsibility,
  });

  React.useEffect(() => {
    if (preUpdatePatientData !== props.tableData.updatePatientDetails) {
      setPreUpdatePatientData(props.tableData.updatePatientDetails);
      if (props.tableData.updatePatientDetails.status) {
        enqueueSnackbar(preUpdatePatientData.message, {
          variant: "success",
        });
        setPatintDetailsEdit(false);
      } else {
        setErrorMessga(true);
      }
    }
  }, [props]);

  React.useEffect(() => {
    if (preValueData !== props.showOfferDEtails.data) {
      setValues({
        Patient_Name: props.showOfferDEtails.data.Patient_Name,
        Address: props.showOfferDEtails.data.Address,
        Address2: props.showOfferDEtails.data.Address2,
        City: props.showOfferDEtails.data.City,
        Zip: props.showOfferDEtails.data.ZipCode,
        SSN: props.showOfferDEtails.data.SSNFull,
        Email: props.showOfferDEtails.data.Email,
        Mobile_Number: props.showOfferDEtails.data.Mobile_Number,
        DoB: moment(props.showOfferDEtails.data.DOB).format("YYYY-MM-DD"),
        ProcedureName: "",
        ProcedureDate: "",
        Insurance_Provider: props.showOfferDEtails.data.Insurance_Provider,
        Policy_Number: props.showOfferDEtails.data.Policy_Number,
        Patient_Responsibility:
          props.showOfferDEtails.data.Patient_Responsibility,
      });
      setPreValueData(props.showOfferDEtails.data);
    }
  }, [props]);

  const onChange = (value, type) => {
    const allValues = { ...values };
    allValues[type] = value;
    if (allValues[type] === allValues.SSN) {
      const ssnValue = value;
      allValues[type] = formatSocialSecurity(ssnValue);
    }
    setValues(allValues);
  };

  const handleSSNFocus = e => {
    e.preventDefault();
    e.target.select();
  };

  const handleVerifySave = () => {
    const patientIdValue = props.showOfferDEtails.data.Patient_id;
    let formType = "add";
    if (patientIdValue !== "") {
      formType = "edit";
    }
    const data = {
      patientId: props.showOfferDEtails.data.Patient_id,
      patientName: values.Patient_Name,
      ssn: values.SSN,
      mobile: values.Mobile_Number,
      email: values.Email,
      dob: values.DoB,
      address1: values.Address,
      address2: values.Address2,
      city: values.City,
      zip: values.Zip,
      formType,
    };
    props.editPatientCall(data);
  };

  function formatSocialSecurity(val) {
    // let val;
    val = val.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    return val;
  }

  const handleCancelChanges = () => {
    const data = {
      Patient_Name: props.showOfferDEtails.data.Patient_Name,
      Address: props.showOfferDEtails.data.Address,
      Address2: props.showOfferDEtails.data.Address2,
      City: props.showOfferDEtails.data.City,
      Zip: props.showOfferDEtails.data.ZipCode,
      SSN: props.showOfferDEtails.data.SSNFull,
      Email: props.showOfferDEtails.data.Email,
      Mobile_Number: props.showOfferDEtails.data.Mobile_Number,
      DoB: moment(props.showOfferDEtails.data.DOB).format("YYYY-MM-DD"),
      ProcedureName: "",
      ProcedureDate: "",
      Insurance_Provider: props.showOfferDEtails.data.Insurance_Provider,
      Policy_Number: props.showOfferDEtails.data.Policy_Number,
      Patient_Responsibility:
        props.showOfferDEtails.data.Patient_Responsibility,
    };
    setValues(data);
    setPatintDetailsEdit(false);
  };
  const disbled = !patintDetailsEdit;
  return (
    <>
      {!patintDetailsEdit && (
        <Grid
          constainer
          style={{ margin: "1%", display: "flex", flexDirection: "row" }}
        >
          <Grid item xs>
            <Button
              variant="contained"
              style={{ marginLeft: "13%" }}
              className={classes.buttonStyle}
              onClick={() => setPatintDetailsEdit(true)}
            >
              Edit Patient Details
            </Button>
          </Grid>
        </Grid>
      )}
      {!disbled && (
        <Grid
          constainer
          align="right"
          style={{
            margin: "1%",
            display: "flex",
            flexDirection: "row",
            width: "97%",
          }}
        >
          <Grid item xs>
            <Button
              variant="contained"
              style={{ marginLeft: "6%" }}
              className={classes.buttonStyle}
              onClick={() => handleVerifySave(true)}
            >
              Verify & Save
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ButtonOriginal
              onClick={() => handleCancelChanges()}
              variant="outlined"
            >
              Cancel Changes
            </ButtonOriginal>
          </Grid>
        </Grid>
      )}

      <Card
        style={{
          borderTop: "2px solid #0d1619",
          background: disbled ? "#d8d8d824" : "#fff",
          margin: "2%",
          marginBottom: "3%",
          marginTop: "0%",
        }}
      >
        <Grid
          constainer
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "1%",
          }}
        >
          <Grid
            item
            xs
            style={{
              marginRight: "2%",
            }}
          >
            <TextField
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableBestOffer,
                  focused: "focused",
                },
              }}
              InputProps={{
                color: "secondary",
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resizeBeestOffer,
                  focused: "focused",
                },
              }}
              disabled={disbled}
              inputProps={{
                autoComplete: "off",
              }}
              fullWidth
              color="secondary"
              value={values.Address}
              onChange={e => onChange(e.target.value, "Address")}
              placeholder="Address"
              label="Address"
              name="Address"
              id="Address"
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              marginRight: "2%",
            }}
          >
            <TextField
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableBestOffer,
                  focused: "focused",
                },
              }}
              disabled={disbled}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resizeBeestOffer,
                  focused: "focused",
                },
              }}
              inputProps={{
                autoComplete: "off",
              }}
              fullWidth
              color="secondary"
              placeholder="Address2"
              label="Address2"
              value={values.Address2}
              onChange={e => onChange(e.target.value, "Address2")}
              name="Address2"
              id="Address2"
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              marginRight: "2%",
            }}
          >
            <TextField
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableBestOffer,
                  focused: "focused",
                },
              }}
              disabled={disbled}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resizeBeestOffer,
                  focused: "focused",
                },
              }}
              inputProps={{
                autoComplete: "off",
              }}
              fullWidth
              color="secondary"
              placeholder="City"
              label="City"
              value={values.City}
              onChange={e => onChange(e.target.value, "City")}
              name="City"
              id="City"
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              marginRight: "2%",
            }}
          >
            <TextField
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableBestOffer,
                  focused: "focused",
                },
              }}
              disabled={disbled}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resizeBeestOffer,
                  focused: "focused",
                },
              }}
              inputProps={{
                autoComplete: "off",
              }}
              fullWidth
              color="secondary"
              value={values.Zip}
              onChange={e => onChange(e.target.value, "Zip")}
              placeholder="Zip"
              label="Zip"
              name="Zip"
              id="Zip"
            />
          </Grid>
        </Grid>
        <Grid
          constainer
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "1%",
          }}
        >
          <Grid
            item
            xs
            style={{
              marginRight: "2%",
            }}
          >
            <TextField
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableBestOffer,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resizeBeestOffer,
                  focused: "focused",
                },
              }}
              disabled={disbled}
              inputProps={{
                autoComplete: "off",
                maxLength: 11,
              }}
              fullWidth
              color="secondary"
              placeholder="SSN # [Numbers Only - No Dashes]"
              onFocus={e => handleSSNFocus(e)}
              value={values.SSN}
              onChange={e => onChange(e.target.value, "SSN")}
              label="SSN # [Numbers Only - No Dashes]"
              name="SSN"
              id="SSN"
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              marginRight: "2%",
            }}
          >
            <TextField
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableBestOffer,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resizeBeestOffer,
                  focused: "focused",
                },
              }}
              // disabled={!unableSection.Email}
              inputProps={{
                autoComplete: "off",
              }}
              fullWidth
              value={values.Email}
              color="secondary"
              placeholder="Email"
              label="Email"
              onChange={e => onChange(e.target.value, "Email")}
              name="Email"
              id="Email"
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              marginRight: "2%",
            }}
          >
            <TextField
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableBestOffer,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resizeBeestOffer,
                  focused: "focused",
                },
              }}
              // disabled={!unableSection.Mobile_Number}
              inputProps={{
                autoComplete: "off",
              }}
              fullWidth
              color="secondary"
              placeholder="Phone Number"
              value={values.Mobile_Number}
              label="Phone Number"
              onChange={e => onChange(e.target.value, "Mobile_Number")}
              name="Mobile_Number"
              id="Mobile_Number"
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              marginRight: "2%",
            }}
          >
            <TextField
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.activeInputLableBestOffer,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resizeBeestOffer,
                  focused: "focused",
                },
              }}
              disabled={disbled}
              inputProps={{
                autoComplete: "off",
              }}
              type="date"
              fullWidth
              color="secondary"
              value={values.DoB}
              onChange={e => onChange(e.target.value, "DoB")}
              placeholder="DoB"
              label="DoB"
              name="DoB"
              id="DoB"
            />
          </Grid>
        </Grid>
        <Modal open={errorMessga} onClose={() => setErrorMessga(false)}>
          <div className={classes.modalDiv}>
            <Card className={classes.modalCard}>
              <div className={classes.cardDiv}>
                {!preUpdatePatientData.status ? (
                  <ErrorIcon style={{ fontSize: "75px", color: "#FF0000" }} />
                ) : (
                  <img alt="" src={gif} width="50%" />
                )}
                <div className={classes.successText}>
                  {preUpdatePatientData.message}
                </div>
                <br />
                {preUpdatePatientData && preUpdatePatientData.errors && preUpdatePatientData.errors.map(key => (
                  <>
                    <div className={classes.successText}>
                      {key.msg}
                    </div>
                    <br />
                  </>
                ))}
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  size="sm"
                  onClick={() => setErrorMessga(false)}
                >
                  Ok
                </Button>
              </div>
            </Card>
          </div>
        </Modal>
      </Card>
    </>
  );
};

PatientDetailsSection.propTypes = {
  showOfferDEtails: PropTypes.object,
  tableData: PropTypes.object,
  editPatientCall: PropTypes.func,
};

export default PatientDetailsSection;
