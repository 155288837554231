/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card } from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import Checkbox from "@material-ui/core/Checkbox";
import ButtonOriginal from "@material-ui/core/Button";
import DatePicker from "react-datepicker";
import { TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "components/CustomButtons/Button";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles(styles);

const PaymentSection = props => {
  const [checkedMonth, setCheckedMonth] = React.useState(props.tableData.genrateOfferData.data[0].Discounted[0].selected);
  const [checkedDown, setCheckedDown] = React.useState(props.tableData.genrateOfferData.data[0].Monthly[0].selected || (props.tableData.paymentMakeDetails && props.tableData.paymentMakeDetails.status && props.tableData.createOffer.data.payType === 2));
  const classes = useStyles();

  const fullCheckBoxDisable = props.tableData.paymentMakeDetails && props.tableData.paymentMakeDetails.status;
  const downPaymentDisable = props.tableData.paymentMakeDetails && props.tableData.paymentMakeDetails.status && props.tableData.createOffer.data.payType === 2;
  const setupMonthlyPayment = props.tableData.paymentMakeDetails && props.tableData.paymentMakeDetails.status;
  return (
    <>
      <Grid constainer style={{ margin: "1%" }}>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
          }}
          xs>
          <Checkbox disabled={fullCheckBoxDisable} checked={checkedMonth} onClick={() => setCheckedMonth(!checkedMonth)} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              fontSize: "20px",
              whiteSpace: "nowrap",
            }}>
            FULL Amount - Discounted
          </div>
        </Grid>
      </Grid>
      <Grid
        constainer
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#48474724",
          margin: "1%",
        }}>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          xs>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              fontSize: "20px",
              whiteSpace: "nowrap",
            }}>
            PLAN:
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              whiteSpace: "nowrap",
            }}>
            Full Amount @ Discounted
          </div>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          xs>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              fontSize: "20px",
              whiteSpace: "nowrap",
            }}>
            Amount:
            {" "}
            {`$${props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced}`}
          </div>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          xs>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              fontSize: "20px",
              whiteSpace: "nowrap",
            }}>
            Discount:
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              whiteSpace: "nowrap",
            }}>
            {props.tableData.genrateOfferData.data[0].Discounted[0].discount}
          </div>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          xs>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              fontSize: "20px",
              whiteSpace: "nowrap",
            }}>
            Discounted Amount:
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              fontSize: "20px",
              whiteSpace: "nowrap",
            }}>
            {`$${props.tableData.genrateOfferData.data[0].Discounted[0].finalPayAmount}`}
          </div>
        </Grid>
      </Grid>
      {checkedMonth && <Card style={{ margin: "1%" }}>
        <Grid constainer style={{ margin: "1%" }}>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            xs>
            Payment accept via Credit Card, Debit Card, ACH/Bank Account or Cash
          </Grid>
        </Grid>
        <Grid
          constainer
          style={{
            margin: "1%",
            display: "flex",
            flexDirection: "row",
          }}>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            xs>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "1%",
                fontSize: "20px",
                whiteSpace: "nowrap",
              }}>
              Full Amount:
              {" "}
              {`$${props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced}`}
            </div>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            xs>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "1%",
                whiteSpace: "nowrap",
              }}>
              Discount on full payment:
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "1%",
                fontSize: "20px",
                whiteSpace: "nowrap",
              }}>
              {`$${props.tableData.genrateOfferData.data[0].Discounted[0].discountAmount}`}
            </div>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            xs>
            <Button style={{ fontSize: "18px" }} variant="contained" disabled={fullCheckBoxDisable} className={classes.buttonStyle} color="#696969" size="sm" onClick={() => props.handlePaymentOffer(1)}>
              Pay
              {" "}
              {`$${props.tableData.genrateOfferData.data[0].Discounted[0].finalPayAmount}`}
              {" "}
              now
            </Button>
          </Grid>
        </Grid>
      </Card>}
      <Grid constainer style={{ margin: "1%" }}>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
          }}
          xs>
          <Checkbox disabled={downPaymentDisable} checked={checkedDown} onClick={() => setCheckedDown(!checkedDown)} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              fontSize: "20px",
              whiteSpace: "nowrap",
            }}>
            MONTHLY PAYMENT PLAN
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "10%",
              margin: "1%",
              whiteSpace: "nowrap",
              color: "#12128e",
            }}>
            Using The Patient Offer AI engine, the most suitable payment plan is
          </div>
        </Grid>
      </Grid>
      <Grid
        constainer
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#48474724",
          margin: "1%",
        }}>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          xs>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              fontSize: "20px",
              whiteSpace: "nowrap",
            }}>
            STATUS:
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              whiteSpace: "nowrap",
            }}>
            Approved
          </div>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          xs>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              fontSize: "20px",
              whiteSpace: "nowrap",
            }}>
            Down:
            {" "}
            {`$${props.values.downPayment}`}
          </div>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          xs>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              fontSize: "20px",
              whiteSpace: "nowrap",
            }}>
            Full Amount:
            {" "}
            {`$${props.tableData.genrateOfferData.data[0].Monthly[0].fullAmount}`}
          </div>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          xs>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              fontSize: "20px",
              whiteSpace: "nowrap",
            }}>
            APR:
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1%",
              whiteSpace: "nowrap",
            }}>
            {`${props.tableData.genrateOfferData.data[0].Monthly[0].APR}%`}
          </div>
        </Grid>
      </Grid>
      {checkedDown && <Card style={{ margin: "1%" }}>
        <Grid
          constainer
          style={{
            margin: "1%",
            display: "flex",
            flexDirection: "row",
          }}>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            xs>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "1%",
                whiteSpace: "nowrap",
                alignItems: "center",
              }}>
              Down Payment ($)
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "1%",
                fontSize: "20px",
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <TextField
                id="outlined-secondary"
                variant="outlined"
                color="secondary"
                value={props.values.downPayment}
                disabled={downPaymentDisable}
                inputProps={{
                  style: {
                    padding: "10px 14px",
                  },
                }}
                onBlur={() => props.handleDownOnBlur()}
                onChange={e => props.handleOnchange(e.target.value, "downPayment")}
              />
            </div>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            xs>
            <Button style={{ fontSize: "18px" }} disabled={downPaymentDisable} variant="contained" className={classes.buttonStyle} color="#696969" size="sm" onClick={() => props.handlePaymentOffer(2)}>
              Pay
              {" "}
              {`$${props.values.downPayment}`}
              {" "}
              now
            </Button>
          </Grid>
        </Grid>
        <Grid constainer style={{ margin: "1%" }}>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            xs>
            Payment accept via Credit Card, Debit Card, ACH/Bank Account or Cash
          </Grid>
        </Grid>
        <Divider />
        <Grid
          constainer
          style={{
            margin: "1%",
            display: "flex",
            flexDirection: "row",
          }}>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            xs>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "1%",
                fontSize: "16px",
                whiteSpace: "nowrap",
                alignItems: "center",
              }}>
              Monthly payment will be setup based on down payment. To change click "Change Monthly Setup"
            </div>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            xs>
            <Button disabled={!setupMonthlyPayment} variant="contained" className={classes.buttonStyle} style={{ padding: "12px" }} color="#696969" size="sm" onClick={() => props.handleSetupMonthlyPayment()}>
              Change Monthly Setup
            </Button>
          </Grid>
        </Grid>
        <Grid
          constainer
          style={{
            margin: "1%",
            display: "flex",
            flexDirection: "row",
          }}>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            xs>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "1%",
                whiteSpace: "nowrap",
                alignItems: "center",
              }}>
              Monthly Payment ($)
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "1%",
                fontSize: "20px",
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <TextField
                id="outlined-secondary"
                variant="outlined"
                color="secondary"
                value={props.values.monthlyPayment}
                disabled={downPaymentDisable}
                onBlur={() => props.handleDownOnMonth()}
                inputProps={{
                  style: {
                    padding: "10px 14px",
                  },
                }}
                onChange={e => props.handleOnchange(e.target.value, "monthlyPayment")}
              />
            </div>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            xs>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "1%",
                whiteSpace: "nowrap",
                alignItems: "center",
              }}>
              Terms (months)
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "1%",
                fontSize: "20px",
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <TextField
                id="outlined-secondary"
                variant="outlined"
                color="secondary"
                value={props.values.terms}
                disabled={downPaymentDisable}
                inputProps={{
                  style: {
                    padding: "10px 14px",
                  },
                }}
                onBlur={() => props.handleDownOnTerm()}
                onChange={e => props.handleOnchange(e.target.value, "terms")}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          constainer
          style={{
            margin: "1%",
            display: "flex",
            flexDirection: "row",
          }}>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            xs>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "1%",
                whiteSpace: "nowrap",
                alignItems: "center",
              }}>
              Start Date
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "1%",
                fontSize: "20px",
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={moment(props.values.startDate).toDate()}
                disabled={downPaymentDisable}
                onChange={date => props.handleOnchange(date, "startDate")}
                customInput={
                  <TextField
                    id="outlined-secondary"
                    variant="outlined"
                    color="secondary"
                    inputProps={{
                      style: {
                        padding: "10px 14px",
                      },
                    }}
                  />
                }
              />
            </div>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            xs>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "1%",
                whiteSpace: "nowrap",
                alignItems: "center",
              }}>
              Maturity Date
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "1%",
                fontSize: "20px",
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <TextField
                id="outlined-secondary"
                variant="outlined"
                color="secondary"
                style={{ padding: "10px 14px" }}
                value={props.values.maturityDate}
                inputProps={{
                  style: {
                    padding: "10px 14px",
                  },
                }}
                disabled
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          constainer
          style={{
            margin: "1%",
            display: "flex",
            flexDirection: "row",
          }}>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            xs>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "1%",
                whiteSpace: "nowrap",
                alignItems: "center",
              }}>
              APR (%)
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "1%",
                fontSize: "20px",
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <TextField
                id="outlined-secondary"
                variant="outlined"
                color="secondary"
                inputProps={{
                  style: {
                    padding: "10px 14px",
                  },
                }}
                value={props.values.apr}
                disabled
              />
            </div>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            xs>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "1%",
                whiteSpace: "nowrap",
                alignItems: "center",
              }}>
              Daily Intrest ($)
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "1%",
                fontSize: "20px",
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <TextField
                id="outlined-secondary"
                variant="outlined"
                color="secondary"
                inputProps={{
                  style: {
                    padding: "10px 14px",
                  },
                }}
                disabled
                value={props.values.dailyIntrest}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          constainer
          style={{
            margin: "1%",
            display: "flex",
            flexDirection: "row",
          }}>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            xs>
            <ButtonOriginal variant="outlined" style={{ height: "30px", marginRight: "2px" }} onClick={() => props.handleResetOffer()}>Default</ButtonOriginal>
            <Button variant="contained" className={classes.buttonStyle} color="#696969" size="sm" onClick={() => props.setShowFinalOfferIn()}>
              Accept & Print
            </Button>
            <Button variant="contained" disabled={setupMonthlyPayment} className={classes.buttonStyle} color="#696969" size="sm" onClick={() => props.handleSendEmail()}>
              Send Offer
            </Button>
          </Grid>
        </Grid>
      </Card>}
    </>
  );
};

PaymentSection.propTypes = {
  tableData: PropTypes.object,
  handlePaymentOffer: PropTypes.func,
  handleOnchange: PropTypes.func,
  values: PropTypes.object,
  setShowFinalOfferIn: PropTypes.func,
  handleDownOnBlur: PropTypes.func,
  handleDownOnMonth: PropTypes.func,
  handleDownOnTerm: PropTypes.func,
  handleSendEmail: PropTypes.func,
  handleSetupMonthlyPayment: PropTypes.func,
  handleResetOffer: PropTypes.func,
};

export default PaymentSection;
