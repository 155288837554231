import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { ReactComponent as PaymentLogo } from "../../../src/assets/img/cs.svg";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Field, Form, Formik } from "formik";
import TableContainer from "@material-ui/core/TableContainer";
import Style from "./style";
import "./cart.css";
import {
  getLiveProviderList,
  getProviderStatusNormal,
  getProviderByFilterData
} from "../../Redux/Actions/ProvideRegistration";
import Validations from "../../Utils/Validations";
import isEmpty from "lodash/isEmpty";
import WebPay from "./src/widget";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(Style);
function MobilePosCheckout(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [merchant, setMerchantId] = useState("");
  const [invoicNumber, setInvoiceNo] = useState("");
  const [errorMsg, setErrorMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isProList, setProList] = React.useState(false);

  React.useEffect(() => {
    props.getProviderStatusNormal();
    const data = {
      statusExpression: "IN"
    };
    props.getProviderByFilterData(data);
  }, []);

  const merchantList =
    props?.data?.provideRegistration?.providerList &&
    props?.data?.provideRegistration?.providerList?.Providers_Data &&
    props?.data?.provideRegistration?.providerList?.Providers_Data?.sort(
      (a, b) =>
        a.Business_Name.toString().localeCompare(b.Business_Name.toString())
    );

  const redirect = () => {
    setTimeout(() => {
      history.push("InvoiceCheckout");
    }, 3000);
  };
  React.useEffect(() => {
    if (window.location.href.includes("cancel_order")) {
      enqueueSnackbar("Payment unsuccessfull", {
        variant: "error"
      });
      redirect();
    } else if (window.location.href.includes("success_order")) {
      enqueueSnackbar("Payment successfull", {
        variant: "success"
      });
      redirect();
    }
  }, [window.location.href]);

  return (
    <>
      {loading && <div className="loader"></div>}
      <TableContainer
        style={{
          width: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          marginTop: "10%",
          position: "relative"
        }}
        className={classes.TableContainer}
        component={Paper}
      >
        <Paper style={{ marginTop: "2%", padding: "40px" }}>
          <Formik
            onSubmit={() => {}}
            enableReinitialize
            initialValues={{
              currency: currency,
              amount: amount,
              invoicNumber: invoicNumber,
              merchant: merchant
            }}
            validationSchema={Validations.paymentGateway}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <Grid
                  spacing={4}
                  container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Grid xs={10} item>
                    <Field
                      as={TextField}
                      fullWidth
                      select
                      variant="outlined"
                      size="small"
                      value={merchant}
                      onChange={(e) => {
                        setFieldValue("merchant", e.target.value);
                        setMerchantId(e.target.value);
                        setErrorMessage(false);
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,

                          focused: "focused"
                        }
                      }}
                      label="Select Merchant"
                      name="merchant"
                      id="merchant"
                      required
                      helperText={
                        errors.merchant && touched.merchant
                          ? errors.merchant
                          : ""
                      }
                      error={errors.merchant && touched.merchant}
                    >
                      {merchantList &&
                        merchantList.map((merchant) => (
                          <MenuItem value={merchant.Provider_id}>
                            {merchant.merchantCode
                              ? "[" +
                                merchant.merchantCode +
                                "]" +
                                " - " +
                                merchant.Business_Name
                              : merchant.Business_Name}
                          </MenuItem>
                        ))}
                    </Field>
                  </Grid>

                  <Grid xs={10} item>
                    <Field
                      as={TextField}
                      fullWidth
                      size="small"
                      variant="outlined"
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,

                          focused: "focused"
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("invoicNumber", e.target.value);
                        setInvoiceNo(e.target.value);
                      }}
                      label="Invoice Number"
                      name="invoicNumber"
                      id="invoicNumber"
                      required
                      helperText={
                        errors.invoicNumber && touched.invoicNumber
                          ? errors.invoicNumber
                          : ""
                      }
                      error={errors.invoicNumber && touched.invoicNumber}
                    />
                  </Grid>
                  <Grid xs={10} item>
                    <Field
                      as={TextField}
                      fullWidth
                      select
                      variant="outlined"
                      size="small"
                      defaultValue="USD"
                      value={currency}
                      onChange={(e) => {
                        setFieldValue("currency", e.target.value);
                        setCurrency(e.target.value);
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,

                          focused: "focused"
                        }
                      }}
                      label="Select Currency"
                      name="currency"
                      id="currency"
                      required
                      helperText={
                        errors.currency && touched.currency
                          ? errors.currency
                          : ""
                      }
                      error={errors.currency && touched.currency}
                    >
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="CAD">CAD</MenuItem>
                    </Field>
                  </Grid>
                  <Grid xs={10} item>
                    <Field
                      as={TextField}
                      fullWidth
                      size="small"
                      variant="outlined"
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,

                          focused: "focused"
                        }
                      }}
                      label="Amount"
                      name="amount"
                      id="amount"
                      value={amount}
                      onChange={(e) => {
                        if (
                          /^[1-9]\d*$/g.test(e.target.value) ||
                          e.target.value == ""
                        ) {
                          setFieldValue("amount", e.target.value);
                          setAmount(e.target.value);
                        }
                      }}
                      required
                      helperText={
                        errors.amount && touched.amount ? errors.amount : ""
                      }
                      error={errors.amount && touched.amount}
                    />
                  </Grid>
                </Grid>
                <Grid alignItems="center" justify="flex-center" container>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "30px"
                    }}
                    item
                    xs={12}
                  >
                    {(!isEmpty(errors) ||
                      merchant == "" ||
                      amount == "" ||
                      invoicNumber == "") && (
                      <Button type="submit">
                        <PaymentLogo style={{ width: "150px" }} />
                      </Button>
                    )}
                    <div
                      coinsuites="Paywidget"
                      id="coinsuitespay-btn"
                      coinsuiteId={merchant}
                      amount={amount}
                      currency={currency}
                      ref_id={invoicNumber}
                      postingMode={"stage"}
                      className="coinsuitespay-btn"
                      style={{
                        display:
                          !isEmpty(errors) ||
                          merchant == "" ||
                          amount == "" ||
                          invoicNumber == ""
                            ? "none"
                            : ""
                      }}
                    />
                    <WebPay
                      setErrorMessage={setErrorMessage}
                      setLoading={setLoading}
                    />
                  </Grid>
                  {errorMsg && <div style={{ color: "red" }}>{errorMsg}</div>}
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </TableContainer>
    </>
  );
}

MobilePosCheckout.propTypes = {
  getProviderByFilterData: PropTypes.func,
  getProviderStatusNormal: PropTypes.func
};

const mapStateToProps = (app) => ({
  data: app
});
export default connect(mapStateToProps, {
  getProviderStatusNormal,
  getProviderByFilterData
})(MobilePosCheckout);
