/* eslint-disable no-restricted-syntax */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/extensions */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { connect } from "react-redux";

import {
  loginRequest,
  setUserRole,
  setAppBarName,
  setLayoutName,
} from "../../Redux/Actions/LoginActions";
import styles from "../../assets/jss/material-dashboard-react/views/LoginPage.js";

const useStyles = makeStyles(styles);
const SessionOut = (props) => {
  const classes = useStyles();

  const onClick = () => {
    props.prop.history.push("/");
  };

  return (
    <div className={classes.container}>
      <GridContainer>
        <GridItem xs>
          <div className={classes.root}>
            <img
              className={classes.logo}
              src={require("../../assets/img/imgpsh_fullsize_anim.png")}
            />
            <Typography color="textPrimary" variant="h4" gutterBottom>
              uh oh!
            </Typography>
            <Typography color="textPrimary" variant="body1" gutterBottom>
              Looks like your session has timed out and we need to log you in
              again.
            </Typography>
            <Typography color="textPrimary" variant="body1" gutterBottom>
              Click on the button below to log in:
            </Typography>
            <Button
              onClick={onClick}
              variant="contained"
              color="primary"
              size="large"
            >
              Login To Continue
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

SessionOut.propTypes = {
  history: PropTypes.object,
  prop: PropTypes.any,
};

const mapStateToProps = ({ app }) => ({
  userData: app.userDetails,
});
export default connect(mapStateToProps, {
  loginRequest,
  setUserRole,
  setAppBarName,
  setLayoutName,
})(SessionOut);
