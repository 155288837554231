import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const users = (state = initialState.users, action) => {
  switch (action.type) {
  case types.USER_LIST_CALL:
    return { ...state, loading: true, userList: false };
  case types.USER_LIST_SUCCESS:
    return { ...state, loading: false, userList: action.data };
  case types.USER_LIST_ERROR:
    return { ...state, error: true, loading: false };
  case types.USER_REGISTRATION_CALL:
    return { ...state, loading: true, userList: false };
  case types.USER_REGISTRATION_SUCCESS:
    return {
      ...state,
      loading: false,
      data: action.data,
    };
  case types.USER_REGISTRATION_ERROR:
    return { ...state, error: true, loading: false };
  case types.COUNTEY_TYPE_LIST_CALL:
    return { ...state, loading: true };
  case types.COUNTEY_TYPE_LIST_SUCCESS:
    return { ...state, countryTypeList: action.data, loading: false };
  case types.COUNTEY_TYPE_LIST_ERROR:
    return {
      ...state,
      loading: false,
      error: true,
    };
  case types.OWNER_TYPE_LIST_CALL:
    return { ...state, loading: true };
  case types.OWNER_TYPE_LIST_SUCCESS:
    return { ...state, loading: false, ownerShipTypeList: action.data };
  case types.OWNER_TYPE_LIST_ERROR:
    return { ...state, error: true, loading: false };
  case types.PROFILE_TYPE_LIST_CALL:
    return { ...state, loading: true };
  case types.PROFILE_TYPE_LIST_SUCCESS:
    return { ...state, profileTypeList: action.data, loading: false };
  case types.PROFILE_TYPE_LIST_ERROR:
    return {
      ...state,
      loading: false,
      error: true,
    };
  case types.ROLE_LIST_CALL:
    return { ...state, loading: true };
  case types.ROLE_LIST_SUCCESS:
    return { ...state, loading: false, roleList: action.data };
  case types.ROLE_LIST_ERROR:
    return { ...state, error: true, loading: false };
  case types.USER_LIST_BY_ID_CALL:
    return { ...state, loading: true, userDetailsById: false };
  case types.USER_LIST_BY_ID_SUCCESS:
    return { ...state, loading: false, userDetailsById: action.data };
  case types.USER_LIST_BY_ID_ERROR:
    return { ...state, error: true, loading: false };
  case types.USER_FORM_MODE:
    return {
      ...state,
      formMode: action.data,
      userDetailsById: false,
    };
  case types.USER_STATUS_CHANGE_SINGLE_CALL:
    return { ...state, loading: true, userList: false };
  case types.USER_STATUS_CHANGE_SINGLE_SUCCESS:
    return { ...state, loading: false, statusUpdate: action.data };
  case types.USER_STATUS_CHANGE_SINGLE_ERROR:
    return { ...state, error: true, loading: false };
  case types.USER_CHANGE_PASSWORD_CALL:
    return { ...state, loading: true, userList: false };
  case types.USER_CHANGE_PASSWORD_SUCCESS:
    return { ...state, loading: false, passwordUpdate: action.data };
  case types.USER_CHANGE_PASSWORD_ERROR:
    return { ...state, error: true, loading: false };
  case types.USER_LIST_BY_FILTERDATA_CALL:
    return {
      ...state,
      loading: true,
      userList: false,
    };
  case types.USER_LIST_BY_FILTERDATA_SUCCESS:
    return {
      ...state,
      loading: false,
      userList: action.data,
    };
  case types.TIMEZONE_LIST_CALL:
    return { ...state, loading: true };
  case types.TIMEZONE_LIST_SUCCESS:
    return { ...state, timeZoneList: action.data, loading: false };
  case types.TIMEZONE_LIST_ERROR:
    return {
      ...state,
      loading: false,
      error: true,
    };
  case types.USER_PROVIDER_LIST_CALL:
    return { ...state, loading: true };
  case types.USER_PROVIDER_LIST_SUCCESS:
    return { ...state, userProvider: action.data, loading: false };
  case types.USER_PROVIDER_LIST_FAIL:
    return {
      ...state,
      loading: false,
      error: true,
    };
  case types.PROVIDER_LIST_ACCESABLE_EMPTY:
    return { ...state, userProvider: false, loading: false };
  case types.GROUP_LIST_DATA_CALL:
    return { ...state, loading: true };
  case types.GROUP_LIST_DATA_SUCCESS:
    return { ...state, groupListData: action.data, loading: false };
  case types.GROUP_LIST_DATA_ERROR:
    return {
      ...state,
      loading: false,
      error: true,
    };
  case types.GET_AGENT_HIERARCHY_CALL:
    return { ...state, loading: true };
  case types.GET_AGENT_HIERARCHY_CALL_SUCCESS:
    return { ...state, loading: false, agentList: action.data };
  case types.GET_AGENT_HIERARCHY_CALL_ERROR:
    return { ...state, error: true, loading: false };

  case types.GET_MERCHANT_HIERARCHY_CALL:
    return { ...state, loading: true };
  case types.GET_MERCHANT_HIERARCHY_CALL_SUCCESS:
    return { ...state, loading: false, merchantList: action.data };
  case types.GET_MERCHANT_HIERARCHY_CALL_ERROR:
    return { ...state, error: true, loading: false };

  case types.ADD_USER_SEARCH_DATA:
    return {
      ...state,
      searchDetails: action.data,
    };
  case types.EMPTY_SEARCH_DATA:
    return {
      ...state,
      searchDetails: {
        Name: "",
        Role: "",
        Current_Status: "Active",
        searchAgentId: "",
        recordPerPage: 10,
        pageNo: 1,
        sortColumn: "FName",
        sortOrder: "asc",
      },
      userList: false,
    };
  default:
    return state;
  }
};

export default users;
