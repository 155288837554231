import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import TableContainer from "@material-ui/core/TableContainer";
import Divider from "@material-ui/core/Divider";
import Style from "./style";
import "./cart.css";
import LenovoTabletImage from "../../assets/tempImages/lenovo-tablet.jpg";
import tradingviewLogo from "../../assets/tempImages/tradingviewLogo.png";

import GooglePixelImage from "../../assets/tempImages/google-pixel.jpg";
import WillfulSmartWatchImage from "../../assets/tempImages/willful-smartwatch.jpg";
import AmazfitSmartWatchImage from "../../assets/tempImages/amazfit-smartwatch.jpg";
import BitcoinSvg from "../../assets/cryptoSvg/bitcoin-btc-logo.svg";
import LitecoinSvg from "../../assets/cryptoSvg/litecoin-ltc-logo.svg";
import DogecoinSvg from "../../assets/cryptoSvg/dogecoin-doge-logo.svg";
import EthereumSvg from "../../assets/cryptoSvg/ethereum-eth-logo.svg";
import BitcoinCashSvg from "../../assets/cryptoSvg/bitcoin-cash-bch-logo.svg";
import WalletQrCode from "../../assets/tempImages/walletQrCode.png";

const useStyles = makeStyles(Style);
const dummyProducts = [
  {
    qa: 10,
    totalRating: 67932,
    id: "123452",
    image: WillfulSmartWatchImage,
    title:
      "Willful Smart Watch for Android Phones and iOS Phones Compatible iPhone Samsung, IP68 Swimming Waterproof Smartwatch Fitness Tracker Fitness Watch Heart Rate Monitor Smart Watches for Men Women Black",
    rating: 4,
    price: 35.99,
    productURL: "#",
    shippingLocation: "United States of America",
  },
  {
    qa: 15,
    totalRating: 24533,
    id: "123454",
    image: GooglePixelImage,
    title:
      "Google Pixel 4a - New Unlocked Android Smartphone - 128 GB of Storage - Up to 24 Hour Battery - Just Black",
    rating: 4,
    price: 53.0,
    productURL: "#",
    shippingLocation: "United States of America",
  },
  {
    qa: 12,
    totalRating: 200,
    id: "123456",
    image: LenovoTabletImage,
    title:
      "Lenovo Tab M8 Tablet, 8\" HD Android Tablet, Quad-Core Processor, 2GHz, 16GB Storage, Full Metal Cover, Long Battery Life, Android 9 Pie, ZA5G0102US, Slate Black",
    rating: 4,
    price: 89.99,
    productURL: "#",
    shippingLocation: "United States of America",
  },
  {
    qa: 29,
    totalRating: 57563,
    id: "123457",
    image: AmazfitSmartWatchImage,
    title:
      "Amazfit Bip U Pro Smart Watch with Built-in GPS, 9-Day Battery Life, Fitness Tracker, Blood Oxygen, Heart Rate, Sleep, Stress Monitor, 60+ Sports Modes, 1.43\" Large HD Display",
    rating: 4,
    price: 69.99,
    productURL: "#",
    shippingLocation: "United States of America",
  },
];

function OrderCart(props) {
  console.log(props);
  const classes = useStyles();

  const formatRemainingTime = time => {
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${minutes}:${seconds}`;
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Too lale...</div>;
    }

    return (
      <div className="timer">
        {/* <div className="text">Remaining</div> */}
        <div className="value">{formatRemainingTime(remainingTime)}</div>
        {/* <div className="text">seconds</div> */}
      </div>
    );
  };

  function amountTotal() {
    let total = 0;
    dummyProducts.map(x => {
      total += x.price;
    });
    return (Math.round(total * 100) / 100).toFixed(2); // return sum of elements in array
  }

  return (
    <>
      <TableContainer
        style={{ overflowY: "scroll" }}
        className={classes.TableContainer}
        component={Paper}
      >
        <div className={classes.checkoutMain}>
          <div style={{ width: "80%" }}>
            <div className="cart-item">
              <Paper style={{ marginTop: "2%" }} variant="outlined">
                <Grid style={{ margin: "4%" }} container>
                  <Grid
                    xs={11}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    item
                    container
                  >
                    <Grid xs={9} item>
                      <Typography
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        Send payment
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          color: "#888888",
                        }}
                      >
                        To make a payment, send payment by using the QR code or
                        buttons below
                      </Typography>
                    </Grid>
                    <Grid
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                      item
                    >
                      <div>
                        <div className="timer-wrapper">
                          <CountdownCircleTimer
                            isPlaying
                            size={65}
                            strokeWidth={5}
                            trailStrokeWidth={5}
                            duration={900}
                            colors={[
                              ["#00acc1", 0.33],
                              ["#F7B801", 0.33],
                              ["#A30000"],
                            ]}
                            onComplete={() => [false]}
                          >
                            {renderTime}
                          </CountdownCircleTimer>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      marginLeft: "-4%",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginTop: "4%",
                      marginBottom: "4%",
                    }}
                    item
                    container
                  >
                    <Grid xs={12} item>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid
                    xs={11}
                    style={{
                      display: "flex",

                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "2%",
                      marginBottom: "2%",
                      textAlign: "center",
                    }}
                    item
                    container
                  >
                    <Grid xs={12} alignItems="center" item>
                      <img
                        alt=""
                        style={{
                          maxHeight: "250px",
                          maxWidth: "250px",
                          backgroundSize: "contain",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        src={WalletQrCode}
                      />
                    </Grid>
                    <Grid xs={12} alignItems="center" item>
                      <Typography
                        style={{
                          color: "#888888",
                        }}
                      >
                        {`Only send ${props.app.paymentReducer.data && props.app.paymentReducer.data.data.coin} to this address`}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      marginLeft: "-4%",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginTop: "4%",
                      marginBottom: "4%",
                    }}
                    item
                    container
                  >
                    <Grid xs={12} item>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid
                    xs={11}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "1%",
                    }}
                    item
                    container
                  >
                    <Grid xs={9} item>
                      <Typography
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          color: "#888888",
                        }}
                      >
                        {
                          `${props.app.paymentReducer.data && props.app.paymentReducer.data.data.coin} Address`
                        }
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        {props.app.paymentReducer.data && props.app.paymentReducer.data.data.address}
                      </Typography>
                    </Grid>
                    <Grid
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                      item
                    >
                      <Button>
                        <Typography style={{ color: "#2437d5" }}>
                          Copy
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      marginLeft: "-4%",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginTop: "4%",
                      marginBottom: "4%",
                    }}
                    item
                    container
                  >
                    <Grid xs={12} item>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid
                    xs={11}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "1%",
                    }}
                    item
                    container
                  >
                    <Grid xs={9} item>
                      <Typography
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          color: "#888888",
                        }}
                      >
                        Total Amount
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        {
                          `${props.app.paymentReducer.data ? 4 / props.app.paymentReducer.data.data.price : 1} ${props.app.paymentReducer.data && props.app.paymentReducer.data.data.coin}`
                        }
                      </Typography>
                    </Grid>
                    <Grid
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                      item
                    >
                      <Button>
                        <Typography style={{ color: "#2437d5" }}>
                          Copy
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </div>
        </div>
      </TableContainer>
    </>
  );
}

OrderCart.propTypes = {
  app: PropTypes.object,
};

const mapStateToProps = app => ({
  app,
});
export default connect(mapStateToProps, null)(OrderCart);
