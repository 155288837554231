/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ExpiringApplication from "components/PatientDetailsSection/ExpiringApplication";
import AcceptPatient from "components/PatientDetailsSection/index";
import RecentPatients from "components/PatientDetailsSection/RecentPatient";
import RippleLoading from "components/RippleCard/Index";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "../../assets/jss/material-dashboard-react/components/DashBoard";

import ActivePatient from "../../components/Graph/activePatient";
import {
  getRecentContactList,
  getRecentAcceptOffer,
  getExpireApplicationList,
  getActivePatientDashboardData,
  getDefaultData,
  getMoveToCollection,
  getBadDebatsData,
  getTotalOutStandingData,
  getDailyDepositeData,
  getCptCodeData,
} from "../../Redux/Actions/Dashboard";
import TotalOutstanding from "../../components/Graph/totalOutStanding";
import BadDebts from "../../components/Graph/badDebts";
import Daliydeposits from "../../components/Graph/dailyDeposites";
import Defaults from "../../components/Graph/defaults";
import MoveToCollection from "../../components/Graph/moveToCollection";
import CptDustribution from "../../components/Graph/cptDistribution";
import MemberRegistrations from "../../components/Graph/memberRegistreation";

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  React.useEffect(() => {
    props.getRecentContactList();
    props.getRecentAcceptOffer();
    props.getExpireApplicationList();
    const data = {
      fromdate: "2020-01-01",
      todate: "2020-12-30",
      ClientId: JSON.stringify(localStorage.getItem("clientId")),
    };
    const moveCollectionData = {
      ClientId: JSON.stringify(localStorage.getItem("clientId")),
      fromdate: "2020-01-01",
      todate: "2020-12-30",
    };
    props.getActivePatientDashboardData(data);
    props.getDefaultData(data);
    props.getMoveToCollection(moveCollectionData);
    props.getBadDebatsData(data);
    props.getTotalOutStandingData();
    props.getDailyDepositeData();
    props.getCptCodeData(data);
  }, []);
  return (
    <div>
      <Grid containe className={classes.gridContainer} spacing={1}>
        <Grid item xs={windowWidth ? true : 12} sm={12} className={classes.margin}>
          <Card>
            {props.dashboardData.loading ? <RippleLoading /> : <ActivePatient {...props} />}
          </Card>
        </Grid>
        <Grid item xs={windowWidth ? true : 12} sm={12} className={classes.margin}>
          <Card>
            {props.dashboardData.loading ? <RippleLoading /> : <Daliydeposits {...props} />}
          </Card>
        </Grid>
        <Grid item xs={windowWidth ? true : 12} sm={12} className={classes.margin}>
          <Card>
            {props.dashboardData.loading ? <RippleLoading /> : <TotalOutstanding {...props} />}
          </Card>
        </Grid>
      </Grid>
      <Grid containe className={classes.gridContainer} spacing={2}>
        <Grid item xs={windowWidth ? true : 12} sm={12} className={classes.margin}>
          <Card>
            {props.dashboardData.loading ? <RippleLoading /> : <Defaults {...props} />}
          </Card>
        </Grid>
        <Grid item xs={windowWidth ? true : 12} sm={12} className={classes.margin}>
          <Card>
            {props.dashboardData.loading ? <RippleLoading /> : <MoveToCollection {...props} />}
          </Card>
        </Grid>
        <Grid item xs={windowWidth ? true : 12} sm={12} className={classes.margin}>
          <Card>
            {props.dashboardData.loading ? <RippleLoading /> : <BadDebts {...props} />}
          </Card>
        </Grid>
      </Grid>
      <Grid containe className={classes.gridContainer} spacing={2}>
        <Grid item xs={windowWidth ? 2 : 12} sm={12} className={classes.cardFontSize}>
          <Card>
            <div className={classes.patientDetailsHeading}>Recent Contacts</div>
            <hr className={classes.hrStyle} />
            {props.dashboardData.loading ? <RippleLoading /> : <RecentPatients {...props} />}
          </Card>
        </Grid>
        <Grid item xs={windowWidth ? 5 : 12} sm={12} className={classes.margin}>
          <Card>
            <div className={classes.patientDetailsHeading}>Recent Accepted Offers</div>
            <hr className={classes.hrStyle} />
            {props.dashboardData.loading ? <RippleLoading /> : <AcceptPatient {...props} />}
          </Card>
        </Grid>
        <Grid item xs={windowWidth ? 5 : 12} sm={12} className={classes.margin}>
          <Card>
            <div className={classes.patientDetailsHeading}>Expiring Applications</div>
            <hr className={classes.hrStyle} />
            {props.dashboardData.loading ? <RippleLoading /> : <ExpiringApplication {...props} />}
          </Card>
        </Grid>
      </Grid>
      <Grid containe className={classes.gridContainer} spacing={2}>
        <Grid item xs={windowWidth ? true : 12} sm={12} className={classes.margin}>
          <Card>
            {props.dashboardData.loading ? <RippleLoading /> : <CptDustribution {...props} />}
          </Card>
        </Grid>
        <Grid item xs={windowWidth ? true : 12} sm={12} className={classes.margin}>
          <Card>
            {props.dashboardData.loading ? <RippleLoading /> : <MemberRegistrations {...props} />}
          </Card>
        </Grid>
      </Grid>
      {/* <Grid containe className={classes.gridContainer} spacing={2}>
          <Grid item xs className={classes.margin}>
            <Card>
              <MonthlyDeposites />
            </Card>
          </Grid>
          <Grid item xs className={classes.margin}>
            <Card>
              <AmountfondedYtd />
            </Card>
          </Grid>
        </Grid> */}
    </div>
  );
}

Dashboard.propTypes = {
  getRecentContactList: PropTypes.func,
  dashboardData: PropTypes.object,
  getRecentAcceptOffer: PropTypes.func,
  getExpireApplicationList: PropTypes.func,
  getActivePatientDashboardData: PropTypes.func,
  getDefaultData: PropTypes.func,
  getMoveToCollection: PropTypes.func,
  getBadDebatsData: PropTypes.func,
  getTotalOutStandingData: PropTypes.func,
  getDailyDepositeData: PropTypes.func,
  getCptCodeData: PropTypes.func,
};

const mapStateToProps = app => ({
  dashboardData: app.dashboard,
});
export default connect(mapStateToProps, {
  getRecentContactList,
  getRecentAcceptOffer,
  getExpireApplicationList,
  getActivePatientDashboardData,
  getDefaultData,
  getMoveToCollection,
  getBadDebatsData,
  getTotalOutStandingData,
  getDailyDepositeData,
  getCptCodeData,
})(Dashboard);
