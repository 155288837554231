/* eslint-disable radix */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable import/extensions */
import React from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DateFnsUtils from "@date-io/date-fns";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import FormControl from "@material-ui/core/FormControl";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PersonIcon from "@material-ui/icons/Person";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import PublicIcon from "@material-ui/icons/Public";
import DescriptionIcon from "@material-ui/icons/Description";
import StorageRoundedIcon from "@material-ui/icons/StorageRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import NearMeRoundedIcon from "@material-ui/icons/NearMeRounded";
import RoomIcon from "@material-ui/icons/Room";
import StarIcon from "@material-ui/icons/Star";
import EditLocationAltIcon from "@material-ui/icons/EditLocation";
import AddLocationAltIcon from "@material-ui/icons/AddLocation";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { locationCity, countryList } from "../../constant/locationConstant";
import { formatPhoneNumber } from "../../Utils/commonUtils";

const useStyles = makeStyles(styles);

const OwnerInformation = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const openDob = React.useRef(null);
  const [mccList, setMccList] = React.useState([]);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [stateInput, setStateInput] = React.useState(true);
  const [photoIdType, setPhotoIdType] = React.useState("");
  const [addressFileType, setAddressFileType] = React.useState("");
  const [disabledValue, setDisabledValue] = React.useState(false);

  const handleSSNFocus = (e) => {
    e.preventDefault();
    e.target.select();
  };

  React.useEffect(() => {
    if (!props.formData.mcclist) {
      props.getMccList();
    }
  }, []);

  React.useEffect(() => {
    if (props.formData.mcclist.MCC_Data) {
      const sortedData = props.formData.mcclist.MCC_Data.map((option) => ({
        MCC_Code: option.MCC_Code,
        MCC_Name: `[${option.MCC_Code}] - ${option.MCC_Name}`
      }));
      setMccList([...sortedData]);
    }
  }, [props.formData.mcclist]);

  return (
    <div>
      <Grid container className={classes.margin}>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            error={props.validationError.businessName}
            helperText={
              props.validationError.businessName && "Business name is required"
            }
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              },
              required: true
            }}
            label="Business Name"
            value={props.values.businessName}
            onChange={(e) => {
              (/^[A-Za-z0-9\d\.\-\/\&\,\ ]*$/g.test(e.target.value) ||
                e.target.value == "") &&
                props.onChange(e.target.value, "businessName");
            }}
            name="businessName"
            id="businessName"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <BusinessCenterIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            error={props.validationError.legalBusiness}
            helperText={
              props.validationError.legalBusiness &&
              "Legal business name is required"
            }
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              },
              required: true
            }}
            label="Legal Business Name"
            value={props.values.legalBusiness}
            onChange={(e) => {
              (/^[A-Za-z0-9\d\.\-\/\&\,\ ]*$/g.test(e.target.value) ||
                e.target.value == "") &&
                props.onChange(e.target.value, "legalBusiness");
            }}
            name="legalBusiness"
            id="legalBusiness"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <EventNoteIcon className={classes.inputBox} fontSize="small" />
          <FormControl className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                clearable
                disabled={props.disabled}
                onChange={(e) => {
                  if (e === null) {
                    props.onChange(e, "businessStartDate");
                  } else {
                    props.onChange(
                      moment(e).format("MM/DD/YYYY"),
                      "businessStartDate"
                    );
                    e > new Date() &&
                      props.setVlidationError({
                        ...props.validationError,
                        businessStartDate: true
                      });
                  }
                }}
                value={
                  props.values.businessStartDate
                    ? moment(props.values.businessStartDate).format(
                        "MM/DD/YYYY"
                      )
                    : null
                }
                required
                label="Business Start Date"
                id="businessStartDate"
                name="businessStartDate"
                placeholder="MM/DD/YYYY"
                maxDate={new Date()}
                maxDateMessage="Business Start Date should not be a Future date."
                format="MM/dd/yyyy"
                keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                KeyboardButtonProps={{
                  size: "small"
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  },
                  endAdornment: (
                    <Tooltip title="cds_DateHelp" placement="top">
                      <IconButton edge="end" size="small">
                        <HelpOutlineOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                error={props.validationError.businessStartDate}
                helperText={
                  props.validationError.businessStartDate &&
                  "please enter valid business date"
                }
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={4} sm={6} style={{ display: "flex" }}>
          <AccountBalanceIcon className={classes.inputBox} fontSize="small" />
          <Autocomplete
            name="USID"
            id="USID"
            label="MCC"
            style={{ fontSize: "14px" }}
            value={
              (mccList || []).find(
                (option) => option.MCC_Code === parseInt(props.values.USID)
              ) || {}
            }
            autoHighlight
            fullWidth
            onChange={(_, value, action) => {
              if (action === "clear") {
                props.onChange("", "USID");
              } else {
                value && props.onChange(value.MCC_Code, "USID");
              }
            }}
            options={mccList || []}
            getOptionLabel={(option) => option.MCC_Name || ""}
            renderInput={(params) => (
              <TextField
                required
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                error={props.validationError.USID}
                helperText={props.validationError.USID && "MCC is required"}
                label="MCC"
              />
            )}
            className={classes.root}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.MCC_Name, inputValue, {
                insideWords: true
              });
              const parts = parse(option.MCC_Name, matches);

              return (
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={`new_${index + 1}`}
                      style={{
                        background: part.highlight ? "#f3ff0070" : "",
                        fontWeight: part.highlight ? 400 : "",
                        fontSize: "14px"
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
            disabled={props.disabled}
          />
        </Grid>

        {props.values.USID === "option" && (
          <Grid item xs={12} lg={4} sm={6}>
            <AccountBalanceIcon className={classes.inputBox} fontSize="small" />
            <TextField
              disabled={props.disabled}
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              label="Enter"
              value={props.values.ussidTypeInput}
              onChange={(e) => props.onChange(e.target.value, "ussidTypeInput")}
              name="USID"
              id="USID"
            />
          </Grid>
        )}
        <Grid item xs={12} lg={4} sm={6} style={{ display: "flex" }}>
          <BusinessCenterIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Business Description"
            value={props.values.businessDiscrition}
            onChange={(e) =>
              props.onChange(e.target.value, "businessDiscrition")
            }
            name="businessDiscrition"
            id="businessDiscrition"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <PriorityHighIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.ownerShipType}
            helperText={
              props.validationError.ownerShipType &&
              "Business address is required."
            }
            label="Ownership Type"
            value={props.values.ownerShipType}
            onChange={(e) => props.onChange(e.target.value, "ownerShipType")}
            name="ownerShipType"
            id="ownerShipType"
            select
          >
            {props.formData.ownerShipTypeList &&
              props.formData.ownerShipTypeList.Ownership_Type_Data.sort(
                (a, b) => a.Type_Name.localeCompare(b.Type_Name)
              ).map((key) => (
                <MenuItem
                  className={classes.dialogBoxDropDown}
                  value={key.Type_Name}
                >
                  {key.Type_Name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StayCurrentPortraitIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Business Phone Number"
            value={formatPhoneNumber(props.values.businessPhoneNumber)}
            onChange={(e) =>
              props.onChange(e.target.value, "businessPhoneNumber")
            }
            name="businessPhoneNumber"
            id="businessPhoneNumber"
            error={props.validationError.businessPhoneNumber}
            helperText={
              props.validationError.businessPhoneNumber &&
              "Phone Number should be 10 digits."
            }
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StayCurrentPortraitIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            error={props.validationError.businessEmail}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true,
              readOnly:
                props.values.businessEmail && !disabledValue ? true : false,
              className:
                props.values.businessEmail && !disabledValue
                  ? "Mui-disabled"
                  : ""
            }}
            label="Email Address"
            value={props.values.businessEmail}
            onChange={(e) => {
              setDisabledValue(true);
              props.onChange(e.target.value, "businessEmail");
            }}
            name="businessEmail"
            id="businessEmail"
            helperText={
              props.validationError.businessEmail && "Invalid email address"
            }
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <PublicIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.businessWebsite}
            label="Website"
            value={props.values.businessWebsite}
            onChange={(e) => props.onChange(e.target.value, "businessWebsite")}
            helperText={
              props.validationError.businessWebsite &&
              "Enter a valid web address."
            }
            name="businessWebsite"
            id="businessWebsite"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DescriptionIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.businessTaxId}
            label="Federal Tax ID"
            value={props.values.businessTaxId}
            onChange={(e) =>
              (/^[0-9]\d*$/g.test(e.target.value) || e.target.value === "") &&
              props.onChange(e.target.value, "businessTaxId")
            }
            helperText={
              props.validationError.businessTaxId &&
              "Federal ID should be 9 digits"
            }
            name="businessTaxId"
            id="businessTaxId"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StorageRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Stock Symbol"
            value={props.values.businessStock}
            onChange={(e) => props.onChange(e.target.value, "businessStock")}
            name="businessStock"
            id="businessStock"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <HomeRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.businessAddress}
            label="Business Address (No P.O. Box)"
            value={props.values.businessAddress}
            onChange={(e) => props.onChange(e.target.value, "businessAddress")}
            name="businessAddress"
            id="businessAddress"
            helperText={
              props.validationError.businessAddress &&
              "Business address is required."
            }
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <MoreHorizRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Suite"
            error={props.validationError.businessSuite}
            value={props.values.businessSuite}
            onChange={(e) => props.onChange(e.target.value, "businessSuite")}
            name="businessSuite"
            id="businessSuite"
            helperText={
              props.validationError.businessSuite &&
              "Business Suite is required"
            }
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <AddIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.businessCountry}
            helperText={
              props.validationError.businessCountry && "Country is required"
            }
            label="Country"
            value={props.values.businessCountry}
            onChange={(e) => props.onChange(e.target.value, "businessCountry")}
            name="businessCountry"
            id="businessCountry"
            select
          >
            {[
              {
                Country_Code: "US",
                Country_Name: "United States",
                Dial_Code: 1
              }
            ].map((key) => (
              <MenuItem
                className={classes.dialogBoxDropDown}
                value={key.Country_Code}
              >
                {key.Country_Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <NearMeRoundedIcon className={classes.inputBox} fontSize="small" />
          {props.values.businessCountry === "US" ? (
            <TextField
              disabled={props.disabled}
              required
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              error={props.validationError.businessState}
              helperText={
                props.validationError.businessState &&
                "Business State is required"
              }
              help
              label="State"
              value={props.values.businessState}
              onChange={(e) => props.onChange(e.target.value, "businessState")}
              name="businessState"
              id="businessState"
              select={stateInput}
            >
              {stateInput &&
                locationCity
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((key) => (
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value={key.value}
                    >
                      {key.name}
                    </MenuItem>
                  ))}
            </TextField>
          ) : (
            <TextField
              required
              disabled={props.disabled}
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              label="State"
              error={props.validationError.businessState}
              helperText={
                props.validationError.businessState &&
                "Business State is required"
              }
              onChange={(e) => props.onChange(e.target.value, "businessState")}
              name="businessState"
              id="businessState"
            />
          )}
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="City"
            error={props.validationError.businessCity}
            value={props.values.businessCity}
            onChange={(e) => props.onChange(e.target.value, "businessCity")}
            name="businessCity"
            id="businessCity"
            helperText={
              props.validationError.businessCity && "Business City is required"
            }
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StarIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="International Province"
            value={props.values.businessInterNational}
            onChange={(e) =>
              props.onChange(e.target.value, "businessInterNational")
            }
            name="businessInterNational"
            id="businessInterNational"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <RoomIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.businessPostalCode}
            label="Postal Code"
            value={props.values.businessPostalCode}
            onChange={(e) =>
              (/^[0-9]\d*$/g.test(e.target.value) || e.target.value === "") &&
              props.onChange(e.target.value, "businessPostalCode")
            }
            helperText={
              props.validationError.businessPostalCode &&
              "Postal Code should be 5 digits"
            }
            name="businessPostalCode"
            id="businessPostalCode"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <AddLocationAltIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.businessFormationCountry}
            label="Business Formation Country"
            value={props.values.businessFormationCountry}
            onChange={(e) =>
              props.onChange(e.target.value, "businessFormationCountry")
            }
            helperText={
              props.validationError.businessFormationCountry &&
              "Business formation country name is required"
            }
            name="businessFormationCountry"
            id="businessFormationCountry"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <EditLocationAltIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.businessTaxResidence}
            helperText={
              props.validationError.businessTaxResidence &&
              "Business tax residence name is required"
            }
            label="Business Tax Residence"
            value={props.values.businessTaxResidence}
            onChange={(e) =>
              props.onChange(e.target.value, "businessTaxResidence")
            }
            name="businessTaxResidence"
            id="businessTaxResidence"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DescriptionIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.businessDomicile}
            helperText={
              props.validationError.businessDomicile &&
              "Business domicile name is required"
            }
            label="Business Domicile"
            value={props.values.businessDomicile}
            onChange={(e) => props.onChange(e.target.value, "businessDomicile")}
            name="businessDomicile"
            id="businessDomicile"
          />
        </Grid>
      </Grid>
    </div>
  );
};

OwnerInformation.propTypes = {
  disabled: PropTypes.bool,
  validationError: PropTypes.object,
  values: PropTypes.object,
  onChange: PropTypes.func,
  formData: PropTypes.object,
  setVlidationError: PropTypes.func,
  getMccList: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration
});
export default connect(mapStateToProps, null)(OwnerInformation);
