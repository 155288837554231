import moment from "moment";
import isEmpty from "lodash/isEmpty";

export const checkValidation = (
  saveDraft = false,
  errorMessage,
  values,
  setErrorMessage,
  setVlidationError,
  setValidationModal,
  checkTC,
  validationError
) => {
  const error = { ...validationError };

  const emailRegEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const websiteRegEx = /^((?!-)[A-Za-z0-9-]{1, 63}(?<!-)\\.)+[A-Za-z]{2, 6}$/;
  const phoneNumberRegEx =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const mesgError = !errorMessage ? [] : errorMessage;
  let validate = true;
  for (const key in values) {
    if (
      values[key] === "" &&
      (key === "businessName" ||
        key === "legalBusiness" ||
        key === "businessEmail")
    ) {
      error[key] = true;
      validate = false;
    } else if (key === "ownerArray") {
      values[key].owners.forEach((item, i) => {
        if (item.Owner_First_Name === "") {
          error[key].owners[i].Owner_First_Name = true;
          validate = false;
        } else if (item.Owner_First_Name !== "") {
          error[key].owners[i].Owner_First_Name = false;
        }
        // error after final submit
        if (!saveDraft && item.Owner_Last_Name === "") {
          error[key].owners[i].Owner_Last_Name = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_Title === "") {
          error[key].owners[i].Owner_Title = true;
          validate = false;
        }

        if (!saveDraft && item.Owner_Ownership_Percentage === "") {
          error[key].owners[i].Owner_Ownership_Percentage = true;
          validate = false;
        }

        if (!saveDraft && item.Owner_Last4_SSN === "") {
          error[key].owners[i].Owner_Last4_SSN = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_Street_Address === "") {
          error[key].owners[i].Owner_Street_Address = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_Country_Code === "") {
          error[key].owners[i].Owner_Country_Code = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_State_Code === "") {
          error[key].owners[i].Owner_State_Code = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_Suite === "") {
          error[key].owners[i].Owner_Suite = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_State_Code === "") {
          error[key].owners[i].Owner_State_Code = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_City === "") {
          error[key].owners[i].Owner_City = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_Postal_Code === "") {
          error[key].owners[i].Owner_Postal_Code = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_Phone === "") {
          error[key].owners[i].Owner_Phone = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_DOB === "") {
          error[key].owners[i].Owner_DOB = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_DOB === undefined) {
          error[key].owners[i].Owner_DOB = true;
          validate = false;
        }
        if (!saveDraft && item.Owner_DOB === null) {
          error[key].owners[i].Owner_DOB = true;
          validate = false;
        }

        if (!saveDraft && item.Owner_DOB !== "") {
          if (moment().diff(item.Owner_DOB, "days") < 0) {
            error[key].owners[i].Owner_DOB = true;
            validate = false;
          }
        }
        if (!saveDraft && item.Owner_Ownership_Percentage === null) {
          error[key].owners[i].Owner_Ownership_Percentage = true;
          validate = false;
        }
        if (!saveDraft && isEmpty(item.ownerPhotoId)) {
          error[key].owners[i].ownerPhotoId = true;
          validate = false;
        } else if (item.ownerPhotoId !== "") {
          error[key].owners[i].ownerPhotoId = false;
        }
        if (!saveDraft && isEmpty(item.ownerPhotoIdBack)) {
          error[key].owners[i].ownerPhotoIdBack = true;
          validate = false;
        } else if (item.ownerPhotoIdBack !== "") {
          error[key].owners[i].ownerPhotoIdBack = false;
        }
        if (!saveDraft && isEmpty(item.ownerAddressProof)) {
          error[key].owners[i].ownerAddressProof = true;
          validate = false;
        } else if (item.ownerAddressProof !== "") {
          error[key].owners[i].ownerAddressProof = false;
        }
        if (!saveDraft && isEmpty(item.ownerBiometricId)) {
          error[key].owners[i].ownerBiometricId = true;
          validate = false;
        } else if (item.ownerBiometricId !== "") {
          error[key].owners[i].ownerBiometricId = false;
        }
      });
      setErrorMessage(mesgError);
    }
    // check for final submit validation
    else if (
      !saveDraft &&
      (values[key] === "" ||
        values[key] === null ||
        values[key] === undefined) &&
      (key === "businessPhoneNumber" ||
        key === "businessTaxId" ||
        key === "ownerShipType" ||
        key === "businessStartDate" ||
        key === "businessAddress" ||
        key === "businessState" ||
        key === "businessCountry" ||
        key === "businessCity" ||
        key === "businessPostalCode" ||
        key === "USID" ||
        key === "businessSuite" ||
        key === "routingNumber" ||
        key === "accountType" ||
        key === "cardVolumeMonth" ||
        key === "averageTicketSize" ||
        key === "highTicket" ||
        key === "accountNumber" ||
        key === "businessFormationCountry" ||
        key === "businessTaxResidence" ||
        key === "businessDomicile" ||
        key === "titleTermsCondition" ||
        key === "nameTermsCondition")
    ) {
      error[key] = true;
      validate = false;
    }
    // final submit validation ends here
    else if (
      !saveDraft &&
      key === "businessStartDate" &&
      (values.businessStartDate === "Invalid date" ||
        values.businessStartDate === "" ||
        values.businessStartDate == null)
    ) {
      mesgError.push({
        msg: " Invalid Business Date Entered."
      });
      error[key] = true;
      validate = false;
    } else if (key === "businessPhoneNumber" && values.businessPhoneNumber) {
      if (!phoneNumberRegEx.test(values.businessPhoneNumber)) {
        validate = false;
        error[key] = true;
      }
    } else if (key === "businessTaxId" && values.businessTaxId) {
      error[key] = false;
    } else if (
      key === "businessFormationCountry" &&
      values.businessFormationCountry
    ) {
      error[key] = false;
    } else if (key === "accountNumber" && values.accountNumber) {
      error[key] = false;
    } else if (key === "routingNumber" && values.routingNumber) {
      error[key] = false;
    } else if (key === "businessStartDate" && values.businessStartDate) {
      if (moment().diff(values.businessStartDate, "days") < 0) {
        validate = false;
        error[key] = true;
      }
    } else if (key === "businessPostalCode" && values.businessPostalCode) {
      error[key] = false;
    } else if (
      key === "businessEmail" &&
      values.businessEmail &&
      !emailRegEx.test(values.businessEmail)
    ) {
      validate = false;
    } else {
      error[key] = false;
    }
    if (!saveDraft && values[key] === "" && key === "incorportionDocument") {
      error[key] = true;
      validate = false;
    } else if (values[key] !== "" && key === "incorportionDocument") {
      error[key] = false;
    }
  }
  setVlidationError(error);
  if (!checkTC && !saveDraft) {
    setValidationModal(true);
    return false;
  }
  if (saveDraft) {
    if (!validate) {
      setValidationModal(true);
    }
  }
  return validate;
};
export const initialValuie = {
  businessName: "",
  legalBusiness: "",
  businessStartDate: "",
  docAdd: false,
  businessDiscrition: "",
  ownerShipType: "",
  businessPhoneNumber: "",
  businessEmail: "",
  businessWebsite: "",
  businessTaxId: "",
  businessStock: "",
  businessAddress: "",
  businessSuite: "",
  businessCity: "",
  businessState: "",
  USID: "",
  businessFormationCountry: "",
  businessTaxResidence: "",
  businessDomicile: "",
  businessPostalCode: "",
  businessInterNational: "",
  deleteCoperation: false,
  businessCountry: "US",
  accountNumber: "",
  routingNumber: "",
  accountName: "",
  accountType: "",
  cardPresent: "",
  description: "",
  ownerArray: {
    owners: [
      {
        Owner_First_Name: "",
        Owner_Last_Name: "",
        Owner_Last4_SSN: "",
        ownerPhotoIdType: "",
        ownerPhotoIdTypeBack: "",
        ownerAddressProofType: "",
        ownerBiometricIdType: "",
        Owner_DOB: "",
        Owner_Ownership_Percentage: "",
        Owner_Title: "",
        Owner_Phone: "",
        Owner_Street_Address: "",
        Owner_Suite: "",
        Owner_City: "",
        Owner_State_Code: "",
        Owner_Postal_Code: "",
        Owner_International_Province: "",
        Owner_Country_Code: "US",
        Owner_Timezone_Code: "IST",
        ownerAddressProof: "",
        ownerPhotoId: "",
        ownerPhotoIdBack: "",
        ownerBiometricId: "",
        deleteOwnerPhotoId: false,
        deleteAddressProofId: false,
        deleteOwnerBiometricId: false,
        ownerAddressProofDetails: {
          File_Name: "",
          File_Type: "",
          Upload_Date_Time: ""
        },
        ownerPhotoIdDetails: {
          File_Name: "",
          File_Type: "",
          Upload_Date_Time: ""
        },
        ownerPhotoIdBackDetails: {
          File_Name: "",
          File_Type: "",
          Upload_Date_Time: ""
        },
        ownerBiometricIdDetails: {
          File_Name: "",
          File_Type: "",
          Upload_Date_Time: ""
        }
      }
    ]
  },
  cardNotPresent: "",
  eCommers: "",
  cardVolumeMonth: "",
  averageTicketSize: "",
  highTicket: "",
  ussidTypeInput: "",
  eCheckVolumeMonth: "",
  incorportionDocument: "",
  incorportionDocumentDetails: {
    File_Name: "",
    File_Type: "",
    File_Path: "",
    Upload_Date_Time: ""
  },
  eCheckAverage: "",
  eCheckHigh: "",
  status: "",
  checkedB: "",
  editable: false,
  titleTermsCondition: "",
  nameTermsCondition: ""
};

export const validationInitailValue = {
  businessName: false,
  legalBusiness: false,
  businessStartDate: false,
  businessDiscrition: false,
  ownerShipType: false,
  businessPhoneNumber: false,
  businessEmail: false,
  businessWebsite: false,
  businessTaxId: false,
  businessStock: false,
  businessAddress: false,
  businessSuite: false,
  businessCity: false,
  businessState: false,
  USID: false,
  businessFormationCountry: false,
  businessTaxResidence: false,
  businessDomicile: false,
  incorportionDocument: false,
  businessPostalCode: false,
  businessInterNational: false,
  businessCountry: false,
  accountNumber: false,
  routingNumber: false,
  accountName: false,
  accountType: false,
  cardPresent: false,
  description: false,
  ownerArray: {
    owners: [
      {
        Owner_First_Name: false,
        Owner_Last_Name: false,
        Owner_Last4_SSN: false,
        Owner_DOB: false,
        Owner_Ownership_Percentage: false,
        Owner_Title: false,
        Owner_Phone: false,
        Owner_Street_Address: false,
        Owner_Suite: false,
        Owner_City: false,
        Owner_State_Code: false,
        Owner_Postal_Code: false,
        Owner_International_Province: false,
        Owner_Country_Code: false,
        Owner_Timezone_Code: false,
        ownerAddressProof: false,
        ownerPhotoId: false,
        ownerPhotoIdBack: false,
        ownerBiometricId: false,
        ownerAddressProofDetails: {
          File_Name: false,
          File_Type: false,
          Upload_Date_Time: false
        },
        ownerPhotoIdDetails: {
          File_Name: false,
          File_Type: false,
          Upload_Date_Time: false
        },
        ownerPhotoIdBackDetails: {
          File_Name: false,
          File_Type: false,
          Upload_Date_Time: false
        },
        ownerBiometricIdDetails: {
          File_Name: false,
          File_Type: false,
          Upload_Date_Time: false
        }
      }
    ]
  },
  cardNotPresent: false,
  eCommers: false,
  cardVolumeMonth: false,
  averageTicketSize: false,
  highTicket: false,
  ussidTypeInput: false,
  eCheckVolumeMonth: false,
  eCheckAverage: false,
  eCheckHigh: false,
  status: "",
  checkedB: true,
  titleTermsCondition: false,
  nameTermsCondition: false
};

export const setValidationAfterSaveClick = (
  validationError,
  props,
  setVlidationError,
  setSubmissionSuccess,
  setValidationModal
) => {
  const allValidation = { ...validationError };
  props.formData.data.errors.forEach((key) => {
    if (key.param === "Business_Name") {
      allValidation.businessName = true;
    }
    if (key.param === "Legal_Name") {
      allValidation.legalBusiness = true;
    }
    if (key.param === "Business_Start_Date") {
      allValidation.businessStartDate = true;
    }
    if (key.param === "MCC") {
      allValidation.USID = true;
    }
    if (key.param === "Business_Description") {
      allValidation.businessDiscrition = true;
    }
    if (key.param === "Ownership_Type") {
      allValidation.ownerShipType = true;
    }
    if (key.param === "Business_Phone") {
      allValidation.businessPhoneNumber = true;
    }
    if (key.param === "Business_EMail") {
      allValidation.businessEmail = true;
    }
    if (key.param === "Website") {
      allValidation.businessWebsite = true;
    }
    if (key.param === "Fedral_Tax_ID") {
      allValidation.businessTaxId = true;
    }
    if (key.param === "Stock_Symbol") {
      allValidation.businessStock = true;
    }
    if (key.param === "Business_Address") {
      allValidation.businessAddress = true;
    }
    if (key.param === "Suite") {
      allValidation.businessSuite = true;
    }
    if (key.param === "Country_Code") {
      allValidation.businessCountry = true;
    }
    if (key.param === "State_Code") {
      allValidation.businessState = true;
    }
    if (key.param === "City") {
      allValidation.businessCity = true;
    }
    if (key.param === "International_Province") {
      allValidation.businessInterNational = true;
    }
    if (key.param === "Postal_Code") {
      allValidation.businessPostalCode = true;
    }
    if (key.param === "Business_Formation_Country") {
      allValidation.businessFormationCountry = true;
    }
    if (key.param === "Business_Tax_Residence") {
      allValidation.businessTaxResidence = true;
    }
    if (key.param === "Business_Domicile") {
      allValidation.businessDomicile = true;
    }
    if (key.param === "Description") {
      allValidation.description = true;
    }
    if (key.param === "Account_Number") {
      allValidation.accountNumber = true;
    }
    if (key.param === "Routing_Number") {
      allValidation.routingNumber = true;
    }
    if (key.param === "Name_On_Account") {
      allValidation.legalBusiness = true;
    }
    if (key.param === "Account_Type") {
      allValidation.accountType = true;
    }
    if (key.param === "Card_Swiped") {
      allValidation.cardPresent = true;
    }
    if (key.param === "Card_Not_Present") {
      allValidation.cardNotPresent = true;
    }
    if (key.param === "ECommerce") {
      allValidation.eCommers = true;
    }
    if (key.param === "Monthly_Card_Volume") {
      allValidation.cardVolumeMonth = true;
    }

    if (key.param === "Average_Ticket_Size") {
      allValidation.averageTicketSize = true;
    }
    if (key.param === "High_Ticket") {
      allValidation.highTicket = true;
    }
    if (key.param === "Monthly_ACH_Volume") {
      allValidation.eCheckVolumeMonth = true;
    }
    if (key.param === "Average_ACH") {
      allValidation.eCheckAverage = true;
    }
    if (key.param === "High_ACH") {
      allValidation.eCheckHigh = true;
    }
    if (key.param === "Owner_Information[0].ownerPhotoId") {
      allValidation.ownerArray.owners[0].ownerPhotoId = true;
    }
    if (key.param === "Owner_Information[0].ownerPhotoIdBack") {
      allValidation.ownerArray.owners[0].ownerPhotoIdBack = true;
    }
    if (key.param === "Owner_Information[0].Owner_DOB") {
      allValidation.ownerArray.owners[0].Owner_DOB = true;
    }
    if (key.param === "Owner_Information[0].Owner_Ownership_Percentage") {
      allValidation.ownerArray.owners[0].Owner_Ownership_Percentage = true;
    }
    if (key.param === "Owner_Information[0].ownerAddressProof") {
      allValidation.ownerArray.owners[0].ownerAddressProof = true;
    }
    if (key.param === "Owner_Information[0].ownerBiometricId") {
      allValidation.ownerArray.owners[0].ownerBiometricId = true;
    }
    if (key.param === "Owner_Information[0].Owner_State_Code") {
      allValidation.ownerArray.owners[0].Owner_State_Code = true;
    }
    if (key.param === "Owner_Information[0].Owner_Country_Code") {
      allValidation.ownerArray.owners[0].Owner_Country_Code = true;
    }
    if (key.param === "nameTermsCondition") {
      allValidation.nameTermsCondition = true;
    }
    if (key.param === "titleTermsCondition") {
      allValidation.titleTermsCondition = true;
    }
  });
  setVlidationError(allValidation);
  setSubmissionSuccess(props.formData.data);
  setValidationModal(true);
};

export const ownerArray = {
  Owner_First_Name: "",
  Owner_Last_Name: "",
  Owner_Last4_SSN: "",
  Owner_DOB: "",
  Owner_Ownership_Percentage: "",
  Owner_Title: "",
  Owner_Phone: "",
  Owner_Street_Address: "",
  Owner_Suite: "",
  Owner_City: "",
  Owner_State_Code: "",
  Owner_Postal_Code: "",
  Owner_International_Province: "",
  Owner_Country_Code: "US",
  Owner_Timezone_Code: "IST",
  ownerAddressProof: "",
  ownerPhotoId: "",
  ownerPhotoIdBack: "",
  ownerBiometricId: "",
  ownerAddressProofDetails: {
    File_Name: "",
    File_Type: "",
    Upload_Date_Time: ""
  },
  ownerPhotoIdDetails: {
    File_Name: "",
    File_Type: "",
    Upload_Date_Time: ""
  },
  ownerPhotoIdBackDetails: {
    File_Name: "",
    File_Type: "",
    Upload_Date_Time: ""
  },
  ownerBiometricIdDetails: {
    File_Name: "",
    File_Type: "",
    Upload_Date_Time: ""
  }
};

export const ownerValidation = {
  Owner_First_Name: false,
  Owner_Last_Name: false,
  Owner_Last4_SSN: false,
  Owner_DOB: false,
  Owner_Ownership_Percentage: false,
  Owner_Title: false,
  Owner_Phone: false,
  Owner_Street_Address: false,
  Owner_Suite: false,
  Owner_City: false,
  Owner_State_Code: false,
  Owner_Postal_Code: false,
  Owner_International_Province: false,
  Owner_Country_Code: false,
  Owner_Timezone_Code: false,
  ownerAddressProof: false,
  ownerPhotoId: false,
  ownerPhotoIdBack: false,
  ownerBiometricId: false,
  ownerAddressProofDetails: {
    File_Name: false,
    File_Type: false,
    Upload_Date_Time: false
  },
  ownerPhotoIdDetails: {
    File_Name: false,
    File_Type: false,
    Upload_Date_Time: false
  },
  ownerPhotoIdBackDetails: {
    File_Name: false,
    File_Type: false,
    Upload_Date_Time: false
  },
  ownerBiometricIdDetails: {
    File_Name: false,
    File_Type: false,
    Upload_Date_Time: false
  }
};
