import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { connect } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import InactiveUser from "views/Reports/InactiveUser";
import AllUser from "views/Reports/AllUser";
import { useLocation } from "react-router-dom";
import UserDataList from "../Reports/Users";
import EditRolePermission from "./EditRolePermission";
import gif from "../../assets/img/success.gif";
import styles from "../../assets/jss/material-dashboard-react/components/businessForm";
import UserManager from "./Information";
import RoleFormManager from "./RoleInformation";
import {
  changeUserFormMode,
  setUserSearchEmpty,
  setUserSearchData,
} from "../../Redux/Actions/UserRegistration";

import {
  updateRolePermisstionRequest,
  changeRoleFormMode,
} from "../../Redux/Actions/RoleRegistration";

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      style={{ overflowX: "hidden" }}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "0px" }} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TabStyle = withStyles(theme => ({
  root: {
    "&$selected": {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  tab: {
    padding: "0.5rem",
    fontFamily: "Open Sans",
    fontSize: "2rem",
    backgroundColor: "grey",
    color: "black",
    "&:hover": {
      backgroundColor: "red",
      color: "white",
      opacity: 1,
    },
  },
  selected: {},
}))(props => <Tab {...props} />);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  ...styles,
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  indicator: {
    color: theme.palette.primary.main,
  },
}));

function RoleManager(props) {
  const classes = useStyles();
  const location = useLocation();
  const [roleChip, setRoleChips] = React.useState([]);
  const [preUpdateRole, setUpdateRole] = React.useState(
    props.tableData.updateRolePermission
  );
  const [updateRoleRequest, setUpdateRoleRequest] = React.useState(
    props.tableData.roleDetailsById
      && props.tableData.roleDetailsById.Roles_Data.LeftMenu
  );
  const theme = useTheme();
  const [value, setValue] = React.useState(
    window.location.pathname.includes("RoleManager") ? 1 : 0
  );
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    const requestData = { ...props.userForm.searchDetails };
    if (newValue === 1) {
      requestData.Current_Status = "Inactive";
      props.setUserSearchData(requestData);
    }
    if (newValue === 0) {
      requestData.Current_Status = "Active";
      props.setUserSearchData(requestData);
    }
    if (newValue === 2) {
      requestData.Current_Status = "All";
      props.setUserSearchData(requestData);
    }
    props.changeUserFormMode("init");
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
    if (
      props.tableData.roleTab
      && props.tableData.roleTab.Roles_Data.LeftMenu
      && !props.tableData.roleTab.Roles_Data.LeftMenu[1].Page_Menu[2].Permission
      && index === 1
    ) {
      setValue(2);
    }
  };

  const createRoleRequest = (data, type) => {
    const dataArray = updateRoleRequest;
    dataArray.forEach((v, i) => {
      if (v.Category_Name === type.Category_Name) {
        dataArray[i].Page_Menu.forEach((item, j) => {
          if (item.Page_Name === data.Page_Name) {
            dataArray[i].Page_Menu[j] = data;
          }
        });
      }
    });
    setUpdateRoleRequest(dataArray);
  };

  const handleBack = () => {
    props.changeRoleFormMode("init");
    props.changeUserFormMode("init");
  };

  React.useEffect(() => {
    if (
      location.pathname === "/admin/Users"
      || location.pathname === "/admin/merchant-users"
    ) {
      props.changeUserFormMode("init");
    }
  }, [location]);

  React.useEffect(() => {
    if (preUpdateRole !== props.tableData.updateRolePermission) {
      if (
        props.tableData.updateRolePermission
        && props.tableData.updateRolePermission.status
      ) {
        setOpen(true);
      }
      setUpdateRole(props.tableData.updateRolePermission);
    }
  }, [preUpdateRole, props]);

  React.useEffect(() => {
    if (props.tableData.roleDetailsById) {
      setUpdateRoleRequest(
        props.tableData.roleDetailsById
          && props.tableData.roleDetailsById.Roles_Data.LeftMenu
      );
    }
  }, [preUpdateRole, props]);

  const updateRequestSubmit = () => {
    const data = {
      role_Id: props.tableData.roleDetailsById.Roles_Data.Role_id,
      Page_Permissions: updateRoleRequest,
    };
    props.updateRolePermisstionRequest(data);
  };

  const handleModalButton = () => {
    setOpen(false);
    props.changeRoleFormMode("init");
    props.changeUserFormMode("init");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          style={{ width: "100%", maxWidth: "fit-content" }}
          TabIndicatorProps={{
            style: {
              background: theme.palette.primary.main,
              color: theme.palette.primary.main,
            },
          }}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <TabStyle
            classes={{ selected: theme.palette.primary.main }}
            style={{ maxWidth: "fit-content", textTransform: "none" }}
            label="Active"
            {...a11yProps(0)}
          />
          <TabStyle
            style={{ maxWidth: "fit-content", textTransform: "none" }}
            label="Inactive"
            {...a11yProps(1)}
          />
          {/* {props.tableData.roleTab &&
            props.tableData.roleTab.Roles_Data.LeftMenu &&
            props.tableData.roleTab.Roles_Data.LeftMenu[1]
              .Page_Menu[2] &&
            props.tableData.roleTab.Roles_Data.LeftMenu[1].Page_Menu[2]
              .Permission && (
              <TabStyle
                style={{ maxWidth: "fit-content" }}
                label="Roles"
                {...a11yProps(1)}
              />
            )} */}
          <TabStyle
            style={{ maxWidth: "fit-content", textTransform: "none" }}
            label="All"
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        style={{ overflow: "unset" }}
        onChangeIndex={handleChangeIndex}
      >
        <>
          {value === 0 && (
            <TabPanel>
              {props.userForm.formMode === "init" && (
                <UserDataList {...props} />
              )}
              {(props.userForm.formMode === "Create"
                || props.userForm.formMode === "Edit") && (
                <UserManager {...props} />
              )}
            </TabPanel>
          )}
          {value === 1 && (
            <TabPanel>
              {props.userForm.formMode === "init" && (
                <InactiveUser
                  setRoleChips={e => setRoleChips(e)}
                  chipValue={roleChip}
                  {...props}
                />
              )}
              {(props.userForm.formMode === "Create"
                || props.userForm.formMode === "Edit") && (
                <UserManager {...props} />
              )}
              {props.tableData.formMode === "ManageRole" && (
                <>
                  <Grid container style={{ margin: "1%" }}>
                    <Grid item xs>
                      {`Role: ${
                        props.tableData.roleDetailsById
                        && props.tableData.roleDetailsById.Roles_Data.Role
                      }`}
                    </Grid>
                    <Grid item xs>
                      {`Profile: ${
                        props.tableData.roleDetailsById
                        && props.tableData.roleDetailsById.Roles_Data.Profile
                      }`}
                    </Grid>
                  </Grid>
                  <Grid container style={{ margin: "1%" }}>
                    <Grid item xs>
                      {`Description: ${
                        props.tableData.roleDetailsById
                        && props.tableData.roleDetailsById.Roles_Data.Description
                      }`}
                    </Grid>
                  </Grid>
                  <Grid container style={{ margin: "1%" }}>
                    <Grid item xs>
                      Setup Permissions :
                    </Grid>
                  </Grid>
                  {props.tableData.roleDetailsById
                    && props.tableData.roleDetailsById.Roles_Data.LeftMenu.map(
                      v => (
                        <EditRolePermission
                          details={v}
                          onChangePermission={data => createRoleRequest(data, v)}
                          {...props}
                        />
                      )
                    )}
                  <Grid container style={{ margin: "1%" }}>
                    <Grid
                      item
                      xs
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className={classes.clearButton}
                        onClick={() => handleBack()}
                      >
                        Back
                      </Button>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={() => updateRequestSubmit()}
                        className={classes.buttonStyle}
                      >
                        Update Role
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              {(props.tableData.formMode === "Create"
                || props.tableData.formMode === "Edit") && (
                <RoleFormManager {...props} />
              )}
            </TabPanel>
          )}
          {value === 2 && (
            <TabPanel>
              {props.userForm.formMode === "init" && <AllUser {...props} />}
              {(props.userForm.formMode === "Create"
                || props.userForm.formMode === "Edit") && (
                <UserManager {...props} />
              )}
            </TabPanel>
          )}
        </>
      </SwipeableViews>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={classes.modalDiv}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            className={classes.modalCard}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className={classes.cardDiv}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={gif} alt="" width="50%" />
              <div className={classes.successText}>
                Role Permission Update Successfully
              </div>
              <br />
              <Button
                variant="contained"
                className={classes.buttonStyle}
                onClick={() => handleModalButton(false)}
              >
                Ok
              </Button>
            </div>
          </Card>
        </div>
      </Modal>
    </div>
  );
}

RoleManager.propTypes = {
  tableData: PropTypes.object,
  updateRolePermisstionRequest: PropTypes.func,
  history: PropTypes.object,
  userForm: PropTypes.object,
  changeRoleFormMode: PropTypes.func,
  changeUserFormMode: PropTypes.func,
  setUserSearchEmpty: PropTypes.func,
  setUserSearchData: PropTypes.func,
};

const mapStateToProps = app => ({
  tableData: app.roles,
  userForm: app.users,
});
export default connect(mapStateToProps, {
  updateRolePermisstionRequest,
  changeRoleFormMode,
  changeUserFormMode,
  setUserSearchEmpty,
  setUserSearchData,
})(RoleManager);
