/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card";
import isEmpty from "lodash/isEmpty";
import Button from "components/CustomButtons/Button";
import { Typography, Paper } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  getPatientOfferData,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  getPersonalOfferData,
} from "Redux/Actions/genrateOfferPatient";
import {
  getPaymentDetailsList,
  getOfferDetailsPatient,
  errorReset,
} from "Redux/Actions/PatientDashboard";
import { useSnackbar } from "notistack";
import ButtonOriginal from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import { financeOfferListFetchCall } from "../../actions/Patient.action";
import gif from "../../assets/img/success.gif";
import PaymentSection from "./PaymentSection";

const useStyles = makeStyles(styles);
const NewOfferPayment = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [preSendEmailResult, setPreSendEmailResult] = React.useState(
    props.tableData.createOffer
  );
  const [prePaymentDetails, setPrePaymentsDetails] = React.useState(
    props.tableData.paymentDetailsData
  );
  const [preError, setPreError] = React.useState(props.patientDashboard.error);
  const [preSetUpMonthlyPayment, setPreSetUpMonthlyPayment] = React.useState(
    props.tableData.setUpMonthlyPayment
  );
  const [showOfferDEtails, setShowOfferDEtails] = React.useState(
    props.tableData.selectPatient
  );
  const [preGenrateOffer, setPreGenrateOffer] = React.useState(
    props.tableData.genrateOfferData
  );
  const [offerId, setOfferId] = React.useState(
    props.tableData.createOffer && props.tableData.createOffer.data.OfferId
  );
  const [preposalData, setPropsalData] = React.useState([]);
  // const [sendEmailModal, setSendEmailModal] = React.useState(false);
  const [preEmailSend, setPreEmailSend] = React.useState(
    props.tableData.sendEmailOffer
  );
  const [prePaymentList, setPrePaymentList] = React.useState(
    props.patientDashboard.paymentList
  );
  const [loanOffers, setLoanOffers] = React.useState({});
  const [preOfferDetails, setPreOfferDetails] = React.useState(
    props.patientDashboard.offetDetailsPatient
  );
  const [emailType, setEmailType] = React.useState(false);
  const [setUpMonthlyModal, setSetUpMonthlyModal] = React.useState(false);
  const [prePrintOfferData, setPrePrintOfferData] = React.useState(
    props.tableData.printOfferData
  );
  const [pageTypePrint, setPageTypePrint] = React.useState(false);
  const [defaultData, setDefaultData] = React.useState(false);
  const [values, setValues] = React.useState({
    downPayment:
      props.tableData.genrateOfferData.data[0].Monthly[0].downPayment,
    monthlyPayment:
      props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment,
    terms: props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth,
    startDate: props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
    maturityDate:
      props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate,
    apr: props.tableData.genrateOfferData.data[0].Monthly[0].APR,
    dailyIntrest:
      props.tableData.genrateOfferData.data[0].Monthly[0].dailyInterestCharge,
  });

  const handleOnchange = (value, type) => {
    const allState = { ...values };
    if (type === "startDate") {
      allState.maturityDate = moment(value)
        .add(allState.terms, "M")
        .format("MM/DD/yyyy");
    }
    allState[type] = value;
    setValues(allState);
  };

  const action = (key) => (
    <>
      <Button
        onClick={() => {
          sendEmailSuccess();
          closeSnackbar(key);
        }}
      >
        'Dismiss'
      </Button>
    </>
  );

  const handleDownOnBlur = () => {
    if (
      props.tableData.genrateOfferData.data[0].Monthly[0].downPayment !==
      values.downPayment
    ) {
      let data;
      if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
        data = {
          amount:
            props.tableData.genrateOfferData.data[0].Discounted[0]
              .amountFinanced,
          terms: values.terms,
          downPayment: values.downPayment,
          monthlyPayment: values.monthlyPayment,
          calculationOn: "term",
        };
      } else {
        data = {
          amount:
            props.tableData.genrateOfferData.data[0].Discounted[0]
              .amountFinanced,
          terms: values.terms,
          offerId:
            props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
          downPayment: values.downPayment,
          monthlyPayment: values.monthlyPayment,
          calculationOn: "term",
        };
      }
      props.getGenrateOfferData(data);
    }
  };

  const handleDownOnMonth = () => {
    if (
      props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment !==
      values.monthlyPayment
    ) {
      let data;
      if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
        data = {
          amount:
            props.tableData.genrateOfferData.data[0].Discounted[0]
              .amountFinanced,
          terms: values.terms,
          downPayment: values.downPayment,
          monthlyPayment: values.monthlyPayment,
          calculationOn: "monthly",
        };
      } else {
        data = {
          amount:
            props.tableData.genrateOfferData.data[0].Discounted[0]
              .amountFinanced,
          terms: values.terms,
          offerId:
            props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
          downPayment: values.downPayment,
          monthlyPayment: values.monthlyPayment,
          calculationOn: "monthly",
        };
      }
      props.getGenrateOfferData(data);
    }
  };

  const handleDownOnTerm = () => {
    if (
      props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth !==
      values.terms
    ) {
      let data;
      if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
        data = {
          amount:
            props.tableData.genrateOfferData.data[0].Discounted[0]
              .amountFinanced,
          terms: values.terms,
          downPayment: values.downPayment,
          monthlyPayment: values.monthlyPayment,
          calculationOn: "monthly",
        };
      } else {
        data = {
          amount:
            props.tableData.genrateOfferData.data[0].Discounted[0]
              .amountFinanced,
          terms: values.terms,
          offerId:
            props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
          downPayment: values.downPayment,
          monthlyPayment: values.monthlyPayment,
          calculationOn: "term",
        };
      }
      props.getGenrateOfferData(data);
    }
  };

  React.useEffect(() => {
    if (preGenrateOffer !== props.tableData.genrateOfferData) {
      setPreGenrateOffer(props.tableData.genrateOfferData);
      if (
        props.tableData.genrateOfferData &&
        props.tableData.genrateOfferData.data
      ) {
        setValues({
          downPayment:
            props.tableData.genrateOfferData.data[0].Monthly[0].downPayment,
          monthlyPayment:
            props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment,
          terms: props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth,
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          maturityDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate,
          apr: props.tableData.genrateOfferData.data[0].Monthly[0].APR,
          dailyIntrest:
            props.tableData.genrateOfferData.data[0].Monthly[0]
              .dailyInterestCharge,
        });
      }
    }
    if (
      prePaymentDetails !== props.tableData.paymentDetailsData &&
      !props.tableData.printOfferData
    ) {
      setPrePaymentsDetails(props.tableData.paymentDetailsData);
      if (props.tableData.paymentDetailsData) {
        props.state();
        props.errorReset();
        props.history.push("/tpo/payments");
      }
    }
    if (prePaymentList !== props.patientDashboard.paymentList) {
      setPrePaymentList(props.patientDashboard.paymentList);
      if (
        props.patientDashboard.paymentList &&
        props.patientDashboard.paymentList.status &&
        props.patientDashboard.offetDetailsPatient &&
        props.patientDashboard.offetDetailsPatient.status
      ) {
        props.history.push("/patient/patientPaymentDetails");
      }
    }
    if (preError !== props.patientDashboard.error) {
      setPreError(props.patientDashboard.error);
      const amount =
        props.tableData.createOffer.data.payType === 1
          ? Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].finalPayAmount
                .toString()
                .replace("$", "")
            )
          : Number(
              props.tableData.genrateOfferData.data[0].Monthly[0].downPayment
                .toString()
                .replace("$", "")
            );
      const data = {
        patientId: showOfferDEtails.data.Patient_id,
        offerId: props.tableData.createOffer.data.OfferId,
        amount,
        paymentId: "",
      };
      props.patientPaymentDetailsCall(data);
    }
    if (preOfferDetails !== props.patientDashboard.offetDetailsPatient) {
      setPreOfferDetails(props.patientDashboard.offetDetailsPatient);
      if (
        props.patientDashboard.paymentList &&
        props.patientDashboard.paymentList.status &&
        props.patientDashboard.offetDetailsPatient &&
        props.patientDashboard.offetDetailsPatient.status
      ) {
        props.history.push("/patient/patientPaymentDetails");
      }
    }
    if (preSendEmailResult !== props.tableData.createOffer) {
      setPreSendEmailResult(props.tableData.createOffer);
      if (props.tableData.createOffer.status) {
        if (emailType) {
          const printRequest = {
            offerId: props.tableData.createOffer.data.OfferId,
          };
          props.sendEmailApiCall(printRequest);
          setEmailType(false);
        }
        if (
          props.tableData.createOffer.data.payType === 1 ||
          props.tableData.createOffer.data.payType === 2
        ) {
          if (!pageTypePrint && !emailType) {
            const paymentListPatient = {
              patientId: showOfferDEtails.data.Patient_id,
            };
            const offerDetailsdata = {
              offerId: props.tableData.createOffer.data.OfferId,
            };
            props.getPaymentDetailsList(paymentListPatient);
            props.getOfferDetailsPatient(offerDetailsdata);
          } else if (!emailType) {
            const printRequest = {
              patientId: showOfferDEtails.data.Patient_id,
              offerId: props.tableData.createOffer.data.OfferId,
            };
            props.printOfferCall(printRequest);
          }
        }
      }
    }
    if (preEmailSend !== props.tableData.sendEmailOffer) {
      setPreEmailSend(props.tableData.sendEmailOffer);
      if (props.tableData.sendEmailOffer.status) {
        // setSendEmailModal(true);
        enqueueSnackbar(
          "Offer Details are sent successfully to registered email id.",
          {
            variant: "success",
            persist: true,
            action,
          }
        );
      }
    }
    if (prePrintOfferData !== props.tableData.printOfferData) {
      if (props.tableData.printOfferData.status) {
        props.state();
        props.history.push("/admin/printoffer");
      }
      setPrePrintOfferData(props.tableData.printOfferData);
    }
    if (
      prePaymentDetails !== props.tableData.paymentDetailsData &&
      !props.tableData.printOfferData
    ) {
      setPrePaymentsDetails(props.tableData.paymentDetailsData);
      if (props.tableData.paymentDetailsData) {
        props.state();
        props.history.push("/tpo/payments");
      }
    }
    if (
      prePaymentDetails !== props.tableData.paymentDetailsData &&
      props.tableData.printOfferData &&
      !emailType
    ) {
      setPrePaymentsDetails(props.tableData.paymentDetailsData);
      if (props.tableData.paymentDetailsData) {
        props.state();
        props.history.push("/tpo/payments");
      }
    }
    if (preSetUpMonthlyPayment !== props.tableData.setUpMonthlyPayment) {
      setPreSetUpMonthlyPayment(props.tableData.setUpMonthlyPayment);
      setSetUpMonthlyModal(true);
    }
  }, [props]);

  const handleSetupMonthlyPayment = () => {
    props.setUpMonthlyOffer(true);
    const amount =
      props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment;
    const paymentListPatient = {
      patientId: showOfferDEtails.data.Patient_id,
    };
    const offerDetailsdata = {
      offerId: props.tableData.createOffer.data.OfferId,
    };
    props.getPaymentDetailsList(paymentListPatient);
    props.getOfferDetailsPatient(offerDetailsdata);
  };

  const sendEmailSuccess = () => {
    props.state();
    props.history.push("/admin/Offers");
    // setSendEmailModal(false);
  };

  const getLoanOfferList = () => {
    const oId = {
      offerId: props.tableData.genrateOfferData.data[0].Monthly[0].offerId,
      patientId: props.tableData.selectPatient.patientId,
      amount:
        props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced,
      clientId: props.tableData.selectPatient.data.Provider_Id,
    };
    props.financeOfferListFetchCall(oId, (data) => {
      setLoanOffers(data);
    });
  };

  const handlePaymentOffer = (type, preValue) => {
    let data = {};
    if (type === 1 || type === 2) {
      setPageTypePrint(false);
      if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
        data = {
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr:
            type === 2
              ? Number(
                  props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
                    "%",
                    ""
                  )
                )
              : Number(
                  props.tableData.genrateOfferData.data[0].Discounted[0].discount
                    .toString()
                    .replace("%", "")
                ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            type === 2
              ? props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate
              : 0,
          termsMonth:
            type === 2
              ? props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth
              : 0,
          perEMIAmt:
            type === 2
              ? Number(
                  props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
                    .toString()
                    .replace("$", "")
                )
              : 0,
          offerStatus: 1,
          downPayment:
            type === 2
              ? Number(values.downPayment.toString().replace("$", ""))
              : 0,
          proposalIds: props.tableData.preposalDataIds,
          offerType: type,
        };
      } else if (
        props.tableData.genrateOfferData.data[0].Discounted[0].offerId
      ) {
        data = {
          offerId:
            props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr:
            type === 2
              ? Number(
                  props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
                    "%",
                    ""
                  )
                )
              : Number(
                  props.tableData.genrateOfferData.data[0].Discounted[0].discount
                    .toString()
                    .replace("%", "")
                ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            type === 2
              ? props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate
              : 0,
          termsMonth:
            type === 2
              ? props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth
              : 0,
          perEMIAmt:
            type === 2
              ? Number(
                  props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
                    .toString()
                    .replace("$", "")
                )
              : 0,
          offerStatus: 1,
          downPayment:
            type === 2
              ? Number(values.downPayment.toString().replace("$", ""))
              : 0,
          proposalIds: props.tableData.preposalDataIds,
          offerType: type,
        };
      }
    } else if (type === 0 && !props.tableData.paymentMakeDetails) {
      if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
        setPageTypePrint(true);
        data = {
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
              "%",
              ""
            )
          ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate,
          termsMonth:
            props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth,
          perEMIAmt: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
              .toString()
              .replace("$", "")
          ),
          offerStatus: 3,
          downPayment: Number(values.downPayment.toString().replace("$", "")),
          proposalIds: props.tableData.preposalDataIds,
          offerType: 2,
        };
        props.createOfferCall(data);
      } else if (
        props.tableData.genrateOfferData.data[0].Discounted[0].offerId
      ) {
        setPageTypePrint(true);
        data = {
          offerId:
            props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
              "%",
              ""
            )
          ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate,
          termsMonth:
            props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth,
          perEMIAmt: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
              .toString()
              .replace("$", "")
          ),
          offerStatus: 3,
          downPayment: Number(values.downPayment.toString().replace("$", "")),
          proposalIds: props.tableData.preposalDataIds,
          offerType: 2,
        };
        props.createOfferCall(data);
      }
    } else if (type === 4) {
      setEmailType(true);
      if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
        data = {
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
              "%",
              ""
            )
          ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate,
          termsMonth:
            props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth,
          perEMIAmt: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
              .toString()
              .replace("$", "")
          ),
          offerStatus: 2,
          downPayment: Number(values.downPayment.toString().replace("$", "")),
          proposalIds: props.tableData.preposalDataIds,
          offerType: 2,
        };
      } else if (
        props.tableData.genrateOfferData.data[0].Discounted[0].offerId
      ) {
        data = {
          offerId:
            props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
              "%",
              ""
            )
          ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate,
          termsMonth:
            props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth,
          perEMIAmt: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
              .toString()
              .replace("$", "")
          ),
          offerStatus: 3,
          downPayment: Number(values.downPayment.toString().replace("$", "")),
          proposalIds: props.tableData.preposalDataIds,
          offerType: 2,
        };
      }
    }

    if (
      type === 0 &&
      props.tableData.paymentMakeDetails &&
      props.tableData.paymentMakeDetails.status &&
      props.tableData.createOffer.data.payType === 2
    ) {
      const printRequest = {
        patientId: preValue
          ? preValue.data.Patient_id
          : showOfferDEtails.data.Patient_id,
        offerId: props.tableData.createOffer.data.OfferId,
      };
      props.printOfferCall(printRequest);
    }
    if (type !== 0) {
      props.createOfferCall(data);
    }
  };

  const handleResetOffer = () => {
    const data = {
      amount: props.tableData.defaultValueOffer.amount,
      terms: props.tableData.defaultValueOffer.terms,
    };
    if (props.tableData.defaultValueOffer.offerId) {
      data.offerId = props.tableData.defaultValueOffer.offerId;
    }
    props.genrateOfferHyperLink(data);
  };

  const handleSetupMonthly = () => {
    const data = {
      offerId,
      apr: Number(
        props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
          "%",
          ""
        )
      ),
      startDate: values.startDate,
      endDate: values.maturityDate,
      termsMonth: values.terms,
      monthlyAmount: Number(values.monthlyPayment.replace("$", "")),
    };
    props.addSetUpMonthlyPayment(data);
  };

  const handleBack = () => {
    const data = {
      commonText: "",
    };
    props.patientSearch(data);
    props.state();
    props.history.push("/admin/offerDetails");
  };

  return (
    <Card>
      <Grid container style={{ padding: "1%", paddingTop: "2%" }}>
        <Grid spacing={2} container xs={9} sm={9} item>
          <Grid
            xs={12}
            sm={12}
            lg={6}
            md={12}
            style={{
              alignSelf: "center",
            }}
            item
          >
            <div className={classes.optionAvailableText}>
              Patient Name: &nbsp;&nbsp;
              {`${showOfferDEtails.data.Patient_Name}`}
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            lg={4}
            md={12}
            style={{
              display: "inline-flex",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
            item
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <div className={classes.optionAvailableText}>TPO Level*:</div>
              <div>
                <div
                  style={{
                    position: "relative",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{
                      color:
                        Math.round(showOfferDEtails.data.tpoLevel) === 0
                          ? "#cfcfcf"
                          : Math.round(showOfferDEtails.data.tpoLevel) === 1
                          ? "#FF0000"
                          : Math.round(showOfferDEtails.data.tpoLevel) === 2
                          ? "#FFA500"
                          : Math.round(showOfferDEtails.data.tpoLevel) === 3
                          ? "#FFFF00"
                          : Math.round(showOfferDEtails.data.tpoLevel) === 4
                          ? "#00FF00"
                          : "#cfcfcf",
                      fontSize: "3rem",
                    }}
                  />
                  <Typography
                    style={{
                      position: "absolute",
                      lineHeight: 1,
                      color: "#000000",
                      fontSize: "1.5rem",
                      fontWeight: 700,
                    }}
                  >
                    {Math.round(showOfferDEtails.data.tpoLevel) === 0
                      ? 0
                      : Math.round(showOfferDEtails.data.tpoLevel) === 1
                      ? 1
                      : Math.round(showOfferDEtails.data.tpoLevel) === 2
                      ? 2
                      : Math.round(showOfferDEtails.data.tpoLevel) === 3
                      ? 3
                      : Math.round(showOfferDEtails.data.tpoLevel) === 4
                      ? 4
                      : ""}
                  </Typography>
                </div>
              </div>
            </div>
            <Typography
              style={{
                fontSize: "12px",
                marginTop: "-10px",
                marginBottom: "16px",
              }}
            >
              * Level calculated using TPO Algorithm 2.0
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <ButtonOriginal
            className={classes.clearButton}
            onClick={() => handleBack()}
          >
            Back
          </ButtonOriginal>
        </Grid>
        <div
          style={{
            marginLeft: "4%",
            marginRight: "4%",
            width: "-webkit-fill-available",
          }}
        >
          <Grid item xs={4} sm={4} className={classes.amountOwed}>
            <Typography style={{ color: "white", padding: "2%" }} variant="h5">
              {`Amount Owed: $${props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced}`}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} />
          <Grid style={{ paddingTop: "2%" }} item xs={7} sm={7}>
            <Typography variant="body1">
              {`${showOfferDEtails.data.Patient_Name}, Following are the Option Curated for you:`}
            </Typography>
          </Grid>
          <Grid style={{ paddingTop: "2%" }} item xs={12}>
            <Paper elevation={1} color="black" variant="outlined">
              <Grid container>
                <Grid item xs={12} className={classes.amountOwed}>
                  <Typography
                    style={{
                      paddingLeft: "1%",
                      fontSize: "1.5rem",
                      color: "white",
                    }}
                  >
                    Pay full amount with a discount.
                  </Typography>
                </Grid>
                <Grid container className={classes.tableData} item xs={12}>
                  <Grid item xs={3}>
                    <Typography>
                      <b>
                        <u>Total Amount</u>
                      </b>
                    </Typography>
                    <Typography>
                      ${" "}
                      {
                        props.tableData.genrateOfferData.data[0].Discounted[0]
                          .amountFinanced
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      <b>
                        <u>Discount Offered</u>
                      </b>
                    </Typography>
                    <Typography>
                      ${" "}
                      {
                        props.tableData.genrateOfferData.data[0].Discounted[0]
                          .discountAmount
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      <b>
                        <u>Discount Rate</u>
                      </b>
                    </Typography>
                    <Typography>
                      {
                        props.tableData.genrateOfferData.data[0].Discounted[0]
                          .discount
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      <b>
                        <u>Net Amount</u>
                      </b>
                    </Typography>
                    <Typography>
                      ${" "}
                      {
                        props.tableData.genrateOfferData.data[0].Discounted[0]
                          .finalPayAmount
                      }
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "10%",
                    paddingBottom: "2%",
                  }}
                  xs={12}
                  item
                >
                  <Button
                    style={{ fontSize: "18px", height: "40px" }}
                    variant="contained"
                    className={classes.buttonStyle}
                    size="sm"
                    onClick={() => handlePaymentOffer(1)}
                  >
                    Accept Discount
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid style={{ paddingTop: "2%" }} item xs={12}>
            <Paper elevation={1} color="black" variant="outlined">
              <Grid container>
                <Grid item xs={12} className={classes.amountOwed}>
                  <Typography
                    style={{
                      paddingLeft: "1%",
                      fontSize: "1.5rem",
                      color: "white",
                    }}
                  >
                    Pay this with a Personal Loan Offer.
                  </Typography>
                </Grid>
                {isEmpty(loanOffers?.data) && (
                  <>
                    <Grid container className={classes.tableData} item xs={12}>
                      <Grid item xs={6}>
                        <Typography>
                          <b>
                            <u>Pay Over Time</u>
                          </b>
                        </Typography>
                        <Typography />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>
                          <b>
                            <u>Total Amount Balance</u>
                          </b>
                        </Typography>
                        <Typography varient="h3">
                          ${" "}
                          {
                            props.tableData.genrateOfferData.data[0].Monthly[0]
                              .fullAmount
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "2%",
                        paddingLeft: "4%",
                        paddingRight: "4%",
                        paddingTop: "1%",
                        textAlign: "center",
                      }}
                      xs={6}
                      item
                    >
                      <Typography>
                        This can help you pay this down in equal monthly
                        installments.
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // paddingRight: "10%",
                        paddingBottom: "2%",
                      }}
                      xs={6}
                      item
                    >
                      <Button
                        style={{ fontSize: "18px", height: "40px" }}
                        variant="contained"
                        className={classes.buttonStyle}
                        size="sm"
                        disabled={loanOffers?.status}
                        onClick={() => {
                          getLoanOfferList();
                          // props.getPersonalOfferData({
                          //   offerId:
                          //     props.tableData.genrateOfferData.data[0].Monthly[0]
                          //       .offerId,
                          // });
                        }}
                      >
                        Show Offers
                      </Button>
                    </Grid>
                  </>
                )}
                {!isEmpty(loanOffers?.data) && (
                  <>
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "1%",
                        paddingLeft: "4%",
                        paddingRight: "4%",
                        paddingTop: "1%",
                        textAlign: "center",
                      }}
                      xs={12}
                      item
                    >
                      <Typography variant="h5">
                        <b> Loan offers for simona Morasca </b>
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "2%",
                        paddingLeft: "4%",
                        paddingRight: "4%",

                        textAlign: "center",
                      }}
                      xs={12}
                      item
                    >
                      <Typography>
                        Your TPO score of 3 may help you save on interest with a
                        personal loan, which can have lower APR then credit
                        cards
                      </Typography>
                    </Grid>

                    {loanOffers.data.map((loan, i) => (
                      <Grid
                        key={i}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingLeft: "3%",
                          paddingBottom: "3%",
                          paddingRight: "3%",
                        }}
                        item
                        xs={12}
                      >
                        <Paper
                          style={{
                            width: "100%",
                            border: "2px solid rgba(0, 0, 0, 0.12)",
                          }}
                          elevation={1}
                          color="black"
                          variant="outlined"
                        >
                          <Grid container>
                            <Grid
                              item
                              style={{ display: "flex", alignItems: "center" }}
                              xs={6}
                            >
                              <Typography
                                style={{
                                  paddingLeft: "5%",
                                  paddingTop: "2%",
                                  fontSize: "1.5rem",
                                }}
                              >
                                {loan.APR} % ARP Est*
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                paddingRight: "3%",
                                paddingTop: "1%",
                                display: "flex",
                                flexDirection: "column",
                                alignContent: "space-around",
                                justifyContent: "center",
                                alignItems: "flex-end",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  className={classes.financerLogo}
                                  key={loan.financerLogo}
                                  src={loan.financerLogo}
                                  alt="Current Logo"
                                  loading="eager"
                                />

                                <Typography variant="caption">
                                  {loan.financerName}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              container
                              className={classes.tableData}
                              item
                              xs={12}
                            >
                              <Grid item xs={2}>
                                <Typography>
                                  <b>
                                    {loan.monthlyPayment}
                                    /Mo
                                  </b>
                                </Typography>
                                <Typography variant="caption">
                                  Payment est*
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography>
                                  <b>{loan.termsMonth} Mo</b>
                                </Typography>
                                <Typography variant="caption">
                                  Length*
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography>
                                  <b>
                                    {loan.dailyInterestCharge}
                                    /Day
                                  </b>
                                </Typography>
                                <Typography variant="caption">
                                  Interest & Fee Est*
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography>
                                  <b>{loan.downPayment}</b>
                                </Typography>
                                <Typography variant="caption">
                                  Down Payment
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography>
                                  <b>{loan.amountFinanced}</b>
                                </Typography>
                                <Typography variant="caption">
                                  Financed Amount
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography>
                                  <b>{loan.fullAmount}</b>
                                </Typography>
                                <Typography variant="caption">
                                  Requested Amount
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingRight: "10%",
                                paddingBottom: "2%",
                              }}
                              xs={12}
                              item
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  style={{ fontSize: "18px", height: "40px" }}
                                  variant="contained"
                                  className={classes.buttonStyle}
                                  size="sm"
                                  onClick={() => {}}
                                >
                                  Accept Offer
                                </Button>
                                <Typography variant="caption">
                                  <Link to={loan.agreement}>
                                    See details, rates and fees
                                  </Link>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>
        </div>
      </Grid>
      {/* <PaymentSection
        handleSendEmail={() => handlePaymentOffer(4)}
        handleSetupMonthlyPayment={() => handleSetupMonthlyPayment()}
        handleDownOnTerm={() => handleDownOnTerm()}
        handleDownOnMonth={() => handleDownOnMonth()}
        handleDownOnBlur={() => handleDownOnBlur()}
        setShowFinalOfferIn={() => handlePaymentOffer(0)}
        handleResetOffer={() => handleResetOffer()}
        {...props}
        values={values}
        handleSetupMonthly={() => handleSetupMonthly()}
        handleOnchange={(v, k) => handleOnchange(v, k)}
        showOfferDEtails={showOfferDEtails}
        handlePaymentOffer={(data) => handlePaymentOffer(data)}
        onClick={() => {}}
      /> */}
      {/* <Modal open={sendEmailModal} onClose={() => setSendEmailModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div className={classes.cardDiv}>
              <img src={gif} width="50%" />
              <div className={classes.successText}>
                Offer Details are sent successfully to registered email id.
              </div>
              <br />
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="sm"
                onClick={() => sendEmailSuccess(false)}
              >
                Ok
              </Button>
            </div>
          </Card>
        </div>
      </Modal> */}
    </Card>
  );
  // <Card><FinalGenrateOffer handleSendEmailSuccess={() => handleSendEmailSuccess()} values={values} {...props} showOfferDEtails={showOfferDEtails} onClick={() => handlePruntBack()} /></Card>
};

NewOfferPayment.propTypes = {
  patientSearch: PropTypes.func,
  tableData: PropTypes.object,
  sendEmailApiCall: PropTypes.object,
  uploadOfferDocument: PropTypes.func,
  updatePatientDetailsOffer: PropTypes.func,
  getGenrateOfferData: PropTypes.func,
  createOfferCall: PropTypes.func,
  printOfferCall: PropTypes.func,
  genrateOfferHyperLink: PropTypes.func,
  deleteDocumentCall: PropTypes.func,
  patientPaymentDetailsCall: PropTypes.func,
  getPatientOfferData: PropTypes.func,
  emptyGraphData: PropTypes.func,
  cptCodeDataCall: PropTypes.func,
  editPatientCall: PropTypes.func,
  getAllActivePlanList: PropTypes.func,
  getPaymentDetailsList: PropTypes.func,
  patientDashboard: PropTypes.object,
  getOfferDetailsPatient: PropTypes.func,
  getAllDocumentList: PropTypes.func,
  offerDownloadFile: PropTypes.func,
  state: PropTypes.func,
  history: PropTypes.object,
  addSetUpMonthlyPayment: PropTypes.func,
  getAllCompletedPlanList: PropTypes.func,
  setUpMonthlyOffer: PropTypes.func,
  setLoadingFalse: PropTypes.func,
  errorReset: PropTypes.func,
  getPersonalOfferData: PropTypes.func,
  financeOfferListFetchCall: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  patientDashboard: app.patientDashboard,
  userProvider: app.users,
});

export default connect(mapStateToProps, {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  getPersonalOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  getOfferDetailsPatient,
  getPatientOfferData,
  getPaymentDetailsList,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  financeOfferListFetchCall,
  errorReset,
})(NewOfferPayment);
