/* eslint-disable import/extensions */
import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  blackColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react";
import bgImage from "../../../img/sidebar-2.jpg";

const sidebarStyle = theme => ({
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "3000",
    overflow: "hidden",
    ...boxShadow,
    backgroundImage: `url(${bgImage})`,
    "&:hover": {
      width: "21%",
      zIndex: "3000",
      overflow: "auto",
    },
    ...transition,
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.up("md")]: {
      width: "6.5%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  slideBar: {
    backgroundImage: `url(${bgImage})`,
  },
  drawerPaperOpen: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "3000",
    ...boxShadow,
    width: "21%",
    "&:hover": {
      width: "21%",
      zIndex: "3000",
      "& $profileName": {
        display: "flex",
        alignItems: "center",
      },
    },
    backgroundImage: `url(${bgImage})`,
    ...transition,
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.up("md")]: {
      width: "21%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  inputBox: {
    margin: "10px",
    marginTop: "20px",
    color: "#000",
  },
  margin: {
    margin: "10px",
  },
  avtarColor: {
    color: "#000",
  },
  profileName: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "0px",
    paddingLeft: "0px",
    [theme.breakpoints.up("md")]: {
      padding: "0px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      paddingLeft: "23px",
    },
    paddingTop: "0px",
    flexDirection: "row",
    alignItems: "center",
    background: "border-box",
    padding: "10px 30px",
    position: "relative",
    color: "#000000",
    zIndex: "4",
  },
  userName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "20px",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "74px",
    },
  },
  hrStyle: {
    position: "relative",
    zIndex: "4",
    width: "80%",
    border: "1px solid #8a8787",
  },
  drawerPaperRTL: {
    [theme.breakpoints.up("md")]: {
      left: "auto !important",
      right: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      left: "0  !important",
      right: "auto !important",
    },
  },
  width: {
    width: "6.5%",
    ...transition,
  },
  scroll: {
    zIndex: "3000",
    color: "black",
    scrollbarColor: "rebeccapurple green",
    scrollbarWidth: "5px",
  },
  logo: {
    position: "relative",
    zIndex: "4000",
    color: theme.palette.primary.contrastText,
    display: "flex",
    alignContent: "space-around",
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: "1%",
    // paddingBottom: "3%",
  },
  smallLogo: {
    position: "relative",
    zIndex: "4000",
    color: theme.palette.primary.contrastText,
    display: "flex",
    alignContent: "space-around",
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: "1%",
  },
  logoImage: {
    width: "54px",
    display: "inline-block",
    zIndex: "4000",
    maxHeight: "30px",
    marginLeft: "10px",
    marginRight: "15px",
  },
  logoWidth: {
    height: "65px",
    // width: "50% !important",
    ...transition,
    display: "block",
  },
  logoMain: {
    height: "48px",
    display: "none",
    ...transition,
    "&:hover": {
      display: "block",
    },
  },
  logoLink: {
    ...defaultFont,
    textTransform: "uppercase",
    padding: "5px 0",
    display: "block",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: whiteColor,
    },
  },
  logoLinkRTL: {
    textAlign: "right",
  },
  itemTextSub: {
    height: "25px",
    width: "25px",
    borderRadius: "50%",
    boxShadow: "0 0 5px gray",
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    margin: "0",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  itemTextSubMessage: {
    ...defaultFont,
    margin: "0",
    lineHeight: "20px",
    fontSize: "14px",
    color: blackColor,
    paddingLeft: "10%",
  },
  img: {
    width: "35px",
    top: "22px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0",
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: "\"\"",
      display: "block",
      background: blackColor,
      opacity: ".8",
    },
  },
  list: {
    marginTop: "20px",
    overflow: "hidden",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset",
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: whiteColor,
    },
  },
  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    padding: "10px 15px",
    height: "20%",
    backgroundColor: "transparent",
    ...defaultFont,
  },
  itemIcon: {
    width: "20px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: `rgba(${hexToRgb(blackColor)}, 0.8)`,
  },
  itemIconRTL: {
    marginRight: "3px",
    marginLeft: "15px",
    float: "right",
  },
  itemText: {
    margin: "0",
    lineHeight: "20px",
    fontSize: "14px",
    color: blackColor,
    paddingLeft: "10%",
  },
  itemTextRTL: {
    textAlign: "right",
  },
  whiteFont: {
    color: blackColor,
  },
  purple: {
    backgroundColor: primaryColor[0],
    ...primaryBoxShadow,
    "&:hover,&:focus": {
      backgroundColor: primaryColor[0],
      ...primaryBoxShadow,
    },
  },
  blue: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
      infoColor[0]
    )},.28), 0 4px 20px 0 rgba(${hexToRgb(
      blackColor
    )},.12), 0 7px 8px -5px rgba(${hexToRgb(infoColor[0])},.2)`,
    "&:hover,&:focus": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        infoColor[0]
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(infoColor[0])},.2)`,
    },
  },
  green: {
    backgroundColor: successColor[0],
    boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
      successColor[0]
    )},.28), 0 4px 20px 0 rgba(${hexToRgb(
      blackColor
    )},.12), 0 7px 8px -5px rgba(${hexToRgb(successColor[0])},.2)`,
    "&:hover,&:focus": {
      backgroundColor: successColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        successColor[0]
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(successColor[0])},.2)`,
    },
  },
  orange: {
    backgroundColor: warningColor[0],
    boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
      warningColor[0]
    )},.28), 0 4px 20px 0 rgba(${hexToRgb(
      blackColor
    )},.12), 0 7px 8px -5px rgba(${hexToRgb(warningColor[0])},.2)`,
    "&:hover,&:focus": {
      backgroundColor: warningColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        warningColor[0]
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(warningColor[0])},.2)`,
    },
  },
  red: {
    backgroundColor: dangerColor[0],
    boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
      dangerColor[0]
    )},.28), 0 4px 20px 0 rgba(${hexToRgb(
      blackColor
    )},.12), 0 7px 8px -5px rgba(${hexToRgb(dangerColor[0])},.2)`,
    "&:hover,&:focus": {
      backgroundColor: dangerColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        dangerColor[0]
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(dangerColor[0])},.2)`,
    },
  },
  sidebarWrapper: {
    position: "relative",
    overflow: "auto",
    overflowY: "auto",
    width: "100%",
    zIndex: "3000",
    overflowScrolling: "touch",
    scrollbarWidth: "5px",
  },
  activePro: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "13px",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
});

export default sidebarStyle;
