const OffersStyle = (theme) => ({
  brandlogo: {
    height: "120px",
    width: "200px",
    backgroundSize: "contain",
    marginLeft: "auto",
    marginRight: "auto",
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 35,
    },
  },
  searchAddButtons: {
    justifyContent: "center",
    marginTop: "2%",
    marginBottom: "2%",
    textAlign: "center",
  },
  patientSearchContainer: {
    justifyContent: "center",
  },
  patientTable: {
    padding: "1%",
  },
});

export default OffersStyle;
