import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import RoleDataList from "../Reports/Roles";
import EditRolePermission from "./EditRolePermission";
import styles from "../../assets/jss/material-dashboard-react/components/businessForm";
import RoleFormManager from "./RoleInformation";
import { changeUserFormMode } from "../../Redux/Actions/UserRegistration";

import {
  updateRolePermisstionRequest,
  changeRoleFormMode,
  emptyRolePermission,
} from "../../Redux/Actions/RoleRegistration";

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      style={{ overflowX: "hidden" }}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "0px" }} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(styles);

const NewRolePage = props => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [roleChip, setRoleChips] = React.useState([]);
  const [preUpdateRole, setUpdateRole] = React.useState(
    props.tableData.updateRolePermission
  );
  const [updateRoleRequest, setUpdateRoleRequest] = React.useState(
    props.tableData.roleDetailsById
      && props.tableData.roleDetailsById.Roles_Data.LeftMenu
  );
  const [preRoleDataById, setPreRoleDataById] = React.useState(props.tableData.roleDetailsById);

  const createRoleRequest = (data, type) => {
    const dataArray = updateRoleRequest;
    dataArray.forEach((v, i) => {
      if (v.Category_Name === type.Category_Name) {
        dataArray[i].Page_Menu.forEach((item, j) => {
          if (item.Page_Name === data.Page_Name) {
            dataArray[i].Page_Menu[j] = data;
          }
        });
      }
    });
    setUpdateRoleRequest(dataArray);
  };

  const handleBack = () => {
    props.changeRoleFormMode("init");
  };

  React.useEffect(() => {
    if (preRoleDataById !== props.tableData.roleDetailsById) {
      setPreRoleDataById(props.tableData.roleDetailsById);
    }
    if (props.tableData.roleDetailsById) {
      setUpdateRoleRequest(
        props.tableData.roleDetailsById
          && props.tableData.roleDetailsById.Roles_Data.LeftMenu
      );
    }
    if (preUpdateRole !== props.tableData.updateRolePermission) {
      setUpdateRole(props.tableData.updateRolePermission);
      enqueueSnackbar(props.tableData.updateRolePermission.message, {
        variant: "success",
      });
    }
  }, [props]);

  React.useEffect(() => () => props.emptyRolePermission(), []);

  const updateRequestSubmit = () => {
    const data = {
      role_Id: props.tableData.roleDetailsById.Roles_Data.Role_id,
      Page_Permissions: updateRoleRequest,
    };
    props.updateRolePermisstionRequest(data);
  };

  return (
    <div className={classes.rootRole}>
      <TabPanel>
        {props.tableData.formMode === "init" && (
          <RoleDataList
            setRoleChips={e => setRoleChips(e)}
            chipValue={roleChip}
            {...props}
          />
        )}
        {props.tableData.formMode === "ManageRole" && (
          <>
            <Grid container style={{ margin: "1%" }}>
              <Grid item xs>
                {`Role: ${
                  props.tableData.roleDetailsById
                  && props.tableData.roleDetailsById.Roles_Data.Role
                }`}
              </Grid>
              <Grid item xs>
                {`Profile: ${
                  props.tableData.roleDetailsById
                  && props.tableData.roleDetailsById.Roles_Data.Profile
                }`}
              </Grid>
            </Grid>
            <Grid container style={{ margin: "1%" }}>
              <Grid item xs>
                {`Description: ${
                  props.tableData.roleDetailsById
                  && props.tableData.roleDetailsById.Roles_Data.Description
                }`}
              </Grid>
            </Grid>
            <Grid container style={{ margin: "1%" }}>
              <Grid item xs>
                Setup Permissions :
              </Grid>
            </Grid>
            {props?.tableData?.roleDetailsById
              && props?.tableData?.roleDetailsById?.Roles_Data.LeftMenu?.map(
                v => (
                  <EditRolePermission
                    details={v}
                    onChangePermission={data => createRoleRequest(data, v)}
                    {...props}
                  />
                )
              )}
            <Grid
              container
              style={{
                margin: "1%",
                display: "flex",
                alignItems: "flex-end",
                marginRight: "50px",
                justifyContent: "flex-end",
              }}>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "30px",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  className={classes.clearButton}
                  onClick={() => handleBack()}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={() => updateRequestSubmit()}
                  className={classes.buttonStyle}
                >
                  Update Role
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {(props.tableData.formMode === "Create"
          || props.tableData.formMode === "Edit") && (
          <RoleFormManager {...props} />
        )}
      </TabPanel>
    </div>
  );
};

NewRolePage.propTypes = {
  tableData: PropTypes.object,
  updateRolePermisstionRequest: PropTypes.func,
  history: PropTypes.object,
  userForm: PropTypes.object,
  changeRoleFormMode: PropTypes.func,
  changeUserFormMode: PropTypes.func,
  emptyRolePermission: PropTypes.func,
};

const mapStateToProps = app => ({
  tableData: app.roles,
  userForm: app.users,
});
export default connect(mapStateToProps, {
  updateRolePermisstionRequest,
  changeRoleFormMode,
  changeUserFormMode,
  emptyRolePermission,
})(NewRolePage);
