import {
  defaultBoxShadow,
  transition
} from "assets/jss/material-dashboard-react";

const styles = (theme) => ({
  rootRole: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  },
  indicatorRole: {
    color: theme.palette.primary.main
  },
  tittleColor: {
    backgroundColor: theme.palette.tableColor.header,
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    height: "2rem"
  },
  usertittleBGColor: {
    backgroundColor: "#f5f5f0",
    color: "#000052",
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    height: "2rem"
  },
  userTitleTextColor: {
    color: "#000052",
    fontSize: "18px !important",
    fontStyle: "Helvetica, Arial,sans-serif !important"
  },
  tittleColorList: {
    backgroundColor: theme.palette.tableColor.header,
    display: "flex",
    justifyContent: "space-between",
    paddingTop: ".7%",
    height: "2rem"
  },
  tittleColorCheck: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: ".7%",
    height: "2.5rem"
  },
  dropFileStyle: {
    minHeight: "0px",
    "@global": {
      ".MuiDropzoneArea-icon": {
        color: theme.palette.primary.main,
        width: "40px"
      }
    }
  },
  dropzoneParagraphClasses: {
    fontSize: "14px",
    color: theme.palette.primary.dark,
    "&::before": {
      content: "hello"
    }
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  tittleTextColor: {
    color: theme.palette.tableColor.cellFontColor,
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  tittleTextColorBlack: {
    color: "black",
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  tittleTextColorList: {
    color: theme.palette.tableColor.cellFontColor,
    fontSize: "14px",
    paddingLeft: "1rem",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  tittleTextColorAtteched: {
    color: "black",
    fontSize: "14px",
    padding: "1rem",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  attechmentSection: {
    color: "black",
    backgroundColor: "#D3D3D3",
    fontSize: "10px",
    padding: "1rem",
    margin: "1rem",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  margin: {
    margin: "1%",
    marginTop: "0px",
    marginBottom: "0px"
  },
  marginConfigs: {
    margin: "1% 1% 0.5% 1% "
  },
  marginCheckList: {
    margin: "2%",
    marginBottom: "0px",
    borderColor: "black"
  },
  textColor: {
    color: theme.palette.primary.dark,
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  fab: {
    margin: 0,
    left: "97.5%",
    top: 0,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    width: "3%",
    bottom: "0%",
    position: "fixed"
  },
  fabCheckList: {
    margin: 0,
    overflow: "auto",
    overflowX: "hidden",
    zIndex: 1150,
    borderRadius: "1%",
    border: "outset",
    minHeight: "100%",
    maxHeight: "100vh",
    height: "fit-content",
    left: "58.2%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
    width: "42%",
    bottom: "0%",
    position: "fixed"
  },
  checkListStatusPending: {
    backgroundColor: "#c10038",
    color: "#fff",
    padding: "3px 22px"
  },
  checkListStatusInProgress: {
    backgroundColor: "#f5d16e",
    color: "#fff",
    padding: "3px 22px"
  },
  checkListStatusComplete: {
    backgroundColor: "#0abf28",
    color: "#fff",
    padding: "3px 22px"
  },
  modalDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "stretch"
  },
  modalDivCheckList: {
    display: "flex",
    marginRight: "1rem",
    flexDirection: "row",
    width: "100%",
    flexWrap: "nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
    alignContent: "stretch"
  },
  input: {
    width: "80%"
  },
  cardWidth: {
    width: "100%"
  },
  iconBox: {
    color: theme.palette.primary.contrastText
  },
  tncBox: {
    height: "40px",
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    borderColor: "black"
  },
  buttonBox: {
    display: "flex",
    // width: "100%",
    justifyContent: "space-between",
    alighItem: "center",
    margin: "2rem"
  },
  rootStatus: {
    width: "40%"
  },
  rootStatusNew: {
    width: "100%"
  },
  buttonBoxPricing: {
    display: "flex",
    // width: "100%",
    justifyContent: "flex-end",
    alighItem: "center",
    margin: "0.5rem"
  },
  optionAvailableText: {
    display: "flex",
    flexDirection: "row",
    margin: "1%",
    fontSize: "20px",
    whiteSpace: "nowrap"
  },
  tpoLevelMargin: {
    marginTop: "-3%",
    marginLeft: "1%"
  },
  ownerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  dialogBoxDropDown: {
    width: "auto",
    overflow: "hidden",
    marginRight: "2%",
    marginLeft: "2%",
    fontSize: "14px",
    boxSizing: "border-box",
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "200",
    lineHeight: "1.5",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },
  successText: {
    color: "gray",
    fontSize: "14px",
    whiteSpace: "normal",
    width: "92% !important"
  },
  statusContainer: {
    justifyContent: "space-between",
    marginBottom: "20px"
  },
  marginStatusItem: {
    marginRight: "2%"
  },
  ownerTittle: {
    marginTop: ".5rem"
  },
  amountOwed: { backgroundColor: theme.palette.primary.main },
  tableData: {
    paddingLeft: "4%",
    paddingRight: "4%",
    paddingTop: "4%",
    paddingBottom: "2%",
    textAlign: "center"
  },
  financerLogo: { objectFit: "contain", maxHeight: "100px", maxWidth: "100px" },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    height: "30px",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  buttonStyleError: {
    backgroundColor: theme.palette.error.main,
    height: "30px",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.main
    }
  },
  resetButtonStyle: {
    backgroundColor: "unset",
    borderRadius: "10px",
    height: "30px",
    padding: "20px"
  },
  verticalButtonStyle: {
    wordBreak: "break-all",
    width: "1em",
    height: "auto",
    minWidth: "40px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  clearButton: {
    backgroundColor: theme.palette.primary.dark,
    height: "30px",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  },
  inputBox: {
    margin: "10px",
    marginTop: "20px",
    color: theme.palette.primary.light
  },
  statusHeadingText: {
    textAlign: "center",
    width: "90%",
    fontSize: "16px"
  },
  offerPageMargin: {
    margin: "3%"
  },
  canvasBackgourd: {
    backgroundColor: theme.palette.primary.light
  },
  statusModalHeader: {
    display: "flex",
    height: "50px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
    padding: "1%"
  },
  activeInputLableColor: {
    fontSize: "14px",
    fontFamily: "sans-serif",
    "&.focused": {
      color: theme.palette.primary.main
    }
  },
  activeInputLableColorStatus: {
    fontSize: "14px",
    fontWeight: "900",
    fontFamily: "sans-serif",
    "&.focused": {
      color: theme.palette.primary.main
    }
  },
  selectRow: {
    "&.MuiTableRow-root.Mui-selected": {
      background: theme.palette.info.dark
    }
  },
  activeInputLableBestOffer: {
    width: "130%",
    fontSize: "130%",
    "&.focused": {
      color: theme.palette.primary.main
    }
  },
  root: {
    width: "80%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  rootDocumentSelect: {
    width: "240px",
    marginRight: "30px",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  rootSelectInput: {
    width: "80%",
    marginBottom: "10%",
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif"
  },
  textFieldWidth: {
    width: "100px"
  },
  textFieldInput: {
    width: "300px",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  resize: {
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark
  },
  resizeTextbar: {
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark,
    padding: "8px",
    width: "fit-content",
    minWidth: "300px"
  },
  resizePrice: {
    fontSize: "14px",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark,
    height: "15px",
    padding: "10px",
    fontWeight: "bolder"
  },
  resizeBeestOffer: {
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark
  },
  resizeDownPayment: {
    fontSize: "130%",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark
  },
  checkBox: {
    padding: "0px"
  },
  cardDiv: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    marginBottom: "10%",
    marginTop: "10%",
    overflow: "auto",
    height: "70vh"
  },
  cardDivApprove: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    marginBottom: "10%",
    marginTop: "10%",
    overflow: "auto"
  },
  cardDiv1: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10%",
    marginTop: "10%",
    overflow: "auto",
    textAlign: "center"
  },
  modalCard: {
    width: "40%",
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  modalCardApprove: {
    width: "30%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  modalCardAttechment: {
    width: "38%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  overLayBox: {
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "91%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "32%"
    },
    padding: "1.5%",
    zIndex: 999,
    cursor: "pointer",
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: "1px 1px 2px white, 0 0 25px #d6d0d0, 0 0 5px #737373"
  },
  modalCardList: {
    width: "100%",
    height: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  mobileModalCard: {
    width: "80%",
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  patientModal: {
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "10%",
    paddingRight: "10%"
  },
  modalRoot: {
    width: "40%",
    marginTop: "10%",
    height: "auto",
    padding: "30px",
    paddingTop: "10px",
    alignItems: "center",
    justifyContent: "center"
  },
  fullInput: {
    width: "93.2%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  halfInput: {
    width: "100%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  halfInputSingle: {
    width: "87%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  withoutOption: {
    width: "26.8%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  quaterInput: {
    width: "73%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  quaterInputPricing: {
    width: "50%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  addIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main
    }
  },
  deleteIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.error.main
    }
  },
  dialogWidth: {
    width: "100%"
  },
  calender: {
    zIndex: "10002",
    position: "absolute",
    width: "auto",
    ...defaultBoxShadow,
    ...transition
  },
  rootDialog: {
    width: "100%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Helvetica, Arial,sans-serif"
    }
  },
  patientNameColor: {
    fontSize: "100%",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.main
  },
  widthAuto: {
    width: "auto"
  },
  widthMax: {
    width: "max-content"
  },
  uploadDocumentBox: {
    display: "flex",
    alignItems: "center",
    margin: "10px 31px",
    justifyContent: "center",
    borderRadius: 10,
    backgroundColor: "#eeeeee",
    padding: "10px",
    width: "auto"
  },
  uploadDocumentBoxRequired: {
    margin: "10px 31px",
    justifyontent: "center",
    padding: "10px",
    borderRadius: 10,
    backgroundColor: "#eeeeee",
    width: "auto"
  },
  signOnModal: {
    width: "900px",
    height: "600px",
    position: "fixed !important",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%,-50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%"
    }
  }
});

export default styles;
