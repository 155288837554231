/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import TableBody from "@material-ui/core/TableBody";
import isEmpty from "lodash/isEmpty";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import noDataImage from "../../assets/img/noDataImage.png";
import { totalOutStanding } from "../../Redux/Actions/TableAction";
import { SimpleDialog } from "../../components/DialogBox/Defaults";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";

const useStyles = makeStyles(tableStyle);

function createData(
  providerId,
  date,
  ehrId,
  patientName,
  defaultDate,
  loanId,
  balance,
  status
) {
  return {
    providerId,
    date,
    ehrId,
    patientName,
    defaultDate,
    loanId,
    balance,
    status,
  };
}

// const rows = [];

// totalOutStandingData.Total_Out_Statnding_Data.forEach((v, i) => {
//     const date = v.Date;
//     const patientName = v.Patient_Name;
//     const ehrId = v.Loan_ID;
//     const defaultDate = v.Default_Date;
//     const loanId = v.Loan_id;
//     const balance = v.Balance;
//     const status = v.Status;
//     rows.push(createData(date, ehrId, patientName, defaultDate, loanId, balance, status));
// } );

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    count, page, rowsPerPage, onChangePage,
  } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography>First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography>Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography>Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography>Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TotalOutstanding = props => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [initialCall, setInitialCall] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (!props.tableData.totalOutStanding) {
      props.totalOutStanding();
    }
  }, []);

  const rows = [];
  if (props.tableData.totalOutStanding) {
    props.tableData.totalOutStanding.Total_Out_Statnding_Data.forEach(
      (v, i) => {
        const providerId = v.Provider_Id;
        const date = v.Date;
        const patientName = v.Patient_Name;
        const ehrId = v.Loan_ID;
        const defaultDate = v.Default_Date;
        const loanId = v.Loan_id;
        const balance = v.Balance;
        const status = v.Status;
        rows.push(
          createData(
            providerId,
            date,
            ehrId,
            patientName,
            defaultDate,
            loanId,
            balance,
            status
          )
        );
      }
    );
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    if (props.tableData.totalOutStanding?.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData]);

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && <Grid container className={classes.searchContainer}>
          <Grid item className={classes.margin}>
            <div className={classes.searchWrapper}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "35%" }}>
                  <TextField
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    inputProps={{
                      autoComplete: "off",
                    }}
                    fullWidth
                    label="Search"
                    onClick={() => handleClickOpen()}
                    name="Search"
                    id="Search"
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog close={() => handleClose()} />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <Button
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </Button>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="contained"
                className={classes.ExportButtonStyle}
              >
                Export
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleCloseMenu}>As Pdf</MenuItem>
                <MenuItem onClick={handleCloseMenu}>My account</MenuItem>
              </Menu>
            </div>
          </Grid>
        </Grid>}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "85%" }}>
                  <TextField
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    inputProps={{
                      autoComplete: "off",
                    }}
                    fullWidth
                    label="Search"
                    onClick={() => handleClickOpen()}
                    name="Search"
                    id="Search"
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog close={() => handleClose()} />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <Button
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </Button>
            </Grid>
            <Grid item className={classes.expirtItemGrid}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
              >
                Export
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleCloseMenu}>As Pdf</MenuItem>
                <MenuItem onClick={handleCloseMenu}>My account</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        )}
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell
                className={classes.cellTextHeaderOffer}
                style={{ width: "10%" }}
              >
                Date
              </TableCell>
              <TableCell
                className={classes.cellTextHeaderOffer}
                style={{ width: "20%" }}
                align="center"
              >
                Provider Code
              </TableCell>
              <TableCell
                className={classes.cellTextHeaderOffer}
                style={{ width: "20%" }}
                align="center"
              >
                EHR ID
              </TableCell>
              <TableCell
                className={classes.cellTextHeaderOffer}
                style={{ width: "20%" }}
                align="center"
              >
                Patient Name
              </TableCell>
              <TableCell
                className={classes.cellTextHeaderOffer}
                style={{ width: "20%" }}
                align="center"
              >
                Balance
              </TableCell>
              <TableCell
                className={classes.cellTextHeaderOffer}
                style={{ width: "10%" }}
                align="center"
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!isEmpty(rows) && initialCall)
            || (isEmpty(rows) && !initialCall) ? (
                (rowsPerPage > 0
                  ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  : rows
                ).map(row => (
                  <TableRow>
                    <TableCell className={classes.cellText}>{row.date}</TableCell>
                    <TableCell className={classes.cellText} align="center">
                      {row.providerId}
                    </TableCell>
                    <TableCell className={classes.cellText} align="center">
                      {row.ehrId}
                    </TableCell>
                    <TableCell
                      className={classes.patientNameColor}
                      align="center"
                    >
                      {row.patientName}
                    </TableCell>
                    <TableCell className={classes.cellText} align="center">
                      {row.balance}
                    </TableCell>
                    <TableCell className={classes.cellText} align="center">
                      {row.status}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={12}>
                    <img
                      src={noDataImage}
                      alt=""
                      className={classes.noDataImage}
                    />
                  </TableCell>
                </TableRow>
              )}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={12}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { placeholder: "Show" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

TotalOutstanding.propTypes = {
  totalOutStanding: PropTypes.func,
  tableData: PropTypes.any,
};

const mapStateToProps = table => ({
  tableData: table.table,
});
export default connect(mapStateToProps, {
  totalOutStanding,
})(TotalOutstanding);
