import { post, put, get } from "./httpClient";
import { GET_SIGNNOW_CALL, GET_DOC_STATUS } from "../Uintls/Urls";

export const getSignNowCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(GET_SIGNNOW_CALL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getDocStatus =
  (providerId, successCallback, failureCallback) => async () => {
    try {
      const data = await get(`${GET_DOC_STATUS}/${providerId}`);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
