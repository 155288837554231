import "./style.scss";
import "../../../assets/css/CountryCode.scss";

import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";

// import Email from "@material-ui/icons/Email";
import { connect } from "react-redux";
import { ErrorMessage } from "formik";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useTheme } from "@material-ui/core/styles";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useSnackbar } from "notistack";
import React from "react";
import isEmpty from "lodash/isEmpty";
import { getCountryList } from "../../../actions/login.action";
import { confirmForgetPassword } from "../../../actions/Operations";
import useStyles from "./Style";

function ResetPassword(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const emailRegEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [countryCode, setCountryCode] = React.useState([]);
  const validationSchema = yup.object({
    // email: yup
    //   .string("Enter your email")
    //   .email("Enter a valid email")
    //   .required("Email is required"),
    email: yup
      .string("Please enter a valid Email/Mobile number.")
      .required("Email/Mobile number is required.")
      .test(
        "id",
        "Please enter a valid Email/Mobile number.",
        (value) =>
          !value ||
          emailRegEx.test(value) ||
          /^(\+\d{1,3}[- ]?)?\d{10}$/.test(parseInt(value))
      )
      .trim(),
  });

  const onSubmit = (values) => {
    const payload = { email: values.email };
    if (/^\d+$/.test(values.email)) {
      payload.countryCode = values.countryCode;
    }
    props.confirmForgetPassword(
      payload,
      (data) => {
        if (data.status) {
          enqueueSnackbar(data.message, {
            variant: "success",
          });
        } else {
          data.errors.map((x) => {
            if (x.location === "body") {
              formik.setFieldError(x.param, x.msg);
            } else {
              enqueueSnackbar(x.msg, {
                variant: "error",
              });
            }
          });
        }
      },
      (err) => {
        // console.log(err.response);
        // err.map((x) => {
        //   if (x.location === "body") {
        //     formik.setFieldError(x.param, x.msg);
        //   } else {
        //     enqueueSnackbar(x.msg, {
        //       variant: "error",
        //     });
        //   }
        // });
        enqueueSnackbar("Request could not be completed", {
          variant: "error",
        });
      }
    );
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      countryCode: 1,
    },
    validationSchema,
    onSubmit,
  });

  React.useEffect(() => {
    if (isEmpty(countryCode)) {
      props.getCountryList((data) => {
        setCountryCode(data.Country_Data);
      });
    }
  }, []);

  return (
    <div className="dashboardTiles">
      <Container component="main" className={classes.rootNew}>
        <CssBaseline />
        <Paper
          className={!windowWidth ? classes.paperNewM : classes.paperNew}
          elevation={5}
        >
          <Box
            height="100%"
            p={{
              xs: 3,
              sm: 4,
              md: 5,
              xl: 6,
            }}
            display={{ xs: "none", sm: "none", md: "flex" }}
            className={`${classes.panelWidth} auth-left-panel`}
          >
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <div className={classes.brandLogo}>
                <img
                  className="imgf"
                  src={
                    props.app.defaultTheme &&
                    props.app.defaultTheme.data[0].Logo_Image
                  }
                  alt="appName"
                />
              </div>
              <Box mt={2} className={classes.brandTag}>
                <Typography component="h6" variant="subtitle1" align="center" />
              </Box>
            </Box>
          </Box>
          <Box
            display={{ xs: "flex", sm: "flex", md: "none" }}
            className="mobileHeader"
            container
            justify="center"
          >
            <div className={classes.brandLogo}>
              <img
                className="imgm"
                src={
                  props.app.defaultTheme &&
                  props.app.defaultTheme.data[0].Logo_Image
                }
                alt="appName"
              />
            </div>
          </Box>
          <Box
            height="100%"
            pt={4}
            pb={4}
            pl={{
              xs: 4,
              sm: 6,
              md: 2,
              xl: 8,
            }}
            pr={{
              xs: 4,
              sm: 6,
              md: 2,
              xl: 8,
            }}
            display="flex"
            alignItems="center"
            className={
              !windowWidth
                ? `${classes.panelWidth} auth-right-panelM`
                : `${classes.panelWidth} auth-right-panel`
            }
          >
            <div className={classes.authInnerForm}>
              <Typography component="p" variant="subtitle2">
                <strong>Forgot Password</strong>
              </Typography>
              <Typography
                style={{ marginTop: "10px", color: "#4856c1" }}
                component="p"
                variant="subtitle2"
              >
                <b>Client ID</b>
                &nbsp; H001
              </Typography>

              <Typography
                style={{ marginBottom: "15px", marginTop: "40px" }}
                component="p"
                variant="caption"
                gutterBottom
              >
                Enter your email address / phone number to receive a password
                reset link.
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <div className={classes.form}>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    className={classes.spaceTop}
                  >
                    <TextField
                      as={TextField}
                      labelText="Email..."
                      id="email"
                      name="email"
                      required
                      fullWidth
                      // style={{ maxWidth: "550px" }}
                      label="Enter Email/Mobile Number"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.email)}
                      // helperText={formik.touched.email && formik.errors.email}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      InputProps={
                        /^\d+$/.test(formik.values.email) && {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Select
                                id="country-menu"
                                value={formik.values.countryCode}
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "countryCode",
                                    event.target.value
                                  );
                                }}
                                classes={{ root: "country-list" }}
                                className="no-underline"
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                {countryCode.map((country) => (
                                  <MenuItem
                                    value={country.Dial_Code}
                                    option={countryCode}
                                    key={country.Country_Code}
                                    className="country"
                                  >
                                    <span
                                      className={`country-flag ${country.Country_Code.toLowerCase().substring(
                                        0,
                                        2
                                      )}`}
                                    />
                                    &nbsp;
                                    <span className="dial-code">{`+${country.Dial_Code}`}</span>
                                    &nbsp;
                                    <span className="country-name">
                                      ({country.Country_Name})
                                    </span>
                                  </MenuItem>
                                ))}
                              </Select>
                            </InputAdornment>
                          ),
                        }
                      }
                    />

                    <Typography
                      component="span"
                      className="error"
                      variant="body2"
                    >
                      {formik.errors.email}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    style={{ marginTop: "20px" }}
                    className={classes.spaceTop}
                  >
                    <Grid sm={10} xs={10} item>
                      <Button
                        size="large"
                        fullWidth
                        style={{ marginBottom: "10px" }}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!isEmpty(formik.errors) || !formik.dirty}
                        className={classes.submit}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    className={classes.spaceTop}
                  >
                    <Grid item>
                      <Button onClick={() => history.push("/")} color="primary">
                        <b>Return To Login</b>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </div>
          </Box>
        </Paper>
      </Container>
    </div>
  );
  // }
}

ResetPassword.propTypes = {
  confirmForgetPassword: PropTypes.func,
  getCountryList: PropTypes.func,
  app: PropTypes.object,
};

const mapStateToProps = ({ app }) => ({
  userData: app.userDetails,
  app,
});

export default connect(mapStateToProps, {
  confirmForgetPassword,
  getCountryList,
})(ResetPassword);
