import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";

import { useStyles } from "./data";

function MultipleSelect(props) {
  const classes = useStyles();
  const [selected, setSelected] = useState(props.formData.userDetailsById && props.formData.userDetailsById.Users_Data[0].groupId.length ? props.formData.userDetailsById.Users_Data[0].groupId : []);

  const handleChange = event => {
    let value = selected;
    if (selected.length > 0) {
      if (selected.filter(key => key === event).length > 0) {
        value = selected.filter(key => key !== event);
      } else {
        value.push(event);
      }
    } else {
      value.push(event);
    }
    setSelected(value);
    console.log(value);
    props.onChange(value);
  };

  return (
    <FormControl className={classes.formControl}>
      <TableContainer>
        <Table
          className={classes.patientTable}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          style={{ border: "1px solid" }}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.cellTextHeader}
              />
              <TableCell
                className={classes.cellTextHeader}
              >
                Group Name
              </TableCell>
              <TableCell
                className={classes.cellTextHeader}
              >
                MID Count
              </TableCell>
              <TableCell
                className={classes.cellTextHeader}
              >
                User Count
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.formData.groupListData && props.formData.groupListData.data.map(
              key => (
                <TableRow
                  hover
                  role="checkbox"
                  key={Math.random()}
                >
                  <TableCell
                    className={classes.cellText}
                    align="left"
                  >
                    <Checkbox checked={selected.filter(item => item === key._id).length > 0} onChange={() => handleChange(key._id)} />
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                  >
                    {key.groupName}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                  >
                    {key.totalMid}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                  >
                    {key.groupUser.length}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
}

MultipleSelect.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
};

export default MultipleSelect;
