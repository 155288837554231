import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import isEmpty from "lodash/isEmpty";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle";
import { iconArray } from "../../constant/IconArray/Isons";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Sidebar(props, sidebarDataProps) {
  const theme = useTheme();
  const location = useLocation();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [collebleState, setCollebleState] = React.useState("");
  const [logoPath, setLogoPath] = React.useState("");
  const classes = useStyles();

  let name = "";
  if (location.pathname.includes("admin")) {
    name =
      props.userRole.userList &&
      props.userRole.userList.Role_Permission_Data &&
      props.userRole.userList.Role_Permission_Data.UserName;
  } else if (location.pathname.includes("client")) {
    name = "Client";
  } else if (location.pathname.includes("cfo")) {
    name = "CFO";
  } else if (location.pathname.includes("frontDesk")) {
    name = "Front Desk";
  } else if (location.pathname.includes("patient")) {
    name =
      props.userRole.userList &&
      props.userRole.userList.Role_Permission_Data &&
      props.userRole.userList.Role_Permission_Data.UserName;
  }

  const handleOnClick = (item) => {
    if (collebleState === item) {
      setCollebleState("");
    } else {
      setCollebleState(item);
    }
  };

  const changeReportRoute = (name, displayName, Category_Name = "") => {
    props.setState();
    if (name !== "WooCommerce") {
      if (name.includes("Users")) {
        props.changeUserFormMode("init");
        props.changeRoleFormMode("init");
        // props.getUserList();
      }
      const barName = Category_Name
        ? `${Category_Name} / ${displayName}`
        : displayName;
      props.setAppBarName(barName);
      props.emptyPatientSelect();
      props.setUserSearchEmpty();
      props.history.push(
        `/${props.userDetails.layout}/${name.replace(/\s/g, "")}`
      );
      if (!windowWidth) {
        props.setopen();
      }
    } else {
      window.open("https://woo.coinsuites.com/", "_blank");
    }
  };
  const { color, image } = props;
  let listItemClasses;
  listItemClasses = classNames({
    [` ${classes[color]}`]: true
  });
  const whiteFontClasses = classNames({
    [` ${classes.whiteFont}`]: true
  });
  const sidebarData = JSON.parse(localStorage.getItem("sidebar"));

  const links = (
    <List className={classes.list}>
      {sidebarData &&
        sidebarData.LeftMenu.map((v, i) => {
          if (v.Permission) {
            const icon = iconArray?.filter(
              (iconItem) => iconItem?.name === v?.Menu_Display_Name
            );
            const IconShow = icon && icon[0]?.icon;
            return (
              <>
                <ListItem
                  key={i}
                  button
                  className={`${
                    props.userDetails.appBarName === v.Menu_Display_Name
                      ? classes.itemLink + listItemClasses
                      : classes.itemLink
                  }`}
                  onClick={() =>
                    v.Page_Menu
                      ? handleOnClick(i)
                      : changeReportRoute(
                          v.Menu_Display_Name,
                          v.Menu_Display_Name
                        )
                  }
                >
                  <IconShow
                    className={classNames(classes?.itemIcon, whiteFontClasses)}
                  />
                  {(props?.open || props?.hoverDrawer) && (
                    <ListItemText
                      className={classNames(
                        classes?.itemText,
                        whiteFontClasses
                      )}
                      primary={v?.Menu_Display_Name}
                      disableTypography
                    />
                  )}
                  {v?.Page_Menu &&
                    (props?.open || props?.hoverDrawer) &&
                    (collebleState === i ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    ))}
                </ListItem>
                <Collapse in={collebleState === i} timeout="auto" unmountOnExit>
                  {v?.IsPageMenu &&
                    v?.Page_Menu?.map((item, j) => {
                      if (item?.Permission) {
                        return (
                          <ListItem
                            key={j}
                            button
                            style={{ height: "auto" }}
                            className={`${
                              props?.userDetails.appBarName ===
                              item?.Menu_Display_Name
                                ? classes?.itemLink + listItemClasses
                                : classes?.itemLink
                            }`}
                            onClick={() =>
                              changeReportRoute(
                                item?.Page_Name,
                                item?.Menu_Display_Name,
                                v?.Category_Name
                              )
                            }
                          >
                            <div className={classes.itemTextSub}>
                              {item?.Page_Name?.match(/\b(\w)/g)?.join("")}
                            </div>
                            {(props.open || props.hoverDrawer) && (
                              <ListItemText
                                className={classNames(
                                  classes.itemTextSubMessage,
                                  whiteFontClasses
                                )}
                                primary={item?.Menu_Display_Name}
                                disableTypography
                              />
                            )}
                          </ListItem>
                        );
                      }
                    })}
                </Collapse>
              </>
            );
          }
        })}
    </List>
  );
  const openDrawerOnHover = () => {
    setTimeout(() => {
      if (!props.hoverDrawer) {
        props.setOpenDrawerHover(true);
      }
    }, 200);
  };
  const closeDrawerOnHover = () => {
    if (props.hoverDrawer) {
      props.setOpenDrawerHover(false);
    }
  };
  if (isEmpty(logoPath)) {
    const logo_path = new Image();
    if (
      props.app.app.portalConfig &&
      props.app.app.portalConfig.data &&
      props.app.app.portalConfig.data.length > 0
    ) {
      const x = props.app.app.portalConfig.data[0]?.Logo_Image;
      logo_path.src = `${x}?" + ${new Date().getTime()}`;
      logo_path.onload = function () {
        setLogoPath(logo_path.src);
      };
      logo_path.onerror = function () {
        const localImage = require("assets/newImg/ThePatientOffer-72.png");
        setLogoPath(localImage);
      };
    }
  }

  const brand = (
    <div className={classes.logo}>
      <img
        src={logoPath || "assets/newImg/ThePatientOffer-72.png"}
        className={classes.logoWidth}
      />
    </div>
  );
  const openBrand = (
    <div className={classes.smallLogo}>
      <img
        src={logoPath || "assets/newImg/ThePatientOffer-72.png"}
        style={{ objectFit: "contain", maxHeight: "65px", maxWidth: "65px" }}
        className={classes.logoWidth}
      />
    </div>
  );

  return (
    <div
      onMouseEnter={() => (windowWidth ? openDrawerOnHover() : {})}
      onMouseLeave={() => (windowWidth ? closeDrawerOnHover() : {})}
    >
      <Drawer
        PaperProps={{
          sx: { width: "90%" }
        }}
        variant={windowWidth ? "permanent" : "presentation"}
        onClose={() => props.setopen()}
        open={props.open || props.hoverDrawer}
        classes={{
          paper: classNames(
            props.open || props.hoverDrawer
              ? windowWidth
                ? classes.drawerPaperOpen
                : ""
              : classes.drawerPaper
          )
        }}
      >
        {props.open || props.hoverDrawer ? brand : openBrand}
        <hr className={classes.hrStyle} />
        <div className={classes.profileName}>
          <Avatar className={classes.avtarColor} />
          {(props.open || props.hoverDrawer) && (
            <div className={classes.userName}>{name}</div>
          )}
        </div>
        <hr className={classes.hrStyle} />
        <Scrollbars style={{ overflow: "hidden" }}>
          <div>{links}</div>
        </Scrollbars>
      </Drawer>
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  setOpenDrawerHover: PropTypes.func,
  hoverDrawer: PropTypes.bool,
  open: PropTypes.bool,
  setState: PropTypes.func,
  emptyPatientSelect: PropTypes.func,
  setUserSearchEmpty: PropTypes.func,
  setopen: PropTypes.func
};
