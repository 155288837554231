/* eslint-disable no-param-reassign */
import qs from "qs";
import _ from "lodash";
import * as types from "./ActionTypes";
import { post, get, put } from "../Helper/index";
import * as urls from "../../Uintls/Urls";

export const userRegistration = (data) => (dispatch) => {
  dispatch({ type: types.USER_REGISTRATION_CALL });
  post({
    url: urls.USER_REGISTRATION_URL,
    success: types.USER_REGISTRATION_SUCCESS,
    failure: types.USER_REGISTRATION_ERROR,
    dispatch,
    body: {
      userType: data.userType,
      reportsTo: data.reportsTo,
      Profile_Code: data.profileCode,
      First_Name: data.FName,
      Middle_Name: data.MName,
      Last_Name: data.LName,
      Email: data.Email,
      Password: data.Password,
      Role_ID: data.Role,
      Address1: data.address1,
      Address2: data.address2,
      dialCode: data.dialCode,
      City: data.city,
      Mobile: data.mobile,
      Phone_No: data.phonenumber,
      Country_Code: data.country,
      State_Code: data.state,
      Zip: data.zip,
      profileVisibility: data.profileVisibility,
      groupId: data.groupId,
      Time_Zone_Code: data.timezone,
      Accessible_Provider:
        typeof data.accessible === "string"
          ? [data.accessible]
          : data.accessible,
      ClientID: data.ClientID,
      CreateByClientId: localStorage.getItem("clientId")
    }
  });
};

export const ownerTypeList = () => (dispatch) => {
  dispatch({ type: types.OWNER_TYPE_LIST_CALL });
  get({
    url: urls.OWNER_SHIP_LIST_URL,
    success: types.OWNER_TYPE_LIST_SUCCESS,
    failure: types.OWNER_TYPE_LIST_ERROR,
    dispatch
  });
};

export const updateRegistration = (data, id) => (dispatch) => {
  dispatch({ type: types.USER_REGISTRATION_CALL });
  put({
    url: urls.USER_REGISTRATION_URL,
    success: types.USER_REGISTRATION_SUCCESS,
    failure: types.USER_REGISTRATION_ERROR,
    dispatch,
    body: {
      user_Id: id,
      Profile_Code: data.profileCode,
      First_Name: data.FName,
      Middle_Name: data.MName,
      Last_Name: data.LName,
      Email: data.Email,
      reportsTo: data.reportsTo,
      dialCode: data.dialCode,
      // Password: data.Password,
      profileVisibility: data.profileVisibility,
      groupId: data.groupId,
      Role_ID: data.Role,
      Address1: data.address1,
      Address2: data.address2,
      City: data.city,
      Mobile: data.mobile,
      Phone_No: data.phonenumber,
      Country_Code: data.country,
      State_Code: data.state,
      Zip: data.zip,
      Time_Zone_Code: data.timezone
    }
  });
};

export const getUserList = (data) => (dispatch) => {
  dispatch({ type: types.USER_LIST_CALL });
  get({
    url: urls.USER_LIST_URL,
    success: types.USER_LIST_SUCCESS,
    failure: types.USER_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getCountryTypeList = () => (dispatch) => {
  dispatch({ type: types.COUNTEY_TYPE_LIST_CALL });
  get({
    url: urls.COMMON_COUNTRY_TBALE_URL,
    success: types.COUNTEY_TYPE_LIST_SUCCESS,
    failure: types.COUNTEY_TYPE_LIST_ERROR,
    dispatch
  });
};

export const getProfileTypeList = (data) => (dispatch) => {
  dispatch({ type: types.PROFILE_TYPE_LIST_CALL });
  get({
    url: urls.COMMON_PROFILE_URL,
    success: types.PROFILE_TYPE_LIST_SUCCESS,
    failure: types.PROFILE_TYPE_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getRoleList = () => (dispatch) => {
  const data = {
    ClientId: localStorage.getItem("clientId"),
    LoginEmail: localStorage.getItem("email")
  };
  dispatch({ type: types.ROLE_LIST_CALL });
  get({
    url: urls.ROLE_LIST_URL,
    success: types.ROLE_LIST_SUCCESS,
    failure: types.ROLE_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getProviderRoleList = (client) => (dispatch) => {
  const data = {
    ClientId: client ? `[${client}]` : localStorage.getItem("clientId"),
    LoginEmail: localStorage.getItem("email")
  };
  dispatch({ type: types.PROVIDER_ROLE_LIST_CALL });
  get({
    url: urls.PROVIDER_ROLE_LIST_URL,
    success: types.PROVIDER_ROLE_LIST_SUCCESS,
    failure: types.PROVIDER_ROLE_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getUserDetailsById = (data, profileCode) => (dispatch) => {
  const value = {
    profileCode,
    userId: data
  };
  dispatch({ type: types.USER_LIST_BY_ID_CALL });
  get({
    url: `${urls.GET_USER_LIST_BY_ID}`,
    success: types.USER_LIST_BY_ID_SUCCESS,
    failure: types.USER_LIST_BY_ID_ERROR,
    dispatch,
    body: value
  });
};

export const changeUserFormMode = (data) => ({
  type: types.USER_FORM_MODE,
  data
});

export const changeSingleStatus = (data) => (dispatch) => {
  dispatch({ type: types.USER_STATUS_CHANGE_SINGLE_CALL });
  put({
    url: urls.USER_STATUS_CHANGE_URL,
    success: types.USER_STATUS_CHANGE_SINGLE_SUCCESS,
    failure: types.USER_STATUS_CHANGE_SINGLE_ERROR,
    dispatch,
    body: {
      user_Id: data.id,
      Status: data.status
    }
  });
};

export const changePassword = (data) => (dispatch) => {
  dispatch({ type: types.USER_CHANGE_PASSWORD_CALL });
  get({
    url: urls.USER_CHANGE_PASSWORD_URL,
    success: types.USER_CHANGE_PASSWORD_SUCCESS,
    failure: types.USER_CHANGE_PASSWORD_ERROR,
    dispatch,
    body: {
      userId: data.id,
      password: data.Password
    }
  });
};

export const getUserByFilterData = (data) => (dispatch) => {
  const finalData = {
    ...data,
    ClientId: JSON.stringify(localStorage.getItem("sendApiClientId")),
    LoginEmail: localStorage.getItem("email")
  };
  dispatch({ type: types.USER_LIST_BY_FILTERDATA_CALL });
  get({
    url: `${urls.USER_LIST_BY_SEARCH_URL}`,
    success: types.USER_LIST_BY_FILTERDATA_SUCCESS,
    failure: types.USER_LIST_BY_FILTERDATA_ERROR,
    dispatch,
    body: finalData
  });
};

export const getTimeZoneList = () => (dispatch) => {
  dispatch({ type: types.TIMEZONE_LIST_CALL });
  get({
    url: urls.TIMEZONE_LIST_URL,
    success: types.TIMEZONE_LIST_SUCCESS,
    failure: types.TIMEZONE_LIST_ERROR,
    dispatch
  });
};

export const getUserListProvider = () => (dispatch) => {
  dispatch({ type: types.USER_PROVIDER_LIST_CALL });
  get({
    url: `${urls.USER_PROVIDER_LIST}`,
    success: types.USER_PROVIDER_LIST_SUCCESS,
    failure: types.USER_PROVIDER_LIST_FAIL,
    dispatch,
    body: false
  });
};

export const getGroupListData = () => (dispatch) => {
  const data = {
    ClientId: localStorage.getItem("clientId"),
    LoginEmail: localStorage.getItem("email")
  };
  dispatch({ type: types.GROUP_LIST_DATA_CALL });
  get({
    url: `${urls.GROUP_LIST_DATA_URL}`,
    success: types.GROUP_LIST_DATA_SUCCESS,
    failure: types.GROUP_LIST_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const setOroviderListEmpty = () => ({
  type: types.PROVIDER_LIST_ACCESABLE_EMPTY
});

export const deleteUserDocument = (data) => (dispatch) => {
  dispatch({ type: types.DELETE_USER_DOCUMENT_CALL });
  get({
    url: `${urls.DELETE_USER_DOCUMENT_CALL}`,
    success: types.DELETE_USER_DOCUMENT_SUCCESS,
    failure: types.DELETE_USER_DOCUMENT_ERROR,
    dispatch,
    body: data
  });
};

export const setUserSearchData = (data) => ({
  type: types.ADD_USER_SEARCH_DATA,
  data
});

export const setUserSearchEmpty = () => ({
  type: types.EMPTY_SEARCH_DATA,
});
