/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { connect } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Switch from "@material-ui/core/Switch";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import styles from "assets/jss/material-dashboard-react/components/userForm.js";
import closeImage from "assets/img/Close_Image.png";
import gif from "../../assets/img/success.gif";
import RoleMultipleSelect from "../../components/RoleMultiSelect/index";

import {
  roleRegistration,
  getProfileTypeList,
  getRoleList,
  getLandingPageList,
  updateRoleRegistration,
  changeRoleFormMode
} from "../../Redux/Actions/RoleRegistration";
import { getUserListProvider } from "../../Redux/Actions/UserRegistration";

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function RoleFormManager(props) {
  const classes = useStyles();
  const [validationModal, setValidationModal] = React.useState(false);
  const theme = useTheme();
  const windowWidthSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [submissionSuccess, setSubmissionSuccess] = React.useState(
    props.formData.data
  );
  const [landingPageData, setLandingPageData] = React.useState(
    props.formData.landingpageList
  );

  const [values, handleInput] = React.useState({
    ClientID: props.userRole.userList.ClientID,
    Profile_Code: "",
    RoleName: "",
    LandingPageId: "",
    CloneId: "",
    Description: "",
    accessible: [props.userRole.userList.ClientID]
  });
  const [validationError, setVlidationError] = React.useState({
    // businessName: !props.formData.formMode === 'Edit',
    Profile_Code: false,
    RoleName: false,
    LandingPageId: false,
    CloneId: false
  });
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(false);

  const checkValidation = () => {
    const error = { ...validationError };
    let validate = true;
    for (const key in values) {
      if (
        values[key] === "" &&
        (key === "Profile_Code" ||
          key === "RoleName" ||
          key === "LandingPageId" ||
          key === "CloneId")
      ) {
        error[key] = true;
        validate = false;
      } else {
        error[key] = false;
      }
    }

    setVlidationError(error);
    return validate;
  };

  const profileCode =
    props?.userRole?.userList &&
    props?.userRole?.userList?.Role_Permission_Data &&
    props?.userRole?.userList?.Role_Permission_Data?.Profile_Code;

  React.useEffect(() => {
    if (!props.formData.profileTypeList && profileCode !== false) {
      props.getProfileTypeList(profileCode);
    }
  }, [profileCode]);

  React.useEffect(() => {
    if (!props.formData.roleList) {
      props.getRoleList();
    }
    if (!props.userForm.userProvider) {
      const data = {
        clientId: props.userRole.userList.ClientID
      };
      props.getUserListProvider(data);
    }
  }, []);

  React.useEffect(() => {
    if (landingPageData) {
      if (
        landingPageData.LandingPage_Data !==
        props.formData.landingpageList.LandingPage_Data
      ) {
        setLandingPageData(props.formData.landingpageList);
      }
    }
  }, [landingPageData]);
  React.useEffect(() => {
    if (submissionSuccess !== props.formData.data) {
      if (props.formData.data && props.formData.data.status) {
        setOpen(true);
        setSubmissionSuccess(props.formData.data);
      } else if (
        props.formData.data &&
        !props.formData.data.status &&
        props.formData.data.message === "email aready exits"
      ) {
        setValidationModal(true);
      }
    }
  }, [submissionSuccess, props]);

  if (
    !data &&
    props.formData.roleDetailsById &&
    props.formData.formMode !== "Create"
  ) {
    const preData = props.formData.roleDetailsById.Roles_Data[0];
    const allState = { ...values };
    allState.Profile_Code = preData.Profile_Code;
    allState.RoleName = preData.RoleName;
    allState.LandingPageId = preData.Landing_Page_Id;
    allState.CloneId = preData.CloneID;
    allState.Description = preData.Description;
    allState.accessible = preData.Accessible_Provider;
    handleInput(allState);
    setData(true);
  }

  const handleBack = () => {
    props.changeRoleFormMode("init");
    setOpen(false);
  };

  const onFormSubbmit = () => {
    if (checkValidation()) {
      if (props.formData.formMode === "Create") {
        props.roleRegistration(values);
      }
      if (props.formData.formMode === "Edit") {
        props.updateRoleRegistration(
          values,
          props.formData.roleDetailsById.Roles_Data[0]._id
        );
      }
    } else {
      setValidationModal(true);
    }
  };

  const onChange = (value, type, e) => {
    const allState = { ...values };
    const error = { ...validationError };
    if (type === "Profile_Code") {
      allState.LandingPageId = "";
      props.getLandingPageList(value);
    }
    if (type === "CloneId") {
      allState[type] = value;
      error[type] = false;
    } else {
      error[type] = false;
      allState[type] = value;
    }
    handleInput(allState);
    setVlidationError(error);
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className={classes.modalDiv}>
          <Card
            className={windowWidthSm ? classes.modalCard : classes.modalCardXs}
          >
            <div className={classes.cardDiv}>
              <img src={gif} width="50%" />
              <div className={classes.successText}>Role Saved Successfully</div>
              <br />
              <Button
                variant="contained"
                className={classes.buttonStyle}
                size="lg"
                onClick={() => handleBack()}
              >
                Ok
              </Button>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal open={validationModal} onClose={() => setValidationModal(false)}>
        <div className={classes.modalDiv}>
          <Card
            className={windowWidthSm ? classes.modalCard : classes.modalCardXs}
          >
            <div className={classes.cardDiv}>
              <img src={closeImage} width="30%" />
              <div
                style={{
                  color: "gray",
                  fontSize: "14px",
                  textAlign: "center"
                }}
              >
                - Fields marked with an asterisk (*) are mandatory
              </div>
              <br />
              <Button
                variant="contained"
                className={classes.buttonStyle}
                size="sm"
                onClick={() => setValidationModal(false)}
              >
                Ok
              </Button>
            </div>
          </Card>
        </div>
      </Modal>
      <Box className={classes.marginBox}>
        <Grid container className={classes.margin}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              required
              error={validationError.Profile_Code}
              className={classes.halfInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              disabled={props.formData.formMode !== "Create"}
              label="Choose your profile"
              value={values.Profile_Code}
              onChange={(e) => onChange(e.target.value, "Profile_Code")}
              name="Profile_Code"
              id="Profile_Code"
              select
              style={{ height: "4.187em" }}
            >
              {props.formData.profileTypeList &&
                props.formData.profileTypeList.Profile_Data.map((key) => (
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value={key.Profile_Code}
                  >
                    {key.ProfileName}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              required
              error={validationError.RoleName}
              className={classes.halfInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize
                },
                required: true
              }}
              label="Role Name"
              value={values.RoleName}
              onChange={(e) => onChange(e.target.value, "RoleName")}
              name="RoleName"
              id="RoleName"
              style={{ height: "4.187em" }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.margin}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              required
              error={validationError.CloneId}
              className={classes.halfInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              disabled={
                props.formData.formMode !== "Create" ||
                values.Profile_Code === ""
              }
              label="Clone Role"
              value={values.CloneId}
              onChange={(e) => onChange(e.target.value, "CloneId")}
              name="CloneId"
              id="CloneId"
              select
              style={{ height: "4.187em" }}
            >
              {props.formData.roleList &&
                props.formData.roleList.Roles_Data.filter(
                  (v) => v.Profile_Code === values.Profile_Code
                ).map((key) => (
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value={key._id}
                  >
                    {key.RoleName}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              required
              error={validationError.LandingPageId}
              className={classes.halfInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize
                },
                required: true
              }}
              disabled={values.CloneId === ""}
              label="Default Landing Page"
              value={values.LandingPageId}
              onChange={(e) => onChange(e.target.value, "LandingPageId")}
              name="LandingPageId"
              id="LandingPageId"
              select
              style={{ height: "4.187em" }}
            >
              {props.formData.landingpageList &&
                props.formData.landingpageList.LandingPage_Data.sort((a, b) =>
                  a.Page_Name > b.Page_Name ? 1 : -1
                ).map((key) => (
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value={key.Page_id}
                  >
                    {key.Page_Name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginBottom: "20px" }}
          className={classes.margin}
        >
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {props.userForm.userProvider && (
              <RoleMultipleSelect
                values={values.accessible}
                data={
                  props.userForm.userProvider &&
                  props.userForm.userProvider.data
                }
                onChangeSelect={(item) => onChange(item, "accessible")}
              />
            )}
          </Grid>
          <Grid item xs />

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              multiline
              rowsMax={1}
              className={classes.halfInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              label="Description"
              value={values.Description}
              onChange={(e) => onChange(e.target.value, "Description")}
              name="Description"
              id="Description"
              placeholder="Description"
              style={{ height: "3.187em" }}
            />
          </Grid>
          <Grid item xs={4} />
        </Grid>
        <Grid container className={classes.margin}>
          <Grid item lg={9} md={9} sm={8} xs={0} />
          <Grid item lg={3} md={3} sm={4} xs={12} textAlign="right">
            <Box className={classes.buttonBox} textAlign="right">
              <Button
                cancel
                variant="contained"
                className={classes.clearButton}
                size="large"
                onClick={() => handleBack()}
              >
                Back
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                className={classes.buttonStyle}
                onClick={() => onFormSubbmit()}
                size="large"
                style={{ alignItems: "right" }}
              >
                {props.formData.formMode === "Create" ? "Submit" : "Update"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

RoleFormManager.propTypes = {
  formData: PropTypes.object,
  getProfileTypeList: PropTypes.func,
  getRoleList: PropTypes.func,
  getLandingPageList: PropTypes.func,
  updateRoleRegistration: PropTypes.func,
  roleRegistration: PropTypes.func,
  changeRoleFormMode: PropTypes.func,
  userRole: PropTypes.object,
  getUserListProvider: PropTypes.func,
  userForm: PropTypes.object
};

const mapStateToProps = (app) => ({
  formData: app.roles,
  userRole: app.userRole
});
export default connect(mapStateToProps, {
  roleRegistration,
  getProfileTypeList,
  getRoleList,
  getLandingPageList,
  updateRoleRegistration,
  changeRoleFormMode,
  getUserListProvider
})(RoleFormManager);
