/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
// core components
import TextField from "@material-ui/core/TextField";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import MenuItem from "@material-ui/core/MenuItem";
import { Checkbox, Button } from "@material-ui/core";
import Card from "components/Card/Card";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";
import { useSnackbar } from "notistack";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import PersonIcon from "@material-ui/icons/Person";
import {
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  mpaChecklistComment,
  kycChecklistAttechment,
  mpaChecklistAttechment,
  updatePricingTemplete,
  getUpdatePricingTemplate
} from "../../actions/Operations";
import {
  getPricingTemplete,
  getApplicationType,
  getProccesorList
} from "../../actions/MerchantSignUp";
import {
  setPricingTempleteEmpty,
  pricingTempleteCall,
  pricingTempleteCallInvite,
  getProviderDetailsById
} from "../../Redux/Actions/ProvideRegistration";

const useStyles = makeStyles(styles);

function BusinessForm(props) {
  const [data, setData] = React.useState(
    props.formData.pricingTemplete && props.formData.pricingTemplete.data
  );
  const classes = useStyles();
  const [pricingTemplateList, setPricingTemplateList] = React.useState([]);
  const [proccssorList, setProccssorList] = React.useState([]);
  const [applicationTypeList, setApplicationTypeList] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [bla, setBla] = React.useState({
    processId: "",
    templateId: "",
    applicationType: ""
  });
  const [isReset, setIsReset] = React.useState(false);
  const [formMode, setFormMode] = React.useState(false);
  const [showSelectedItem, setShowSelectedItem] = React.useState(false);

  const editForm = () => {
    setShowSelectedItem(true);
    setFormMode(props.formData.formMode);
  };

  React.useEffect(() => {
    if (
      data !== props.formData.pricingTemplete &&
      props.formData.pricingTemplete.data
    ) {
      const preValues = [...props.formData.pricingTemplete.data];
      setData(preValues);
    }
  }, [props.formData.pricingTemplete]);

  React.useEffect(() => {
    props.getPricingTemplete(
      (data) => {
        if (data.status) {
          setPricingTemplateList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
    props.getApplicationType(
      (data) => {
        if (data.status) {
          setApplicationTypeList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
    props.getProccesorList(
      (data) => {
        if (data.status) {
          setProccssorList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
    if (props.formData.providerDetailsById) {
      const callPricingTempleteData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id
      };
      props.pricingTempleteCall(callPricingTempleteData);
    }
  }, []);

  const handleBack = () => {
    if (props.formData.providerDetailsById) {
      props.getProviderDetailsById(
        props.formData.providerDetailsById.Providers_Data[0]._id
      );
    }
    props.history.push(`/${props.mainData.app.layout}/user`);
  };

  const showIempMin = (data) => {
    const returnArray = [];
    const ticker = data.perItemTicker * 10000;
    const to = data.perItemTo * 10000;
    const from = data.perItemFrom * 10000;
    if (data.perItemTicker !== 0) {
      if (data.perItemTicker % 1 !== 0) {
        for (let i = from; i <= to; i += ticker) {
          if (i > 0) {
            const finalValue = i / 10000;
            returnArray.push(
              data.perItemTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      } else {
        for (let i = from; i <= to; i += ticker) {
          if (i > 0) {
            const finalValue = i / 10000;
            returnArray.push(
              data.perItemTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      }
    }
    if (ticker === 0 && to === 0 && from === 0) {
      returnArray.push("0");
    }
    if (
      returnArray.filter((key) => Number(key) === Number(data.perItemRate))
        .length === 0
    ) {
      returnArray.unshift("Custom");
    }
    return returnArray;
  };

  const handleFocus = (data) => {
    setTimeout(() => document.getElementById(data).focus(), 50);
  };

  React.useEffect(
    () => () => {
      props.setPricingTempleteEmpty();
    },
    []
  );

  const handleOnChangeProccess = (data, type) => {
    const allValue = { ...bla };
    allValue[type] = data;
    if (
      type === "templateId" &&
      allValue.processId !== "" &&
      allValue.applicationType !== ""
    ) {
      const dataRequest = {
        templateId: data,
        processorId: bla.processId
      };
      setFormMode(props.formData.formMode);
      props.pricingTempleteCallInvite(dataRequest);
    }
    setBla(allValue);
    props.setPricingTempleteEmpty();
  };

  const onChange = (value, type, mainIndex, itemIndex) => {
    const updatedDate = [...data];
    if (type !== "check") {
      updatedDate[mainIndex].categoryItem[itemIndex].perItemRate = value;
    } else {
      updatedDate[mainIndex].categoryItem[itemIndex].perItemRate = 0;
      updatedDate[mainIndex].categoryItem[itemIndex].activeCheckbox = value;
    }
    if (type === "text") {
      handleFocus(updatedDate[mainIndex].categoryItem[itemIndex].itemName);
    }
    setData([...updatedDate]);
  };

  const handleReset = () => {
    props.setPricingTempleteEmpty();
    setIsReset(true);
    setFormMode(false);
    setShowSelectedItem(false);
    setBla({
      processId: "",
      templateId: "",
      applicationType: ""
    });
    setData(false);
    if (props.formData.providerDetailsById) {
      const callPricingTempleteData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id
      };
      props.pricingTempleteCall(callPricingTempleteData);
    }
  };

  const handelValidation = () => {
    let validate = true;
    data.forEach((key) => {
      key.categoryItem.forEach((main) => {
        if (main.activeCheckbox) {
          if (main.perItemFrom > 0 && main.perItemTo > 0) {
            const checkValue =
              (Number(main.perItemRate) >= Number(main.perItemFrom) &&
                Number(main.perItemRate) <= Number(main.perItemTo) &&
                Number(main.perItemRate) <= Number(main.perItemTo)) ||
              Number(main.perItemRate) === 0;
            if (!checkValue && validate) {
              validate = false;
              enqueueSnackbar(
                `The value of ${main.itemName} Should be in range of ${main.perItemFrom} to ${main.perItemTo}`,
                {
                  variant: "error"
                }
              );
            }
          }
        }
      });
    });
    return validate;
  };

  // React.useEffect(() => {
  //   if (bla.processId !== "" && bla.templateId !== "" && !data) {
  //     const dataRequest = {
  //       templateId: bla.templateId,
  //       processorId: bla.processId,
  //     };
  //     props.pricingTempleteCallInvite(dataRequest);
  //   }
  // }, [bla.processId, bla.templateId, data]);

  const handleSave = () => {
    if (handelValidation()) {
      const dataRequest = {
        merchantId: props.formData.providerDetailsById.Providers_Data[0]._id,
        templateId:
          props.formData.providerDetailsById.Providers_Data[0].TemplateID,
        merchantTemplate: data
      };
      props.updatePricingTemplete(
        dataRequest,
        (data) => {
          if (data.status) {
            setFormMode(false);
            setShowSelectedItem(false);

            enqueueSnackbar(`${data.message}`, {
              variant: "success"
            });
          } else {
            enqueueSnackbar(`${data.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        }
      );
    }
  };

  const profileTypeCheck =
    props.mainData.userRole.userList &&
    props.mainData.userRole.userList.Role_Permission_Data &&
    props.mainData.userRole.userList.Role_Permission_Data.Profile_Code.includes(
      "Merchant"
    );
  // && props.mainData.provideRegistration.providerDetailsById.Providers_Data[0].Current_Status == 5;

  const providerStatusCheck =
    props.mainData.provideRegistration.providerDetailsById &&
    props.mainData.provideRegistration.providerDetailsById.Providers_Data[0]
      .Current_Status === 5;

  const handleSubmit = () => {
    const dataHandleSubmit = {
      merchantId: props.formData.providerDetailsById.Providers_Data[0]._id,
      templateId: bla.templateId,
      processorId: bla.processId
    };
    const mainData = data;
    props.getUpdatePricingTemplate(
      dataHandleSubmit,
      (data) => {
        if (data.status) {
          setFormMode(false);
          setShowSelectedItem(false);
          enqueueSnackbar(`${data.message}`, {
            variant: "success"
          });
          setTimeout(() => {
            const dataRequest = {
              merchantId:
                props.formData.providerDetailsById.Providers_Data[0]._id,
              templateId: bla.templateId,
              merchantTemplate: mainData
            };
            props.updatePricingTemplete(dataRequest, {}, {});
          }, 1000);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const name =
    props.formData.providerDetailsById &&
    props.formData.providerDetailsById.Providers_Data[0] &&
    props.formData.providerDetailsById.Providers_Data[0].Legal_Name;

  return (
    <div style={{ paddingBottom: "40px" }}>
      <Card
        style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
      >
        <Box
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            alignItems: "center",
            padding: "10px"
          }}
          className={classes.tittleColor}
          height={40}
        >
          <Grid
            style={{
              display: "flex",
              position: "relative",
              flexWrap: "nowrap",
              boxSizing: "border-box",
              flexDirection: "row",
              alignItems: "center"
            }}
            container
          >
            <Grid style={{ position: "absolute" }} item>
              <IconButton
                color="white"
                aria-label="Back"
                onClick={() => handleBack()}
                component="span"
                style={{ background: "white", padding: "6px" }}
              >
                <KeyboardBackspaceIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid style={{ margin: "auto" }} item>
              <Typography
                style={{ margin: "auto" }}
                className={classes.tittleTextColor}
              >
                {`Pricing View(${
                  props.formData.providerDetailsById &&
                  props.formData.providerDetailsById.Providers_Data &&
                  props.formData.providerDetailsById.Providers_Data[0]
                    .Business_Name
                })`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {data.length > 0 && !profileTypeCheck && !providerStatusCheck && (
          <Box
            className={classes.buttonBoxPricing}
            style={{ marginBottom: "0px" }}
          >
            <Grid
              container
              item
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Grid
                item
                sm={6}
                md={6}
                lg={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {`Template Name: ${
                    props.formData.providerDetailsById &&
                    props.formData.providerDetailsById.Providers_Data &&
                    props.formData.providerDetailsById.Providers_Data[0]
                      .pricingTemplateView
                      ? props.formData.pricingTemplete &&
                        props.formData.pricingTemplete.templateName
                      : pricingTemplateList.filter(
                          (key) => key._id === bla.templateId
                        )[0].name
                  }`}
                </Typography>
              </Grid>
              <div style={{ justifyContent: "end" }}>
                {props.formData.providerDetailsById &&
                  props.formData.providerDetailsById.Providers_Data &&
                  props.formData.providerDetailsById.Providers_Data[0]
                    .pricingTemplateView && (
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        marginRight: "40px"
                      }}
                    >
                      {!formMode ? (
                        <Button
                          className={classes.resetButtonStyle}
                          variant="outlined"
                          fullWidth
                          color="#696969"
                          onClick={() => editForm()}
                        >
                          Edit
                        </Button>
                      ) : (
                        <>
                          <Button
                            variant="outlined"
                            fullWidth
                            color="#696969"
                            onClick={() => handleReset()}
                          >
                            Reset
                          </Button>

                          {/* <Button
                            className={classes.buttonStyle}
                            variant="contained"
                            fullWidth
                            color="#696969"
                            onClick={() => handleSubmit()}
                            style={{ height: "unset" }}
                          >
                            Submit
                          </Button> */}
                        </>
                      )}
                    </div>
                  )}
                {props.formData.providerDetailsById &&
                  props.formData.providerDetailsById.Providers_Data &&
                  !props.formData.providerDetailsById.Providers_Data[0]
                    .pricingTemplateView && (
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <Button
                        variant="outlined"
                        fullWidth
                        color="#696969"
                        onClick={() => handleReset()}
                      >
                        Reset
                      </Button>

                      <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        fullWidth
                        color="#696969"
                        onClick={() => handleSave()}
                        style={{ height: "unset" }}
                      >
                        Save
                      </Button>
                    </div>
                  )}
              </div>
            </Grid>
          </Box>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center"
            // padding: "4%",
          }}
        >
          {!data.length && (
            <>
              <Box className={classes.buttonBoxPricing}>
                <Grid
                  container
                  item
                  spacing={2}
                  style={{
                    textAlign: "center",
                    justifyContent: "inherit",
                    paddingTop: "25px"
                  }}
                >
                  <Grid
                    item
                    sm={4}
                    xs={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {`Pricing Template: ${name}`}
                    </Typography>
                  </Grid>
                  <Grid item sm={8} xs={8} />
                </Grid>
              </Box>
              <Grid container>
                <Grid item lg={4} sm={4} xs={12}>
                  <PersonIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    select
                    required
                    onChange={(e) => {
                      handleOnChangeProccess(e.target.value, "processId");
                    }}
                    value={bla.processId}
                    fullWidth
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Processor"
                    name="processor"
                    id="processor"
                  >
                    {proccssorList.map((key) => (
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value={key._id}
                      >
                        {key.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                  <PersonIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    select
                    required
                    fullWidth
                    disabled={bla.processId === ""}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    onChange={(e) => {
                      handleOnChangeProccess(e.target.value, "applicationType");
                    }}
                    value={bla.applicationType}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Application Type,"
                    name="applicationType"
                    id="applicationType"
                  >
                    {applicationTypeList.map((key) => (
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value={key._id}
                      >
                        {key.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                  <EmailIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    select
                    required
                    fullWidth
                    className={classes.root}
                    disabled={
                      bla.processId === "" || bla.applicationType === ""
                    }
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    onChange={(e) => {
                      handleOnChangeProccess(e.target.value, "templateId");
                    }}
                    value={bla.templateId}
                    label="Pricing Template"
                    name="pricingTemplate"
                    id="pricingTemplate"
                  >
                    {pricingTemplateList
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key._id}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </>
          )}
          {data &&
            !formMode &&
            data
              .sort((a, b) => (a.categoryName > b.categoryName ? 1 : -1))
              .map((key, mainIndex) => (
                <>
                  <Box
                    style={{
                      alignItems: "center",
                      marginTop: "10px"
                    }}
                    key={Math.random()}
                    className={classes.tittleColor}
                    height={40}
                  >
                    <Typography
                      className={classes.tittleTextColor}
                      style={{ color: "white" }}
                    >
                      {key.categoryName}
                    </Typography>
                  </Box>
                  <Box className={classes.marginBox}>
                    <Grid container className={classes.margin}>
                      {key.categoryItem
                        .sort((a, b) => (a.itemName > b.itemName ? 1 : -1))
                        .map((item, index) => {
                          if (item.activeCheckbox) {
                            return (
                              <>
                                <Grid
                                  key={Math.random()}
                                  item
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: "20px"
                                  }}
                                  lg={4}
                                  sm={6}
                                  xs={12}
                                >
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      padding: "1%",
                                      fontWeight: "bold",
                                      width: "70%",
                                      display: "flex",
                                      flexWrap: "wrap"
                                    }}
                                  >
                                    {item.itemName}:{" "}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      padding: "1%",
                                      width: "30%",
                                      fontWeight: "normal",
                                      display: "flex"
                                    }}
                                  >
                                    ${item.perItemRate}
                                  </span>
                                </Grid>
                              </>
                            );
                          }
                          return "";
                        })}
                    </Grid>
                  </Box>
                </>
              ))}
          {data &&
            formMode &&
            data
              .sort((a, b) => (a.categoryName > b.categoryName ? 1 : -1))
              .map((key, mainIndex) => (
                <>
                  <Box
                    style={{
                      alignItems: "center",
                      marginTop: "10px"
                    }}
                    key={Math.random()}
                    className={classes.tittleColor}
                    height={40}
                  >
                    <Typography className={classes.tittleTextColor}>
                      {key.categoryName}
                    </Typography>
                  </Box>
                  <Box className={classes.marginBox}>
                    <Grid container className={classes.margin}>
                      {key.categoryItem
                        .sort((a, b) => (a.itemName > b.itemName ? 1 : -1))
                        .map((item, index) => (
                          <>
                            <Grid
                              key={Math.random()}
                              item
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "20px"
                              }}
                              lg={6}
                              sm={6}
                              xs={12}
                            >
                              <div style={{ minWidth: "5%" }}>
                                <Checkbox
                                  disabled={profileTypeCheck}
                                  checked={item.activeCheckbox}
                                  onChange={(e) =>
                                    onChange(
                                      e.target.checked,
                                      "check",
                                      mainIndex,
                                      index
                                    )
                                  }
                                  inputProps={{
                                    "aria-label": "select all desserts"
                                  }}
                                  color="primary"
                                />
                              </div>
                              <span
                                style={{
                                  fontSize: "16px",
                                  lineHeight: "20px",
                                  maxWidth: "25%",
                                  minWidth: "25%",
                                  padding: "1%"
                                }}
                              >
                                {item.itemName}
                              </span>
                              {!profileTypeCheck && (
                                <div
                                  style={{
                                    maxWidth: "25%",
                                    minWidth: "25%",
                                    padding: "1%"
                                  }}
                                >
                                  <TextField
                                    className={classes.quaterInputPricing}
                                    key={Math.random()}
                                    disabled={!item.activeCheckbox}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.activeInputLableColor,
                                        focused: "focused"
                                      }
                                    }}
                                    InputProps={{
                                      classes: {
                                        root: classes.activeInputColor,
                                        input: classes.resizePrice,
                                        focused: "focused"
                                      }
                                    }}
                                    defaultValue="Custom"
                                    value={
                                      item.perItemTicker % 1 !== 0
                                        ? Number(item.perItemRate)
                                            .toFixed(4)
                                            .toString()
                                        : item.perItemRate
                                    }
                                    onChange={(e) =>
                                      onChange(
                                        e.target.value,
                                        item,
                                        mainIndex,
                                        index
                                      )
                                    }
                                    name={Math.random()
                                      .toString(36)
                                      .substr(2, 5)}
                                    id={Math.random().toString(36).substr(2, 5)}
                                    select
                                  >
                                    {showIempMin(
                                      item,
                                      index,
                                      key.categoryItem.length
                                    ).length > 0 &&
                                      showIempMin(
                                        item,
                                        index,
                                        key.categoryItem.length
                                      ).map((number) => (
                                        <MenuItem
                                          key={Math.random()}
                                          className={classes.dialogBoxDropDown}
                                          value={
                                            number === "Custom"
                                              ? item.perItemTicker % 1 !== 0
                                                ? Number(item.perItemRate)
                                                    .toFixed(4)
                                                    .toString()
                                                : item.perItemRate
                                              : number
                                          }
                                        >
                                          {number}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                </div>
                              )}
                              <div
                                style={{
                                  maxWidth: "25%",
                                  minWidth: "25%",
                                  padding: "1%",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center"
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    maxWidth: "25%",
                                    minWidth: "25%",
                                    padding: "1%",
                                    textAlign: "end"
                                  }}
                                >
                                  {item.rateTypeSing === "$"
                                    ? item.rateTypeSing
                                    : ""}
                                </span>
                                <TextField
                                  className={classes.quaterInputPricing}
                                  key={Math.random()}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.activeInputLableColor,
                                      focused: "focused"
                                    }
                                  }}
                                  InputProps={{
                                    classes: {
                                      root: classes.activeInputColor,
                                      input: classes.resizePrice,
                                      focused: "focused"
                                    }
                                  }}
                                  variant="outlined"
                                  style={{ backgroundColor: "#f9f7f7" }}
                                  value={item.perItemRate}
                                  disabled={
                                    !item.activeCheckbox || profileTypeCheck
                                  }
                                  onChange={(e) =>
                                    onChange(
                                      e.target.value,
                                      "text",
                                      mainIndex,
                                      index
                                    )
                                  }
                                  name={item.itemName}
                                  id={item.itemName}
                                />
                                <span
                                  style={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    maxWidth: "25%",
                                    minWidth: "25%",
                                    padding: "1%"
                                  }}
                                >
                                  {item.rateTypeSing !== "$"
                                    ? item.rateTypeSing
                                    : ""}
                                </span>
                              </div>
                            </Grid>
                          </>
                        ))}
                    </Grid>
                  </Box>
                </>
              ))}
          {props.formData.providerDetailsById &&
            props.formData.providerDetailsById.Providers_Data &&
            props.formData.providerDetailsById.Providers_Data[0]
              .pricingTemplateView &&
            !profileTypeCheck &&
            !providerStatusCheck && (
              <Box className={classes.buttonBoxPricing}>
                <Grid
                  container
                  item
                  spacing={2}
                  style={{
                    textAlign: "center",
                    justifyContent: "inherit"
                  }}
                >
                  <Grid item sm={1} xs={1}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="#696969"
                      onClick={() => handleReset()}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item sm={1} xs={1}>
                    <Button
                      className={classes.buttonStyle}
                      variant="contained"
                      fullWidth
                      color="#696969"
                      onClick={() => handleSave()}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}

          {props.formData.providerDetailsById &&
            props.formData.providerDetailsById.Providers_Data &&
            !props.formData.providerDetailsById.Providers_Data[0]
              .pricingTemplateView && (
              <Box className={classes.buttonBoxPricing}>
                <Grid
                  container
                  item
                  spacing={2}
                  style={{
                    textAlign: "center",
                    justifyContent: "inherit"
                  }}
                >
                  <Grid item sm={1} xs={1}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="#696969"
                      onClick={() => handleReset()}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item sm={1} xs={1}>
                    <Button
                      className={classes.buttonStyle}
                      variant="contained"
                      fullWidth
                      color="#696969"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item sm={1} xs={1} />
                </Grid>
              </Box>
            )}
        </div>
      </Card>
    </div>
  );
}

BusinessForm.propTypes = {
  formData: PropTypes.object,
  updatePricingTemplete: PropTypes.func,
  mainData: PropTypes.object,
  history: PropTypes.object,
  setPricingTempleteEmpty: PropTypes.func,
  pricingTempleteCall: PropTypes.func,
  getPricingTemplete: PropTypes.func,
  getApplicationType: PropTypes.func,
  getProccesorList: PropTypes.func,
  pricingTempleteCallInvite: PropTypes.func,
  getUpdatePricingTemplate: PropTypes.func,
  getProviderDetailsById: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app
});

export default connect(mapStateToProps, {
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  mpaChecklistComment,
  kycChecklistAttechment,
  getProviderDetailsById,
  mpaChecklistAttechment,
  updatePricingTemplete,
  pricingTempleteCall,
  setPricingTempleteEmpty,
  getPricingTemplete,
  getApplicationType,
  getProccesorList,
  getUpdatePricingTemplate,
  pricingTempleteCallInvite
})(BusinessForm);
