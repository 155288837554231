import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Search from "@material-ui/icons/Search";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import IdleTimer from "react-idle-timer/dist/modern";
import Button from "components/CustomButtons/Button";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle";
import MultipleSelect from "../MultiSelect/Index";

const useStyles = makeStyles(styles);

function AdminNavbarLinks(props) {
  const classes = useStyles();
  const [accessible, setAccessible] = React.useState(
    !localStorage.getItem("sendApiClientId")
      ? ""
      : JSON.parse(localStorage.getItem("sendApiClientId"))
  );
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const idleTimer = React.useRef();
  const idleTime = parseInt(process.env.REACT_APP_IDEAL_TIME_MINUTES);
  const timeOutLimit = 10000 * 60 * idleTime;

  const onIdle = () => {
    if (window.location.pathname === "/admin/user") {
      document.getElementById("saveDraftBusinessForm").click();
    }
    props.setState();
    localStorage.setItem(
      "idealPath",
      JSON.stringify({
        path: window.location.pathname,
        landingPageName: props.app,
      })
    );
    props.logout();
    localStorage.clear();
    setAccessible("");
    props.setOroviderListEmpty();
    props.resetReducer();
    props.history.push("/sessionOut");
  };

  const profileTypeCheck =
    props.userRole.userList &&
    props.userRole.userList.Role_Permission_Data &&
    props.userRole.userList.Role_Permission_Data.Profile_Code;
  React.useEffect(() => {
    if (!props.userProvider.userProvider && !props.userProvider.loading) {
      props.getUserListProvider();
    } else if (
      accessible === "" &&
      props.userProvider.userProvider &&
      !props.userProvider.loading &&
      !localStorage.getItem("sendApiClientId")
    ) {
      const value = [];
      props.userProvider.userProvider.data.forEach((v) => {
        value.push(v.Porvider_Id);
      });
      localStorage.setItem("sendApiClientId", JSON.stringify(value));
      setAccessible(value);
    }
  }, [props]);
  const handleCloseProfile = () => {
    props.setState();
    props.logout();
    localStorage.clear();
    localStorage.clear();
    props.setCallRolePermission();
    setAccessible("");
    props.setOroviderListEmpty();
    props.resetReducer();
    props.history.push("/");
  };

  const clientIdSelect = () => {
    localStorage.removeItem("sendApiClientId");
    localStorage.setItem("sendApiClientId", JSON.stringify(accessible));
    window.location.reload();
  };
  return (
    <div
      style={{
        display: `${profileTypeCheck === "Patient" ? "" : "flex"}`,
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {/* <div className={classes.hideSearch}>
        {profileTypeCheck !== "Patient" && props.userProvider.userProvider && (
          <MultipleSelect
            className={classes.hideSearch}
            lable
            clientId={props.menuData.userList.ClientID}
            values={accessible}
            data={
              props.userProvider.userProvider
            && props.userProvider.userProvider.data
            }
            onChangeSelect={item => setAccessible(item)}
          />
        )}
        {profileTypeCheck !== "Patient" && (
          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            onClick={() => clientIdSelect()}
          >
            <Search />
          </Button>
        )}
      </div> */}
      <div className={classes.manager}>
        <Button
          color="white"
          aria-label="edit"
          round
          justIcon
          onClick={handleClickProfile}
        >
          <Person />
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom-end"
          className={`${classNames({ [classes.popperClose]: !openProfile })} ${
            classes.popperNav
          }`}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin: "right",
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    setOpenProfile(null);
                  }}
                >
                  <MenuList role="menu">
                    {/* <MenuItem className={classes.dialogBoxDropDown}>
                      Profile
                    </MenuItem>
                    <MenuItem className={classes.dialogBoxDropDown}>
                      Settings
                    </MenuItem>
                    <Divider light /> */}
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dialogBoxDropDown}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
        <IdleTimer
          ref={idleTimer}
          element={document}
          onIdle={() => onIdle()}
          onAction={() => {
            idleTimer.current.reset();
          }}
          debounce={250}
          timeout={timeOutLimit}
        />
      </div>
    </div>
  );
}

AdminNavbarLinks.propTypes = {
  userProvider: PropTypes.object,
  history: PropTypes.object,
  getUserListProvider: PropTypes.func,
  userRole: PropTypes.object,
  setState: PropTypes.func,
  logout: PropTypes.func,
  setOroviderListEmpty: PropTypes.func,
  resetReducer: PropTypes.func,
  menuData: PropTypes.object,
  app: PropTypes.any,
  setCallRolePermission: PropTypes.func,
};

export default AdminNavbarLinks;
