/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import ReportIcon from "@material-ui/icons/Report";
import PersonIcon from "@material-ui/icons/Person";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import PhonelinkLockIcon from "@material-ui/icons/PhonelinkLock";
import FormatPaintIcon from "@material-ui/icons/FormatPaint";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
// import TableList from "views/TableList/TableList.js";
// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// // core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";
/* eslint-disable import/extensions */
// @material-ui/icons
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
// import TableList from "views/TableList/TableList.js";
// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// // core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
// import TableList from "views/TableList/TableList.js";
// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// // core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";

export const iconArray = [
  {
    name: "Dashboard",
    icon: Dashboard,
  },
  {
    name: "Reports",
    icon: ReportIcon,
  },
  {
    name: "Merchants",
    icon: AssignmentIndIcon,
  },
  {
    name: "User Setup",
    icon: SettingsApplicationsIcon,
  },
  {
    name: "Access Control",
    icon: PhonelinkLockIcon,
  },
  {
    name: "Offers",
    icon: LocalOfferIcon,
  },
  {
    name: "Checkout",
    icon: LocalOfferIcon,
  },
  {
    name: "Permissions",
    icon: LocalOfferIcon,
  },
];
