/* eslint-disable camelcase */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useRef, useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import PropTypes from "prop-types";
import { total_out_colors_graph } from "../../assets/GloableColor";

function Daliydeposits(props) {
  useEffect(() => {
    if (document.getElementById("totalout") !== null) {
      const chart = am4core.create("totalout", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.colors.step = 2;
      chart.maskBullets = false;
	  const title = chart.titles.create();
	  title.text = "Total OutStanding";
	  title.fontSize = 13;
      title.fontFamily = "Calibri";
      title.paddingBottom = 10;
      title.align = "left";
      title.fontWeight = "bold";

	  if (props.dashboardData.totalOutStandingData && props.dashboardData.totalOutStandingData.data) {
        // Add data
        chart.data = props.dashboardData.totalOutStandingData.data[0].data;
      }
		  const dateAxis = chart.xAxes.push(
			  new am4charts.DateAxis()
      );
			 // dateAxis.renderer.labels.template.location = 20;
      dateAxis.renderer.minGridDistance = 0;
      dateAxis.renderer.grid.template.disabled = true;
      dateAxis.renderer.fullWidthTooltip = true;
      dateAxis.renderer.labels.template.rotation = 315;
      dateAxis.renderer.labels.template.verticalCenter = "right";
      dateAxis.renderer.labels.template.fontSize = am4core.percent(65);
      dateAxis.tooltip.disabled = true;
      dateAxis.renderer.labels.template.url = "/admin/totalOutStanding";
		  	dateAxis.renderer.labels.template.urlTarget = "_self";

			 const distanceAxis = chart.yAxes.push(new am4charts.ValueAxis());
			 distanceAxis.title.text = "Volume ($)";
			 distanceAxis.title.fontSize = am4core.percent(65);
			 distanceAxis.renderer.grid.template.disabled = true;
			 distanceAxis.renderer.labels.template.fontSize = am4core.percent(65);
			 distanceAxis.tooltip.disabled = true;

			 const durationAxis = chart.yAxes.push(new am4charts.ValueAxis());
			 durationAxis.title.text = "Count";
			 durationAxis.title.fontSize = am4core.percent(65);
			 durationAxis.baseUnit = "total_count";
			 durationAxis.renderer.labels.template.fontSize = am4core.percent(65);
			 durationAxis.renderer.grid.template.disabled = true;
			 durationAxis.renderer.opposite = true;
			 durationAxis.syncWithAxis = distanceAxis;
			 durationAxis.tooltip.disabled = true;

			 // durationAxis.durationFormatter.durationFormat = "hh'h' mm'min'";

			 // Create series
			 const distanceSeries = chart.series.push(new am4charts.ColumnSeries());
			 distanceSeries.dataFields.valueY = "total_amount";
			 distanceSeries.dataFields.dateX = "month_year";
			 distanceSeries.yAxis = distanceAxis;

		  //    distanceSeries.tooltipText = "{valueY}";
			 distanceSeries.name = "Volume($)";
			 distanceSeries.fontSize = am4core.percent(70);
			 distanceSeries.columns.template.width = am4core.percent(50);
			 distanceSeries.fill = total_out_colors_graph.volume;
			 distanceSeries.stroke = total_out_colors_graph.volume;
			 distanceSeries.columns.template.fillOpacity = 8;
			 distanceSeries.columns.template.propertyFields.strokeDasharray = "dashLength";
			 distanceSeries.columns.template.propertyFields.fillOpacity = "alpha";
			 distanceSeries.showOnInit = true;

			 const distanceState = distanceSeries.columns.template.states.create("hover");
			 distanceState.properties.fillOpacity = 0.9;

			 const durationSeries = chart.series.push(new am4charts.LineSeries());
			 durationSeries.dataFields.valueY = "total_count";
			 durationSeries.dataFields.dateX = "month_year";
			 durationSeries.name = "Count";
			 durationSeries.yAxis = durationAxis;
			 durationSeries.strokeWidth = 1;
			 durationSeries.propertyFields.strokeDasharray = "dashLength";
			 // durationSeries.tooltipText = "{valueY}";
		 // durationSeries.tooltipText = "[bold]{date}[\]\n[font-size:13px]Count: [/][font-size:11px]{total_count}\n[font-size:13px]{name} = [/][font-size:11px]{valueY}";
		 durationSeries.tooltipHTML = `<table style="font-size:10px">
		 <tr>
		 <center style="font-size:10px"><strong>{month_year}</strong></center>
		 <hr />
	   </tr>
	  
		 <tr>
		   <th align="left">Count:</th>
		   <td>{total_count}</td>
		 </tr>
		 <tr>
		   <th align="left">Volume($):</th>
		   <td>{total_amount}</td>
		 </tr>
		 
		 </table>`;
		 durationSeries.showOnInit = true;
			 durationSeries.stroke = total_out_colors_graph.line;
			 durationSeries.fill = total_out_colors_graph.line;

			 const durationBullet = durationSeries.bullets.push(new am4charts.Bullet());
			 const durationRectangle = durationBullet.createChild(am4core.Rectangle);
			 durationBullet.horizontalCenter = "middle";
			 durationBullet.verticalCenter = "middle";
			 const circleBullet = durationSeries.bullets.push(new am4charts.CircleBullet());
			 circleBullet.circle.radius = 2;

			 const durationState = durationBullet.states.create("hover");
			 durationState.properties.scale = 1.2;

			 // Add legend
			 chart.legend = new am4charts.Legend();
		   chart.legend.position = "top";
		   chart.legend.fontSize = 10;
		   chart.legend.useDefaultMarker = true;
		   const marker = chart.legend.markers.template.children.getIndex(0);
		   marker.cornerRadius(12, 12, 12, 12);
			  marker.strokeWidth = 0;
			  marker.strokeOpacity = 1;

		   chart.tooltip.label.fill = am4core.color("#f00");

		   const markerTemplate = chart.legend.markers.template;
		   markerTemplate.width = 10;
		   markerTemplate.height = 10;

		   chart.tooltip.getFillFromObject = true;
      chart.tooltip.background.fill = am4core.color("#67b7dc");

			 // Add cursor
			 durationSeries.tooltip.dy = -8;

			 chart.cursor = new am4charts.XYCursor();
			 chart.cursor.fullWidthLineX = true;
			 chart.cursor.xAxis = dateAxis;

			 chart.cursor.lineX.strokeOpacity = 0;
			 chart.cursor.lineX.fill = total_out_colors_graph.total_amount;
			 chart.cursor.lineX.fillOpacity = 0.1;
			 chart.cursor.lineY.disabled = true;

			 const hoverState = distanceSeries.columns.template.states.create("hover");
			 hoverState.properties.fill = am4core.color(total_out_colors_graph.columnHover);
			 hoverState.properties.fillOpacity = 0.5;

			 const buttonContainer = chart.plotContainer.createChild(am4core.Container);
			  buttonContainer.shouldClone = false;
			  buttonContainer.align = "right";
			  buttonContainer.zIndex = Number.MAX_SAFE_INTEGER;
			  buttonContainer.layout = "horizontal";
		  }
		  }, [props]);
  return (
    <div id="totalout" style={{ width: "100%", height: "20rem" }} />
  );
}

Daliydeposits.propTypes = {
  dashboardData: PropTypes.object,
};

export default Daliydeposits;
