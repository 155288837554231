/* eslint-disable import/extensions */
import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextField, Grid, MenuItem } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

const useStyles = makeStyles(styles);

const SettlementAccount = props => {
  const classes = useStyles();

  return (
    <Box className={classes.marginBox}>
      <Grid container className={classes.margin}>
        <Grid item lg={3} sm={3} xs={12}>
          <AccountBalanceIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.quaterInput}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            error={props.validationError.accountNumber}
            label="Account Number"
            value={props.values.accountNumber}
            onChange={e => (/^[0-9]\d*$/g.test(e.target.value)
                        || e.target.value === "")
                      && props.onChange(e.target.value, "accountNumber")}
            helperText={
              props.validationError.accountNumber
                      && "Account number should be between 4 to 17 digit"
            }
            name="accountNumber"
            id="accountNumber"
          />
        </Grid>
        <Grid item lg={3} sm={3} xs={12}>
          <AccountBalanceIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.quaterInput}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            error={props.validationError.routingNumber}
            label="Routing Number"
            value={props.values.routingNumber}
            onChange={e => (/^[0-9]\d*$/g.test(e.target.value)
                        || e.target.value === "")
                      && props.onChange(e.target.value, "routingNumber")}
            helperText={
              props.validationError.routingNumber
                      && "Routing number should be 9 digit"
            }
            name="routingNumber"
            id="routingNumber"
          />
        </Grid>
        <Grid item lg={3} sm={3} xs={12}>
          <AccountBalanceIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.quaterInput}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Name on Account"
            value={props.values.legalBusiness}
            // onChange={(e) => props.onChange(e.target.value, "accountName")}
            name="accountName"
            id="accountName"
          />
        </Grid>
        <Grid item lg={3} sm={3} xs={12}>
          <AccountBalanceIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            required
            className={classes.quaterInput}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            error={props.validationError.accountType}
            helperText={
              props.validationError.accountType && "Account type is required"
            }
            label="Account Type"
            value={props.values.accountType}
            onChange={e => props.onChange(e.target.value, "accountType")}
            name="accountType"
            id="accountType"
            select
          >
            {props.formData.accountTypeList
                      && props.formData.accountTypeList.Account_Type_Data.map(
                        key => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key.Account_Type_Name}
                          >
                            {key.Account_Type_Name}
                          </MenuItem>
                        )
                      )}
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );
};

SettlementAccount.propTypes = {
  disabled: PropTypes.bool,
  validationError: PropTypes.object,
  values: PropTypes.object,
  onChange: PropTypes.func,
  formData: PropTypes.object,
};

const mapStateToProps = app => ({
  formData: app.provideRegistration,
});
export default connect(mapStateToProps, null)(SettlementAccount);
