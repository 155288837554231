import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Modal from "@material-ui/core/Modal";
import Notes from "views/Reports/Notes";

const useStyles = makeStyles(styles);

const InfoHeaderSection = (props) => {
  const classes = useStyles();
  const [openNotes, setOpenNotes] = React.useState(false);

  const activity = () => {
    const data = {
      providerId:
        props.formData.providerDetailsById &&
        props.formData.providerDetailsById.Providers_Data[0]._id
    };
    props.getProviderActivityById(data);
    props.history.push(`/${props.mainData.app.layout}/merchantactivity`);
  };

  const setConfiguration = () => {
    const data = {
      providerId: props.formData.providerDetailsById.Providers_Data[0]._id
    };
    // props.getProviderActivityById(data);
    props.history.push(`/${props.mainData.app.layout}/configuration`);
  };

  const saveStatus = () => {
    const data = {
      id: props.formData.providerDetailsById.Providers_Data[0]._id,
      status: props.values.status
    };
    props.changeSingleStatus(data);
  };

  const userStatus =
    props.formData.statusListById &&
    props.formData.statusListById.Provider_Status_Data &&
    props.formData.statusListById.Provider_Status_Data[0].Status_Code;

  const merchantPortalSetupKey =
    props.formData.providerDetailsById &&
    props.formData.providerDetailsById.Providers_Data[0] &&
    props.formData.providerDetailsById.Providers_Data[0].merchantSettingSetup;

  let userId =
    props.formData.providerDetailsById &&
    props.formData.providerDetailsById.Providers_Data[0] &&
    props.formData.providerDetailsById.Providers_Data[0]._id;

  return (
    <>
      <Grid
        container
        style={{ padding: "0px 25px" }}
        className={classes.statusContainer}
        justify="space-between"
      >
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: "14px" }}>
            {`Agent Name: ${
              props.formData.providerDetailsById &&
              props.formData.providerDetailsById.Providers_Data[0].agentFullName
            }`}
          </div>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            onClick={() => props.handlePricingTemplete()}
            style={{ textTransform: "capitalize", padding: "10px" }}
          >
            {props.formData.providerDetailsById.Providers_Data &&
              props.formData.providerDetailsById.Providers_Data[0]
                .pricingTemplateView && (
                <CheckCircleIcon style={{ marginRight: "10px" }} />
              )}
            Pricing
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            onClick={() => activity()}
            style={{ textTransform: "capitalize", padding: "10px" }}
          >
            Activity History
          </Button>
        </Grid>
        {(userStatus == 12 ||
          userStatus == 11 ||
          userStatus == 9 ||
          merchantPortalSetupKey) && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              onClick={() => setConfiguration()}
              style={{ textTransform: "capitalize", padding: "10px" }}
            >
              {merchantPortalSetupKey && (
                <CheckCircleIcon style={{ marginRight: "10px" }} />
              )}
              Configuration
            </Button>
          </Grid>
        )}

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            onClick={() => setOpenNotes(true)}
            style={{ textTransform: "capitalize", padding: "10px" }}
            sx
          >
            Notes
          </Button>
          <Modal
            open={openNotes}
            onClose={() => setOpenNotes(false)}
            align="center"
          >
            <Notes
              setOpenNotes={setOpenNotes}
              openNotes={openNotes}
              userId={userId}
            />
          </Modal>
        </Grid>

        {props.formData.statusList && (
          <div
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <TextField
              className={
                !props.changeStatus ? classes.rootStatus : classes.rootStatusNew
              }
              variant="outlined"
              size="small"
              disabled={!props.changeStatus}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColorStatus,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              label="Status"
              value={props.values.status}
              onChange={(e) => props.onChange(e.target.value, "status")}
              name="status"
              style={{ textAlign: "initial", minWidth: "fit-content" }}
              id="status"
              select
            >
              {props.formData.statusListById &&
                props.formData.statusListById.Provider_Status_Data.map(
                  (key) => (
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value={key.Status_Code}
                    >
                      {key.Provider_Status}
                    </MenuItem>
                  )
                )}
            </TextField>

            {props.changeStatus && (
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                onClick={() => saveStatus()}
                style={{
                  minWidth: "fit-content",
                  marginLeft: "15px",
                  textTransform: "capitalize"
                }}
              >
                Change Status
              </Button>
            )}
          </div>
        )}
      </Grid>
      {props.disabled && (
        <Grid
          container
          spacing={2}
          style={{
            color: "red",
            fontWeight: "bold",
            justifyContent: "center"
          }}
          className={classes.statusContainer}
          justify="space-between"
        >
          This application can not be edited as it is in progress.
        </Grid>
      )}
      {!props.disabled && !props.permission.edit && (
        <Grid
          container
          spacing={2}
          style={{
            color: "red",
            fontWeight: "bold",
            justifyContent: "center"
          }}
          className={classes.statusContainer}
          justify="space-between"
        >
          This application can not be edited as you don't have the permission to
          edit.
        </Grid>
      )}
    </>
  );
};

InfoHeaderSection.propTypes = {
  handlePricingTemplete: PropTypes.func,
  formData: PropTypes.object,
  disabled: PropTypes.bool,
  values: PropTypes.object,
  changeStatus: PropTypes.bool,
  getProviderActivityById: PropTypes.func,
  history: PropTypes.object,
  mainData: PropTypes.object,
  changeSingleStatus: PropTypes.func,
  onChange: PropTypes.func,
  permission: PropTypes.object
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app
});
export default connect(mapStateToProps, null)(InfoHeaderSection);
