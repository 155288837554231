import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

export const exportToCSV = (mappedLogs, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(mappedLogs);

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export function formatSocialSecurity(v) {
  let r = v.replace(/\D/g, "");
  if (r.length > 9) {
    r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
    return r;
  }
  if (r.length > 4) {
    r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
  } else {
    r = r.replace(/^(\d*)/, "$1");
  }
  return r;
}

export function formatPhoneNumExtension(v) {
  let r = v.replace(/\D/g, "");
  if (r.length == 10) {
    return r.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  if (r.length > 5) {
    return r.replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1-$2-$3");
  } else if (r.length > 3) {
    r = r.replace(/(\d\d\d)(\d{0,3})/, "$1-$2");
  } else {
    r = r.replace(/(\d*)/, "$1");
  }
  return null;
}

export function formatPhoneNumber(v) {
  var r = v.replace(/\D/g, "");
  if (r.length > 11) {
    r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    return r;
  } else if (r.length > 6) {
    r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
  } else if (r.length > 3) {
    r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
  } else {
    r = r.replace(/^(\d*)/, "$1");
  }
  return r;
  // // let val;
  // val = val.replace(/\D/g, "");
  // val = val.replace(/^(\d{3})/, "$1-");
  // val = val.replace(/-(\d{2})/, "-$1-");
  // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
  // return val;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const actionPermission = (roleData, catId, pageId, type) => {
  const roles = get(roleData, "leftMenu.data", false);
  if (isEmpty(roles) || !catId || !pageId || !type) {
    return roles;
  }
  if (!isEmpty(roles.LeftMenu)) {
    const mainCat = roles.LeftMenu.filter((cat) => cat.Cat_id === catId);
    let permission = !!mainCat;
    if (!permission) {
      return false;
    }
    const pageDetails = mainCat[0].Page_Menu.filter(
      (page) => page.Page_id === pageId
    );
    permission = !!pageDetails;
    if (!permission) {
      return false;
    }
    const permissionData = pageDetails[0].Options.find(
      (per) => per.value === type
    );
    if (permissionData) {
      return permissionData.permission;
    }
    return false;
  }
};
