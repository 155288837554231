/* eslint-disable import/extensions */
import React, { forwardRef, useRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  ErrorMessage, Field, Form, Formik,
} from "formik";
import { connect } from "react-redux";
import EventNoteIcon from "@material-ui/icons/EventNote";
import moment from "moment";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import FormControl from "@material-ui/core/FormControl";
import { activePatient } from "../../Redux/Actions/TableAction";
import AgentSearchChip from "./AgentSearchChip";

const useStyles = makeStyles(styles);
export function SimpleDialog(props, ref) {
  const [values, setValue] = React.useState(props.searchValue);
  const classes = useStyles();

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValue(allState);
  };

  const handleClose = () => {
    setValue({
      MidOrDba: "",
      cryptoName: "",
      maxAmount: "",
      minAmount: "",
      fromDate: "",
      toDate: "",
      searchAgentId: "",
    });
    props.seacrhChipData({
      MidOrDba: "",
      cryptoName: "",
      maxAmount: "",
      minAmount: "",
      fromDate: "",
      toDate: "",
      searchAgentId: "",
    });
    props.close();
  };

  const onFormSubmit = () => {
    props.seacrhChipData(values);
    props.close();
  };

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize,
              },
            }}
            label="MID / DBA Name"
            value={values?.MidOrDba}
            onChange={e => onChange(e.target.value, "MidOrDba")}
            name="MidOrDba"
            id="MidOrDba"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize,
              },
            }}
            label="Crypto Name"
            value={values?.cryptoName}
            onChange={e => onChange(e.target.value, "cryptoName")}
            name="cryptoName"
            id="cryptoName"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <AgentSearchChip
          onChange={(value, type) => onChange(value, type)}
          values={values}
        />
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: "3%" }}>
        <Grid item xs={6} lg={6}>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Maximum Amount"
            value={values?.maxAmount}
            onChange={e => onChange(e.target.value, "maxAmount")}
            name="maxAmount"
            id="maxAmount"
            style={{ fontSize: "82%" }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          lg={6}
          style={{
            position: "relative",
            display: "inline-flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          <TextField
            className={classes.halfInputSingle}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Minimum Amount"
            value={values?.minAmount}
            onChange={e => onChange(e.target.value, "minAmount")}
            name="minAmount"
            id="minAmount"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "3%" }}>
        <Grid item xs={6} lg={6}>
          <FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                clearable
                disabled={props.disabled}
                onChange={e => {
                  if (e === null) {
                    onChange(e, "fromDate");
                  } else {
                    onChange(moment(e).format("MM/DD/YYYY"), "fromDate");
                    e > new Date()
                      && props.setVlidationError({
                        ...props.validationError,
                        fromDate: true,
                      });
                  }
                }}
                value={
                  values?.fromDate
                    ? moment(values.fromDate).format("MM/DD/YYYY")
                    : null
                }
                required
                label="Transaction Date (From)"
                id="fromDate"
                name="fromDate"
                placeholder="MM/DD/YYYY"
                maxDate={new Date()}
                maxDateMessage="Transaction Date should not be a Future date."
                format="MM/dd/yyyy"
                keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                KeyboardButtonProps={{
                  size: "small",
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                  endAdornment: (
                    <Tooltip title="cds_DateHelp" placement="top">
                      <IconButton edge="end" size="small">
                        <HelpOutlineOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={6} style={{ paddingLeft: "30px" }}>
          <FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                clearable
                disabled={props.disabled}
                onChange={e => {
                  if (e === null) {
                    onChange(e, "toDate");
                  } else {
                    onChange(moment(e).format("MM/DD/YYYY"), "toDate");
                    e > new Date()
                      && props.setVlidationError({
                        ...props.validationError,
                        toDate: true,
                      });
                  }
                }}
                value={
                  values?.toDate
                    ? moment(values.toDate).format("MM/DD/YYYY")
                    : null
                }
                required
                label="Transaction Date (To)"
                id="toDate"
                name="toDate"
                placeholder="MM/DD/YYYY"
                maxDate={new Date()}
                maxDateMessage="Transaction Date should not be a Future date."
                format="MM/dd/yyyy"
                keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                KeyboardButtonProps={{
                  size: "small",
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                  endAdornment: (
                    <Tooltip title="cds_DateHelp" placement="top">
                      <IconButton edge="end" size="small">
                        <HelpOutlineOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          position: "relative",
          display: "inline-flex",
          justifyContent: "right",
          alignItems: "center",
        }}
      >
        <Grid item>
          <br />
          <Button
            cancel
            variant="contained"
            className={classes.clearButton}
            disabled={
              values.MidOrDba === ""
              && values.cryptoName === ""
              && values.maxAmount === ""
              && values.minAmount === ""
              && values.fromDate === ""
              && values.toDate === ""
              && values.searchAgentId === ""
            }
            size="md"
            onClick={() => handleClose()}
          >
            clear
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            size="md"
            onClick={() => onFormSubmit()}
            disabled={
              values.MidOrDba === ""
              && values.cryptoName === ""
              && values.maxAmount === ""
              && values.minAmount === ""
              && values.fromDate === ""
              && values.toDate === ""
              && values.searchAgentId === ""
            }
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  close: PropTypes.func,
  validationError: PropTypes.object,
  searchValue: PropTypes.object,
  seacrhChipData: PropTypes.func,
};

const mapStateToProps = app => ({
  // formData: app.activePatient,
  tableData: app.provideRegistration,
});

export default connect(mapStateToProps, {
  activePatient,
})(SimpleDialog);
