/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./app.css";

const expectedAttr = [
  "coinsuites",
  "coinsuiteId",
  "amount",
  "currency",
  "ref_id",
  "postingMode"
];
const currencyCodes = ["USD", "CAD", "EUR"];
Modal.setAppElement("body");

const WebPay = (props) => {
  const serverUrl = process.env.REACT_APP_API_BASE_PATH;
  const obj = {
    coinsuites: "",
    coinsuiteId: "",
    amount: "",
    currency: "",
    ref_id: "",
    postingMode: process.env.PAYMENT_POST_MODE
  };
  const [state, setInitialState] = useState(obj);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const elem = document.querySelector("[id=coinsuitespay-btn]");
    const stateObj = { ...state };
    expectedAttr.map((attr) => {
      stateObj[attr] = elem.getAttribute(attr);
      return null;
    });
    setInitialState(stateObj);
    elem.innerHTML = `<button class="coinsuitesAction"><img src="https://apps.coinsuites.com/widget/241598bec3befd3f2dc3b3ad6b417e53.svg" style="width:150px"></button>`;
    elem.addEventListener("click", () => handleClick());
  }, [modalIsOpen]);

  const closeModal = () => {
    setIsOpen(false);
    props.setErrorMessage(false);
  };
  const handleCoinSelect = (cryptoCurrency) => {
    const data = { ...state };
    data.cryptoCurrency = cryptoCurrency;
    data.callbackurl = window.location.href.includes("?")
      ? window.location.href.split("?")[0]
      : window.location.href;

    closeModal();
    const redirectUrl = `${serverUrl}/widget-payment?coinsuiteId=${data.coinsuiteId}&amount=${data.amount}&currency=${data.currency}&ref_id=${data.ref_id}&cryptoCurrency=${data.cryptoCurrency}&postingMode=${data.postingMode}&callbackurl=${data.callbackurl}&cancelurl=${data.callbackurl}?cancel_order=true&successurl=${data.callbackurl}?success_order=true`;

    props.setLoading(true);
    axios
      .get(redirectUrl)
      .then((data) => {
        if (data.status == 200) {
          props.setLoading(false);
          props.setErrorMessage(false);
          window.location.replace(
            data.request.responseURL,
            "coinsuite-pay",
            "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=350,height=600,left=500"
          );
        }
      })
      .catch((error) => {
        props.setLoading(false);
        props.setErrorMessage(error.response.data.data);
      });
  };

  if (modalIsOpen) {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        overlayClassName="overlay"
        className="coinsuites-modal"
      >
        <div className="coinsuitesModalInner">
          <div className="coinsuitesModalTop">
            <img
              src="https://apps.coinsuites.com/widget/241598bec3befd3f2dc3b3ad6b417e53.svg"
              className="coinsuiteslogo"
              alt="coinsuites"
            />
          </div>
          <div className="coinsuitesModalContent">
            <div className="section">
              <div>
                Select your Payment Currency
                <button
                  type="button"
                  className="selectBtn"
                  onClick={() => handleCoinSelect("BTC")}
                >
                  <img
                    src="https://apps.coinsuites.com/widget/e8a8b815be2fdcb5a2465a4306d5de1f.png"
                    className="coinselect"
                    alt="Bitcoin"
                  />{" "}
                  Bitcoin
                </button>
                <div>
                  <button
                    type="button"
                    className="selectBtn"
                    onClick={() => handleCoinSelect("ETH")}
                  >
                    <img
                      src="https://apps.coinsuites.com/widget/45bad3a3f28d209ef4bc938bdc2293c3.png"
                      className="coinselect"
                      alt="Ethereum"
                    />{" "}
                    Ethereum
                  </button>
                  <button
                    type="button"
                    className="selectBtn"
                    onClick={() => handleCoinSelect("BCH")}
                  >
                    <img
                      src="https://apps.coinsuites.com/widget/c01fac2042dca4a5468325fc58a45327.png"
                      className="coinselect"
                      alt="Bitcoin Cash"
                    />{" "}
                    Bitcoin Cash
                  </button>
                  <button
                    type="button"
                    className="selectBtn"
                    onClick={() => handleCoinSelect("LIC")}
                  >
                    <img
                      src="https://apps.coinsuites.com/widget/b1555e52a61f8bc51b4017847b2f9e33.png"
                      className="coinselect"
                      alt="Litecoin"
                    />{" "}
                    Litecoin
                  </button>
                </div>
                <button
                  type="button"
                  onClick={closeModal}
                  className="coinsuitesbtn"
                >
                  {" "}
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  return null;
};

export default WebPay;

// spotPayment - manual entry
/*
  Payment Method - options[BTC]
  Invoide id
  Amount
  Currency - 
*/

// spotPayment - false
// Payment Method - options[BTC]

// required fields for API
/**
 * ref_id  - invoice no.
 * currency - USD/EURO
 * amount - number
 * cryptoCurrency - BTC
 * coinsuites_id - merchant id
 */
