/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Card from "components/Card/Card";
import TextField from "@material-ui/core/TextField";
import closeImage from "assets/img/Close_Image.png";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import { verifyPaymentOTP } from "Redux/Actions/genrateOfferPatient";
import verifyedVisa from "../../assets/img/verifyed-visa.png";

const useStyles = makeStyles(styles);
const PaymentOtp = (props) => {
  const classes = useStyles();
  const [validationModal, setValidationModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const [submissionSuccess, setSubmissionSuccess] = React.useState(
    props.formData.verifyOTPData
  );
  const [values, handleInput] = React.useState({
    otpNumber: "",
  });

  const [validationError, setValidationError] = React.useState({
    otpNumber: true,
  });

  const checkValidation = () => {
    const validationValues = { ...validationError };
    let validate = true;
    for (const key in values) {
      if (values.otpNumber === "") {
        validationValues[key] = true;
        validate = false;
      } else {
        validationValues[key] = false;
      }
    }
    setValidationError(validationValues);
    return validate;
  };

  const submitVerifyPaymentOTP = () => {
    if (checkValidation()) {
      const data = {
        messageId: props.formData.sentOTPData.data,
        countryCode: "91",
        mobile: props.formData.paymentDetailsData.data.mobile,
        otpCode: values.otpNumber,
      };
      props.verifyPaymentOTP(data);
      setSubmissionSuccess(props.formData.verifyOTPData);
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
  };

  React.useEffect(() => {
    if (submissionSuccess !== props.formData.verifyOTPData) {
      if (props.formData.verifyOTPData && props.formData.verifyOTPData.status) {
        props.onFormSubmit();
        props.setOtpConfirmation();
        setValidationModal(false);
      } else if (
        props.formData.verifyOTPData &&
        !props.formData.verifyOTPData.status
      ) {
        setErrorMessage(props.formData.verifyOTPData.message);
        setValidationModal(true);
      }
    }
  }, [submissionSuccess, props]);

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    error[type] = false;
    allState[type] = value;
    handleInput(allState);
    setValidationError(error);
  };

  const handleBackToSearch = () => {
    if (!props.patientDashboard.offerAccountStatusData) {
      if (props.formData.emailState) {
        props.history.push("/admin/email/offer");
      } else {
        setOpenCancelModal(false);
        props.history.push("/admin/offerpayment");
        props.state();
      }
    } else {
      props.history.push("/patient/Dashboard");
      props.state();
    }
  };

  const handleErrorMessage = () => {
    setValidationModal(false);
    setErrorMessage(false);
  };

  let lastCardNumber;
  if (props.formData.paymentDetailsData.data.Last4CardNumber !== "") {
    lastCardNumber = props.formData.paymentDetailsData.data.Last4CardNumber;
  } else {
    lastCardNumber = props.values.cardNumber;
  }

  return (
    <>
      <Modal open={openCancelModal} onClose={() => setOpenCancelModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "60%",
                height: "150px",
                paddingBottom: "5%",
              }}
            >
              <Grid container className={classes.margin}>
                <Grid
                  item
                  xs
                  style={{
                    margin: "10%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "-webkit-center",
                  }}
                >
                  Do You want to cancel the process of making payment?
                </Grid>
              </Grid>
              <Grid container className={classes.margin}>
                <Grid item xs={2} />
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    style={{ height: "30px" }}
                    color="#696969"
                    size="sm"
                    onClick={() => handleBackToSearch()}
                  >
                    Ok
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    className={classes.clearButton}
                    color="#696969"
                    size="sm"
                    onClick={() => setOpenCancelModal(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal open={validationModal} onClose={() => setValidationModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div className={classes.cardDiv}>
              <img src={closeImage} width="30%" alt="" />
              <div className={classes.successText}>
                {`${
                  validationError.otpNumber
                    ? "One time code field is mandatory. Enter a valid one time code"
                    : ""
                }`}
              </div>
              {errorMessage && (
                <div className={classes.successText}>
                  <div className={classes.successText}>{errorMessage} </div>
                </div>
              )}
              <br />
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="sm"
                onClick={() => handleErrorMessage()}
              >
                Ok
              </Button>
            </div>
          </Card>
        </div>
      </Modal>
      <Grid container style={{ marginTop: "5%", marginBottom: "5%" }}>
        <Grid item xs={6}>
          <img
            src={verifyedVisa}
            alt=""
            style={{ width: "30%", marginLeft: "15%" }}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <img src={fullOrigin} align="right" style={{marginRight: "15%"}}/> */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs
          style={{
            marginLeft: "8%",
            fontSize: "21px",
            fontWeight: "bold",
            color: "#23A1BE",
          }}
        >
          {`Dear ${props.values.firstName}`}
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs
          style={{
            marginLeft: "10%",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#A6A6A6",
            marginBottom: "2%",
            marginTop: "1%",
          }}
        >
          Please submit your Verified by Visa Saved Payment Information
        </Grid>
      </Grid>
      <div align="center">
        <Grid container>
          <Grid item xs={2} />
          <Grid item xs={3} align="right" style={{ fontWeight: "600" }}>
            Provider Name
          </Grid>
          <Grid
            item
            xs={1}
            align="left"
            style={{ maxWidth: "3%", fontWeight: "600" }}
          >
            :{" "}
          </Grid>
          <Grid item xs={3} align="left">
            {props.formData.paymentDetailsData.data.providerName}
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container>
          <Grid item xs={2} />
          <Grid item xs={3} align="right" style={{ fontWeight: "600" }}>
            Amount
          </Grid>
          <Grid
            item
            xs={1}
            align="left"
            style={{ maxWidth: "3%", fontWeight: "600" }}
          >
            :{" "}
          </Grid>
          <Grid item xs={3} align="left" style={{ fontWeight: "600" }}>
            {props.formData.paymentDetailsData.data.amount}
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container>
          <Grid item xs={2} />
          <Grid item xs={3} align="right" style={{ fontWeight: "600" }}>
            Date
          </Grid>
          <Grid
            item
            xs={1}
            align="left"
            style={{ maxWidth: "3%", fontWeight: "600" }}
          >
            :{" "}
          </Grid>
          <Grid item xs={3} align="left">
            {moment(new Date()).format("MM/DD/YYYY")}
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container>
          <Grid item xs={2} />
          <Grid item xs={3} align="right" style={{ fontWeight: "600" }}>
            Card Number
          </Grid>
          <Grid
            item
            xs={1}
            align="left"
            style={{ maxWidth: "3%", fontWeight: "600" }}
          >
            :{" "}
          </Grid>
          <Grid item xs={3} align="left">
            XXXX XXXX XXXX
            {lastCardNumber.substr(-4)}
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container>
          <Grid item xs={2} />
          <Grid
            item
            xs={3}
            align="right"
            style={{ fontSize: "18px", fontWeight: "600", color: "#AD9447" }}
          >
            One Time Code
          </Grid>
          <Grid
            item
            xs={1}
            align="left"
            style={{
              maxWidth: "3%",
              fontSize: "18px",
              fontWeight: "600",
              color: "#AD9447",
            }}
          >
            :{" "}
          </Grid>
          <Grid item xs={2} align="left">
            <TextField
              required
              error={validationError.otpNumber}
              className={classes.halfInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
                required: true,
              }}
              value={values.otpNumber}
              onChange={(e) => onChange(e.target.value, "otpNumber")}
              name="otpNumber"
              id="otpNumber"
            />
          </Grid>
          <Grid
            item
            xs={2}
            align="left"
            style={{ fontSize: "18px", fontWeight: "600", color: "#F3000F" }}
          >
            02:45
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5} />
          <Grid
            item
            xs={6}
            align="left"
            style={{
              fontSize: "14px",
              color: "#1AAA71",
              marginTop: "1%",
              paddingLeft: "3%",
            }}
          >
            Enter One Time Code sent to{" "}
            {`91-${props.formData.paymentDetailsData.data.mobile.substr(
              0,
              2
            )}XXXXXX${props.formData.paymentDetailsData.data.mobile.substr(
              -2
            )}`}{" "}
            to access Saved Cards
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container>
          <Grid item xs={5} />
          <Grid
            item
            xs={2}
            align="left"
            style={{
              fontSize: "12px",
              color: "#3C63A5",
              marginTop: "2%",
              textDecoration: "underline",
              cursor: "pointer",
              marginLeft: "3%",
            }}
          >
            Resend One Time Code
          </Grid>
          <Grid item xs={5} />
        </Grid>
        <Grid container style={{ marginTop: "1%", marginBottom: "5%" }}>
          <Grid item xs={6} align="right">
            <Button
              variant="contained"
              className={classes.buttonStyle}
              color="#696969"
              size="large"
              onClick={() => submitVerifyPaymentOTP()}
            >
              Submit
            </Button>
          </Grid>
          <Grid
            item
            xs={1}
            align="right"
            style={{
              fontSize: "12px",
              color: "#3C63A5",
              marginTop: "1%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Help
          </Grid>
          <Grid
            item
            xs={1}
            align="right"
            style={{
              fontSize: "12px",
              color: "#3C63A5",
              marginTop: "1%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            <div onClick={() => setOpenCancelModal(true)}>Cancel</div>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </div>
    </>
  );
};

PaymentOtp.propTypes = {
  verifyPaymentOTP: PropTypes.object,
  formData: PropTypes.object,
  history: PropTypes.object,
  state: PropTypes.func,
  values: PropTypes.object,
  onFormSubmit: PropTypes.func,
  patientDashboard: PropTypes.object,
  setOtpConfirmation: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  userProvider: app.users,
  patientDashboard: app.patientDashboard,
});

export default connect(mapStateToProps, { verifyPaymentOTP })(PaymentOtp);
