/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar";
import jwt from "jsonwebtoken";
import Sidebar from "components/Sidebar/Sidebar";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DashboardPage from "views/Dashboard/Dashboard";
import BusinessForm from "views/UserProfile/BuainessInformation";
import BusinessFormOpen from "views/PricingTemplete/BuainessInformation";
import Roles from "views/Reports/Roles";
import GenrateOffer from "components/GenrateOffer/index";
import PaymentGenrateOffer from "views/PaymentOffer/index";
import ActivePatient from "views/Reports/ActivePatient";
import DailyDeposite from "views/Reports/DailyDeposit";
import MoveToCollection from "views/Reports/MoveToCollection";
import Defaults from "views/Reports/Defaults";
import TotalOutstanding from "views/Reports/TotalOutstanding";
import BadDebts from "views/Reports/BadDebts";
import AppLoader from "components/AppLoader/Index";
import CptCode from "views/Reports/CptCode";
import ResetPassword from "views/Login/NewLogin/ResetPassword";
import SessionOut from "views/Login/SessionOut";
import MemberRegistration from "views/Reports/memberRegistration";
import ProviderReport from "views/Reports/Provider";
import RoleManager from "views/RoleManager/Index";
import PartnerInvite from "views/RoleManager/PartnerInvite";
import ProviderActivity from "views/Reports/ProviderActivityHistory";
import Configuration from "views/Reports/Configuration";
import ProviderParameter from "views/ProviderParameter/Index";
import EmailGenrateOffer from "views/PaymentOffer/EmailFinalGenrateOffer";
import FinalGenrateOffer from "views/PaymentOffer/FinalGenrateOffer";
import PatientPaymentDashboard from "views/Patient/PatientPaymentDetails";
import Payments from "views/Payment/PaymentOffer";
import PatientRegister from "views/Patient/PatientRegister";
import OfferDetails from "views/OfferDetails/Index";
import OfferPayment from "views/Offerpayment/NewOfferPayment";
import OfferPrintPatient from "views/OfferPrintPatient/Index";
import PaymentOtp from "views/Payment/PaymentOtp";
import AddPatientForm from "views/Patient/AddPatient";
import OnBording from "views/OnBoarding/Index";
import NewRolePage from "views/RoleManager/NewRolePage";
import logo from "../assets/img/7OHMVp.jpg";
import bgImage from "../assets/img/sidebar-2.jpg";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle";
import NewLogin from "../views/Login/NewLogin/NewLogin";
import {
  getPatientDetailsEmail,
  getPatientInfoEmail,
  checkResetPasswordAuthCall,
  setUserIdResetPaassword,
  emptyPatientSelect
} from "../Redux/Actions/genrateOfferPatient";
import PdfComponent from "../components/Pdf/index";
import {
  setAppBarName,
  setUserRole,
  setLayoutName,
  clientThemeCall,
  getUserLeftMenuCall,
  clientPortalConfig
} from "../Redux/Actions/LoginActions";
import {
  getUserList,
  changeUserFormMode,
  getUserListProvider,
  setOroviderListEmpty,
  getUserDetailsById,
  setUserSearchEmpty
} from "../Redux/Actions/UserRegistration";
import {
  getProviderList,
  getProviderDetailsById,
  changeProviderFormMode
} from "../Redux/Actions/ProvideRegistration";
import { resetReducer } from "../Redux/Actions/appActions";
import {
  changeRoleFormMode,
  rolePermisstionRequest
} from "../Redux/Actions/RoleRegistration";
import Patients from "../views/Patient/Dashboard";
import { checkLayoutName } from "../Uintls/gloables";
import { logout } from "../Redux/Actions/LoginActions";
import OpenFunding from "../views/PatientReport/OpenFunding";
import PendingOffer from "../views/PatientReport/PendingOffers";
import AccountStatement from "../views/PatientReport/AccountStatement";
import AdminSettings from "../views/PanelConfig/ProvidersSettings";
import Products from "../views/Transactions/Products";
import OrderCart from "../views/Transactions/OrderCart";
import NewPayments from "../views/Transactions/Payments";
import GroupsDataList from "../views/Reports/Groups";
import MobilePosCheckout from "../views/Transactions/MobilePosCheckout";

const switchRoutes = (value, rest) => (
  <Switch>
    <Route exact path="/" component={NewLogin} />
    <Route exact path="/login" component={NewLogin} />
    <Route
      exact
      path="/resetPassword"
      component={() => <ResetPassword state={value} prop={rest} />}
    />
    <Route
      exact
      path="/sessionOut"
      component={() => <SessionOut state={value} prop={rest} />}
    />
    <Route path="/admin/email/offer" component={EmailGenrateOffer} />
    <Route path="/admin/print/offer" component={FinalGenrateOffer} />
    <Route path="/admin/email/register" component={PatientRegister} />
    <Route path="/admin/paymentotp" component={PaymentOtp} />
    <Route
      path="/tpo/payments"
      render={(props) => <Payments state={value} {...props} />}
    />
    <Route
      path="/patient/patientPaymentDetails"
      render={(props) => <PatientPaymentDashboard state={value} {...props} />}
    />
    <Route
      path={`/${rest.userDetails.layout}/Dashboard`}
      component={
        rest.userDetails.layout === "patient" ? Patients : DashboardPage
      }
    />
    <Route path={`/${rest.userDetails.layout}/user`} component={BusinessForm} />
    <Route
      path={`/${rest.userDetails.layout}/addpatient`}
      component={AddPatientForm}
    />
    <Route path={`/${rest.userDetails.layout}/Roles`} component={NewRolePage} />
    <Route
      path={`/${rest.userDetails.layout}/SendInvite`}
      component={OnBording}
    />
    <Route
      path={`/${rest.userDetails.layout}/Offers`}
      render={(props) => <PaymentGenrateOffer state={value} {...props} />}
    />
    <Route
      path={`/${rest.userDetails.layout}/panelConfigurations`}
      render={(props) => <AdminSettings state={value} {...props} />}
    />
    <Route
      path={`/${rest.userDetails.layout}/OpenFunding`}
      render={(props) => <OpenFunding state={value} {...props} />}
    />
    <Route
      path={`/${rest.userDetails.layout}/PendingOffer`}
      render={(props) => <PendingOffer state={value} {...props} />}
    />
    <Route
      path={`/${rest.userDetails.layout}/AccountStatement`}
      render={(props) => <AccountStatement state={value} {...props} />}
    />
    <Route
      path={`/${rest.userDetails.layout}/offerDetails`}
      render={(props) => <OfferDetails state={value} {...props} />}
    />
    <Route
      path={`/${rest.userDetails.layout}/offerpayment`}
      render={(props) => <OfferPayment state={value} {...props} />}
    />
    <Route
      path={`/${rest.userDetails.layout}/printoffer`}
      render={(props) => <OfferPrintPatient state={value} {...props} />}
    />
    <Route
      path={`/${rest.userDetails.layout}/GenerateOffer`}
      component={GenrateOffer}
    />
    <Route
      path={`/${rest.userDetails.layout}/Transactions`}
      component={ActivePatient}
    />
    <Route
      path={`/${rest.userDetails.layout}/DailyDeposit`}
      component={DailyDeposite}
    />
    <Route
      path={`/${rest.userDetails.layout}/Collections`}
      component={MoveToCollection}
    />
    <Route
      path={`/${rest.userDetails.layout}/providerParameter`}
      component={ProviderParameter}
    />
    <Route path={`/${rest.userDetails.layout}/Defaults`} component={Defaults} />
    <Route path={`/${rest.userDetails.layout}/pdf`} component={PdfComponent} />
    <Route
      path={`/${rest.userDetails.layout}/TotalOutstanding`}
      component={TotalOutstanding}
    />
    <Route path={`/${rest.userDetails.layout}/BedDebts`} component={BadDebts} />
    <Route path={`/${rest.userDetails.layout}/CPTCode`} component={CptCode} />
    <Route
      path={`/${rest.userDetails.layout}/MemberRegister`}
      component={MemberRegistration}
    />
    <Route
      path={`/${rest.userDetails.layout}/Live`}
      component={ProviderReport}
    />
    <Route
      path={`/${rest.userDetails.layout}/InProgress`}
      component={ProviderReport}
    />
    <Route path={`/${rest.userDetails.layout}/Users`} component={RoleManager} />
    <Route
      path={`/${rest.userDetails.layout}/send-partner-invite`}
      component={PartnerInvite}
    />
    <Route
      path={`/${rest.userDetails.layout}/merchant-users`}
      component={RoleManager}
    />
    <Route
      path={`/${rest.userDetails.layout}/groups`}
      component={GroupsDataList}
    />
    <Route
      path={`/${rest.userDetails.layout}/merchantactivity`}
      component={ProviderActivity}
    />
    <Route
      path={`/${rest.userDetails.layout}/configuration`}
      component={Configuration}
    />
    <Route path={`/${rest.userDetails.layout}/products`} component={Products} />
    <Route path={`/${rest.userDetails.layout}/Paynow`} component={OrderCart} />
    <Route
      path={`/${rest.userDetails.layout}/payments`}
      component={NewPayments}
    />
    <Route
      path={`/${rest.userDetails.layout}/InvoiceCheckout`}
      component={MobilePosCheckout}
    />
    <Route
      path={`/${rest.userDetails.layout}/pricingTemplete`}
      component={BusinessFormOpen}
    />
    {/* <Route path={`/${rest.userDetails.layout}/WooCommerce`} /> */}
  </Switch>
);

function Admin({ ...rest }) {
  const useStyles = makeStyles(styles);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [preUserByIdDetails, setPreUserByIdDetails] = React.useState(
    rest.userProvider.userDetailsById
  );
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  const [state, setState] = React.useState(false);
  const [hoverDrawer, setOpenDrawerHover] = React.useState(false);
  // states and functions
  const [image] = React.useState(bgImage);
  const [callRolePermission, setCallRolePermission] = React.useState(false);
  const [color] = React.useState("blue");
  const [preWindowDetails, setPreWindowDetails] = React.useState(false);
  const [openDrawer, setOpen] = React.useState(false);
  const [preProviderListData, setPreProviderListData] = React.useState(
    rest.tableData.providerList
  );
  const [preDatas, setPreData] = React.useState(false);

  React.useEffect(() => {
    if (preProviderListData !== rest.tableData.providerList) {
      setPreProviderListData(rest.tableData.providerList);
      if (
        rest.tableData.providerList &&
        rest.tableData.providerList.Providers_Data &&
        !rest.tableData.search
      ) {
        const providerListLength =
          rest.tableData.providerList.Providers_Data.length;
        if (providerListLength === 1) {
          const merchantId =
            rest.tableData.providerList.Providers_Data[0].Provider_id;
          const lastPath = localStorage.getItem("last");
          if (lastPath !== "/admin/InvoiceCheckout") {
            rest.history.push(`/${rest.userDetails.layout}/user`);
            rest.getProviderDetailsById(merchantId);
            rest.changeProviderFormMode("Edit");
          }
        }
      }
    }
  }, [rest]);

  React.useEffect(() => {
    if (preUserByIdDetails !== rest.userProvider.userDetailsById) {
      if (!rest.app.roles.roleTab) {
        if (
          rest.userProvider.userDetailsById &&
          rest.userProvider.userDetailsById &&
          rest.userProvider.userDetailsById.Users_Data &&
          rest.userProvider.userDetailsById.Users_Data.length > 0
        ) {
          const data = {
            role_id: rest.userProvider.userDetailsById.Users_Data[0].RoleID
          };
          setCallRolePermission(true);
          rest.rolePermisstionRequest(data, "admin");
          setPreUserByIdDetails(rest.userProvider.userDetailsById);
        }
      }
    }
  }, [rest.userProvider, rest]);

  React.useEffect(() => {
    let withLocal = true;
    if (!preWindowDetails && window.location.pathname.match("Offers/email")) {
      const splitValue = window.location.pathname.split("/");
      setPreWindowDetails(true);
      setPreData(true);
      const data = {
        guid: splitValue[splitValue.length - 1]
      };
      rest.getPatientDetailsEmail(data);
      rest.history.push("/admin/email/offer");
    } else if (window.location.pathname.match("register")) {
      const splitValue = window.location.pathname.split("/");
      const data = {
        patientId: splitValue[splitValue.length - 1]
      };
      rest.getPatientInfoEmail(data);
      withLocal = false;
      rest.history.push("/admin/email/register");
    } else if (window.location.pathname.match("password")) {
      withLocal = false;
      if (window.location.pathname.match("user")) {
        const splitValue = window.location.pathname.split("/");
        rest.setUserIdResetPaassword(splitValue[splitValue.length - 1]);
        rest.history.push("/admin/email/register");
      } else {
        const splitValue = window.location.pathname.split("/");
        const data = {
          emailToken: splitValue[splitValue.length - 1]
        };
        withLocal = false;
        rest.checkResetPasswordAuthCall(data);
        rest.history.push("/admin/email/register");
      }
    } else if (
      !localStorage.length &&
      !window.location.pathname.match("register") &&
      !window.location.pathname.match("login") &&
      !window.location.pathname.match("newLogin")
    ) {
      rest.history.push("/");
    }
    if (!rest.userRole.userList && withLocal) {
      const preData = localStorage.getItem("sidebar");
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");
      if (preData && !preDatas && token) {
        jwt.verify(
          localStorage.getItem("token").split(" ")[1],
          "Support@Vaminfosyshserus",
          (err, decoded) => {
            if (decoded !== undefined) {
              rest.clientThemeCall(decoded.ClientID);
              rest.clientPortalConfig(decoded.ClientID);
              rest.getUserLeftMenuCall();
              rest.getUserDetailsById(decoded.UserID);
              rest.setUserRole(decoded);
            }
          }
        );
        rest.setLayoutName(checkLayoutName(JSON.parse(preData).Profile_Code));
        const idealPath = "";
        let LandingPage = "/";
        let MainCat = "";
        if (idealPath) {
          LandingPage = idealPath.landingPageName;
        } else {
          LandingPage = JSON.parse(preData).LandingPage;
          MainCat = JSON.parse(preData).Main_Category_LandingPage;
        }
        rest.setAppBarName(
          MainCat ? `${MainCat} / ${LandingPage}` : LandingPage
        );
        const layoutName = checkLayoutName(JSON.parse(preData).Profile_Code);
        rest.clientThemeCall(JSON.parse(preData).ClientID);
        rest.clientPortalConfig(JSON.parse(preData).ClientID);
        rest.getUserDetailsById(userId);
        const lastPath = localStorage.getItem("last");
        if (idealPath) {
          rest.history.push(idealPath.path);
        } else if (lastPath === "") {
          rest.history.push("/");
        } else if (lastPath === "/admin/user") {
          rest.history.push("/admin/Live");
        }
        setPreData(true);
      }
    }
  }, []);
  if (rest?.app?.app?.leftMenu?.data !== undefined) {
    localStorage.setItem(
      "sidebar",
      JSON.stringify(rest?.app?.app?.leftMenu?.data)
    );
  }

  if (localStorage.getItem("sessionExpired") === "true") {
    rest.history.push("/sessionOut");
    localStorage.clear();
  }

  const routeState = () => {
    setState(!state);
  };

  if (window.performance.navigation.type === 1) {
    localStorage.setItem("last", window.location.pathname);
  } else {
    console.log("This page is not reloaded");
  }

  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/sessionOut" &&
    window.location.pathname !== "/Login1" &&
    window.location.pathname !== "/login" &&
    window.location.pathname !== "/" &&
    window.location.pathname !== "/admin/email/offer" &&
    window.location.pathname !== "/admin/print/offer" &&
    window.location.pathname !== "/tpo/payments" &&
    window.location.pathname !== "/admin/email/register" &&
    window.location.pathname !== "/resetPassword" &&
    window.location.pathname !== "/newLogin"
  ) {
    if (!rest.app.app.mainAppLoading) {
      return (
        <div className={classes.wrapper}>
          <AppLoader
            loader={
              rest.app.genrateOfferPatient.loading ||
              rest.app.provideRegistration.loading ||
              rest.app.roles.loading ||
              rest.app.table.loading ||
              rest.app.userRole.loading ||
              rest.app.users.loading
            }
          />
          {openDrawer && !windowWidth && (
            <Sidebar
              logoText="Vam Infosys"
              logo={logo}
              image={image}
              setState={() => setState(!state)}
              open={openDrawer}
              setopen={() => setOpen(false)}
              hoverDrawer={hoverDrawer}
              setOpenDrawerHover={(value) => setOpenDrawerHover(value)}
              color={color}
              {...rest}
            />
          )}
          {windowWidth && (
            <Sidebar
              logoText="Vam Infosys"
              logo={logo}
              image={image}
              setState={() => setState(!state)}
              open={openDrawer}
              setopen={() => setOpen(false)}
              hoverDrawer={hoverDrawer}
              setOpenDrawerHover={(value) => setOpenDrawerHover(value)}
              color={color}
              {...rest}
            />
          )}
          {/* <IdleTimer
          ref={idleTimer}
          element={document}
          onActive={() => onActive()}
          onIdle={() => onIdle()}
          onAction={() => onAction()}
          debounce={250}
          timeout={timeOutLimit}
        /> */}
          <div
            className={classNames({
              [classes.mainPanelHide]: !openDrawer && true,
              [classes.mainPanel]: openDrawer && true
            })}
            ref={mainPanel}
          >
            <Navbar
              setopen={() => setOpen(!openDrawer)}
              setState={() => setState(!state)}
              openDrawer={openDrawer}
              hoverDrawer={hoverDrawer}
              setOpenDrawerHover={(value) => setOpenDrawerHover(value)}
              setCallRolePermission={() => setCallRolePermission(false)}
              color={color}
              {...rest}
            />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            <div className={classes.content}>
              <div className={classes.container}>
                {switchRoutes(routeState, { ...rest })}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <AppLoader loader />
      </div>
    );
  }
  const value = { ...rest };
  return (
    <div>
      <AppLoader
        loader={
          rest.app.provideRegistration.loading ||
          rest.app.roles.loading ||
          rest.app.table.loading ||
          rest.app.userRole.loading ||
          rest.app.users.loading
        }
      />
      {switchRoutes(routeState, value)}
    </div>
  );
}

Admin.propTypes = {
  userDetails: PropTypes.object,
  userRole: PropTypes.object,
  setAppBarName: PropTypes.func,
  getUserList: PropTypes.func,
  changeUserFormMode: PropTypes.func,
  getUserListProvider: PropTypes.func,
  setLayoutName: PropTypes.func,
  setOroviderListEmpty: PropTypes.func,
  resetReducer: PropTypes.func,
  getPatientDetailsEmail: PropTypes.func,
  getPatientInfoEmail: PropTypes.func,
  genrateOffer: PropTypes.object,
  app: PropTypes.object,
  logout: PropTypes.func,
  checkResetPasswordAuthCall: PropTypes.func,
  setUserIdResetPaassword: PropTypes.func,
  clientThemeCall: PropTypes.func,
  clientPortalConfig: PropTypes.func,
  getProviderList: PropTypes.func,
  tableData: PropTypes.object,
  getProviderDetailsById: PropTypes.func,
  changeProviderFormMode: PropTypes.func,
  rolePermisstionRequest: PropTypes.func,
  getUserDetailsById: PropTypes.func,
  getUserLeftMenuCall: PropTypes.func,
  setUserSearchEmpty: PropTypes.func
};

const mapStateToProps = (app) => ({
  userRole: app.userRole,
  userProvider: app.users,
  tableData: app.provideRegistration,
  app,
  userDetails: app.app,
  genrateOffer: app.genrateOfferPatient
});

export default connect(mapStateToProps, {
  setAppBarName,
  logout,
  getProviderList,
  getProviderDetailsById,
  changeProviderFormMode,
  getUserList,
  changeUserFormMode,
  changeRoleFormMode,
  setUserRole,
  getUserListProvider,
  setLayoutName,
  setOroviderListEmpty,
  resetReducer,
  getPatientDetailsEmail,
  getPatientInfoEmail,
  checkResetPasswordAuthCall,
  emptyPatientSelect,
  setUserIdResetPaassword,
  clientThemeCall,
  clientPortalConfig,
  rolePermisstionRequest,
  getUserDetailsById,
  getUserLeftMenuCall,
  setUserSearchEmpty
})(Admin);
