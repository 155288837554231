/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Card from "components/Card/Card";
import Store from "@material-ui/icons/Store";
import { Grid, Typography } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import { useTheme } from "@material-ui/core/styles";
import CardFooter from "components/Card/CardFooter";
import CardBody from "components/Card/CardBody";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Accessibility from "@material-ui/icons/Accessibility";
import RippleLoading from "components/RippleCard/Index";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  getAllActivePlanList,
  getOpenFundingAmount,
  getPendingOfferAmount,
  getPaymentStatus,
  getOfferAccountStatus,
  getOriginalLoanTerm,
  getRemainingFundingGraph,
  getPaymentDetailsList,
  getOfferDetailsPatient,
} from "Redux/Actions/PatientDashboard";
import Ripple from "components/RippleEffect/Ripple";
import Button from "components/CustomButtons/Button";
import { Link } from "react-router-dom";
import styles from "./Dashboard.style";
import RemainingFund from "../../components/Graph/remainingFund";
import VisaImage from "../../assets/img/visa.png";
import MasterImage from "../../assets/img/masterCard.png";

const useStyles = makeStyles(styles);

const Patients = props => {
  const classes = useStyles();
  const [allActiveOOffer, setAllActiveOOffer] = React.useState(
    props.tableData.activePlanPatientListlse
      && props.tableData.activePlanPatientListlse.data[0].offerId
  );
  const [initailCall, setInitailCall] = React.useState(false);
  const [callOfferIdAfter, setCallOfferIdAfter] = React.useState(false);
  const [remainingFundData, setRemainingFundData] = React.useState(
    props.tableData.remainingFundingData
  );
  const [prePaymentList, setPrePaymentList] = React.useState(
    props.tableData.paymentList
  );
  const [preOfferDetails, setPreOfferDetails] = React.useState(
    props.tableData.offetDetailsPatient
  );
  const theme = useTheme();
  const [values, setValues] = React.useState("");

  if (!initailCall) {
    const request = {
      userId: localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : props.userDetails.UserID,
      ClientId: localStorage.getItem("sendApiClientId")
        ? JSON.stringify(localStorage.getItem("sendApiClientId"))
        : props.userDetails.Role_Permission_Data.ProviderId,
    };
    props.getAllActivePlanList(request);
    props.getOpenFundingAmount(request);
    props.getPendingOfferAmount(request);
    props.getRemainingFundingGraph(request);
    setInitailCall(true);
  }
  if (
    !callOfferIdAfter
    && props.tableData.activePlanPatientList
    && props.tableData.activePlanPatientList.status
    && props.tableData.activePlanPatientList.data[0].offerId
  ) {
    const offerIdValueData = {
      offerId: props.tableData.activePlanPatientList.data[0].offerId,
    };
    setValues(props.tableData.activePlanPatientList.data[0].offerId);
    props.getPaymentStatus(offerIdValueData);
    props.getOriginalLoanTerm(offerIdValueData);
    props.getOfferAccountStatus(offerIdValueData);
    setCallOfferIdAfter(true);
  }
  React.useEffect(() => {
    if (remainingFundData !== props.tableData.remainingFundingData) {
      setRemainingFundData(props.tableData.remainingFundingData);
    }
    if (prePaymentList !== props.tableData.paymentList) {
      setPrePaymentList(props.tableData.paymentList);
      if (
        props.tableData.paymentList
        && props.tableData.paymentList.status
        && props.tableData.offetDetailsPatient
        && props.tableData.offetDetailsPatient.status
      ) {
        props.history.push("/patient/patientPaymentDetails");
      }
    }
    if (preOfferDetails !== props.tableData.offetDetailsPatient) {
      setPreOfferDetails(props.tableData.offetDetailsPatient);
      if (
        props.tableData.paymentList
        && props.tableData.paymentList.status
        && props.tableData.offetDetailsPatient
        && props.tableData.offetDetailsPatient.status
      ) {
        props.history.push("/patient/patientPaymentDetails");
      }
    }
  }, [props]);

  const handlePaymentChange = () => {
    const paymentListPatient = {
      patientId: props.tableData.activePlanPatientList.data[0].patientId,
    };
    const offerDetailsdata = {
      offerId: values,
    };
    props.getPaymentDetailsList(paymentListPatient);
    props.getOfferDetailsPatient(offerDetailsdata);
  };

  const onChange = value => {
    const offerIdValue = {
      offerId: value,
    };
    props.getPaymentStatus(offerIdValue);
    props.getOriginalLoanTerm(offerIdValue);
    props.getOfferAccountStatus(offerIdValue);
    setValues(value);
  };

  const hexToRgb = input => {
    input += "";
    input = input.replace("#", "");
    const hexRegex = /[0-9A-Fa-f]/g;
    if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
      throw new Error("input is not a valid hex color.");
    }
    if (input.length === 3) {
      const first = input[0];
      const second = input[1];
      const last = input[2];
      input = first + first + second + second + last + last;
    }
    input = input.toUpperCase();
    const first = input[0] + input[1];
    const second = input[2] + input[3];
    const last = input[4] + input[5];
    return `${parseInt(first, 16)}, ${parseInt(second, 16)}, ${parseInt(
      last,
      16
    )}`;
  };

  return (
    <div>
      <Grid container style={{ marginTop: "-3%" }}>
        <Grid container direction="row" spacing={3}>
          <Grid container item xs={8} spacing={3}>
            <Grid item xs={6}>
              {/* <div style={{ padding: "1%", width: "60%" }}> */}
              {props.tableData.openFundingData ? (
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon color="primary">
                      <Store />
                    </CardIcon>
                    <h3 className={classes.cardTitle}>
                      {`${props.tableData.openFundingData.totalCount}`}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <h3 className={classes.cardTitle}>
                      {`${props.tableData.openFundingData.totalFundAmount}`}
                    </h3>
                    <Typography
                      style={{ color: "#c3cbc3", fontSize: "16px" }}
                      align="center"
                    >
                      Open Funding Accounts
                    </Typography>
                  </CardBody>
                  <CardFooter stats>
                    <Link to="/patient/OpenFunding?redirected=Dashboard">
                      View Details
                    </Link>
                  </CardFooter>
                </Card>
              ) : (
                <RippleLoading />
              )}
              {/* </div> */}
            </Grid>
            <Grid item xs={6}>
              {/* <div style={{ padding: "1%", width: "60%" }}> */}
              {props.tableData.patientOfferAmountData ? (
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon color="primary">
                      <Store />
                    </CardIcon>
                    <h3 className={classes.cardTitle}>
                      {`${props.tableData.patientOfferAmountData.totalCount}`}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <h3 className={classes.cardTitle}>
                      {`${props.tableData.patientOfferAmountData.pendingAmount}`}
                    </h3>
                    <Typography
                      style={{ color: "#c3cbc3", fontSize: "16px" }}
                      align="center"
                    >
                      Pending Offers
                    </Typography>
                  </CardBody>
                  <CardFooter stats>
                    <Link to="/patient/PendingOffer?redirected=Dashboard">
                      View Details
                    </Link>
                  </CardFooter>
                </Card>
              ) : (
                <RippleLoading />
              )}
              {/* </div> */}
            </Grid>
            <Grid
              alignItems="center"
              style={{ marginTop: "-21px" }}
              item
              xs={12}
            >
              {props.tableData.activePlanPatientList ? (
                <Select
                  defaultValue={JSON.stringify(allActiveOOffer)}
                  id="offerId"
                  style={{
                    width: "99%",
                    fontWeight: "bold",
                    color: "#887b7b",
                    marginLeft: "2%",
                  }}
                  value={values}
                  selectMenu
                  name="offerId"
                  onChange={e => onChange(e.target.value)}
                >
                  {props.tableData.activePlanPatientList
                    && props.tableData.activePlanPatientList.data.map(key => (
                      <MenuItem value={key.offerId}>
                        {key.offerNumber}
                        {" "}
&nbsp;&nbsp;&nbsp;&nbsp;
                        {key.amountfunded}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Skeleton animation="wave" />
              )}
            </Grid>
          </Grid>
          <Grid container xs={4} spacing={3}>
            <Grid item xs={12}>
              {props.tableData.remainingFundingData ? (
                <Card>
                  <RemainingFund remainingFundData={remainingFundData} />
                </Card>
              ) : (
                <RippleLoading />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6} md={4} style={{ padding: "15px" }}>
          {props.tableData.paymentStatusData ? (
            <Card>
              <CardHeader
                color="info"
                stats
                icon
                style={{ margin: "0px 20px" }}
              >
                <CardIcon
                  color="info"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    float: "none",
                    padding: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Accessibility />
                  <Typography
                    noWrap
                    style={{ color: "#fff" }}
                    className={classes.cardTitle}
                  >
                    Payment Status
                  </Typography>
                </CardIcon>
              </CardHeader>
              <CardBody style={{ height: "240px", padding: "0px" }}>
                <Typography
                  style={{
                    color: "#0B9600",
                    fontSize: "21px",
                    padding: "1rem",
                  }}
                  align="center"
                >
                  {`Dues: $${
                    props.tableData.paymentStatusData
                    && props.tableData.paymentStatusData.data[0].dues
                  }`}
                </Typography>
                {props.tableData.paymentStatusData ? (
                  <Grid container className={classes.noBackgroundColor}>
                    <Grid item xs={1} />
                    <Grid item xs={6} align="left">
                      Next Due Date&nbsp;
                    </Grid>
                    <Grid item xs={4} align="right">
                      {props.tableData.paymentStatusData.data[0].nextDueDate}
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                ) : (
                  <Ripple duration={3000} />
                )}
                {props.tableData.paymentStatusData && (
                  <Grid container className={classes.backgroudColor}>
                    <Grid item xs={1} />
                    <Grid item xs={6} align="left">
                      Payment Mode&nbsp;
                    </Grid>
                    <Grid item xs={4} align="right">
                      {`${
                        props.tableData.paymentStatusData
                        && props.tableData.paymentStatusData.data[0].paymentMode
                      }`}
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                )}
                <Grid container style={{ paddingTop: "10%" }}>
                  <Grid item xs>
                    <Typography
                      align="center"
                      style={{ color: "#3f51b5", fontWeight: "bold" }}
                    >
                      <img
                        src={
                          props.tableData.paymentStatusData
                          && props.tableData.paymentStatusData.data[0].cardMode
                            === "visa"
                            ? VisaImage
                            : MasterImage
                        }
                        style={{ width: "15%", marginBottom: "-3%" }}
                      />
                      {" "}
                      {props.tableData.paymentStatusData
                        && props.tableData.paymentStatusData.data[0]
                          .lastCardNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs align="center">
                    <Button onClick={() => handlePaymentChange()}>
                      change
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter stats style={{ marginTop: "0px" }}>
                <div className={classes.stats} />
                <div className={classes.stats} align="right">
                  View your recently scheduled payments
                </div>
              </CardFooter>
            </Card>
          ) : (
            <RippleLoading />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ padding: "15px" }}>
          {props.tableData.offerAccountStatusData ? (
            <Card>
              <CardHeader
                color="info"
                stats
                icon
                style={{ margin: "0px 20px" }}
              >
                <CardIcon
                  color="info"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    float: "none",
                    padding: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Accessibility />
                  <Typography
                    noWrap
                    style={{ color: "#fff" }}
                    className={classes.cardTitle}
                  >
                    Account Status
                  </Typography>
                </CardIcon>
              </CardHeader>
              <CardBody style={{ height: "240px", padding: "0px" }}>
                <Typography
                  style={{
                    color: "#0B9600",
                    fontSize: "21px",
                    padding: "1rem",
                  }}
                  align="center"
                >
                  {`PayOff: $${
                    props.tableData.offerAccountStatusData
                    && props.tableData.offerAccountStatusData.data[0].payOff
                  }`}
                </Typography>
                <Grid container className={classes.noBackgroundColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Monthly Payment
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`$${
                      props.tableData.offerAccountStatusData
                      && props.tableData.offerAccountStatusData.data[0]
                        .monthlyPayment
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.backgroudColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Last Payment On
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`${
                      props.tableData.offerAccountStatusData
                      && props.tableData.offerAccountStatusData.data[0]
                        .lastPaymentDate
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.noBackgroundColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Balance term left
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`${
                      props.tableData.offerAccountStatusData
                      && props.tableData.offerAccountStatusData.data[0]
                        .balanceTerms
                    }`}
                  </Grid>
                </Grid>
                <Grid container className={classes.backgroudColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Principal Balance
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`$${
                      props.tableData.offerAccountStatusData
                      && props.tableData.offerAccountStatusData.data[0]
                        .principalBalance
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </CardBody>
              <CardFooter stats style={{ marginTop: "0px" }}>
                <div className={classes.stats} />
                <div className={classes.stats} align="right">
                  View Details
                </div>
              </CardFooter>
            </Card>
          ) : (
            <RippleLoading />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ padding: "15px" }}>
          {props.tableData.originalLoanTermData ? (
            <Card>
              <CardHeader
                color="info"
                stats
                icon
                style={{ margin: "0px 20px" }}
              >
                <CardIcon
                  color="info"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    float: "none",
                    padding: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Accessibility />
                  <Typography
                    noWrap
                    style={{ color: "#fff" }}
                    className={classes.cardTitle}
                  >
                    Original Loan Term
                  </Typography>
                </CardIcon>
              </CardHeader>
              <CardBody style={{ height: "240px", padding: "0px" }}>
                <Typography
                  style={{
                    color: "#0B9600",
                    fontSize: "21px",
                    padding: "1rem",
                  }}
                  align="center"
                >
                  {`Amount Financed: $${
                    props.tableData.originalLoanTermData
                    && props.tableData.originalLoanTermData.data[0].amountFinanced
                  }`}
                </Typography>
                <Grid container className={classes.noBackgroundColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Funding date
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`${
                      props.tableData.originalLoanTermData
                      && props.tableData.originalLoanTermData.data[0].fundingDate
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.backgroudColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Down Payment
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`$${
                      props.tableData.originalLoanTermData
                      && props.tableData.originalLoanTermData.data[0].downPayment
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.noBackgroundColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Monthly Payment
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`$${
                      props.tableData.originalLoanTermData
                      && props.tableData.originalLoanTermData.data[0]
                        .monthlyPayment
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.backgroudColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Terms (Monthly)
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`${
                      props.tableData.originalLoanTermData
                      && props.tableData.originalLoanTermData.data[0].terms
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.noBackgroundColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    APR
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`${
                      props.tableData.originalLoanTermData
                      && props.tableData.originalLoanTermData.data[0].apr
                    }%`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.backgroudColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Daily Interest
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`$${
                      props.tableData.originalLoanTermData
                      && props.tableData.originalLoanTermData.data[0].dailyInterest
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </CardBody>
              <CardFooter stats style={{ marginTop: "0px" }}>
                <div className={classes.stats} />
                <div className={classes.stats} align="right">
                  View Details
                </div>
              </CardFooter>
            </Card>
          ) : (
            <RippleLoading />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

Patients.propTypes = {
  getAllActivePlanList: PropTypes.func,
  getOpenFundingAmount: PropTypes.func,
  getPendingOfferAmount: PropTypes.func,
  getPaymentStatus: PropTypes.func,
  getOfferAccountStatus: PropTypes.func,
  getOriginalLoanTerm: PropTypes.func,
  getRemainingFundingGraph: PropTypes.func,
  tableData: PropTypes.object,
  userDetails: PropTypes.object,
  history: PropTypes.object,
  getPaymentDetailsList: PropTypes.func,
  getOfferDetailsPatient: PropTypes.func,
};

const mapStateToProps = app => ({
  tableData: app.patientDashboard,
  userDetails: app.userRole,
});

export default connect(mapStateToProps, {
  getAllActivePlanList,
  getOpenFundingAmount,
  getPendingOfferAmount,
  getPaymentStatus,
  getOfferAccountStatus,
  getOriginalLoanTerm,
  getRemainingFundingGraph,
  getPaymentDetailsList,
  getOfferDetailsPatient,
})(Patients);
