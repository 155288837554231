/* eslint-disable camelcase */
import React, { useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import PropTypes from "prop-types";
import { daily_deposit_colors_graph } from "../../assets/GloableColor";

function Daliydeposits(props) {
  useEffect(() => {
    if (document.getElementById("daily") !== null) {
      const chart = am4core.create("daily", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.colors.step = 2;
      chart.maskBullets = false;

      const title = chart.titles.create();
      title.text = "Daily Deposit";
      title.fontSize = 13;
      title.fontFamily = "Calibri";
      title.paddingBottom = 10;
      title.align = "left";
      title.fontWeight = "bold";
      title.color = am4core.color("#fff");
      // title.text.color=am4core.color("#2b2d30");

      if (props.dashboardData.dailyDepositeData && props.dashboardData.dailyDepositeData.data) {
        // Add data
        chart.data = props.dashboardData.dailyDepositeData.data;
      }

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      // dateAxis.renderer.labels.template.location = 20;
      dateAxis.renderer.minGridDistance = 0;
      dateAxis.renderer.grid.template.disabled = true;
      dateAxis.renderer.fullWidthTooltip = false;
      dateAxis.renderer.labels.template.rotation = 315;
      dateAxis.renderer.labels.template.verticalCenter = "right";
      dateAxis.renderer.labels.template.fontSize = am4core.percent(65);

      const distanceAxis = chart.yAxes.push(new am4charts.ValueAxis());
      distanceAxis.title.text = "Volume ($)";
      distanceAxis.title.fontSize = am4core.percent(70);
      distanceAxis.renderer.grid.template.disabled = true;
      distanceAxis.renderer.labels.template.fontSize = am4core.percent(65);

      const durationAxis = chart.yAxes.push(new am4charts.ValueAxis());
      durationAxis.title.text = "Count";
      durationAxis.title.fontSize = am4core.percent(70);
      durationAxis.baseUnit = "count";
      durationAxis.renderer.labels.template.fontSize = am4core.percent(65);
      durationAxis.renderer.grid.template.disabled = false;
      durationAxis.renderer.opposite = true;
      durationAxis.syncWithAxis = distanceAxis;

      // durationAxis.durationFormatter.durationFormat = "hh'h' mm'min'";

      // Create series
      const distanceSeries = chart.series.push(new am4charts.ColumnSeries());
      distanceSeries.dataFields.valueY = "totalAmount";
      distanceSeries.dataFields.dateX = "monthYear";
      distanceSeries.yAxis = distanceAxis;

      //    distanceSeries.tooltipText = "{valueY}";
      distanceSeries.name = "Volume($)";
      distanceSeries.fontSize = am4core.percent(70);
      distanceSeries.columns.template.width = am4core.percent(50);
      distanceSeries.fill = daily_deposit_colors_graph.volume;
      distanceSeries.stroke = daily_deposit_colors_graph.volume;
      distanceSeries.columns.template.fillOpacity = 8;
      distanceSeries.columns.template.propertyFields.strokeDasharray = "dashLength";
      distanceSeries.columns.template.propertyFields.fillOpacity = "alpha";
      distanceSeries.showOnInit = true;

      const distanceState = distanceSeries.columns.template.states.create("hover");
      distanceState.properties.fillOpacity = 0.9;

      const durationSeries = chart.series.push(new am4charts.LineSeries());
      durationSeries.dataFields.valueY = "count";
      durationSeries.dataFields.dateX = "monthYear";
      durationSeries.name = "Count";
      durationSeries.yAxis = durationAxis;
      durationSeries.strokeWidth = 1;
      durationSeries.propertyFields.strokeDasharray = "dashLength";

      //    durationSeries.tooltipText = "{valueY}";

      durationSeries.tooltip.dy = -8;
      // durationSeries.tooltip.dx=0;

      durationSeries.tooltipHTML = `<table style="font-size:10px">
<tr>
<center style="font-size:10px"><strong>{monthYear}</strong></center>
<hr />
</tr>

<tr>
<th align="left">Count:</th>
<td>{count}</td>
</tr>
<tr>
<th align="left">Volume($):</th>
<td>{totalAmount}</td>
</tr>

</table>`;
      durationSeries.showSystemTooltip = true;
      durationSeries.showOnInit = true;
      durationSeries.stroke = daily_deposit_colors_graph.line;
      durationSeries.fill = daily_deposit_colors_graph.line;
      durationSeries.cursorTooltipEnabled = true;

      const durationBullet = durationSeries.bullets.push(new am4charts.Bullet());
      durationBullet.horizontalCenter = "middle";
      durationBullet.verticalCenter = "middle";
      const circleBullet = durationSeries.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.radius = 2;

      const durationState = durationBullet.states.create("hover");
      durationState.properties.scale = 1.2;

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";
      chart.legend.fontSize = 10;
      chart.legend.useDefaultMarker = true;
      const marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 0;
      marker.strokeOpacity = 1;

      chart.tooltip.label.fill = am4core.color("#f00");

      const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;

      chart.tooltip.getFillFromObject = true;
      chart.tooltip.background.fill = am4core.color("#67b7dc");

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.fullWidthLineX = true;
      chart.cursor.lineY.disabled = true;
      chart.cursor.xAxis = dateAxis;

      chart.cursor.lineX.strokeOpacity = 0;
      chart.cursor.lineX.fill = daily_deposit_colors_graph.volume;
      chart.cursor.lineX.fillOpacity = 0.1;

      const hoverState = distanceSeries.columns.template.states.create("hover");
      hoverState.properties.fill = am4core.color(daily_deposit_colors_graph.columnHover);
      hoverState.properties.fillOpacity = 0.5;
      chart.cursor.lineX.fillOpacity = 0;

      const buttonContainer = chart.plotContainer.createChild(am4core.Container);
      buttonContainer.shouldClone = false;
      buttonContainer.align = "right";
      buttonContainer.zIndex = Number.MAX_SAFE_INTEGER;
      buttonContainer.layout = "horizontal";
    }
  }, [props]);
  return (
    <div id="daily" style={{ width: "100%", height: "20rem" }} />
  );
}

Daliydeposits.propTypes = {
  dashboardData: PropTypes.object,
};

export default Daliydeposits;
