/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import ErrorIcon from '@material-ui/icons/Error';
import Card from "components/Card/Card.js";
import fullOrigin from "../../assets/img/imgpsh_fullsize_anim.png";

const useStyles = makeStyles(styles);
const AlreadyRegister = props => {
  const [preForgetPassword, setPrePassword] = React.useState(props.formData.forgetPassword);
  const classes = useStyles();

  const handleBackToLogin = () => {
    props.history.push("/");
  };

  const handleForgetPassword = () => {
    const data = {
      userId: props.formData.patientId,
    };
    props.confirmForgetPassword(data);
  };

  React.useState(() => {
    if (preForgetPassword !== props.formData.forgetPassword) {
      setPrePassword(props.formData.forgetPassword);
    }
  });

  return (
    <Card style={{ width: "55%" }}>
      <Grid container style={{marginTop:"5%"}}>
        <Grid item xs align="center">
          <img src={fullOrigin} width="20%" height="auto" alt="" />
        </Grid>
      </Grid>
      <Grid container style={{ margin: "1% 0% 0% 0%" }}>
        <Grid iten xs={2} />
        <Grid iten xs={8} align="center">
          <ErrorIcon style={{fontSize: "75px", color: "#FF0000"}}/>
        </Grid>
        <Grid iten xs={2} />
      </Grid>
      <Grid container>
        <Grid iten xs={2} />
        <Grid
          item
          xs={8}
          style={{
            margin: "3% 0% 0% 0%",
            fontSize: "16px",
            color: "#3a57a7",
            fontFamily: "sans-serif",
          }}>
          <div align="center">
            {!preForgetPassword ? <div>
              You are already a registered user of TPO.
              {" "}
              {" "}
              Just login to manage your payments
              {" "}
              .
            </div> : <div>
              {preForgetPassword.message}
            </div>}
          </div>
        </Grid>
        <Grid iten xs={2} />
      </Grid>
      <Grid container style={{ marginTop: "2%", marginBottom: "10%" }}>
        <Grid iten xs={4} />
        <Grid item xs={2} textAlign="center">
          <Button variant="contained" onClick={() => handleBackToLogin()} size="large">
            Login
          </Button>
        </Grid>
        {!preForgetPassword && <Grid item xs={2} textAlign="center">
          <Button variant="contained" onClick={() => handleForgetPassword()} className={classes.clearButton} size="large">
            Forget Password
          </Button>
        </Grid>}
      </Grid>
    </Card>
  );
};

AlreadyRegister.propTypes = {
  history: PropTypes.object,
  formData: PropTypes.object,
  confirmForgetPassword: PropTypes.func,
};

export default AlreadyRegister;
