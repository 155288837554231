import React from "react";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./Redux/store";

// core components
// eslint-disable-next-line import/no-unresolved
import "./assets/css/material-dashboard-react.css?v=1.9.0";
import AppView from "./AppView";

const App = () => (
  <BrowserRouter>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      preventDuplicate
      autoHideDuration={2500}
    >
      <Provider store={store}>
        <AppView />
      </Provider>
    </SnackbarProvider>
  </BrowserRouter>
);

export default App;
