import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const genrateOfferPatient = (
  state = initialState.genrateOfferPatient,
  action
) => {
  switch (action.type) {
    case types.GENRATE_OFFER_PATIENT_SEARCH_CALL:
      return { ...state, loading: true, setUpMonthltFromOffer: false };
    case types.GENRATE_OFFER_PATIENT_SEARCH_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case types.GENRATE_OFFER_PATIENT_SEARCH_ERROR:
      return { ...state, error: false, loading: false };
    case types.UPLOAD_DOCUMNET_FINAL_OFFER_CALL:
      return { ...state, loading: true };
    case types.UPLOAD_DOCUMNET_FINAL_OFFER_SUCCESS:
      return { ...state, uploadDocument: action.data, loading: false };
    case types.UPLOAD_DOCUMNET_FINAL_OFFER_ERROR:
      return { ...state, error: false, loading: false };
    case types.SEND_OFFER_EMAIL_CALL:
      return { ...state, loading: true };
    case types.SEND_OFFER_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailOffer: action.data,
        activePlanPatientList: false,
        loading: false,
      };
    case types.SEND_OFFER_EMAIL_ERROR:
      return { ...state, error: false, loading: false };
    case types.SHOW_EMAIL_PATIENT_DETAILS_CALL:
      return { ...state, loading: true };
    case types.SHOW_EMAIL_PATIENT_DETAILS_SUCCESS:
      return {
        ...state,
        showPatientDetailsViaMail: action.data,
        loading: false,
      };
    case types.SHOW_EMAIL_PATIENT_DETAILS_ERROR:
      return { ...state, error: false, loading: false };
    case types.UPDATE_PATIENT_DETAILS_OFFER_CALL:
      return { ...state, loading: true };
    case types.UPDATE_PATIENT_DETAILS_OFFER_SUCCESS:
      return {
        ...state,
        updatePatientDetailsOffer: action.data,
        loading: false,
      };
    case types.UPDATE_PATIENT_DETAILS_OFFER_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_GENRATE_OFFER_CALL:
      return { ...state, loading: true };
    case types.PATIENT_GENRATE_OFFER_SUCCESS:
      return {
        ...state,
        genrateOfferData: action.data,
        loading: false,
        createOffer: false,
        paymentMakeDetails: false,
        paymentDetailsData: false,
      };
    case types.PATIENT_GENRATE_OFFER_ERROR:
      return { ...state, error: false, loading: false };

    case types.PERSONAL_LOAN_OFFER_CALL:
      return { ...state, loading: true };
    case types.PERSONAL_LOAN_OFFER_SUCCESS:
      return {
        ...state,
        generatedFinanceList: action.data,
        loading: false,
        createOffer: false,
        paymentMakeDetails: false,
        paymentDetailsData: false,
      };
    case types.PERSONAL_LOAN_OFFER_ERROR:
      return { ...state, error: false, loading: false };

    case types.CREATE_OFFER_PATIENT_CALL:
      return { ...state, loading: true };
    case types.CREATE_OFFER_PATIENT_SUCCESS:
      return { ...state, createOffer: action.data, loading: false };
    case types.CREATE_OFFER_PATIENT_ERROR:
      return { ...state, error: false, loading: false };
    case types.PRINT_OFFER_PATIENT_CALL:
      return { ...state, loading: true };
    case types.PRINT_OFFER_PATIENT_SUCCESS:
      return { ...state, printOfferData: action.data, loading: false };
    case types.PRINT_OFFER_PATIENT_ERROR:
      return { ...state, error: false, loading: false };
    case types.MAKE_PATIENT_PAYMENT_CALL:
      return { ...state, loading: true };
    case types.MAKE_PATIENT_PAYMENT_SUCCESS:
      return { ...state, paymentMakeDetails: action.data, loading: false };
    case types.MAKE_PATIENT_PAYMENT_ERROR:
      return { ...state, error: false, loading: false };
    case types.DELETE_PATIENT_OFFER_DOCUMENTS_CALL:
      return { ...state, loading: true };
    case types.DELETE_PATIENT_OFFER_DOCUMENTS_SUCCESS:
      return { ...state, deleteDocument: action.data, loading: false };
    case types.DELETE_PATIENT_OFFER_DOCUMENTS_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_INFO_BILLING_DETAILS_CALL:
      return {
        ...state,
        loading: true,
        paymentDetailsData: false,
        printOfferData: false,
      };
    case types.PATIENT_INFO_BILLING_DETAILS_SUCCESS:
      return { ...state, paymentDetailsData: action.data, loading: false };
    case types.PATIENT_INFO_BILLING_DETAILS_ERROR:
      return { ...state, error: false, loading: false };
    case types.SHOW_EMAIL_PATIENT_INFO_CALL:
      return { ...state, loading: true, patientId: action.data.patientId };
    case types.SHOW_EMAIL_PATIENT_INFO_SUCCESS:
      return { ...state, showPatientInfoViaMail: action.data, loading: false };
    case types.SHOW_EMAIL_PATIENT_INFO_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_REGISTER_CALL:
      return { ...state, loading: true };
    case types.PATIENT_REGISTER_SUCCESS:
      return { ...state, patientRegisterData: action.data, loading: false };
    case types.PATIENT_REGISTER_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_OFFER_GRAPH_CALL:
      return { ...state, loading: true };
    case types.PATIENT_OFFER_GRAPH_SUCCESS:
      return { ...state, patientGraphData: action.data, loading: false };
    case types.PATIENT_OFFER_GRAPH_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_OFFER_GRAPH_DATA_EMPTY:
      return { ...state, error: false, patientGraphData: false };
    case types.PATIENT_OFFER_EMAIL_SET:
      return { ...state, error: false, emailState: true };
    case types.PATIENT_FORGET_PASSWORD_CALL:
      return { ...state, loading: true };
    case types.PATIENT_FORGET_PASSWORD_SUCCESS:
      return { ...state, forgetPassword: action.data, loading: false };
    case types.PATIENT_FORGET_PASSWORD_ERROR:
      return { ...state, error: false, loading: false };
    case types.CHECK_RESET_PASSWORD_AUTH_CHECK_CALL:
      return { ...state, loading: true };
    case types.CHECK_RESET_PASSWORD_AUTH_CHECK_SUCCESS:
      return { ...state, authTokenCheck: action.data, loading: false };
    case types.CHECK_RESET_PASSWORD_AUTH_CHECK_ERROR:
      return { ...state, error: false, loading: false };
    case types.CHANGE_PATIENT_PASSWORD_CALL:
      return { ...state, loading: true };
    case types.CHANGE_PATIENT_PASSWORD_SUCCESS:
      return { ...state, changePassword: action.data, loading: false };
    case types.CHANGE_PATIENT_PASSWORD_ERROR:
      return { ...state, error: false, loading: false };
    case types.SERVICES_CPT_CODE_PATIENT_CALL:
      return { ...state, loading: true };
    case types.SERVICES_CPT_CODE_PATIENT_SUCCESS:
      return { ...state, cptCodeData: action.data, loading: false };
    case types.SERVICES_CPT_CODE_PATIENT_ERROR:
      return { ...state, error: false, loading: false };
    case types.ADD_PATIENT_DETAILS_OFFER_CALL:
      return { ...state, loading: true };
    case types.ADD_PATIENT_DETAILS_OFFER_SUCCESS:
      return { ...state, addPatientData: action.data, loading: false };
    case types.ADD_PATIENT_DETAILS_OFFER_ERROR:
      return { ...state, error: false, loading: false };
    case types.UPDATE_PATIENT_DETAILS_CALL:
      return { ...state, loading: true };
    case types.UPDATE_PATIENT_DETAILS_SUCCESS:
      return { ...state, updatePatientDetails: action.data, loading: false };
    case types.UPDATE_PATIENT_DETAILS_ERROR:
      return { ...state, error: false, loading: false };
    case types.ACTIVE_PLAN_PATIENT_LIST_CALL:
      return { ...state, loading: true };
    case types.ACTIVE_PLAN_PATIENT_LIST_SUCCESS:
      return { ...state, activePlanPatientList: action.data, loading: false };
    case types.ACTIVE_PLAN_PATIENT_LIST_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_HYPER_LINK_GENRATE_OFFER_CALL:
      return { ...state, loading: true };
    case types.PATIENT_HYPER_LINK_GENRATE_OFFER_SUCCESS:
      return {
        ...state,
        genrateOfferData: action.data,
        loading: false,
        createOffer: false,
        paymentMakeDetails: false,
        paymentDetailsData: false,
      };
    case types.PATIENT_HYPER_LINK_GENRATE_OFFER_ERROR:
      return { ...state, error: false, loading: false };
    case types.PATIENT_DOCUMENT_LIST_CALL:
      return { ...state, loading: true };
    case types.PATIENT_DOCUMENT_LIST_SUCCESS:
      return { ...state, patientDocumentList: action.data, loading: false };
    case types.PATIENT_DOCUMENT_LIST_ERROR:
      return { ...state, error: false, loading: false };
    case types.OFFER_DOWNLOAD_FILE_CALL:
      return { ...state, loading: true };
    case types.OFFER_DOWNLOAD_FILE_SUCCESS:
      return { ...state, offerDownloadFile: action.data, loading: false };
    case types.OFFER_DOWNLOAD_FILE_ERROR:
      return { ...state, error: false, loading: false };
    case types.ADD_PROPOSAL_DETAILS_CALL:
      return { ...state, loading: true, addProposalData: false };
    case types.ADD_PROPOSAL_DETAILS_SUCCESS:
      return {
        ...state,
        addProposalData: action.data,
        loading: false,
        data: false,
      };
    case types.ADD_PROPOSAL_DETAILS_ERROR:
      return { ...state, error: false, loading: false };
    case types.SETUP_MONTHLY_PAYMENT_CALL:
      return { ...state, loading: true };
    case types.SETUP_MONTHLY_PAYMENT_SUCCESS:
      return { ...state, setUpMonthlyPayment: action.data, loading: false };
    case types.SETUP_MONTHLY_PAYMENT_ERROR:
      return { ...state, error: false, loading: false };
    case types.COMPLETED_PLAN_PATIENT_LIST_CALL:
      return { ...state, loading: true };
    case types.COMPLETED_PLAN_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        completedPlanPatientList: action.data,
        loading: false,
      };
    case types.COMPLETED_PLAN_PATIENT_LIST_ERROR:
      return { ...state, error: false, loading: false };
    case types.SETUP_MONTHLY_FROM_OFFER:
      return { ...state, setUpMonthltFromOffer: action.data };
    case types.GENRATE_OFFER_LOADING_FALSE:
      return { ...state, genrateOfferLoading: action.data };
    case types.SET_DEFAULT_VALUE_OFFER:
      return { ...state, defaultValueOffer: action.data };
    case types.SET_PATIENT_OFFER_SELECT_PATINET:
      return { ...state, selectPatient: action.data };
    case types.SET_BACK_TO_SEARCH_FROM_OFFER:
      return { ...state, activePlanPatientList: false };
    case types.SET_PREPOSAL_IDS_ARRAY:
      return { ...state, preposalDataIds: action.data };
    case types.EMPTY_OFFER_SELECT_PATIENT:
      return {
        ...state,
        activePlanPatientList: false,
        completedPlanPatientList: false,
        patientDocumentList: false,
      };
    case types.BEFORE_PAYMENT_SEND_OTP_CALL:
      return { ...state, loading: true };
    case types.BEFORE_PAYMENT_SEND_OTP_SUCCESS:
      return { ...state, sentOTPData: action.data, loading: false };
    case types.BEFORE_PAYMENT_SEND_OTP_ERROR:
      return { ...state, error: false, loading: false };
    case types.VERIFY_PAYMENT_OTP_CALL:
      return { ...state, loading: true };
    case types.VERIFY_PAYMENT_OTP_SUCCESS:
      return { ...state, verifyOTPData: action.data, loading: false };
    case types.VERIFY_PAYMENT_OTP_ERROR:
      return { ...state, error: false, loading: false };
    case types.SET_USER_ID_FORGET_PASSWORD:
      return { ...state, userIdForgetPassword: action.data };
    case types.PATIENT_TPO_SCORE_CALL:
      return { ...state, loading: true };
    case types.PATIENT_TPO_SCORE_SUCCESS:
      return { ...state, patientTPOScoreList: action.data, loading: false };
    case types.PATIENT_TPO_SCORE_ERROR:
      return { ...state, error: false, loading: false };
    case types.GENERATE_PATIENT_TPO_SCORE_CALL:
      return { ...state, loading: true };
    case types.GENERATE_PATIENT_TPO_SCORE_SUCCESS:
      return { ...state, generatePatientTPOScore: action.data, loading: false };
    case types.GENERATE_PATIENT_TPO_SCORE_ERROR:
      return { ...state, error: false, loading: false };
    case types.PAYMENT_CHECKOUT_CALL:
      return { ...state, loading: true };
    case types.PAYMENT_CHECKOUT_SUCCESS:
      return { ...state, paymentCheckoutData: action.data, loading: false };
    case types.PAYMENT_CHECKOUT_ERROR:
      return { ...state, error: false, loading: false };
    case types.GLOBAL_PATIENT_SEARCH_CALL:
      return { ...state, loading: true, cernerPatientSearch: false };
    case types.GLOBAL_PATIENT_SEARCH_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case types.GLOBAL_PATIENT_SEARCH_ERROR:
      return { ...state, error: false, loading: false };
    default:
      return state;
  }
};

export default genrateOfferPatient;
