/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import isEmpty from "lodash/isEmpty";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import noDataImage from "../../assets/img/noDataImage.png";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";

const useStyles = makeStyles(tableStyle);

function createData(
  patientName,
  ssn,
  email,
  phone,
  dob,
  pendingProposal,
  proposalInprocess,
  tpoLevel,
  patientId,
  offerGenrateCount,
  activePlanCount,
  newDueCount,
  newDues,
  data
) {
  return {
    patientName,
    ssn,
    email,
    phone,
    dob,
    pendingProposal,
    proposalInprocess,
    tpoLevel,
    patientId,
    offerGenrateCount,
    activePlanCount,
    newDueCount,
    newDues,
    data,
  };
}

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography
            style={{
              fontFamily: "sans-serif",
              fontSize: "13px",
              textTransform: "uppercase",
            }}
          >
            Last
          </Typography>
        ) : (
          <Typography
            style={{
              fontFamily: "sans-serif",
              fontSize: "13px",
              textTransform: "uppercase",
            }}
          >
            First
          </Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography
            style={{
              fontFamily: "sans-serif",
              fontSize: "13px",
              textTransform: "uppercase",
            }}
          >
            Previous
          </Typography>
        ) : (
          <Typography
            style={{
              fontFamily: "sans-serif",
              fontSize: "13px",
              textTransform: "uppercase",
            }}
          >
            Previous
          </Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography
            style={{
              fontFamily: "sans-serif",
              fontSize: "13px",
              textTransform: "uppercase",
            }}
          >
            Next
          </Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography
            style={{
              fontFamily: "sans-serif",
              fontSize: "13px",
              textTransform: "uppercase",
            }}
          >
            Last
          </Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const PatientTable = (props) => {
  const classes = useStyles();
  const [preTableData, setPreTableData] = React.useState(props.tableData.data);
  const [page, setPage] = React.useState(0);
  const [initialCall, setInitialCall] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    if (preTableData !== props.tableData.data) {
      setPreTableData(props.tableData.data);
      setPage(0);
    }
  }, [props]);

  React.useEffect(() => {
    if (props.tableData.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData]);

  const rows = [];
  if (preTableData) {
    preTableData.forEach((v, i) => {
      const patientName = v.Patient_Name;
      const ssn = v.SSN;
      const email = v.Email;
      const phone = v.Mobile_Number;
      const dob = v.DOB;
      const pendingProposal = v.pendingProposal;
      const proposalInprocess = v.proposalInprocess;
      const tpoLevel = v.tpoLevel;
      const patientId = v.Patient_id;
      const offerGenrateCount = v.offerGenratedPlans;
      const activePlanCount = v.activePlans;
      const newDueCount = v.newDuesCount;
      const newDues = v.newDues;
      const data = v;
      rows.push(
        createData(
          patientName,
          ssn,
          email,
          phone,
          dob,
          pendingProposal,
          proposalInprocess,
          tpoLevel,
          patientId,
          offerGenrateCount,
          activePlanCount,
          newDueCount,
          newDues,
          data
        )
      );
    });
  }
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper} style={{ borderRadius: "12px" }}>
        <Table
          className={
            !props.showOfferDEtails ? classes.table : classes.patientTable
          }
          aria-label="simple table"
        >
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                TPO Level
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Patient Id
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Patient Name
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                SSN Last 4
              </TableCell>
              {!props.showOfferDEtails && (
                <>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    align="center"
                  >
                    Phone
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    align="center"
                  >
                    Active Plans
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    align="center"
                  >
                    Offer Generated
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    align="center"
                  >
                    New Dues
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    align="center"
                  >
                    New Dues ($)
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(!isEmpty(rows) && initialCall) ||
            (isEmpty(rows) && !initialCall) ? (
              (rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row) => (
                <TableRow key={Math.random()}>
                  <TableCell
                    className={classes.patientNameColor}
                    style={{ cursor: "pointer" }}
                    onClick={() => props.setShowOfferDEtails(row)}
                    align="center"
                  >
                    {/* {(Math.round(row.tpoLevel) === 0 && (

                  )) ||
                    (Math.round(row.tpoLevel) === 1 && (
                      <img src={FiberManualRecordIcon} alt="" />
                    )) ||
                    (Math.round(row.tpoLevel) === 2 && (
                      <img src={FiberManualRecordIcon} alt="" />
                    )) ||
                    (Math.round(row.tpoLevel) === 3 && (
                      <img src={FiberManualRecordIcon} alt="" />
                    )) ||
                    (Math.round(row.tpoLevel) >= 4 && (
                      <img src={FiberManualRecordIcon} alt="" />
                    ))} */}
                    <div
                      style={{
                        position: "relative",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FiberManualRecordIcon
                        fontSize="large"
                        style={{
                          color:
                            Math.round(row.tpoLevel) === 0
                              ? "#cfcfcf"
                              : Math.round(row.tpoLevel) === 1
                              ? "#FF0000"
                              : Math.round(row.tpoLevel) === 2
                              ? "#FFA500"
                              : Math.round(row.tpoLevel) === 3
                              ? "#FFFF00"
                              : Math.round(row.tpoLevel) === 4
                              ? "#00FF00"
                              : "#cfcfcf",
                          fontSize: "4rem",
                        }}
                      />
                      <Typography
                        style={{
                          position: "absolute",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          lineHeight: 2,
                          color: "#000",
                          textAlign: "center",
                          fontSize: "1.5em",
                          fontWeight: 700,
                        }}
                      >
                        {Math.round(row.tpoLevel) === 0
                          ? 0
                          : Math.round(row.tpoLevel) === 1
                          ? 1
                          : Math.round(row.tpoLevel) === 2
                          ? 2
                          : Math.round(row.tpoLevel) === 3
                          ? 3
                          : Math.round(row.tpoLevel) === 4
                          ? 4
                          : ""}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.patientId}
                  </TableCell>
                  <TableCell
                    className={classes.patientNameColor}
                    style={{ cursor: "pointer" }}
                    onClick={() => props.setShowOfferDEtails(row)}
                    align="center"
                  >
                    {row.patientName}
                  </TableCell>

                  <TableCell className={classes.cellText} align="center">
                    {row.ssn}
                  </TableCell>
                  {!props.showOfferDEtails && (
                    <>
                      <TableCell className={classes.cellText} align="center">
                        {row.phone}
                      </TableCell>
                      <TableCell className={classes.cellText} align="center">
                        {row.activePlanCount}
                      </TableCell>
                      <TableCell className={classes.cellText} align="center">
                        {row.offerGenrateCount}
                      </TableCell>
                      <TableCell className={classes.cellText} align="center">
                        {row.newDueCount}
                      </TableCell>
                      <TableCell className={classes.cellText} align="center">
                        {row.newDues}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img src={noDataImage} alt="" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              style={{ overflow: "hidden" }}
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={12}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { placeholder: "Show" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

PatientTable.propTypes = {
  tableData: PropTypes.any,
  setShowOfferDEtails: PropTypes.func,
  showOfferDEtails: PropTypes.any,
};

export default PatientTable;
