import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { Field, Form, Formik } from "formik";
import styles from "assets/jss/material-dashboard-react/components/businessForm";

const useStyles = makeStyles(styles);

function SimpleDialog(props) {
  const classes = useStyles();
  const {
    onClose,
    searchValues,
    groupTypeList,
    setSearchValues,
    searchChipData,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const onReset = () => {
    setSearchValues({
      groupName: "",
      groupType: "",
      apiType: "search",
      groupStatus: "",
    });
    onFormSubmit({
      groupName: "",
      groupType: "",
      apiType: "search",
      groupStatus: "",
    });
  };

  const onFormSubmit = (values) => {
    searchChipData(values);
  };

  return (
    <>
      <Formik
        onSubmit={onFormSubmit}
        enableReinitialize
        initialValues={searchValues}
      >
        {({ setFieldValue }) => (
          <Form>
            <Grid
              container
              className={classes.margin}
              style={{ marginBottom: "5%" }}
            >
              <Grid item xs>
                <Field
                  as={TextField}
                  className={classes.rootDialog}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      focused: "focused",
                      input: classes.resize,
                    },
                  }}
                  autoComplete="off"
                  label="Group Name"
                  onChange={(event) => {
                    setFieldValue("groupName", event.target.value);
                    setSearchValues({
                      ...searchValues,
                      groupName: event.target.value,
                    });
                  }}
                  name="groupName"
                  id="groupName"
                  style={{ fontSize: "82%" }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ marginBottom: "5%" }}
            >
              <Grid item xs>
                <Field
                  as={TextField}
                  select
                  className={classes.rootDialog}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      focused: "focused",
                      input: classes.resize,
                    },
                  }}
                  label="Group Type"
                  name="groupType"
                  onChange={(event) => {
                    setFieldValue("groupType", event.target.value);
                    setSearchValues({
                      ...searchValues,
                      groupType: event.target.value,
                    });
                  }}
                  id="groupType"
                  style={{ fontSize: "82%" }}
                >
                  <MenuItem className={classes.dialogBoxDropDown} value="All">
                    All
                  </MenuItem>
                  {groupTypeList.map((type) => (
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value={type.groupTypeCode}
                      key={type._id}
                    >
                      {type.groupTypeName}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ marginBottom: "5%" }}
            >
              <Grid item xs>
                <Field
                  as={TextField}
                  className={classes.halfInput}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  SelectProps={{
                    MenuProps: { disablePortal: false },
                  }}
                  label="Group Status"
                  onChange={(event) => {
                    setFieldValue("groupStatus", event.target.value);
                    setSearchValues({
                      ...searchValues,
                      groupStatus: event.target.value,
                    });
                  }}
                  name="groupStatus"
                  id="groupStatus"
                  select
                  style={{ fontSize: "82%" }}
                >
                  <MenuItem className={classes.dialogBoxDropDown} value="All">
                    All
                  </MenuItem>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Active"
                  >
                    Active
                  </MenuItem>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="InActive"
                  >
                    InActive
                  </MenuItem>
                </Field>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ marginBottom: "8%" }}
            >
              <Grid item xs textAlign="right">
                <br />
                <Button
                  cancel
                  variant="contained"
                  className={classes.clearButton}
                  size="md"
                  onClick={() => {
                    onReset();
                  }}
                >
                  Clear
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.buttonStyle}
                  size="md"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  searchValues: PropTypes.object,
  setSearchValues: PropTypes.func,
  groupTypeList: PropTypes.array,
  searchChipData: PropTypes.func,
};

const mapStateToProps = (app) => ({
  formData: app.users,
});

export default connect(mapStateToProps, {})(SimpleDialog);
