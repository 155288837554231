/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import ClearIcon from "@material-ui/icons/Clear";
import CoustomButton from "components/CustomButtons/Button";
import Button from "@material-ui/core/Button";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Card from "components/Card/Card";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import ChipInput from "material-ui-chip-input";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Modal from "@material-ui/core/Modal";
import { useLocation } from "react-router-dom";
import { exportToCSV, actionPermission } from "../../Utils/commonUtils";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/Providers";
import LongMenu from "../../components/Menu/Index";
import {
  changeProviderFormMode,
  getProviderStatusNormal,
  changeSingleStatus,
  addSearchData,
  removeSearchData,
  getProviderByFilterData,
  getProviderDetailsById,
  getAgentByHierarchy,
} from "../../Redux/Actions/ProvideRegistration";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";

const useStyles = makeStyles(tableStyle);

const merchantsCatId = "62c28d681bc249cb83529a32";

const merchantsLivePageId = "62c28d681bc249cb83529a33";

const merchantsInprogessPageId = "62c28d681bc249cb83529a34";

const defaults = {
  view: 1,
  create: 2,
  edit: 3,
  changeStatus: 4,
  allowToBoard: 5,
  allowToChecklist: 6,
  allowToLive: 7,
};

function createData(
  DBA_Name,
  E_mail,
  Ownership_Type,
  Contact_Name,
  Phone,
  Creation_Date,
  Update_Date,
  status,
  Id,
  Sub_MerchantID,
  Error_Code,
  Error_Description,
  Client_Id,
  Current_Status,
  Assign_To
) {
  return {
    DBA_Name,
    E_mail,
    Ownership_Type,
    Contact_Name,
    Phone,
    Creation_Date,
    Update_Date,
    status,
    Id,
    Sub_MerchantID,
    Error_Code,
    Error_Description,
    Client_Id,
    Current_Status,
    Assign_To,
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    count, page, rowsPerPage, onChangePage,
  } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "Business_Name",
      numeric: false,
      disablePadding: true,
      label: "DBA Name",
      headClassName: classes.DBA_Name,
    },
    {
      id: "Business_EMail",
      numeric: false,
      disablePadding: true,
      label: "User Name",
      headClassName: classes.E_mail,
    },
    {
      id: "Assign_To",
      numeric: false,
      disablePadding: false,
      label: "Assigned To",
      headClassName: classes.Assign_To,
    },
    {
      id: "Type_Name",
      numeric: false,
      disablePadding: false,
      label: "Ownership Type",
      headClassName: classes.OwnershipType,
    },
    {
      id: "Owner_Information",
      numeric: false,
      disablePadding: true,
      label: "Contact Name",
      headClassName: classes.Contact_Name,
    },
    {
      id: "Business_Phone",
      numeric: true,
      disablePadding: false,
      label: "Phone",
      headClassName: classes.Phone,
    },
    {
      id: "Created_date",
      numeric: true,
      disablePadding: false,
      label: "Creation Date",
      headClassName: classes.Creation_Date,
    },
    {
      id: "Updated_date",
      numeric: true,
      disablePadding: false,
      label: "Update Date",
      headClassName: classes.Update_Date,
    },
    {
      id: "Provider_Status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      headClassName: classes.status,
    },
    {
      id: "blank",
      numeric: false,
      disablePadding: false,
      label: "",
      headClassName: classes.status,
    },
  ];

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const ProviderReport = props => {
  const [statusDetails, setStatusPid] = React.useState({
    pid: "",
    name: "",
    status: "",
  });
  const [values, setValues] = React.useState({
    status: "",
    id: "",
  });
  const classes = useStyles();
  const location = useLocation();
  const path = window.location.pathname.split("/")[2];
  const [page, setPage] = React.useState(props.tableData.searchDetails.pageNo);
  const [preProviderDetailsById, setPreProviderDetailsById] = React.useState(
    props.tableData.providerDetailsById
  );
  const [order, setOrder] = React.useState(
    props.tableData.searchDetails.sortOrder
  );
  const [orderBy, setOrderBy] = React.useState(
    props.tableData.searchDetails.sortColumn
  );
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const [preData, setPreData] = React.useState(props.tableData.agentList);
  const [temp, setTemp] = React.useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const [openBoardingModel, setopenBoardingModel] = React.useState(false);
  const [userOptions, setUserOptions] = React.useState(
    props.mainData.roles.roleTab
      && props.mainData.roles.roleTab?.Roles_Data.LeftMenu[1]?.Page_Menu[0]
        ?.Options[3]
  );
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [preStatusUpdate, setPreStatusUpdate] = React.useState(
    props.tableData.statusUpdate
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);

  function providerregisterurl() {
    props.history.push(`/${props.userDetails.layout}/user`);
    props.changeProviderFormMode("Create");
  }

  React.useEffect(() => {
    const data = {
      userID: localStorage.getItem("userId"),
    };
    if (!props.tableData.agentList) {
      props.getAgentByHierarchy(data);
    }
  }, []);

  React.useEffect(() => {
    if (preData !== props.tableData.providerList) {
      if (
        props.tableData.providerList
        && props.tableData.providerList.Providers_Data
        && props.tableData.providerList.Providers_Data.length === 0
      ) {
        const searchData = { ...props.tableData.searchDetails };
        searchData.pageNo = 1;
        props.addSearchData(searchData);
      }
      setPreData(props.tableData.providerList);
    }
    if (props.tableData.searchDetails.pageNo !== page) {
      setPage(props.tableData.searchDetails.pageNo);
    }
    if (props.tableData.searchDetails.sortOrder !== order) {
      setOrder(props.tableData.searchDetails.sortOrder);
    }
    if (props.tableData.searchDetails.sortColumn !== orderBy) {
      setOrderBy(props.tableData.searchDetails.sortColumn);
    }
  }, [props]);

  React.useEffect(() => {
    if (!props.tableData.statusList) {
      props.getProviderStatusNormal();
    }
    if (location.pathname === "/admin/Live") {
      const requestData = { ...props.tableData.searchDetails };
      requestData.statusExpression = "IN";
      props.getProviderByFilterData({ ...requestData });
      getProviderByFilterDataHandle(requestData);
    }
    if (location.pathname === "/admin/InProgress") {
      const requestData = { ...props.tableData.searchDetails };
      requestData.statusExpression = "NOT_IN";
      props.getProviderByFilterData({ ...requestData });
      getProviderByFilterDataHandle(requestData);
    }
  }, []);

  const statusLive = props?.tableData?.statusList
    && props?.tableData?.statusList?.Provider_Status_Data
    && props?.tableData?.statusList?.Provider_Status_Data?.find(
      status => status.Provider_Status === "Live"
    );

  const filterStatusInProgress = props?.tableData?.statusList
    && props?.tableData?.statusList?.Provider_Status_Data
    && props?.tableData?.statusList?.Provider_Status_Data?.filter(
      status => status.Provider_Status !== "Live"
    );

  const statusInProgress = [];

  if (filterStatusInProgress && filterStatusInProgress.length > 0) {
    filterStatusInProgress.map(progress => {
      statusInProgress.push(progress.Status_Code);
    });
  }
  const inprogressStatus = statusInProgress.toString();

  React.useEffect(() => {
    if (preStatusUpdate !== props.tableData.statusUpdate) {
      setPreStatusUpdate(props.tableData.statusUpdate);
      if (
        props.tableData.searchDetails.searchDbaName === ""
        || props.tableData.searchDetails.searchStatus === ""
      ) {
        if (
          location.pathname === "/admin/Live"
          && statusLive.Provider_Status === "Live"
        ) {
          props.getProviderByFilterData({ ...props.tableData.searchDetails });
        }
      }
      if (location.pathname === "/admin/InProgress") {
        props.getProviderByFilterData({ ...props.tableData.searchDsetails });
      }
      setOpenModel(false);
    }
    if (props.mainData.roles.roleTab && !userOptions) {
      setUserOptions(
        props.mainData.roles.roleTab.Roles_Data.LeftMenu[1].Page_Menu[0]
          .Options[3]
      );
    }
    if (preProviderDetailsById !== props.tableData.providerDetailsById) {
      setPreProviderDetailsById(props.tableData.providerDetailsById);
      if (
        props.tableData.providerDetailsById
        && props.tableData.providerDetailsById.status
      ) {
        props.history.push(`/${props.userDetails.layout}/user`);
      }
    }
    setopenBoardingModel(false);
  }, [props, location, statusLive, inprogressStatus]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const requestData = { ...props.tableData.searchDetails };
    requestData.pageNo = newPage + 1;
    props.getProviderByFilterData(requestData);
    props.addSearchData(requestData);
  };

  const saveStatus = () => {
    props.changeSingleStatus(values);
  };

  const exportListData = () => {
    if (!isEmpty(rows)) {
      const mappedLogs = rows.map(row => ({
        "DBA Name": row.DBA_Name,
        Email: row.E_mail,
        "Ownership Type": row.Ownership_Type,
        "Contact Name": row.Contact_Name,
        Phone: row.Phone,
        "Creation Date": row.Creation_Date,
        "Update Date": row.Update_Date,
        "Assigned To": row.Assign_To,
        Status: row.status,
      }));
      exportToCSV(mappedLogs, "Merchants Details");
    }
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const requestData = { ...props.tableData.searchDetails };
    requestData.recordPerPage = parseInt(event.target.value, 10);
    requestData.pageNo = 1;
    props.getProviderByFilterData({ ...requestData });
    props.addSearchData(requestData);
  };

  // new fun
  const handleDeleteChip = chip => {
    if (chipValue.length) {
      setChipValue(chipValue.filter(v => v !== chip));
      const requestData = { ...props.tableData.searchDetails };
      const fillterPreChip = chipValue.filter(v => v !== chip);
      const filterRemoveChip = chipValue.filter(v => v === chip);
      if (fillterPreChip.length !== 0) {
        fillterPreChip.forEach(v => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "DBA Name") {
            requestData.searchDbaName = splitSearchName[1].trim();
          }
          if (splitSearchName[0] === "Status") {
            const filterStatusCode = props.tableData.statusList.Provider_Status_Data.filter(
              item => item.Provider_Status.trim() === splitSearchName[1].trim()
            );
            requestData.searchStatus = filterStatusCode.length > 0 ? filterStatusCode[0].Status_Code : "";
          }
          if (splitSearchName[0] === "Agent") {
            const filterAgentName = props.tableData.agentList.data.filter(key => key.Name.trim() === splitSearchName[1].trim());
            requestData.searchAgentId = filterAgentName[0].User_Id;
          }
        });
      }
      if (filterRemoveChip.length !== 0) {
        filterRemoveChip.forEach(v => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "DBA Name") {
            requestData.searchDbaName = "";
          }
          if (splitSearchName[0] === "Status") {
            requestData.searchStatus = "";
          }
          if (splitSearchName[0] === "Agent") {
            requestData.searchAgentId = "";
          }
        });
      }
      props.addSearchData(requestData);
      props.getProviderByFilterData({ ...requestData });
    } else {
      const requestData = { ...props.tableData.searchDetails };
      requestData.searchDbaName = "";
      requestData.searchAgentId = "";
      requestData.searchStatus = "";
      requestData.statusExpression = location.pathname === "/admin/Live" ? "IN" : "NOT_IN";
      props.addSearchData(requestData);
      props.getProviderByFilterData({ ...requestData });
      setChipValue(chipValue);
    }
    if (chipValue.length === 0) {
      const requestData = { ...props.tableData.searchDetails };
      requestData.searchDbaName = "";
      requestData.searchAgentId = "";
      requestData.searchStatus = "";
      requestData.statusExpression = location.pathname === "/admin/Live" ? "IN" : "NOT_IN";
      props.addSearchData(requestData);
      props.getProviderByFilterData({ ...requestData });
      setChipValue([]);
    }
    setTemp(temp);
  };
  // new fun end

  React.useEffect(() => {
    if (!props.tableData.loading) {
      if (location.pathname === "/admin/Live") {
        const requestData = { ...props.tableData.searchDetails };
        requestData.statusExpression = "IN";
        props.getProviderByFilterData({ ...requestData });
        getProviderByFilterDataHandle(requestData);
      }
      if (location.pathname === "/admin/InProgress") {
        const requestData = { ...props.tableData.searchDetails };
        requestData.statusExpression = "NOT_IN";
        props.getProviderByFilterData({ ...requestData });
        getProviderByFilterDataHandle(requestData);
      }
    }
  }, [location]);

  const getProviderByFilterDataHandle = value => {
    props.addSearchData(value);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (
          property === "searchDbaName"
          || property === "searchAgentId"
          || property === "searchStatus"
        ) {
          chipArray.push(
            `${
              property === "searchDbaName"
                ? "DBA Name"
                : property === "searchStatus"
                  ? "Status"
                  : "Agent"
            }: ${
              property === "searchStatus" && location.pathname === "/admin/Live"
                ? props.tableData.statusList.Provider_Status_Data.filter(
                  item => item.Status_Code === value[property]
                )[0].Provider_Status
                : property === "searchStatus"
                  && location.pathname === "/admin/InProgress"
                  ? props.tableData.statusList.Provider_Status_Data.filter(
                    item => item.Status_Code === value[property]
                  )[0].Provider_Status
                  : property === "searchAgentId"
                    ? props.tableData.agentList.data.filter(
                      item => item.User_Id === value[property]
                    )[0].Name
                    : value[property]
            }`
          );
        }
      }
    }
    setChipValue(chipArray);
  };

  rows = [];
  let merchantList = [];

  if (preData) {
    if (preData && preData.Providers_Data) {
      merchantList = preData.Providers_Data;
      merchantList.forEach((v, i) => {
        const Id = v.Provider_id;
        const DBA_Name = v.Business_Name;
        const E_mail = v.Business_EMail;
        const Ownership_Type = v.Type_Name;
        const Contact_Name = v.Owner_Information;
        const Phone = v.Business_Phone;
        const Creation_Date = moment(v.Created_date).format("MM/DD/YYYY");
        const Update_Date = moment(v.Updated_date).format("MM/DD/YYYY");
        const status = v.Provider_Status;
        const Assign_To = v.Assign_To;
        const Sub_MerchantID = v.Guid;
        const Error_Code = v.Error_Code;
        const Error_Description = v.Error_Description;
        const Client_Id = v.Client_Id;
        const Current_Status = v.Current_Status;
        rows.push(
          createData(
            DBA_Name,
            E_mail,
            Ownership_Type,
            Contact_Name,
            Phone,
            Creation_Date,
            Update_Date,
            status,
            Id,
            Sub_MerchantID,
            Error_Code,
            Error_Description,
            Client_Id,
            Current_Status,
            Assign_To
          )
        );
      });
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const requestData = { ...props.tableData.searchDetails };
    requestData.sortColumn = property;
    requestData.sortOrder = isAsc ? "desc" : "asc";
    props.getProviderByFilterData(requestData);
    props.addSearchData(requestData);
  };

  const handleEdit = (type, providerId) => {
    if (type === "edit") {
      props.getProviderDetailsById(providerId);
      props.changeProviderFormMode("Edit");
    }
  };

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        <Modal
          open={openBoardingModel}
          onClose={() => setopenBoardingModel(false)}
          align="center"
        >
          <div
            className={classes.modalDiv}
            style={{ width: "48%" }}
            align="center"
          >
            <Card
              className={
                windowWidthSm ? classes.modalRoot : classes.modalRootXs
              }
            >
              <div>
                <ClearIcon
                  onClick={() => setopenBoardingModel(false)}
                  style={{ float: "right", pointer: "cursor" }}
                />
                <CardHeader subheader="Activity Response" />
              </div>
              <div style={{ margin: "3%" }}>
                <Grid container className={classes.margin}>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>DBA Name: </span>
                    {" "}
                    {`${statusDetails.name}`}
                  </Grid>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>Email: </span>
                    {" "}
                    {`${statusDetails.email}`}
                  </Grid>
                </Grid>
                <Grid container className={classes.margin}>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>
                      Sub Marchent Id:
                      {" "}
                    </span>
                    {" "}
                    {`${statusDetails.submarchecntId}`}
                  </Grid>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>Status: </span>
                    {" "}
                    {`${statusDetails.status}`}
                  </Grid>
                </Grid>
              </div>
              <div>
                <CardHeader subheader="Error Log" />
              </div>
              <div>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.cellTextHeader}
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        Error Code
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeader}
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        Field Name
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeader}
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        Description
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.cellText} />
                      <TableCell className={classes.cellText} align="center" />
                      <TableCell className={classes.cellText} align="center" />
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Card>
          </div>
        </Modal>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                {/* {location.pathname !== "/admin/Live" && (
                  <> */}

                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => handleClose()}
                >
                  <Box style={{ minWidth: "35%" }}>
                    <ChipInput
                      value={chipValue.length > 0 ? chipValue : []}
                      onClick={() => handleClickOpen()}
                      style={{ display: "flex" }}
                      classes={{ chipContainer: classes.chipContainer }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Search"
                      onDelete={(chip, index) => handleDeleteChip(chip, index)}
                    />
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className={classes.overLayBox}
                    >
                      <SimpleDialog
                        close={() => handleClose()}
                        seacrhChipData={data => getProviderByFilterDataHandle(data)}
                      />
                    </Collapse>
                  </Box>
                </ClickAwayListener>
                <CoustomButton
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  onClick={() => {}}
                >
                  <Search />
                </CoustomButton>
                {/* </>
                )} */}
                <CoustomButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={exportListData}
                  variant="contained"
                  className={classes.ExportButtonStyle}
                >
                  Export
                </CoustomButton>
                <CoustomButton
                  aria-controls="simple-menu"
                  variant="contained"
                  className={classes.ExportButtonStyle}
                  onClick={() => providerregisterurl()}
                >
                  <AddIcon />
                  New
                </CoustomButton>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "85%" }}>
                  <ChipInput
                    defaultValue={chipValue.length > 0 ? chipValue : []}
                    classes={{ chipContainer: classes.chipContainer }}
                    onClick={() => handleClickOpen()}
                    style={{ display: "flex" }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    label="Search"
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog
                      close={() => handleClose()}
                      seacrhChipData={data => getProviderByFilterDataHandle(data)}
                    />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <CoustomButton
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </CoustomButton>
            </Grid>
            <Grid item className={classes.expirtItemGrid}>
              <CoustomButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportListData}
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
              >
                Export
              </CoustomButton>
              {actionPermission(
                props.roles,
                merchantsCatId,
                path === "Live"
                  ? merchantsLivePageId
                  : merchantsInprogessPageId,
                defaults.create
              ) && (
                <CoustomButton
                  aria-controls="simple-menu"
                  variant="contained"
                  className={classes.ExportButtonStyle}
                  onClick={() => providerregisterurl()}
                >
                  <AddIcon />
                  New
                </CoustomButton>
              )}
            </Grid>
          </Grid>
        )}
        <Table
          className={classes.table}
          aria-label="simple table"
          style={{ marginBottom: rowsPerPage > 25 ? "55px" : "" }}
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              rows.map(row => (
                <TableRow className={classes.cellHeadSign} key={row.Id}>
                  <TableCell
                    className={classes.cellText}
                    onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.DBA_Name}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.E_mail}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.Assign_To}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.Ownership_Type}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.Contact_Name}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.Phone}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.Creation_Date}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.Update_Date}
                  </TableCell>

                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                  >
                    {row.status}
                  </TableCell>
                  <TableCell className={classes.cellText}>
                    <LongMenu
                      details={row}
                      provider
                      userOptions={userOptions}
                      {...props}
                      setValues={setValues}
                      openBoardingModel={() => setopenBoardingModel(true)}
                      openForm={() => setOpenModel(true)}
                      saveStatus={() => saveStatus()}
                      setStatusPid={() => setStatusPid({
                        id: row.Id,
                        name: row.DBA_Name,
                        email: row.E_mail,
                        submarchecntId: row.Sub_MerchantID,
                        errorCode: row.Error_Code,
                        errorDescription: row.Error_Description,
                        status: row.status,
                        clientid: row.Client_Id,
                      })}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter className={rowsPerPage > 25 && classes.stickyFooter}>
              <TableRow
                style={{
                  display: rowsPerPage > 25 ? "flex" : "",
                  justifyContent: rowsPerPage > 25 ? "end" : "",
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value:
                        preData && preData.Providers_Data
                          ? props.tableData.providerList.totCount
                          : 0,
                    },
                  ]}
                  colSpan={12}
                  count={props.tableData.providerList.totCount}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } },
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

ProviderReport.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  changeProviderFormMode: PropTypes.func,
  getProviderStatusNormal: PropTypes.func,
  changeSingleStatus: PropTypes.func,
  userDetails: PropTypes.object,
  getProviderByFilterData: PropTypes.func,
  getProviderDetailsById: PropTypes.func,
  mainData: PropTypes.object,
  addSearchData: PropTypes.func,
  removeSearchData: PropTypes.func,
  roles: PropTypes.any,
  getAgentByHierarchy: PropTypes.func,
};

const mapStateToProps = app => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  mainData: app,
  roles: app.app,
});
export default connect(mapStateToProps, {
  changeProviderFormMode,
  getProviderStatusNormal,
  changeSingleStatus,
  getProviderByFilterData,
  getProviderDetailsById,
  addSearchData,
  removeSearchData,
  getAgentByHierarchy,
})(ProviderReport);
