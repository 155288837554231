/* eslint-disable no-param-reassign */
import * as types from "./ActionTypes";
import { post, get, put } from "../Helper/index";
import * as urls from "../../Uintls/Urls";

export const roleRegistration = (data) => (dispatch) => {
  dispatch({ type: types.ROLE_REGISTRATION_CALL });
  post({
    url: urls.ROLE_LIST_URL,
    success: types.ROLE_REGISTRATION_SUCCESS,
    failure: types.ROLE_REGISTRATION_ERROR,
    dispatch,
    body: {
      ClientID: localStorage.getItem("clientId"),
      profileCode: data.Profile_Code,
      RoleName: data.RoleName,
      LandingPageId: data.LandingPageId,
      CloneId: data.CloneId,
      Description: data.Description,
      Accessible_Provider: data.accessible
    }
  });
};

export const updateRoleRegistration = (data, id) => (dispatch) => {
  dispatch({ type: types.ROLE_REGISTRATION_CALL });
  put({
    url: urls.ROLE_LIST_URL,
    success: types.ROLE_REGISTRATION_SUCCESS,
    failure: types.ROLE_REGISTRATION_ERROR,
    dispatch,
    body: {
      role_Id: id,
      Profile_Code: data.Profile_Code,
      RoleName: data.RoleName,
      LandingPageId: data.LandingPageId,
      Description: data.Description,
      Accessible_Provider: data.accessible
    }
  });
};

export const getRoleList = () => (dispatch) => {
  const data = {
    ClientId: localStorage.getItem("clientId"),
    LoginEmail: localStorage.getItem("email")
  };
  dispatch({ type: types.ROLE_LIST_CALL });
  get({
    url: `${urls.ROLE_LIST_URL}`,
    success: types.ROLE_LIST_SUCCESS,
    failure: types.ROLE_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const rolePermisstionRequest = (data, typed) => (dispatch) => {
  const finalData = {
    ...data,
    ClientId: JSON.stringify(localStorage.getItem("clientId")),
    LoginEmail: localStorage.getItem("email")
  };
  dispatch({ type: types.SET_ROLL_PERMISSIONS_CALL });
  get({
    url: `${urls.ROLE_PAGE_PERMISSION}`,
    success:
      typed === "admin"
        ? types.SET_ROLE_DETAILS_DATA
        : types.SET_ROLL_PERMISSIONS_SUCCESS,
    failure: types.SET_ROLL_PERMISSIONS_ERROR,
    dispatch,
    body: finalData
  });
};

export const emptyRolePermission = () => ({
  type: types.SET_ROLL_PERMISSIONS_EMPTY
});

export const updateRolePermisstionRequest = (data) => (dispatch) => {
  dispatch({ type: types.UPDATE_ROLL_PERMISSIONS_CALL });
  put({
    url: urls.ROLE_PAGE_PERMISSION,
    success: types.UPDATE_ROLL_PERMISSIONS_SUCCESS,
    failure: types.UPDATE_ROLL_PERMISSIONS_ERROR,
    dispatch,
    body: data
  });
};

export const getProfileTypeList = (data) => (dispatch) => {
  dispatch({ type: types.PROFILE_TYPE_LIST_CALL });
  get({
    url: urls.COMMON_PROFILE_URL,
    success: types.PROFILE_TYPE_LIST_SUCCESS,
    failure: types.PROFILE_TYPE_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getLandingPageList = (data) => (dispatch) => {
  const value = {
    profileCode: data
  };
  dispatch({ type: types.LANDING_PAGE_LIST_CALL });
  get({
    url: urls.LANDING_PAGE_LIST_URL,
    success: types.LANDING_PAGE_LIST_SUCCESS,
    failure: types.LANDING_PAGE_LIST_ERROR,
    dispatch,
    body: value
  });
};

export const changeRoleFormMode = (data) => ({
  type: types.ROLE_FORM_MODE,
  data
});

export const getRoleDetailsById = (data) => (dispatch) => {
  const value = {
    roleId: data,
    ClientId: localStorage.getItem("clientId"),
    LoginEmail: localStorage.getItem("email")
  };
  dispatch({ type: types.ROLE_LIST_BY_ID_CALL });
  get({
    url: urls.GET_ROLE_LIST_BY_ID,
    success: types.ROLE_LIST_BY_ID_SUCCESS,
    failure: types.ROLE_LIST_BY_ID_ERROR,
    dispatch,
    body: value
  });
};

export const getRoleByFilterData = (data) => (dispatch) => {
  const finalData = {
    ...data,
    ClientId: JSON.stringify(localStorage.getItem("sendApiClientId")),
    LoginEmail: localStorage.getItem("email")
  };
  dispatch({ type: types.ROLE_LIST_BY_FILTERDATA_CALL });
  get({
    url: `${urls.ROLE_LIST_BY_SEARCH_URL}`,
    success: types.ROLE_LIST_BY_FILTERDATA_SUCCESS,
    failure: types.ROLE_LIST_BY_FILTERDATA_ERROR,
    dispatch,
    body: finalData
  });
};

export const roleStatusUpdate = (data) => (dispatch) => {
  dispatch({ type: types.ROLE_STATUS_UPDATE_CALL });
  get({
    url: `${urls.ROLE_STATUS_UPDATE_URL}`,
    success: types.ROLE_STATUS_UPDATE_SUCCESS,
    failure: types.ROLE_STATUS_UPDATE_ERROR,
    dispatch,
    body: data
  });
};

export const setRoleSearchData = (data) => ({
  type: types.ADD_ROLLS_SEARCH_DATA,
  data
});
