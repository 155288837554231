import "../../assets/css/CountryCode.scss";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import deepclone from "lodash/cloneDeep";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/components/userForm";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import isEmpty from "lodash/isEmpty";
import { useLocation } from "react-router-dom";
import {
  updateUserApiCall,
  createNewUserApiCall,
  autoGeneratedRepCode
} from "../../actions/Operations";
import {
  getCountryTypeList,
  getProfileTypeList,
  getRoleList,
  changeUserFormMode,
  getTimeZoneList,
  getUserList,
  getUserListProvider,
  getGroupListData,
  getProviderRoleList,
  ownerTypeList,
  deleteUserDocument,
  getUserByFilterData
} from "../../Redux/Actions/UserRegistration";
import { userListApi } from "../../actions/TableData";
import Validation from "../../Utils/Validations";
import MultipleSelect from "../../components/GroupsMultipleSelect/Index";

const useStyles = makeStyles(styles);

const GreenRadio = withStyles((theme) => ({
  root: {
    "&$checked": {
      color: theme.palette.primary.main
    }
  },
  checked: {}
}))((props) => <Radio color="default" {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function PartnerInvite(props) {
  const classes = useStyles();
  const history = useHistory();
  const filter = createFilterOptions();
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = React.useRef(null);
  const [filterDataRole, setFilterDataRole] = React.useState(false);
  const [userList, setUserList] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    Profile_Code: "Partner",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Email: "",
    Password: "Temp@123#$",
    Role_ID: "5ffed18c0bcf8b38d4052312",
    Accessible_Provider: [props.userRole.userList.ClientID],
    reportsTo: "",
    profileVisibility: "1",
    confirmPassword: "Temp@123#$",
    groupId: [],
    repoCode: "",
    CreateByClientId: localStorage.getItem("userId"),
    ClientID: props.userRole.userList.ClientID,
    Created_By: localStorage.getItem("userId"),
    dialCode: 1
  });

  const autoGeneratedRepCodeCall = () => {
    props.autoGeneratedRepCode(
      (data) => {
        if (data.status) {
          formikRef.current.setFieldValue("repoCode", data.data.newRepoCode);
        }
      },
      (err) => {
        enqueueSnackbar("Failed to auto generate.", {
          variant: "error"
        });
      }
    );
  };

  const handleVisability = (event) => {
    if (event.target.value === "3") {
      formikRef.current.setFieldValue("profileVisibility", "3");
      props.getGroupListData();
    } else {
      formikRef.current.setFieldValue("profileVisibility", event.target.value);
    }
  };

  const handleUserType = (e) => {
    const valueRole = props?.roles?.roleList?.Roles_Data?.filter(
      (v) => v.Profile_Code === e
    );
    setFilterDataRole(valueRole);
    formikRef.current.setFieldValue("Profile_Code", e);
    formikRef.current.setFieldValue("Role_ID", "");
  };

  const handleBack = () => {
    history.goBack();
  };

  const onFormSubmit = (values) => {
    props.createNewUserApiCall(
      { ...values },
      (data) => {
        if (data.status) {
          enqueueSnackbar("Partner successfully created", {
            variant: "success"
          });
          handleBack();
        } else {
          enqueueSnackbar(data.message, {
            variant: "error"
          });
        }
      },
      (err) => {
        console.log(err);
        enqueueSnackbar(err.message, {
          variant: "error"
        });
      }
    );
  };

  const profileCode =
    props?.userRole?.userList &&
    props?.userRole?.userList?.Role_Permission_Data &&
    props?.userRole?.userList?.Role_Permission_Data?.Profile_Code;

  React.useEffect(() => {
    if (!props.formData.profileTypeList && profileCode !== false) {
      props.getProfileTypeList(profileCode);
    }
  }, [profileCode]);

  React.useEffect(() => {
    if (!props.formData.roleList) {
      props.getRoleList();
    }
  }, []);

  React.useEffect(() => {
    const valueRole = props?.roles?.roleList?.Roles_Data?.filter(
      (v) => v.Profile_Code === "Partner"
    );
    setFilterDataRole(valueRole);
  }, [props.formData.roleList]);

  const callUserListApi = () => {
    props.userListApi(
      {
        ClientId: localStorage.getItem("clientId"),
        LoginEmail: localStorage.getItem("email"),
        formType: "reportsTo"
      },
      (data) => {
        setUserList(data.Users_Data);
      }
    );
  };

  React.useEffect(() => {
    callUserListApi();
    if (props.formData.formMode !== "Create") {
      props.changeUserFormMode("Create");
    }
  }, []);

  const defaultProps = {
    options: userList
  };

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: "white",
        paddingTop: "20px",
        borderRadius: "5px"
      }}
    >
      <Box className={classes.marginBox}>
        <Formik
          innerRef={(p) => {
            formikRef.current = p;
          }}
          onSubmit={onFormSubmit}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Validation.parterInvite}
        >
          {({ errors, setFieldValue, values, touched, handleSubmit }) => (
            <Form>
              <Grid container className={classes.margin}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={touched.Profile_Code && errors.Profile_Code}
                    className={classes.halfInput}
                    onChange={(e) => handleUserType(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Choose User Type"
                    name="Profile_Code"
                    id="Profile_Code"
                    select
                    style={{ height: "5.187em" }}
                    helperText={
                      touched.Profile_Code && errors.Profile_Code
                        ? errors.Profile_Code
                        : ""
                    }
                  >
                    {props.formData.profileTypeList &&
                      props.formData?.profileTypeList?.Profile_Data.map(
                        (key) => {
                          return (
                            <MenuItem
                              className={classes.dialogBoxDropDown}
                              value={key.Profile_Code}
                            >
                              {key.ProfileName}
                            </MenuItem>
                          );
                        }
                      )}
                  </Field>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.repoCode && touched.repoCode}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            style={{ marginBottom: "5px" }}
                            size="small"
                            disabled={props.formData.formMode !== "Create"}
                            aria-label="toggle password visibility"
                            onClick={() => autoGeneratedRepCodeCall()}
                          >
                            Auto Generate
                          </Button>
                          <Divider
                            variant="middle"
                            style={{ height: 18, margin: 4 }}
                            orientation="vertical"
                          />
                          <Tooltip
                            style={{ marginBottom: "5px" }}
                            title="Can be Entered manually Or You can auto-Generated"
                            classes={{
                              tooltip: classes.customTooltip
                            }}
                          >
                            <ErrorOutlineIcon />
                          </Tooltip>
                        </InputAdornment>
                      ),
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="User Code"
                    onChange={(e) => {
                      setFieldValue("repoCode", e.target.value.trim());
                    }}
                    name="repoCode"
                    id="repoCode"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.repoCode && touched.repoCode ? errors.repoCode : ""
                    }
                  />
                </Grid>
                <Grid item xs />
              </Grid>
              <Grid container className={classes.margin}>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.First_Name && touched.First_Name}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="First Name"
                    name="First_Name"
                    helperText={
                      errors.First_Name && touched.First_Name
                        ? errors.First_Name
                        : ""
                    }
                    id="First_Name"
                    style={{ height: "5.187em" }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    error={errors.Middle_Name && touched.Middle_Name}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="Middle Name"
                    name="Middle_Name"
                    id="Middle_Name"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.Middle_Name && touched.Middle_Name
                        ? touched.Middle_Name
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.Last_Name && touched.Last_Name}
                    className={classes.halfInput}
                    InputLabelProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="Last Name"
                    name="Last_Name"
                    id="Last_Name"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.Last_Name && touched.Last_Name
                        ? errors.Last_Name
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.Email && touched.Email}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="Email"
                    name="Email"
                    id="Email"
                    style={{ height: "5.187em" }}
                    helperText={
                      errors.Email && touched.Email ? errors.Email : ""
                    }
                    w
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Field
                    as={TextField}
                    required
                    error={errors.Role_ID && touched.Role_ID}
                    className={classes.halfInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Role"
                    name="Role_ID"
                    id="Role_ID"
                    select
                    helperText={
                      errors.Role_ID && touched.Role_ID ? errors.Role_ID : ""
                    }
                    disabled={isEmpty(initialValues.Profile_Code)}
                    style={{ height: "5.187em" }}
                  >
                    {filterDataRole &&
                      filterDataRole.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key._id}
                        >
                          {key.RoleName}
                        </MenuItem>
                      ))}
                  </Field>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Autocomplete
                    {...defaultProps}
                    className={classes.halfInput}
                    name="reportsTo"
                    id="reportsTo"
                    label="Reports To"
                    value={
                      values?.reportsTo &&
                      userList?.find((option) =>
                        option?.UserID === values?.reportsTo
                          ? values?.reportsTo
                          : ""
                      )
                    }
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      // Suggest the creation of a new value
                      if (params.inputValue !== "") {
                        filtered.unshift(params.inputValue);
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      const labelName =
                        option.FName && option.LName !== undefined
                          ? `${option.FName} ${option.LName}`
                          : "";
                      return labelName;
                    }}
                    renderOption={(option) => (
                      <>
                        {option.FName && option.LName && option.Email && (
                          <span
                            className={classes.dialogBoxDropDown}
                            style={{
                              width: "100%",
                              margin: "-5px -16px",
                              padding: "4px 16px"
                            }}
                          >
                            {option.FName} {option.LName} [{option.Email}]
                          </span>
                        )}
                      </>
                    )}
                    onChange={(event, value) => {
                      if (value && value.UserID) {
                        setFieldValue("reportsTo", value.UserID);
                      }
                    }}
                    renderInput={(params) => (
                      <Field
                        component={TextField}
                        {...params}
                        label="Reports to"
                        required
                        error={errors.reportsTo && touched.reportsTo}
                        style={{ height: "5.187em" }}
                        helperText={
                          errors.reportsTo && touched.reportsTo
                            ? errors.reportsTo
                            : ""
                        }
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "20px" }} />
              <Grid container className={classes.margin}>
                <Grid item xs>
                  <Box height={40} className={classes.radioBox}>
                    <div
                      style={{ marginRight: "2%" }}
                      className={classes.radioBoxRoot}
                    >
                      <Typography className={classes.textColorRadioCard}>
                        Profile Visibility :
                      </Typography>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <GreenRadio
                        className={classes.checkBox}
                        checked={values.profileVisibility === "1"}
                        onChange={handleVisability}
                        value="1"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "1" }}
                        style={{ marginRight: "10px" }}
                      />
                      <Typography className={classes.textColorRadioCard}>
                        Own
                      </Typography>
                    </div>
                    <div
                      style={{ marginRight: "2%" }}
                      className={classes.radioBoxRoot}
                    >
                      <GreenRadio
                        checked={values.profileVisibility === "2"}
                        onChange={handleVisability}
                        className={classes.checkBox}
                        value="2"
                        style={{ marginRight: "10px" }}
                        inputProps={{ "aria-label": "2" }}
                      />
                      <Typography className={classes.textColorRadioCard}>
                        Own and Persons Directly reporting to me
                      </Typography>
                    </div>
                    <div
                      style={{ marginRight: "2%" }}
                      className={classes.radioBoxRoot}
                    >
                      <GreenRadio
                        checked={values.profileVisibility === "3"}
                        onChange={handleVisability}
                        className={classes.checkBox}
                        value="3"
                        style={{ marginRight: "10px" }}
                        inputProps={{ "aria-label": "3" }}
                      />
                      <Typography className={classes.textColorRadioCard}>
                        Groups
                      </Typography>
                    </div>
                  </Box>
                  <div style={{ textAlign: "center", textAlignLast: "center" }}>
                    {isEmpty(values.profileVisibility) ? (
                      <Typography
                        component="span"
                        className="error"
                        variant="body2"
                      >
                        {errors.profileVisibility}
                      </Typography>
                    ) : isEmpty(values.groupId) &&
                      values.profileVisibility === "3" ? (
                      <Typography
                        component="span"
                        className="error"
                        variant="body2"
                      >
                        Please select at least 1 group to continue.
                      </Typography>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
              </Grid>
              {values.profileVisibility === "3" && (
                <Grid container className={classes.margin}>
                  <Grid item xs textAlign="right" sx={{ border: 1 }}>
                    <MultipleSelect
                      {...props}
                      onChange={(event) => {
                        setFieldValue("groupId", event);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container className={classes.margin}>
                <Grid item lg={9} md={9} sm={8} xs={0} />
                <Grid item lg={3} md={3} sm={4} xs={12} textAlign="right">
                  <Box className={classes.buttonBox} textAlign="right">
                    <Button
                      variant="contained"
                      className={classes.clearButton}
                      onClick={() => handleBack()}
                    >
                      Back
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      disabled={
                        values.profileVisibility === "3" &&
                        isEmpty(values.groupId)
                      }
                      className={classes.buttonStyle}
                      onClick={() => {
                        handleSubmit();
                      }}
                      style={{ alignItems: "right" }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
}

PartnerInvite.propTypes = {
  formData: PropTypes.object,
  getCountryTypeList: PropTypes.func,
  getProfileTypeList: PropTypes.func,
  getRoleList: PropTypes.func,
  getTimeZoneList: PropTypes.func,
  changeUserFormMode: PropTypes.func,
  getUserList: PropTypes.func,
  getProviderRoleList: PropTypes.func,
  userRole: PropTypes.object,
  getUserListProvider: PropTypes.func,
  roles: PropTypes.object,
  userListApi: PropTypes.func,
  getGroupListData: PropTypes.func,
  autoGeneratedRepCode: PropTypes.func,
  updateUserApiCall: PropTypes.func,
  createNewUserApiCall: PropTypes.func,
  ownerTypeList: PropTypes.func,
  handleUploadOnChange: PropTypes.func,
  handleDeleteUpload: PropTypes.func,
  deleteUserDocument: PropTypes.func,
  getUserByFilterData: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.users,
  userRole: app.userRole,
  roles: app.roles
});
export default connect(mapStateToProps, {
  updateUserApiCall,
  createNewUserApiCall,
  getCountryTypeList,
  getProfileTypeList,
  getRoleList,
  ownerTypeList,
  autoGeneratedRepCode,
  userListApi,
  changeUserFormMode,
  getTimeZoneList,
  getUserList,
  getUserListProvider,
  getProviderRoleList,
  getGroupListData,
  deleteUserDocument,
  getUserByFilterData
})(PartnerInvite);
