/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import isEmpty from "lodash/isEmpty";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { exportToCSV } from "../../Utils/commonUtils";
import noDataImage from "../../assets/img/noDataImage.png";
import { dailyDeposite, activePatient } from "../../Redux/Actions/TableAction";
import { SimpleDialog } from "../../components/DialogBox/DailyDeposits";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { getAgentByHierarchy } from "../../Redux/Actions/ProvideRegistration";

const useStyles = makeStyles(tableStyle);

function createData(
  providerId,
  mid,
  userCode,
  dbaName,
  cryptoName,
  cryptoUnits,
  cryptoValue,
  transactionDate,
  card,
  amount,
  countTxn,
  id
) {
  return {
    providerId,
    mid,
    userCode,
    dbaName,
    cryptoName,
    cryptoUnits,
    cryptoValue,
    transactionDate,
    card,
    amount,
    countTxn,
    id
  };
}

// const rows = [];

// dailyDeposite.Daily_deposite_Data.forEach((v) => {
//     const ehrId = v.EHR_ID;
//     const patientName = v.Patient_Name;
//     const paymentDate = v.Payment_Date;
//     const paymentAmount = v.Payment_amt;
//     const transactioId = v.Transaction_id;
//     rows.push(createData(providerId, ehrId, patientName, paymentDate, paymentAmount, transactioId));
// } );

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography>First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography>Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography>Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography>Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

function EnhancedTableHead(props) {
  const {
    classes,
    sortOrder,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    numSelected,
    rowCount,
    recordPerPage,
    page
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "MID",
      numeric: false,
      disablePadding: true,
      label: "MID"
      // headClassName: classes.DBA_Name,
    },
    {
      id: "userCode",
      numeric: false,
      disablePadding: true,
      label: "User Code"
      // headClassName: classes.E_mail,
    },
    {
      id: "DBA_Name",
      numeric: false,
      disablePadding: true,
      label: "DBA Name"
      // headClassName: classes.DBA_Name,
    },
    {
      id: "Crypto_Name",
      numeric: false,
      disablePadding: false,
      label: "Crypto Name"
      // headClassName: classes.Crypto_Name,
    },
    {
      id: "Crypto_Units",
      numeric: false,
      disablePadding: false,
      label: "Crypto Units"
      // headClassName: classes.OwnershipType,
    },
    {
      id: "cryptoValue",
      numeric: true,
      disablePadding: false,

      label: "Exchange Rate"

      // headClassName: classes.Phone,
    },
    {
      id: "Transaction_Date",
      numeric: true,
      disablePadding: false,
      label: "Transaction Date"
      // headClassName: classes.Creation_Date,
    },
    {
      id: "card",
      numeric: true,
      disablePadding: false,
      label: "Wallet Address"
      // headClassName: classes.Update_Date,
    },
    {
      id: "Amount",
      numeric: false,
      disablePadding: false,
      label: "Amount",
      headClassName: classes.amount
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "status",
      headClassName: classes.status
    }
  ];

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? sortOrder : false}
          >
            <TableSortLabel
              active={headCell.id === "transactionDate"}
              direction={orderBy === headCell.id ? sortOrder : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  sortOrder: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const DailyDeposite = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [initialCall, setInitialCall] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState("desc");
  const [sortColumn, setSortColumn] = React.useState("Transaction_Date");
  const [orderBy, setOrderBy] = React.useState("Transaction_Date");
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    if (!props.tableData.dailyDeposite) {
      props.dailyDeposite();
    }
  }, []);

  React.useEffect(() => {
    if (props.tableData.dailyDeposite?.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData]);

  let userid = localStorage.getItem("userId");
  React.useEffect(() => {
    const data = {
      userID: userid
    };
    if (!props.tableData.agentList) {
      props.getAgentByHierarchy(data);
    }
  }, []);

  const rows = [];
  if (props.tableData.dailyDeposite && props.tableData.dailyDeposite.data) {
    props.tableData.dailyDeposite.data.forEach((v, i) => {
      const providerId = v.Provider_Id;
      const mid = v.MID;
      const userCode = v.userCode;
      const dbaName = v.DBA_Name;
      const cryptoName = v.Crypto_Name;
      const cryptoUnits = v.Crypto_Units;
      const cryptoValue = v.Crypto_Value;
      const transactionDate = moment(v.Transaction_Date).format("MM/DD/YYYY");
      const card = v.Card;
      const amount = v.Amount;
      const countTxn = v.countTxn;
      const id = v.Transaction_Id;
      rows.push(
        createData(
          providerId,
          mid,
          userCode,
          dbaName,
          cryptoName,
          cryptoUnits,
          cryptoValue,
          transactionDate,
          card,
          amount,
          countTxn,
          id
        )
      );
    });
  }

  const exportListData = () => {
    if (props.tableData.activePatient && props.tableData.activePatient.data) {
      const mappedLogs = props.tableData.activePatient.data.map((row) => ({
        MID: row.MID,
        "User Code": row.userCode,
        "DBA Name": row.DBA_Name,
        "Crypto Name": row.Crypto_Name,
        "Crypto Units": row.Crypto_Units,
        "Crypto Value ($)": row.Crypto_Value,
        Amount: row.Amount,
        "Wallet Address": row.Card,
        "Transaction Date": row.Transaction_Date,
        "Transaction Id": row.Transaction_Id,
        "Provider Id": row.Provider_Id
      }));
      exportToCSV(mappedLogs, "Daily Deposit List");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onClickTransactionHandle = (mid) => {
    const data = {
      loginEmail: localStorage.getItem("email"),
      mid
    };
    props.activePatient(data);
    props.history.push("/admin/Transactions");
  };

  if (rows.length === 1) {
    onClickTransactionHandle(rows[0].mid);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => handleClose()}
                >
                  <Box style={{ width: "35%" }}>
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      inputProps={{
                        autoComplete: "off"
                      }}
                      fullWidth
                      label="Search"
                      onClick={() => handleClickOpen()}
                      name="Search"
                      id="Search"
                    />
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className={classes.overLayBox}
                    >
                      <SimpleDialog close={() => handleClose()} />
                    </Collapse>
                  </Box>
                </ClickAwayListener>
                <Button
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  onClick={() => {}}
                >
                  <Search />
                </Button>
                <Button
                  // aria-controls="simple-menu"
                  // aria-haspopup="true"
                  onClick={exportListData}
                  variant="contained"
                  className={classes.ExportButtonStyle}
                >
                  Export
                </Button>
                {/* <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={handleCloseMenu}>As Pdf</MenuItem>
                  <MenuItem onClick={handleCloseMenu}>My account</MenuItem>
                </Menu> */}
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "85%" }}>
                  <TextField
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    inputProps={{
                      autoComplete: "off"
                    }}
                    fullWidth
                    label="Search"
                    onClick={() => handleClickOpen()}
                    name="Search"
                    id="Search"
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog close={() => handleClose()} />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <Button
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </Button>
            </Grid>
            <Grid item className={classes.expirtItemGrid}>
              <Button
                // aria-controls="simple-menu"
                // aria-haspopup="true"
                onClick={exportListData}
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
              >
                Export
              </Button>
              {/* <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleCloseMenu}>As Excel</MenuItem>
              </Menu> */}
            </Grid>
          </Grid>
        )}
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            sortOrder={sortOrder}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {(!isEmpty(rows) && initialCall) ||
            (isEmpty(rows) && !initialCall) ? (
              (rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row) => (
                <TableRow>
                  <TableCell
                    className={classes.patientNameColor}
                    onClick={() => onClickTransactionHandle(row.mid)}
                    style={{ cursor: "pointer" }}
                  >
                    {row.mid}
                  </TableCell>
                  <TableCell className={classes.cellText}>
                    {row.userCode}
                  </TableCell>
                  <TableCell className={classes.cellText}>
                    {row.dbaName}
                  </TableCell>
                  <TableCell className={classes.cellText}>
                    {row.countTxn}
                  </TableCell>
                  <TableCell className={classes.cellText}>
                    {row.cryptoName}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.cryptoUnits}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.cryptoValue}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.transactionDate}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.amount}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={12}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                // SelectProps={{
                //   inputProps: { placeholder: "Show" },
                //   native: true,
                // }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

DailyDeposite.propTypes = {
  dailyDeposite: PropTypes.func,
  activePatient: PropTypes.func,
  tableData: PropTypes.any,
  getAgentByHierarchy: PropTypes.func
};

const mapStateToProps = (table) => ({
  tableData: table.table
});
export default connect(mapStateToProps, {
  dailyDeposite,
  activePatient,
  getAgentByHierarchy
})(DailyDeposite);
